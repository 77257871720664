import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EditButton, FaEyeButton } from 'UIs/Buttons';
import { TRLoader } from 'UIs/SkeletonLoader';
import { Pagination } from 'Plugins';
import fns from 'helpers/functions';
import * as OrgService from 'Services/organisation.service';
import { t } from 'i18next';
import { SimpleWhite } from 'components/interfaces/Buttons';

const Organisations = () => {
    const user = fns.LOGGED_USER();
    const navigate = useNavigate();
    const [organisations, setOrganisations] = useState(null);
    const [fetching, setFetching] = useState(true);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fns.CHECK_PERMISSION(["C1", "C3"], true);

        fetchOrganisations();

        return () => {
        }
    }, []);

    /** Fetch list of organisations */
    const fetchOrganisations = async (page = 1) => {
        const res = await OrgService.FetchOrgsAPI('', page, sizePerPage);

        if (res.response) {
            setFetching(false);
            setOrganisations(res.data.data);
            setTotalCount(res.data.totalSize);
        } else {
            setFetching(false);
            setOrganisations(null);
            setTotalCount(0);
        }
    }

    return (
        <>
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>


                    <div className='sm:w-11/12 w-full mx-auto px-5 py-5 pt-16 xl:pt-0 xl:pl-64 relative'>
                        <h3 className='absolute -left-[4.6%] top-0 bg-blue text-white text-center text-xs p-5 w-80 mr-4 uppercase'>Participants</h3>
                        <h4 className='float-left text-blue text-lg sm:p-3 py-3 leading-7 font-Averta_Semibold uppercase'>
                            {/* <span className='ml-5'>{user?.organization_name}</span> */}
                        </h4>
                        {/*<Link to={`/concierge/organisation/create`} className='float-right text-xs rounded-xl text-burgandy hover:text-blue font-Averta_Semibold sm:flex sm:justify-end sm:items-end bg-blue hover:bg-burgandy px-12 py-3 capitalize'>Add New Participant</Link>*/ }
                        
                        <SimpleWhite onClick={() => { navigate(`/concierge/organisation/create`); }} name={'Add New Participant'} />                        
                        
                        <div className='clear-both'></div>
                    </div>

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>
                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <div className='w-full float-left pl-16'>
                                            <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                                <div className="flex flex-col">
                                                    <div className="overflow-x-auto">
                                                        <div className="py-2 inline-block min-w-full">
                                                            <div className="overflow-x-auto mb-2">
                                                                {
                                                                    fetching
                                                                        ?
                                                                        <TRLoader count={10} />
                                                                        :
                                                                        (
                                                                            organisations && organisations.length > 0
                                                                                ?
                                                                                <table className="min-w-full text-black">
                                                                                    <thead className="">
                                                                                        <tr>
                                                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">FSP No.</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Reg No.</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">VAT No</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Institution Name</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Contact Person</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Email</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Status</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-right">Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className=''>
                                                                                        {
                                                                                            organisations
                                                                                            &&
                                                                                            organisations.map((organisation, index) => {
                                                                                                return (
                                                                                                    <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                                            {organisation.fsp_number}
                                                                                                        </td>
                                                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                                            {organisation.reg_credit_provider}
                                                                                                        </td>
                                                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                                            {organisation.vat_num ?? 'N/A'}
                                                                                                        </td>
                                                                                                        <td className="text-xs px-4 py-2 font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                                            {organisation.institution_name}
                                                                                                        </td>
                                                                                                        <td className="text-xs px-4 py-2 font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                                            {organisation.contact_person ?? 'N/A'}
                                                                                                        </td>
                                                                                                        <td className="text-xs px-4 py-2 font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit text-[#1EC465]">
                                                                                                            {
                                                                                                                organisation?.contact_email
                                                                                                                    ?
                                                                                                                    <a href={`mailto:${organisation.contact_email}`}>{organisation.contact_email}</a>
                                                                                                                    :
                                                                                                                    'N/A'
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td className={`text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit text-[#${(organisation.contact_email != "" && organisation.status != 0) ? '1EC465' : 'EB5757'}]`}>
                                                                                                            {(organisation.contact_email != "" && organisation.status != 0) ? 'Activated' : 'In Draft'}
                                                                                                        </td>
                                                                                                        <td className="text-xs px-4 py-2 text-right font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                                            {
                                                                                                                (organisation.contact_email != "" && organisation.status != 0) ? <button onClick={() => { navigate(`/concierge/organisation/${organisation._id}/details`, { replace: false, state: { institution_name: organisation.institution_name } }); }} className='mx-2 hover:text-burgandy'><FaEyeButton /></button> : null
                                                                                                            }
                                                                                                            <Link className='hover:text-burgandy' to={`/concierge/organisation/${organisation._id}/business-details`}><EditButton /></Link>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                :
                                                                                <p className='text-center text-[14px] mt-2'>No participants are onboarded yet.</p>
                                                                        )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    total_count > sizePerPage
                                                    &&
                                                    <div className="float-left w-full">
                                                        <div className="text-center float-left w-full">
                                                            <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchOrganisations(data.selected + 1) }} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='clear-both'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>

                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    );
}

export default Organisations