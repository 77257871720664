import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const TRLoader = ({ count }) => {
    return (
        <Skeleton
            count={count}
            style={{
                lineHeight: 2,
                padding: '0.5rem',
                marginBottom: '0.5rem',
                width: '100%',
            }}
        />
    )
}

export const ButtonLoader = ({ count, width, height }) => {
    return (
        <Skeleton className='float-right xl:-mr-80 mt-5 py-3 px-12 rounded-xl text-sm w-[40%]' />
    )
}

export const ButtonCLoader = ({ classd }) => {
    return (
        <Skeleton className={classd} />
    )
}

export const LineLoader = ({ count, width, height }) => {
    return (
        <Skeleton
            count={count}
            style={{
                lineHeight: 1,
                padding: '0.5rem',
                marginBottom: '0.4rem',
                width: width,
            }}
        />
    )
}

export const SmLineLoader = ({ count, width, height }) => {
    return (
        <Skeleton
            count={count}
            style={{
                lineHeight: 1,
                padding: '0.4rem',
                marginBottom: '0.5rem',
                width: width,
            }}
        />
    )
}

export const RoundedInputLoader = ({ count }) => {
    return (
        <Skeleton
            count={count}
            className='w-full px-6 rounded-full py-3 pb-4 pt-2 peer'
        />
    )
}

export const ButtonRect = ({ count }) => {
    return (
        <Skeleton
            count={count}
            className='py-1 px-3 w-[130px]'
        />
    )
}

export const InputRect = ({ count }) => {
    return (
        <Skeleton
            count={count}
            className='w-full bg-white/25 shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg'
        />
    )
}

export const BlockLoader = ({ count, width, height }) => {
    return (
        <Skeleton
            count={count}
            style={{
                padding: '0.5rem',
                marginBottom: '0.4rem',
                width: width,
                height: height,
            }}
        />
    )
}