import fns from 'helpers/functions'
import React from 'react'

const UnavailableOffer = ({ offer }) => {
    return (
        <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 text-black">
            <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">N/A</td>
            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">N/A</td>
            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">N/A</td>
            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">N/A</td>
            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">N/A</td>
            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                <svg className='hover:stroke-burgandy m-auto cursor-pointer' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_253_19391)">
                        <path d="M10.59 10.59C10.384 10.811 10.1356 10.9883 9.85961 11.1113C9.58362 11.2343 9.28568 11.3004 8.98357 11.3057C8.68146 11.3111 8.38137 11.2555 8.10121 11.1423C7.82104 11.0292 7.56654 10.8607 7.35288 10.6471C7.13923 10.4334 6.97079 10.1789 6.85763 9.89875C6.74447 9.61859 6.68889 9.3185 6.69423 9.01639C6.69956 8.71428 6.76568 8.41634 6.88866 8.14034C7.01163 7.86435 7.18894 7.61594 7.41 7.40996M13.455 13.455C12.1729 14.4322 10.6118 14.9736 9 15C3.75 15 0.75 8.99996 0.75 8.99996C1.68292 7.26138 2.97685 5.74241 4.545 4.54496L13.455 13.455ZM7.425 3.17996C7.94125 3.05912 8.4698 2.99871 9 2.99996C14.25 2.99996 17.25 8.99996 17.25 8.99996C16.7947 9.85166 16.2518 10.6535 15.63 11.3925L7.425 3.17996Z" stroke="#160B32" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M0.75 0.749939L17.25 17.2499" stroke="#160B32" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_253_19391">
                            <rect width="18" height="18" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </td>
            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit text-[#818181]">N/A</td>
            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                <div className={`w-[100px] bg-gradient-to-r from-[${fns.GET_COLOR_GRADIANT_REV(offer?._lock_expiry_days)}] h-[7px] block m-auto mb-1`}></div>
                <span className='text-[8px] block'>{offer?._lock_expiry_days} {offer?._lock_expiry_days <= 1 ? 'day' : 'days'} left</span>
            </td>
        </tr>
    )
}

export default UnavailableOffer