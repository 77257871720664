import axios from "axios";

const END_POINT = "https://jsonplaceholder.typicode.com";

export const TestGetAPI = async () => {
    const data = await axios.get(`${END_POINT}/posts/1`).then((response) => {
        return response.data;
    }).catch(function (error) {
        console.log(`TestGet: ${error.message}`);
        return null;
    });

    return data;
}