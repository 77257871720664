import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import fns from 'helpers/functions';
import constants from 'helpers/constants';
import QuickMenus from './../QuickMenus';
import * as ManageOfferService from 'Services/manage-offers.service';
import { Modal } from 'flowbite-react';
import LockedOfferRow from './LockedOfferRow';
import UnavailableOffer from './UnavailableOffer';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'modules/fragments';

const SearchOffers = () => {
    const [search_result, setSearchResult] = useState(null);
    const [searched, setSearched] = useState(null);

    useEffect(() => {
        fns.CHECK_PERMISSION(["B1", "B2", "B3"], true);

        return () => {
        }
    }, []);


    return (
        !searched
            ?
            <SearchForm setSearchResult={setSearchResult} setSearched={setSearched} />
            :
            <SearchResult searched={searched} result={search_result} />
    );
};

export default SearchOffers;

const SearchForm = ({ setSearchResult, setSearched }) => {
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const { register, handleSubmit, formState: { errors }, setError, watch } = useForm();

    const SearchOffer = async (form) => {
        var search_term = form.id_number.trim();
        var search_type = "id_number";

        if (form.id_number.trim() === '' && form.passport.trim() !== '') {
            search_term = form.passport.trim();
            search_type = "passport";
        } else if (form.id_number.trim() === '') {
            setError("id_number", { type: "focus", message: 'Please enter ID number or passport.' }, { shouldFocus: true });
            return false;
        }

        const formx = {
            search_term: search_term,
            search_type: search_type
        };

        setProcessing(true);
        const res = await ManageOfferService.SearchOffersAPI(formx);

        if (res.response) {
            if (res.data?.locked_offers?.data.length <= 0 && res.data?.open_offers?.data.length <= 0 && res.data?.unavailable_offers?.data.length <= 0) {
                navigate(`/create-negotiation-request`, { replace: true, state: { formx: formx } });
            }

            setSearchResult(res.data);
            setSearched(formx);
            setProcessing(false);
        } else {
            fns.TOAST_ERR(res.errors);
            setSearchResult(null);
            setProcessing(false);
        }
    }

    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] w-full float-left font-Averta m-0">
                <div className='w-full float-left relative'>
                    <img className='w-full float-left h-screen md:max-h-screen object-cover' src={constants.UPLOAD_BANNER_SEC} alt="" />
                    {/* 3.2 Search the Hub */}
                    <div className=''>
                        <h3 className='absolute top-5 left-0 float-left bg-blue text-white text-right text-xs p-5 w-80'>NEW CUSTOMER SEARCH</h3>
                        <div className="xl:w-5/12 w-12/12 mx-auto px-5 py-5 absolute inset-x-0 top-2/4 -translate-y-2/4">
                            <div className="m-auto w-full">
                                <form onSubmit={handleSubmit(SearchOffer)}>
                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-7 px-4 sm:px-16 pb-7">
                                        <div className="flex flex-col mb-6">
                                            <div className="relative w-full md:w-10/12 m-auto mb-5">
                                                <input
                                                    {...register("id_number", {
                                                        pattern: {
                                                            value: /^[0-9]{6}[0-9]{4}[0-9]{2}[0-9]{1}$/,
                                                            message: "Please enter valid ID number. (E.g. : 9405105001011)"
                                                        },
                                                        validate: (val: string) => {
                                                            if (watch('passport').trim() === '' && val.trim() === '') {
                                                                return 'Please enter ID number or passport.'
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    className="w-full placeholder:text-[#818181] focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none shadow-md shadow-black/25 text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-blue pl-14"
                                                    placeholder="Customer ID Number"
                                                />
                                                <svg className="absolute left-2 top-1" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_474_379)">
                                                        <path d="M24.5417 22.1667H23.2908L22.8475 21.7392C24.3992 19.9342 25.3333 17.5908 25.3333 15.0417C25.3333 9.3575 20.7258 4.75 15.0417 4.75C9.3575 4.75 4.75 9.3575 4.75 15.0417C4.75 20.7258 9.3575 25.3333 15.0417 25.3333C17.5908 25.3333 19.9342 24.3992 21.7392 22.8475L22.1667 23.2908V24.5417L30.0833 32.4425L32.4425 30.0833L24.5417 22.1667ZM15.0417 22.1667C11.0992 22.1667 7.91667 18.9842 7.91667 15.0417C7.91667 11.0992 11.0992 7.91667 15.0417 7.91667C18.9842 7.91667 22.1667 11.0992 22.1667 15.0417C22.1667 18.9842 18.9842 22.1667 15.0417 22.1667Z" fill="#818181" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_474_379">
                                                            <rect width="38" height="38" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                {
                                                    errors.id_number
                                                        ?
                                                        <>
                                                            <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                {errors.id_number && errors.id_number.type !== "required" && errors.id_number.message}
                                                            </span>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                            <div className="relative w-full md:w-10/12 m-auto mb-5 text-center text-blue font-Averta_Semibold">
                                                or
                                            </div>
                                            <div className="relative w-full md:w-10/12 m-auto mb-5">
                                                <input
                                                    {...register("passport", {
                                                        minLength: {
                                                            value: 8,
                                                            message: "Passport length must be 8 characters."
                                                        },
                                                        maxLength: {
                                                            value: 8,
                                                            message: "Passport length must be 8 characters."
                                                        },
                                                        pattern: {
                                                            value: /^[A-Z]{1,2}[0-9]{6,7}$/,
                                                            message: "Passport must be alpha-numberic value. (E.g. : A1234567)"
                                                        },
                                                        validate: (val: string) => {
                                                            if (watch('id_number').trim() === '' && val.trim() === '') {
                                                                return 'Please enter Passport or ID number.'
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    className="w-full placeholder:text-[#818181] focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none shadow-md shadow-black/25 text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-blue pl-14"
                                                    placeholder="Customer Passport Number"
                                                />
                                                <svg className="absolute left-2 top-1" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_474_379)">
                                                        <path d="M24.5417 22.1667H23.2908L22.8475 21.7392C24.3992 19.9342 25.3333 17.5908 25.3333 15.0417C25.3333 9.3575 20.7258 4.75 15.0417 4.75C9.3575 4.75 4.75 9.3575 4.75 15.0417C4.75 20.7258 9.3575 25.3333 15.0417 25.3333C17.5908 25.3333 19.9342 24.3992 21.7392 22.8475L22.1667 23.2908V24.5417L30.0833 32.4425L32.4425 30.0833L24.5417 22.1667ZM15.0417 22.1667C11.0992 22.1667 7.91667 18.9842 7.91667 15.0417C7.91667 11.0992 11.0992 7.91667 15.0417 7.91667C18.9842 7.91667 22.1667 11.0992 22.1667 15.0417C22.1667 18.9842 18.9842 22.1667 15.0417 22.1667Z" fill="#818181" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_474_379">
                                                            <rect width="38" height="38" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                {
                                                    errors.passport
                                                        ?
                                                        <>
                                                            <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                {errors.passport && errors.passport.type !== "required" && errors.passport.message}
                                                            </span>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                        </div>

                                        <div className="flex flex-col mb-6">
                                            <div className='w-full pb-5'>
                                                <div className="relative float-left max-w-[45px] w-2/12 mr-2 align-middle select-none transition duration-200 ease-in">
                                                    <input
                                                        {...register("term1", { required: "required" })}
                                                        type="checkbox"
                                                        id="toggle"
                                                        className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-[#C4C4C4] border-2 border-[#F2F2F2] appearance-none cursor-pointer -mt-0.5"
                                                    />
                                                    <label htmlFor="toggle" className="toggle-label w-[45px] block overflow-hidden h-4 rounded-full bg-[#EB5757] cursor-pointer"></label>
                                                    <div className='clear-both'></div>
                                                </div>
                                                <label htmlFor="toggle" className="cursor-pointer text-xs float-left w-10/12 pl-3">I have Customer Consent to make this search</label>
                                                <div className='clear-both'></div>
                                            </div>
                                            <div className='w-full pb-5'>
                                                <div className="relative float-left max-w-[45px] w-2/12 mr-2 mt-1 align-middle select-none transition duration-200 ease-in">
                                                    <input
                                                        {...register("term2", { required: "required" })}
                                                        type="checkbox"
                                                        id="toggle1"
                                                        className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-[#C4C4C4] border-2 border-[#F2F2F2] appearance-none cursor-pointer -mt-0.5"
                                                    />
                                                    <label htmlFor="toggle1" className="toggle-label w-[45px] block overflow-hidden h-4 rounded-full bg-[#EB5757] cursor-pointer"></label>
                                                    <div className='clear-both'></div>
                                                </div>
                                                <label htmlFor="toggle1" className="cursor-pointer text-xs float-left w-10/12 pl-3">I have read and accepted the Terms and Conditions of SettleHub & publishing an Offer on SettleHub <Link target="_blank" to={`/page/terms-conditions`} className='font-Averta_Semibold text-blue hover:text-burgandy'>here</Link></label>
                                                <div className='clear-both'></div>
                                            </div>
                                            <div className='w-full pb-0'>
                                                <div className="relative float-left max-w-[45px] w-2/12 mr-2 align-middle select-none transition duration-200 ease-in">
                                                    <input
                                                        {...register("term3", { required: "required" })}
                                                        type="checkbox"
                                                        id="toggle2"
                                                        className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-[#C4C4C4] border-2 border-[#F2F2F2] appearance-none cursor-pointer -mt-0.5"
                                                    />
                                                    <label htmlFor="toggle2" className="toggle-label w-[45px] block overflow-hidden h-4 rounded-full bg-[#EB5757] cursor-pointer"></label>
                                                    <div className='clear-both'></div>
                                                </div>
                                                <label htmlFor="toggle2" className="cursor-pointer text-xs float-left w-10/12 pl-3">I am aware this search carries a fee if there are results</label>
                                                <div className='clear-both'></div>
                                            </div>
                                        </div>
                                        {
                                            (errors?.term1 || errors?.term2 || errors?.term3)
                                                ?
                                                <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                    {
                                                        (errors?.term1?.type === "required" || errors?.term2?.type === "required" || errors?.term3?.type === "required")
                                                            ?
                                                            'Please agree all terms and conditions.'
                                                            :
                                                            null
                                                    }
                                                </span>
                                                :
                                                null
                                        }
                                    </div>
                                    <button
                                        disabled={processing}
                                        className='mt-8 float-right bg-blue text-burgandy hover:text-blue text-sm font-Averta_Semibold py-4 px-12 rounded-xl p-3 hover:bg-burgandy'

                                    >
                                        {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'SEARCH'}
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className='clear-both'></div>
                    </div>
                </div>

                <QuickMenus />

            </section>
        </>
    )
}

const SearchResult = ({ searched, result }) => {
    const user = fns.LOGGED_USER();
    const navigate = useNavigate();
    const [search_result, setSearchResult] = useState(result);

    const [selected, setSelected] = useState([]);
    const [selectSettle, setSelectSettle] = useState([]);
    const [pdf_id, setPdfId] = useState(null);

    const [modalCsv, SetModalCsv] = useState(false);
    const [modalPdf, SetModalPdf] = useState(false);
    const [modalLock, SetModalLock] = useState(false);
    const [modalSettle, SetModalSettle] = useState(false);

    const handleCheckboxChange = (e) => {
        setSelected(fns.TOGGLE_ARR_ELE(selected, e.target.value));
    }

    const handleSettleCheckboxChange = (e) => {
        setSelectSettle(fns.TOGGLE_ARR_ELE(selectSettle, e.target.value));
    }

    const downloadCSV = async () => {
        if (selected?.length <= 0) {
            fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.');
            return;
        }

        const res = await ManageOfferService.DownloadOfferCsvAPI({ ids: selected });
        if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    const downloadPDF = async () => {
        const res = await ManageOfferService.DownloadOfferPdfAPI(pdf_id);
        if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    const lockOffers = async () => {
        if (selected?.length <= 0) {
            fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.');
            return;
        }

        const res = await ManageOfferService.LockOffersAPI({ ids: selected });
        if (res.response) {
            if (res?.data?.locked_offers > 0) {
                fns.TOAST_SUC(`${res?.data?.locked_offers} Offer(s) locked successfully`);
            }

            if (res?.data?.already_locked_offers > 0) {
                fns.TOAST_SUC(`${res?.data?.already_locked_offers} Offer(s) already locked successfully.`);
            }

            SearchOffer();
        } else {
            fns.TOAST_ERR(res.errors);
        }
    }

    const SearchOffer = async (form) => {
        const res = await ManageOfferService.SearchOffersAPI(searched);

        if (res.response) {
            setSearchResult(res.data);
            setSelected([]);
            setSelectSettle([]);
        } else {
            fns.TOAST_ERR(res.errors);
        }
    }

    const settleOffers = async () => {
        if (selectSettle?.length <= 0) {
            fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.');
            return;
        }

        const res = await ManageOfferService.SettleOfferAPI({ ids: selectSettle });
        if (res.response) {
            if (res?.data?.data?.settled_offers > 0) {
                fns.TOAST_SUC(`${res?.data?.data?.settled_offers} Offer(s) settled successfully`);
                navigate(`/deals-in-progress`);
            }
        } else {
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <>
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <Breadcrumb
                        page={'MANUAL OFFER CREATION'}
                        desc={
                            <>
                                <span className='inline-block align-middle mr-3'>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="15.5" fill="white" stroke="#160B32" />
                                        <path d="M16 8.6521C14.7727 8.6521 13.8139 9.10817 13.1236 10.0203C12.4332 10.9324 12.0881 12.0984 12.0881 13.5183C12.0795 15.2823 12.642 16.6806 13.7756 17.7132C13.9205 17.8509 13.9716 18.0273 13.929 18.2425L13.6349 18.862C13.5412 19.0685 13.4027 19.2299 13.2195 19.3461C13.0362 19.4622 12.6506 19.6279 12.0625 19.843C12.0369 19.8516 11.4979 20.028 10.4453 20.3722C9.39276 20.7164 8.83239 20.9057 8.7642 20.9401C8.0483 21.2413 7.57955 21.5554 7.35795 21.8824C7.11932 22.4245 7 23.797 7 25.9999H25C25 23.797 24.8807 22.4245 24.642 21.8824C24.4205 21.5554 23.9517 21.2413 23.2358 20.9401C23.1676 20.9057 22.6072 20.7164 21.5547 20.3722C20.5021 20.028 19.9631 19.8516 19.9375 19.843C19.3494 19.6279 18.9638 19.4622 18.7805 19.3461C18.5973 19.2299 18.4588 19.0685 18.3651 18.862L18.071 18.2425C18.0284 18.0273 18.0795 17.8509 18.2244 17.7132C19.358 16.6806 19.9205 15.2823 19.9119 13.5183C19.9119 12.0984 19.5668 10.9324 18.8764 10.0203C18.1861 9.10817 17.2273 8.6521 16 8.6521Z" fill="#160B32" />
                                    </svg>
                                </span>
                                {fns.STR_REPLACE(search_result?.search_request?.search_type, '_', ' ')}: {search_result?.search_request?.search_term}
                            </>
                        }
                        contact={null}
                    />

                    {
                        search_result?.open_offers?.data.length > 0
                            ?
                            <div className='lg:w-5/12 sm:w-10/12 w-full mx-auto px-5 py-5 pt-0 relative'>
                                <div className='px-5 py-5 bg-[#F2F2F2] rounded-lg text-xl shadow-md shadow-black/25 text-center font-Averta_Semibold mb-10 text-[#1EC365]'>
                                    <span className='text-2xl'>{search_result?.open_offers?.data.length}</span> AVAILABLE OFFERS ON THE HUB FOR THIS ID!
                                </div>
                            </div>
                            :
                            null
                    }

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>
                                    {
                                        search_result?.open_offers?.data.length > 0
                                            ?
                                            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                                <div className='inline-block align-middle mr-3'>
                                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g filter="url(#filter0_d_15_6)">
                                                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                                        </g>
                                                        <path d="M36.41 23.41L27.58 14.58C27.21 14.21 26.7 14 26.17 14H19C17.9 14 17 14.9 17 16V23.17C17 23.7 17.21 24.21 17.59 24.58L26.42 33.41C27.2 34.19 28.47 34.19 29.25 33.41L36.42 26.24C37.2 25.46 37.2 24.2 36.41 23.41ZM21.5 20C20.67 20 20 19.33 20 18.5C20 17.67 20.67 17 21.5 17C22.33 17 23 17.67 23 18.5C23 19.33 22.33 20 21.5 20Z" fill="#160B32" />
                                                        <defs>
                                                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                <feOffset dy="4" />
                                                                <feGaussianBlur stdDeviation="2" />
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </div>

                                                {search_result?.open_offers?.data.length} OPEN OFFERS
                                                <div className='w-full float-left pl-16'>
                                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                                        <div className="flex flex-col">
                                                            <div className="overflow-x-auto">
                                                                <div className="py-2 inline-block min-w-full">
                                                                    <div className="overflow-x-auto pb-4 xl:pb-0">
                                                                        <table className="min-w-full text-black">
                                                                            <thead className="">
                                                                                <tr>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">
                                                                                        Institution
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Outstanding Balance
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Code Bracket
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Account Type
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Settlement Value
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Ref
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Settlement Party
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Expiry
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Download
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Select
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className=''>
                                                                                {
                                                                                    search_result?.open_offers?.data.length > 0
                                                                                        ?
                                                                                        search_result?.open_offers?.data.map((offer, index) =>
                                                                                            <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                                <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                    {offer?.institution}
                                                                                                </td>
                                                                                                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#EB5757]">
                                                                                                    {offer?._outstanding_balance_text}
                                                                                                </td>
                                                                                                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                    {offer?.cd_bracket}
                                                                                                </td>
                                                                                                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                    {offer?._account_type_text}
                                                                                                </td>
                                                                                                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#1EC465]">
                                                                                                    {offer?._settlement_value_text}
                                                                                                </td>
                                                                                                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                                    <svg className='hover:stroke-burgandy m-auto cursor-pointer' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <g clipPath="url(#clip0_253_19391)">
                                                                                                            <path d="M10.59 10.59C10.384 10.811 10.1356 10.9883 9.85961 11.1113C9.58362 11.2343 9.28568 11.3004 8.98357 11.3057C8.68146 11.3111 8.38137 11.2555 8.10121 11.1423C7.82104 11.0292 7.56654 10.8607 7.35288 10.6471C7.13923 10.4334 6.97079 10.1789 6.85763 9.89875C6.74447 9.61859 6.68889 9.3185 6.69423 9.01639C6.69956 8.71428 6.76568 8.41634 6.88866 8.14034C7.01163 7.86435 7.18894 7.61594 7.41 7.40996M13.455 13.455C12.1729 14.4322 10.6118 14.9736 9 15C3.75 15 0.75 8.99996 0.75 8.99996C1.68292 7.26138 2.97685 5.74241 4.545 4.54496L13.455 13.455ZM7.425 3.17996C7.94125 3.05912 8.4698 2.99871 9 2.99996C14.25 2.99996 17.25 8.99996 17.25 8.99996C16.7947 9.85166 16.2518 10.6535 15.63 11.3925L7.425 3.17996Z" stroke="#160B32" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                            <path d="M0.75 0.749939L17.25 17.2499" stroke="#160B32" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        </g>
                                                                                                        <defs>
                                                                                                            <clipPath id="clip0_253_19391">
                                                                                                                <rect width="18" height="18" fill="white" />
                                                                                                            </clipPath>
                                                                                                        </defs>
                                                                                                    </svg>
                                                                                                </td>
                                                                                                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit text-[#818181]">
                                                                                                    {offer?.settlement_party}
                                                                                                    {user?.organization_name}
                                                                                                </td>
                                                                                                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                                    <div className={`w-[100px] bg-gradient-to-r from-[${fns.GET_COLOR_GRADIANT(offer?._expiry_days)}] h-[7px] block m-auto mb-1`}></div>
                                                                                                    {offer?._expiry_days} days
                                                                                                </td>
                                                                                                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                                    <button onClick={(e) => { setPdfId(offer?._id); SetModalPdf(true); }}>
                                                                                                        <svg className='hover:stroke-burgandy m-auto cursor-pointer' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path d="M24.5 17.5V22.1667C24.5 22.7855 24.2542 23.379 23.8166 23.8166C23.379 24.2542 22.7855 24.5 22.1667 24.5H5.83333C5.21449 24.5 4.621 24.2542 4.18342 23.8166C3.74583 23.379 3.5 22.7855 3.5 22.1667V17.5" stroke="#160B32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                            <path d="M8.1665 11.6667L13.9998 17.5L19.8332 11.6667" stroke="#160B32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                            <path d="M14 17.5V3.5" stroke="#160B32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                </td>
                                                                                                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                                    <input
                                                                                                        className='w-[14px] h-[14px]'
                                                                                                        id={`offer${index}`}
                                                                                                        name={`offer${index}`}
                                                                                                        onChange={handleCheckboxChange}
                                                                                                        type="checkbox"
                                                                                                        value={offer?._id}
                                                                                                        defaultChecked={false}
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className='float-right text-xs text-blue py-1 border px-3 mt-4 hover:text-burgandy' onClick={(e) => { (selected?.length <= 0) ? fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.') : SetModalCsv(true); }}>DOWNLOAD ALL</button>
                                                    <div className='clear-both'></div>
                                                    {
                                                        fns.CHECK_PERMISSION(["B2"])
                                                            ?
                                                            <button
                                                                className='mt-8 float-right bg-[#2D6E5C] text-white text-sm font-Averta_Semibold py-4 px-10 rounded-xl hover:bg-burgandy'
                                                                onClick={(e) => { (selected?.length <= 0) ? fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.') : SetModalLock(true); }}
                                                            >
                                                                Lock this Offer
                                                            </button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        search_result?.locked_offers?.data.length > 0
                                            ?
                                            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                                <div className='inline-block align-middle mr-3'>
                                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g filter="url(#filter0_d_15_6)">
                                                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                                        </g>
                                                        <path d="M37 22.5C37 21.1213 35.8787 20 34.5 20H33.25V16.25C33.25 12.8038 30.4462 10 27 10C23.5538 10 20.75 12.8038 20.75 16.25V20H19.5C18.1212 20 17 21.1213 17 22.5V32.5C17 33.8787 18.1212 35 19.5 35H34.5C35.8787 35 37 33.8787 37 32.5V22.5ZM23.25 16.25C23.25 14.1825 24.9325 12.5 27 12.5C29.0675 12.5 30.75 14.1825 30.75 16.25V20H23.25V16.25Z" fill="#160B32" />
                                                        <defs>
                                                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                <feOffset dy="4" />
                                                                <feGaussianBlur stdDeviation="2" />
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                {search_result?.locked_offers?.data.length} LOCKED OFFERS
                                                <div className='w-full float-left pl-16'>
                                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-4 px-4 sm:px-8 pb-5 mb-10">
                                                        <div className="flex flex-col">
                                                            <div className="overflow-x-auto">
                                                                <div className="py-2 inline-block min-w-full">
                                                                    <div className="overflow-x-auto mb-2">
                                                                        <table className="min-w-full text-black">
                                                                            <thead className="">
                                                                                <tr>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Institution</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Outstanding Balance</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">SH Ref</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Account Type</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Value</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Ref</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Party</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Lock Expires</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Select</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Bin</th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Extend</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className=''>
                                                                                {
                                                                                    search_result?.locked_offers?.data.length > 0
                                                                                        ?
                                                                                        search_result?.locked_offers?.data.map((offer, index) => <LockedOfferRow
                                                                                            index={index}
                                                                                            key={index}
                                                                                            offer={offer}
                                                                                            ReloadOffer={SearchOffer}
                                                                                            handleSettleCheckboxChange={handleSettleCheckboxChange}
                                                                                        />)
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        fns.CHECK_PERMISSION(["B3"])
                                                            ?
                                                            <button
                                                                className='mt-8 float-right bg-[#2D6E5C] text-white text-sm font-Averta_Semibold py-4 px-10 rounded-xl hover:bg-burgandy'
                                                                onClick={(e) => { (selectSettle?.length <= 0) ? fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.') : SetModalSettle(true); }}
                                                            >
                                                                Let's Settle
                                                            </button>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        search_result?.unavailable_offers?.data.length > 0
                                            ?
                                            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                                <div className='inline-block align-middle mr-3'>
                                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g filter="url(#filter0_d_15_6)">
                                                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                                        </g>
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M36.5461 11.454C37.7997 12.7076 38.7941 14.1959 39.4725 15.8338C40.151 17.4717 40.5002 19.2272 40.5002 21C40.5002 22.7729 40.151 24.5284 39.4725 26.1663C38.7941 27.8042 37.7997 29.2924 36.5461 30.546C35.2925 31.7996 33.8042 32.794 32.1663 33.4725C30.5284 34.1509 28.7729 34.5001 27.0001 34.5001C25.2272 34.5001 23.4717 34.1509 21.8338 33.4725C20.1959 32.794 18.7077 31.7996 17.4541 30.546C14.9223 28.0143 13.5 24.5805 13.5 21C13.5 17.4196 14.9223 13.9858 17.4541 11.454C19.9858 8.92227 23.4196 7.49994 27.0001 7.49994C30.5805 7.49994 34.0143 8.92227 36.5461 11.454V11.454ZM33.2881 29.409L18.5896 14.712C17.076 16.7344 16.342 19.2346 16.5223 21.7542C16.7027 24.2738 17.7852 26.644 19.5714 28.4302C21.3576 30.2164 23.7278 31.2989 26.2474 31.4793C28.767 31.6596 31.2672 30.9256 33.2896 29.412L33.2881 29.409ZM34.4251 13.575C36.2102 15.3606 37.2923 17.7295 37.4733 20.2479C37.6543 22.7662 36.922 25.2655 35.4106 27.288L20.7106 12.5895C22.7331 11.0774 25.2328 10.3447 27.7515 10.5257C30.2703 10.7067 32.6396 11.7892 34.4251 13.575V13.575Z" fill="#160B32" />
                                                        <defs>
                                                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                <feOffset dy="4" />
                                                                <feGaussianBlur stdDeviation="2" />
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                {search_result?.unavailable_offers?.data.length} UNAVAILABLE
                                                <div className='w-full float-left pl-16'>
                                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                                        <div className="flex flex-col">
                                                            <div className="overflow-x-auto">
                                                                <div className="py-2 inline-block min-w-full">
                                                                    <div className="overflow-x-auto pb-4 xl:pb-0">
                                                                        <table className="min-w-full text-black">
                                                                            <thead className="text-black">
                                                                                <tr>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">
                                                                                        Institution
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Outstanding Balance
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        SH Ref
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Account Type
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Settlement Value
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Ref
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Settlement Party
                                                                                    </th>
                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">
                                                                                        Lock Expires
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className=''>
                                                                                {
                                                                                    search_result?.unavailable_offers?.data.length > 0
                                                                                        ?
                                                                                        search_result?.unavailable_offers?.data.map((offer, index) => <UnavailableOffer key={index} offer={offer} />)
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>
                    <div className='clear-both'></div>
                </div>
            </section>

            <Modal show={modalCsv} className="bg-black/60">
                <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalCsv(!modalCsv) }}>
                    &nbsp;
                </Modal.Header>
                <Modal.Body className='text-center pt-0'>
                    <div className="bg-white px-8 pt-0 pb-4 sm:pb-4">
                        <div className="text-center">
                            <div className="pt-0 text-center">
                                <h3 className="text-base font-Averta_Semibold leading-6 uppercase" id="modal-title">download data</h3>
                                <p className="mt-3 text-sm">Are you sure you want to download .csv file?</p>

                                <p className="mt-3 text-sm">By downloading you accept the Terms & Conditions of SettleHub <Link target="_blank" to={`/page/terms-conditions`} className='text-burgandy font-Averta_Semibold'>here</Link>.</p>
                            </div>
                        </div>
                    </div>
                    <button type='submit' onClick={() => { SetModalCsv(false); downloadCSV(); }} className="mt-3 m-auto inline-flex w-full rounded-xl justify-center text-burgandy bg-blue px-12 py-2 font-Averta_Semibold focus:outline-none sm:mt-4 sm:ml-3 sm:w-auto text-sm hover:text-blue hover:bg-burgandy">Download</button>
                </Modal.Body>
            </Modal>

            <Modal show={modalPdf} className="bg-black/60">
                <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalPdf(!modalPdf) }}>
                    &nbsp;
                </Modal.Header>
                <Modal.Body className='text-center pt-0'>
                    <div className="bg-white px-8 pt-0 pb-4 sm:pb-4">
                        <div className="text-center">
                            <div className="pt-0 text-center">
                                <h3 className="text-base font-Averta_Semibold leading-6 uppercase" id="modal-title">download data</h3>
                                <p className="mt-3 text-sm">Are you sure you want to download .pdf file?</p>

                                <p className="mt-3 text-sm">By downloading you accept the Terms & Conditions of SettleHub <Link target="_blank" to={`/page/terms-conditions`} className='text-burgandy font-Averta_Semibold'>here</Link>.</p>
                            </div>
                        </div>
                    </div>
                    <button type='submit' onClick={() => { SetModalPdf(false); downloadPDF(); }} className="mt-3 m-auto inline-flex w-full rounded-xl justify-center text-burgandy bg-blue px-12 py-2 font-Averta_Semibold focus:outline-none sm:mt-4 sm:ml-3 sm:w-auto text-sm hover:text-blue hover:bg-burgandy">Download</button>
                </Modal.Body>
            </Modal>

            <Modal show={modalLock} className="bg-black/60">
                <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalLock(!modalLock) }}>
                    &nbsp;
                </Modal.Header>
                <Modal.Body className='text-center pt-0'>
                    <div className="bg-white px-8 pt-0 pb-4 sm:pb-4">
                        <div className="text-center">
                            <div className="pt-0 text-center">
                                <h3 className="text-base font-Averta_Semibold leading-6 uppercase" id="modal-title">Lock loan(s)</h3>
                                <p className="mt-3 text-sm">Are you sure you want to lock selected loan(s)?</p>

                                <p className="mt-3 text-sm">Locking this loan will prevent other credit providers from seeing the details for 9 days.</p>
                            </div>
                        </div>
                    </div>
                    <button type='submit' onClick={() => { SetModalLock(false); lockOffers(); }} className="mt-3 m-auto inline-flex w-full rounded-xl justify-center text-burgandy bg-blue px-12 py-2 font-Averta_Semibold focus:outline-none sm:mt-4 sm:ml-3 sm:w-auto text-sm hover:text-blue hover:bg-burgandy">Yes, Lock</button>
                </Modal.Body>
            </Modal>

            <Modal show={modalSettle} className="bg-black/60">
                <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalSettle(!modalSettle) }}>
                    &nbsp;
                </Modal.Header>
                <Modal.Body className='text-center pt-0'>
                    <div className="bg-white px-8 pt-0 pb-4 sm:pb-4">
                        <div className="text-center">
                            <div className="pt-0 text-center">
                                <h3 className="text-base font-Averta_Semibold leading-6 uppercase" id="modal-title">Please confirm</h3>
                                <p className="mt-3 text-sm">Please confirm you would like the SettheHub Finance Team to get selected loans consolidated.</p>
                            </div>
                        </div>
                    </div>
                    <button type='submit' onClick={() => { SetModalSettle(false); settleOffers(); }} className="mt-3 m-auto inline-flex w-full rounded-xl justify-center text-burgandy bg-blue px-12 py-2 font-Averta_Semibold focus:outline-none sm:mt-4 sm:ml-3 sm:w-auto text-sm hover:text-blue hover:bg-burgandy">Yes, Let's Settle</button>
                </Modal.Body>
            </Modal>
        </>
    );
}