import { LineLoader } from 'UIs/SkeletonLoader';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as GenService from 'Services/general.service';
import { GoBack } from '../../../components/interfaces/Buttons';
import { Link, useNavigate } from 'react-router-dom';
import fns from 'helpers/functions';
const CommonCms = () => {
    const { _page } = useParams();
    const [page, setPage] = useState();
    const [sub_page, setSubPage] = useState();
    const [active_page, setAtivePage] = useState(0);
    const [fetching, setFetching] = useState(true);
    const navigate = useNavigate();
    const page_title = _page === 'faqs' ? 'Help center' : 'terms & conditions';
    const user = fns.LOGGED_USER();
    useEffect(() => {
        fetchPage();

        return () => {
        }
    }, [_page]);

    const fetchPage = async () => {
        setFetching(true);
        const res = await GenService.FetchCmsPage(_page);
        if (res.response) {
            setFetching(false);
            setPage(res.data);
            setSubPage(res.data?.sub_pages);
        } else {
            setFetching(false);
            setPage(null);
            setSubPage(null);
        }
    }
    const minusIcon = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path></svg>'
    const plusIcon  = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path></svg>'
    
    function Accordion({ pgindex, length, title, content }) {
        const [expanded, setExpanded] = useState(false)
        const toggleExpanded = () => setExpanded((current) => !current)
        let classname = '';
        let opened = false;
        classname='border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800';
        if(pgindex == 1){
            classname='rounded-t-lg border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800';
        }
        if(pgindex == length){
            classname='rounded-b-lg border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800';
        }
        return (
            <>
            <div className={classname}>
                <h2 className="mb-0" id="headingOne">
                <button onClick={toggleExpanded} className="group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]" type="button" data-te-collapse-init data-te-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <strong className="text-burgandy hover:text-blue">{pgindex}. {title}</strong>
                    <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                    {expanded 
                    ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    : 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>
                    }
                    </span>
                </button>
                </h2>
                <div id="collapseOne" className={`px-6 pt-0 overflow-hidden transition-[max-height] duration-500 ease-in ${expanded ? "max-h-40" : "max-h-0"}`} data-te-collapse-item data-te-collapse-show aria-labelledby="headingOne" data-te-parent="#accordionExample">
                    <div className="px-5 py-4">{content}</div>
                </div>
            </div>
            </>
        )
    }
    return (
        fetching
            ?
            <PageSkeleton />
            :
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left relative'>

                    <div className='sm:w-11/12 w-full mx-auto px-5 py-5 pt-16 xl:pt-0 xl:pl-64 relative'>
                        <h3 className='absolute -left-[4.6%] top-0 bg-blue text-white text-center text-xs p-5 w-80 mr-4 uppercase'>{page_title}</h3>
                        <h4 className='float-left text-blue text-lg sm:p-3 py-3 leading-7 font-Averta_Semibold uppercase'>{page?.title}</h4>
                        {user ? (
                            <div className="lg:container mx-auto px-5"><GoBack onClick={() => { navigate(`/`); }} name={'Go back'} /></div>
                        ) : (
                            <div className="lg:container mx-auto px-5"><GoBack onClick={() => { navigate(`/login`); }} name={'Back to Login'} /></div>
                        )}
                        <div className='clear-both'></div>
                    </div>

                    <div className="xl:w-10/12 w-full mx-auto px-5 py-5">
                        {
                            
                            _page == 'faqs'
                            ?
                            <>
                            <div id="accordionExample">
                                {
                                sub_page.map((pg, index, ) =>
                                <Accordion key={index} pgindex={index+1} length={sub_page.length} title={pg.title} content={pg.description} />
                                )
                                }
                            </div>
                            
                            </>
                            :
                            _page != 'faqs' && sub_page && sub_page.length > 0
                                ?
                                <>
                                    <div className="w-full md:w-3/12 p-2 float-left">
                                        <ul className="w-full float-left">
                                            {
                                                sub_page.map((pg, index) =>
                                                    <li onClick={(e) => { setAtivePage(index); window.scrollTo(0, 0); }} key={index} className="w-full p-2 float-left cursor-pointer">
                                                        <span className='text-xs text-blue hover:text-burgandy uppercase'>
                                                            <svg className='inline-block mr-2' width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g filter="url(#filter0_d_15_6)">
                                                                    <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                                                </g>
                                                                <path d="M37 7H17C16.4696 7 15.9609 7.21071 15.5858 7.58579C15.2107 7.96086 15 8.46957 15 9V37C15 37.5304 15.2107 38.0391 15.5858 38.4142C15.9609 38.7893 16.4696 39 17 39H37C37.5304 39 38.0391 38.7893 38.4142 38.4142C38.7893 38.0391 39 37.5304 39 37V9C39 8.46957 38.7893 7.96086 38.4142 7.58579C38.0391 7.21071 37.5304 7 37 7ZM22 31H20V29H22V31ZM22 27H20V25H22V27ZM22 23H20V21H22V23ZM22 19H20V17H22V19ZM22 15H20V13H22V15ZM34 31H24V29H34V31ZM34 27H24V25H34V27ZM34 23H24V21H34V23ZM34 19H24V17H34V19ZM34 15H24V13H34V15Z" fill="#160B32" />
                                                                <defs>
                                                                    <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                        <feOffset dy="4" />
                                                                        <feGaussianBlur stdDeviation="2" />
                                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                                    </filter>
                                                                </defs>
                                                            </svg>
                                                            {pg.title}
                                                        </span>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    <div className="w-full md:w-9/12 p-2 float-left">
                                        <div className='w-full px-10 py-10 float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 font-Averta text-xs'>
                                            <h3 className='text-lg pb-2 font-Averta_Semibold uppercase'>{sub_page[active_page]?.title}</h3>
                                            <p className='text-sm leading-5 pb-6'>{sub_page[active_page]?.description}</p>
                                        </div>
                                    </div>
                                </>
                            :
                            null
                        }
                        <div className='clear-both'></div>
                    </div>

                    <div className='clear-both'></div>
                </div>
            </section>
    )
}

export default CommonCms

const PageSkeleton = () => {
    return (
        <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
            <div className='w-full float-left relative'>

                <div className='sm:w-11/12 w-full mx-auto px-5 py-5 pt-16 xl:pt-0 xl:pl-64 relative'>
                    <h3 className='absolute -left-[4.6%] top-0 bg-blue text-white text-right text-xs p-5 w-80 mr-4'>&nbsp;</h3>
                    <h4 className='float-left text-blue text-lg sm:p-3 py-3 leading-7 font-Averta_Semibold'><LineLoader width={'200px'} /> </h4>
                    <div className='clear-both'></div>
                </div>

                <div className="xl:w-10/12 w-full mx-auto px-5 py-5">
                    <div className="w-full md:w-3/12 p-2 float-left">
                        <ul className="w-full float-left">
                            <li className="w-full p-2 float-left">
                                <a className='text-xs text-blue hover:text-burgandy' href="">
                                    <svg className='inline-block mr-2' width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_15_6)">
                                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                        </g>
                                        <path d="M37 7H17C16.4696 7 15.9609 7.21071 15.5858 7.58579C15.2107 7.96086 15 8.46957 15 9V37C15 37.5304 15.2107 38.0391 15.5858 38.4142C15.9609 38.7893 16.4696 39 17 39H37C37.5304 39 38.0391 38.7893 38.4142 38.4142C38.7893 38.0391 39 37.5304 39 37V9C39 8.46957 38.7893 7.96086 38.4142 7.58579C38.0391 7.21071 37.5304 7 37 7ZM22 31H20V29H22V31ZM22 27H20V25H22V27ZM22 23H20V21H22V23ZM22 19H20V17H22V19ZM22 15H20V13H22V15ZM34 31H24V29H34V31ZM34 27H24V25H34V27ZM34 23H24V21H34V23ZM34 19H24V17H34V19ZM34 15H24V13H34V15Z" fill="#160B32" />
                                        <defs>
                                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                    <LineLoader width={'150px'} />
                                </a>
                            </li>
                            <li className="w-full p-2 float-left">
                                <a className='text-xs text-blue hover:text-burgandy' href="">
                                    <svg className='inline-block mr-2' width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_15_6)">
                                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                        </g>
                                        <path d="M37 7H17C16.4696 7 15.9609 7.21071 15.5858 7.58579C15.2107 7.96086 15 8.46957 15 9V37C15 37.5304 15.2107 38.0391 15.5858 38.4142C15.9609 38.7893 16.4696 39 17 39H37C37.5304 39 38.0391 38.7893 38.4142 38.4142C38.7893 38.0391 39 37.5304 39 37V9C39 8.46957 38.7893 7.96086 38.4142 7.58579C38.0391 7.21071 37.5304 7 37 7ZM22 31H20V29H22V31ZM22 27H20V25H22V27ZM22 23H20V21H22V23ZM22 19H20V17H22V19ZM22 15H20V13H22V15ZM34 31H24V29H34V31ZM34 27H24V25H34V27ZM34 23H24V21H34V23ZM34 19H24V17H34V19ZM34 15H24V13H34V15Z" fill="#160B32" />
                                        <defs>
                                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                    <LineLoader width={'150px'} />
                                </a>
                            </li>
                            <li className="w-full p-2 float-left">
                                <a className='text-xs text-blue hover:text-burgandy' href="">
                                    <svg className='inline-block mr-2' width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_15_6)">
                                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                        </g>
                                        <path d="M37 7H17C16.4696 7 15.9609 7.21071 15.5858 7.58579C15.2107 7.96086 15 8.46957 15 9V37C15 37.5304 15.2107 38.0391 15.5858 38.4142C15.9609 38.7893 16.4696 39 17 39H37C37.5304 39 38.0391 38.7893 38.4142 38.4142C38.7893 38.0391 39 37.5304 39 37V9C39 8.46957 38.7893 7.96086 38.4142 7.58579C38.0391 7.21071 37.5304 7 37 7ZM22 31H20V29H22V31ZM22 27H20V25H22V27ZM22 23H20V21H22V23ZM22 19H20V17H22V19ZM22 15H20V13H22V15ZM34 31H24V29H34V31ZM34 27H24V25H34V27ZM34 23H24V21H34V23ZM34 19H24V17H34V19ZM34 15H24V13H34V15Z" fill="#160B32" />
                                        <defs>
                                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                    <LineLoader width={'150px'} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-9/12 p-2 float-left">
                        <div className='w-full px-10 py-10 float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 font-Averta text-xs'>
                            <h3 className='text-lg pb-2 font-Averta_Semibold'><LineLoader width={'150px'} /></h3>
                            <LineLoader width={'100%'} count={10} />
                        </div>
                    </div>
                    <div className='clear-both'></div>
                </div>

                <div className='clear-both'></div>
            </div>
        </section>
    );
}