import axios from "axios";

const END_POINT = `${process.env.REACT_APP_API_URL}auth/`;

/**
 * Login API for all users
 * @param {string} email emaill/username
 * @param {string} password password
 * @returns 
 */
export const LoginAPI = async (email, password) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}sign-in`, {
        email: email,
        password: password
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`LoginAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }

            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Do Loing API for login with OTP.
 * @param {FormData} form Formdata object with OTP and email, password
 * @returns 
 */
export const LoginVerifyAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}sign-in`, form
    ).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`LoginVerifyAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Something went wrong.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Forgot password API call
 * @param {FormData} form Form data object with email
 * @returns 
 */
export const ForgotAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}forgot-password`, form
    ).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`ForgotAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }

            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Reset Password API call
 * @param {FormData} form Formdata object with new password and reste token
 * @returns 
 */
export const ResetPasswordAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}reset-password`, form
    ).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`ResetPasswordAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }

            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}