import fns from 'helpers/functions';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'modules/fragments';
import { useLocation } from "react-router-dom";
import { PlusButton } from 'UIs/Buttons';
import { useState } from 'react';
import * as OrgService from 'Services/organisation.service';
import { AttachmentIcon } from 'UIs/Icons';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useEffectOnce from 'hooks/useEffectOnce';
import * as UploadService from 'Services/client-upload.service';
import * as NegotitationService from 'Services/negotitation-deals.service';

const CreateNegotationTicket = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlState = location.state;
    const user = fns.LOGGED_USER();

    const validationSchema = Yup.object().shape({
        tickets: Yup.array().of(
            Yup.object().shape({
                institution_id: Yup.string().required('Please select institution'),
                outstanding_balance: Yup.number().required('Please enter outstanding balance.').min(1, 'Outstanding balance must be greater than zero.').typeError('Please enter numeric value.'),
                cd_bracket: Yup.string().required('Please enter code bracket').trim().matches(/^CD/i, 'Code bracket must start with CD.'),
                account_number: Yup.string().required('Please enter account number.'),
                account_type: Yup.number().required('Please select account type.').typeError('Please select account type.'),
                settlement_value: Yup.number().required('Please enter settlement value.').min(1, 'Settlement value must be greater than zero.').typeError('Please enter numeric value.'),
                documents: Yup.mixed().test("file", "You need to provide a file", (value) => {
                    if (value.length > 0) {
                        return true;
                    }

                    return false;
                }),
            })
        ),
        description: Yup.string().required('Please enter ad hoc details.'),
        // terms: Yup.boolean().oneOf(['1'],'Please agree terms & conditions.'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, control, handleSubmit, formState, watch, setError } = useForm(formOptions);
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'tickets', control });

    const INITIAL_STATE = { institution_id: "", outstanding_balance: "", cd_bracket: "", account_number: "", account_type: "", settlement_value: "" };

    const [accountType, setAccountType] = useState(null);
    const [institutions, setInstitutions] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [terms, setTerms] = useState(false);

    useEffectOnce(() => {
        fns.CHECK_PERMISSION(["B5"], true);
        if (!urlState) {
            navigate(`/`);
        }

        fetchAccountType();
        fetchInstitutions();
        addRequest();

        return () => {
        }
    }, []);

    const fetchInstitutions = async () => {
        const res = await OrgService.FetchInstitutionsAPI();

        if (res.response) {
            setFetching(false);
            var listItems = [];
            res.data.forEach(function (value, i) {
                listItems.push({ value: value._id, label: value.institution_name });
            });

            setInstitutions(listItems);
        } else {
            setFetching(false);
            setInstitutions(null);
        }
    }

    const fetchAccountType = async () => {
        const res = await UploadService.FetchAccountTypesAPI();

        if (res.response) {
            var newObj = [];
            for (let atype in res.data) {
                newObj.push({ id: atype, value: res.data[atype] });
            }

            setAccountType(newObj);
        } else {
            setAccountType(null);
        }
    }

    const addRequest = () => {
        append(INITIAL_STATE);
    }

    const saveTickets = async (form) => {
        if (!terms) {
            setError("terms", { type: "focus", message: 'Please agree terms and conditions.' }, { shouldFocus: true });
            return;
        }
        const formx = { ...form, type: urlState.formx.search_type, id_number: urlState.formx.search_term };

        const forms = new FormData();
        for (var key in formx) {
            if (key !== 'tickets') {
                forms.append(key, formx[key]);
            }
        }

        delete forms.tickets;

        formx.tickets.map((item, i) => {
            for (var key in item) {
                if (key === 'documents') {
                    for (var keyys in item[key]) {
                        if (typeof item[key][keyys] === 'object') {
                            forms.append(`tickets[${i}][${key}][${keyys}]`, item[key][keyys]);
                        }
                    }
                } else {
                    forms.append(`tickets[${i}][${key}]`, item[key]);
                }
            }
        })

        setProcessing(true);
        const res = await NegotitationService.CreateNegotiationAPI(forms);

        if (res.response) {
            setProcessing(false);
            fns.TOAST_SUC(res.data.message);
            navigate(`/negotiation-request-created`, { replace: true, state: { ...urlState, id: res.data.data.id } });
        } else {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    const ZeroMatchHtml = () => {
        return (
            <div className='lg:w-5/12 sm:w-10/12 w-full mx-auto px-5 py-5 pt-0 relative'>
                <div className='px-5 py-2 bg-[#F2F2F2] rounded-lg text-xl shadow-md shadow-black/25 text-center font-Averta_Semibold mb-10 text-blue'>
                    <span className='text-[#B33717] text-2xl'> 0</span><span className='text-[#B33717]'> MATCHES ON SETTLEHUB</span><br />COMPLETE THE FORM BELOW FOR A MANUAL NEGOTIATION
                </div>
            </div>
        );
    }

    const thClass = `text-xs font-Averta px-4 py-4`;
    const tdClass = `w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-2 border rounded-lg border-gray`;

    return (
        urlState
            ?
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <Breadcrumb
                        page={'YOUR SEARCH RESULTS'}
                        desc={
                            <>
                                <span className='inline-block align-middle mr-3'>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="15.5" fill="white" stroke="#160B32" />
                                        <path d="M16 8.6521C14.7727 8.6521 13.8139 9.10817 13.1236 10.0203C12.4332 10.9324 12.0881 12.0984 12.0881 13.5183C12.0795 15.2823 12.642 16.6806 13.7756 17.7132C13.9205 17.8509 13.9716 18.0273 13.929 18.2425L13.6349 18.862C13.5412 19.0685 13.4027 19.2299 13.2195 19.3461C13.0362 19.4622 12.6506 19.6279 12.0625 19.843C12.0369 19.8516 11.4979 20.028 10.4453 20.3722C9.39276 20.7164 8.83239 20.9057 8.7642 20.9401C8.0483 21.2413 7.57955 21.5554 7.35795 21.8824C7.11932 22.4245 7 23.797 7 25.9999H25C25 23.797 24.8807 22.4245 24.642 21.8824C24.4205 21.5554 23.9517 21.2413 23.2358 20.9401C23.1676 20.9057 22.6072 20.7164 21.5547 20.3722C20.5021 20.028 19.9631 19.8516 19.9375 19.843C19.3494 19.6279 18.9638 19.4622 18.7805 19.3461C18.5973 19.2299 18.4588 19.0685 18.3651 18.862L18.071 18.2425C18.0284 18.0273 18.0795 17.8509 18.2244 17.7132C19.358 16.6806 19.9205 15.2823 19.9119 13.5183C19.9119 12.0984 19.5668 10.9324 18.8764 10.0203C18.1861 9.10817 17.2273 8.6521 16 8.6521Z" fill="#160B32" />
                                    </svg>
                                </span>
                                {fns.STR_REPLACE(urlState?.formx?.search_type, '_', ' ')}: {urlState?.formx?.search_term}
                            </>
                        }
                        contact={null}
                    />

                    {<ZeroMatchHtml />}

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>
                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <form onSubmit={handleSubmit(saveTickets)}>
                                            <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                                <div className="flex flex-col">
                                                    <div className="overflow-x-auto">
                                                        <div className="py-2 inline-block min-w-full">
                                                            <div className=" mb-2">
                                                                <table className="min-w-full text-black">
                                                                    <thead className="">
                                                                        <tr>
                                                                            <th scope="col" className={`${thClass} text-left`}>Institution</th>
                                                                            <th scope="col" className={`${thClass} text-center`}>Outstanding Balance</th>
                                                                            <th scope="col" className={`${thClass} text-center`}>Code Bracket</th>
                                                                            <th scope="col" className={`${thClass} text-center`}>Account Number</th>
                                                                            <th scope="col" className={`${thClass} text-center`}>Account Type</th>
                                                                            <th scope="col" className={`${thClass} text-center`}>Your Offer</th>
                                                                            <th scope="col" className={`${thClass} text-center`}>Settlement Party</th>
                                                                            <th scope="col" className={`${thClass} text-center`}>Any Client Attachments?</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className=''>
                                                                        {
                                                                            fields.map((element, index) => (
                                                                                <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg border-b border-gray mb-2">
                                                                                    <td className="text-xs px-4 py-2 text-left font-Averta min-w-[200px] relative">
                                                                                        <Controller
                                                                                            control={control}
                                                                                            name={`tickets.${index}.institution_id`}
                                                                                            id="course_id"
                                                                                            rules={{ required: true }}
                                                                                            render={({ field: { onChange, value, name, ref } }) => (
                                                                                                <Select
                                                                                                    inputRef={ref}
                                                                                                    classNamePrefix="addl-class"
                                                                                                    options={institutions}
                                                                                                    value={institutions?.find((c) => c.value === value)}
                                                                                                    onChange={(e) => onChange(e.value)}
                                                                                                    placeholder={"Institution"}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <div className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{errors.tickets?.[index]?.institution_id?.message}</div>
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta min-w-[200px] text-[#EB5757]">
                                                                                        <input
                                                                                            {...register(`tickets.${index}.outstanding_balance`)}
                                                                                            className={tdClass}
                                                                                            type="text"
                                                                                            placeholder="R"
                                                                                        />
                                                                                        <div className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{errors.tickets?.[index]?.outstanding_balance?.message}</div>
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px]">
                                                                                        <input
                                                                                            {...register(`tickets.${index}.cd_bracket`)}
                                                                                            className={tdClass}
                                                                                            type="text"
                                                                                            placeholder="Code"
                                                                                        />
                                                                                        <div className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{errors.tickets?.[index]?.cd_bracket?.message}</div>
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px]">
                                                                                        <input
                                                                                            {...register(`tickets.${index}.account_number`)}
                                                                                            className={tdClass}
                                                                                            type="text"
                                                                                            placeholder="Account Number"
                                                                                        />
                                                                                        <div className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{errors.tickets?.[index]?.account_number?.message}</div>
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px]">
                                                                                        <select
                                                                                            {...register(`tickets.${index}.account_type`)}
                                                                                            className={tdClass}
                                                                                        >
                                                                                            <option value="">Select account type</option>
                                                                                            {
                                                                                                accountType !== null
                                                                                                    ?
                                                                                                    <>
                                                                                                        {
                                                                                                            (accountType !== null && accountType.length > 0)
                                                                                                            &&
                                                                                                            accountType.map((type, index) => {
                                                                                                                return (
                                                                                                                    <option key={index} value={type.id}>{type.value}</option>
                                                                                                                );
                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </select>
                                                                                        <div className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{errors.tickets?.[index]?.account_type?.message}</div>
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px] text-[#1EC465]">
                                                                                        <input
                                                                                            {...register(`tickets.${index}.settlement_value`)}
                                                                                            className={tdClass}
                                                                                            type="text"
                                                                                            placeholder="Settlement Offer"
                                                                                        />
                                                                                        <div className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{errors.tickets?.[index]?.settlement_value?.message}</div>
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px] text-[#818181]">
                                                                                        {user.organization_name}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px]">
                                                                                        <label htmlFor={`file_${index}`}><AttachmentIcon /></label>
                                                                                        <input id={`file_${index}`} type='file' className='hidden' {...register(`tickets.${index}.documents`)} multiple />
                                                                                        {
                                                                                            typeof watch(`tickets.${index}.documents`) !== 'undefined' && watch(`tickets.${index}.documents`).length > 0
                                                                                                ?
                                                                                                <ol className='list-decimal'>
                                                                                                    {
                                                                                                        Object.keys(watch(`tickets.${index}.documents`)).map((keyName, i) => (
                                                                                                            <li key={i}>{watch(`tickets.${index}.documents`)[keyName].name}</li>
                                                                                                        ))
                                                                                                    }
                                                                                                </ol>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        <div className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{errors.tickets?.[index]?.documents?.message}</div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="overflow-x-auto pt-6 pb-06 pr-20 sm:pr-24 mb-2">
                                                                <PlusButton onClick={addRequest} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:w-10/12 w-full sm:mt-0 mt-3 mx-auto">
                                                    <div className='w-full float-left text-xs text-blue font-Averta_Semibold leading-8 pb-4'>
                                                        <svg className='mr-3 inline-block' width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_245_26897)">
                                                                <path d="M11.8108 0.794987L11.9412 0.645987C12.0393 0.533904 12.1558 0.445008 12.284 0.384374C12.4122 0.32374 12.5496 0.292556 12.6883 0.292603C12.8271 0.292649 12.9644 0.323925 13.0926 0.384645C13.2208 0.445364 13.3372 0.534339 13.4353 0.646487C13.5333 0.758636 13.6111 0.891763 13.6642 1.03827C13.7172 1.18477 13.7445 1.34178 13.7445 1.50034C13.7444 1.6589 13.7171 1.81589 13.6639 1.96236C13.6108 2.10883 13.533 2.2419 13.4348 2.35399L13.3045 2.50199C13.5275 2.78785 13.6464 3.16038 13.6368 3.54322C13.6272 3.92605 13.4898 4.29019 13.2528 4.56099L4.24733 14.854C4.19104 14.918 4.12056 14.9633 4.04346 14.985L0.543458 15.985C0.470214 16.0058 0.393497 16.0047 0.320764 15.9816C0.248032 15.9585 0.181758 15.9144 0.128385 15.8534C0.0750119 15.7924 0.0363555 15.7166 0.0161733 15.6335C-0.00400895 15.5504 -0.00503042 15.4627 0.0132082 15.379L0.888208 11.379C0.907353 11.2912 0.946968 11.2111 1.00283 11.147L9.43958 1.50499C9.3552 1.43712 9.25255 1.40551 9.14976 1.41576C9.04697 1.42601 8.95065 1.47745 8.87783 1.56099L5.99733 4.85399C5.95666 4.90048 5.90837 4.93735 5.85522 4.96251C5.80207 4.98767 5.74511 5.00062 5.68758 5.00062C5.63006 5.00062 5.57309 4.98767 5.51995 4.96251C5.4668 4.93735 5.41851 4.90048 5.37783 4.85399C5.33716 4.8075 5.30489 4.75231 5.28288 4.69157C5.26086 4.63083 5.24953 4.56573 5.24953 4.49999C5.24953 4.43424 5.26086 4.36914 5.28288 4.3084C5.30489 4.24766 5.33716 4.19248 5.37783 4.14599L8.26008 0.853987C8.49719 0.583003 8.81605 0.426008 9.15124 0.415223C9.48643 0.404437 9.81251 0.540679 10.0626 0.795987C10.303 0.550426 10.6141 0.414599 10.9365 0.414415C11.259 0.414231 11.5702 0.549701 11.8108 0.794987Z" fill="#160B32" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_245_26897">
                                                                    <rect width="14" height="16" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        ANY AD HOC DETAILS FOR US TO CONSIDER?
                                                    </div>
                                                    <div className='w-full float-left text-xs text-blue font-Averta_Semibold pb-4'>
                                                        <textarea {...register(`description`)} className="w-full h-[155px] placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-2 border rounded-lg border-gray resize-none" id="" cols="30" rows="10" placeholder='Notes for us to consider - such as what percentage you are willing to settle the outstanding debt for? For example, "I am willing to settle the debt between 30 - 40% discount"'></textarea>
                                                        <div className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{errors.description?.message}</div>
                                                    </div>
                                                    <div className='w-full float-left text-xs text-blue font-Averta'>
                                                        <div className="relative float-left max-w-[45px] w-2/12 mr-2 mt-1 sm:mt-0 align-middle select-none transition duration-200 ease-in">
                                                            <input onClick={(e) => (setTerms(!terms))} {...register(`terms`)} type="checkbox" name="toggle" id="toggle" className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-[#C4C4C4] border-2 border-[#F2F2F2] appearance-none cursor-pointer -mt-0.5" value={'1'} />
                                                            <label htmlFor="toggle" className="toggle-label w-[45px] block overflow-hidden h-4 rounded-full bg-[#EB5757] cursor-pointer"></label>
                                                            <div className='clear-both'></div>
                                                        </div>
                                                        <label htmlFor="toggle" className="text-xs float-left w-10/12 cursor-pointer">I have read the Terms and Conditions of SettleHub for searching for a Settlement Offer on SettleHub <Link target="_blank" className='font-Averta_Semibold text-blue hover:text-burgandy' to={`/page/terms-conditions`}>here</Link>.</label>
                                                        <div className="text-red-500 text-xs font-Averta mt-1 w-full float-left block">{errors.terms?.message}</div>
                                                        <div className='clear-both'></div>
                                                    </div>
                                                    <div className='clear-both'></div>
                                                </div>
                                            </div>
                                            <button disabled={processing} className='px-12 text-center float-right bg-blue text-burgandy text-sm font-Averta_Semibold py-4 rounded-xl p-3 hover:bg-burgandy hover:text-blue mb-3'> {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Create Negotiation Ticket'} </button>
                                            <Link to={`/dashboard`} className='px-12 text-center float-left bg-burgandy text-blue text-sm font-Averta_Semibold py-4 rounded-xl hover:bg-blue hover:text-burgandy'>Cancel</Link>
                                        </form>
                                    </div>
                                    <div className='clear-both'></div>
                                    <div className="w-full max-w-2xl m-auto block text-blue text-xs font-Averta text-center leading-6">NOTE: OUR CONCIERGE TEAM WILL MANUALLY ENGAGE WITH THE ORIGINATOR TO ATTEMPT TO SETTLE ANY LOANS; THERE IS NO GUARANTEE A SETTLEMENT WILL BE REACHED.</div>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>
                    <div className='clear-both'></div>
                </div>
            </section>
            :
            null
    )
}

export default CreateNegotationTicket