export const UserSqare = ({ onClick }) => {
    return (
        <div className='inline-block align-middle mr-3'>
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_15_6)">
                    <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                </g>
                <path d="M27 12.1736C25.3636 12.1736 24.0852 12.7737 23.1648 13.9739C22.2443 15.1741 21.7841 16.7083 21.7841 18.5765C21.7727 20.8976 22.5227 22.7375 24.0341 24.0962C24.2273 24.2774 24.2955 24.5095 24.2386 24.7925L23.8466 25.6077C23.7216 25.8795 23.5369 26.0918 23.2926 26.2446C23.0483 26.3975 22.5341 26.6154 21.75 26.8985C21.7159 26.9098 20.9972 27.1419 19.5938 27.5948C18.1903 28.0477 17.4432 28.2968 17.3523 28.3421C16.3977 28.7384 15.7727 29.1517 15.4773 29.5819C15.1591 30.2952 15 32.1012 15 34.9997H39C39 32.1012 38.8409 30.2952 38.5227 29.5819C38.2273 29.1517 37.6023 28.7384 36.6477 28.3421C36.5568 28.2968 35.8097 28.0477 34.4062 27.5948C33.0028 27.1419 32.2841 26.9098 32.25 26.8985C31.4659 26.6154 30.9517 26.3975 30.7074 26.2446C30.4631 26.0918 30.2784 25.8795 30.1534 25.6077L29.7614 24.7925C29.7045 24.5095 29.7727 24.2774 29.9659 24.0962C31.4773 22.7375 32.2273 20.8976 32.2159 18.5765C32.2159 16.7083 31.7557 15.1741 30.8352 13.9739C29.9148 12.7737 28.6364 12.1736 27 12.1736Z" fill="#160B32" />
                <defs>
                    <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                    </filter>
                </defs>
            </svg>
        </div>
    )
}

export const UserRound = ({ onClick }) => {
    return (
        <span className='font-Averta text-[9px] text-blue hover:text-burgandy'>
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.4998" cy="17.0769" r="16.5769" fill="white" stroke="#160B32" />
                <g clipPath="url(#clip0_15_6)">
                    <path d="M17.5003 8.16705C16.1904 8.16705 15.1671 8.65382 14.4303 9.62735C13.6935 10.6009 13.3251 11.8453 13.3251 13.3608C13.316 15.2435 13.9163 16.736 15.1261 17.8381C15.2808 17.985 15.3354 18.1733 15.2899 18.4029L14.9761 19.0642C14.876 19.2846 14.7282 19.4568 14.5326 19.5808C14.337 19.7048 13.9254 19.8816 13.2978 20.1112C13.2705 20.1204 12.6951 20.3086 11.5717 20.676C10.4483 21.0434 9.85025 21.2454 9.77748 21.2822C9.01339 21.6036 8.51308 21.9388 8.27658 22.2879C8.02188 22.8665 7.89453 24.3313 7.89453 26.6825H27.1061C27.1061 24.3313 26.9787 22.8665 26.724 22.2879C26.4875 21.9388 25.9872 21.6036 25.2231 21.2822C25.1503 21.2454 24.5523 21.0434 23.4289 20.676C22.3055 20.3086 21.7301 20.1204 21.7028 20.1112C21.0752 19.8816 20.6636 19.7048 20.468 19.5808C20.2724 19.4568 20.1246 19.2846 20.0245 19.0642L19.7107 18.4029C19.6652 18.1733 19.7198 17.985 19.8745 17.8381C21.0843 16.736 21.6846 15.2435 21.6755 13.3608C21.6755 11.8453 21.3071 10.6009 20.5703 9.62735C19.8335 8.65382 18.8102 8.16705 17.5003 8.16705Z" fill="#160B32" />
                </g>
                <defs>
                    <clipPath id="clip0_15_6">
                        <rect width="19.2115" height="20.2788" fill="white" transform="translate(7.89453 6.40387)" />
                    </clipPath>
                </defs>
            </svg>
            VIEW
        </span>
    )
}

export const AttachmentIcon = () => {
    return (
        <svg className='m-auto' width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5834 9.33342H4.86119C4.67701 9.33342 4.50038 9.25147 4.37015 9.10561C4.23991 8.95975 4.16675 8.76192 4.16675 8.55564V5.44453C4.16675 5.23825 4.23991 5.04042 4.37015 4.89455C4.50038 4.74869 4.67701 4.66675 4.86119 4.66675H14.5834C14.7676 4.66675 14.9442 4.74869 15.0745 4.89455C15.2047 5.04042 15.2779 5.23825 15.2779 5.44453V8.55564C15.2779 8.76192 15.2047 8.95975 15.0745 9.10561C14.9442 9.25147 14.7676 9.33342 14.5834 9.33342ZM5.55564 7.77786H13.889V6.17564H5.55564V7.77786Z" fill="#160B32" />
            <path d="M14.5834 10.951H4.86119C4.67701 10.951 4.50038 11.033 4.37015 11.1789C4.23991 11.3247 4.16675 11.5225 4.16675 11.7288V14.7777C4.16675 14.984 4.23991 15.1818 4.37015 15.3277C4.50038 15.4736 4.67701 15.5555 4.86119 15.5555H12.7501L15.2779 12.6777V11.7288C15.2779 11.5225 15.2047 11.3247 15.0745 11.1789C14.9442 11.033 14.7676 10.951 14.5834 10.951ZM13.889 13.9999H5.55564V12.4444H13.889V13.9999Z" fill="#160B32" />
            <path d="M7.68058 24.5078V24.4611L7.9028 23.38H2.7778V3.1111H16.6667V11.0833L18.0556 9.61332V2.33332C18.0556 2.12704 17.9824 1.92921 17.8522 1.78335C17.722 1.63749 17.5453 1.55554 17.3611 1.55554H2.08336C1.89918 1.55554 1.72255 1.63749 1.59231 1.78335C1.46208 1.92921 1.38892 2.12704 1.38892 2.33332V24.1111C1.38892 24.3174 1.46208 24.5152 1.59231 24.6611C1.72255 24.8069 1.89918 24.8889 2.08336 24.8889H7.63892C7.64464 24.7609 7.65856 24.6335 7.68058 24.5078Z" fill="#160B32" />
            <path d="M15.2778 14.91L14.7361 15.5245C14.8705 15.4934 14.9936 15.4185 15.09 15.3092C15.1864 15.1998 15.2518 15.0609 15.2778 14.91Z" fill="#160B32" />
            <path d="M4.16675 20.9533C4.16675 21.1596 4.23991 21.3574 4.37015 21.5033C4.50038 21.6491 4.67701 21.7311 4.86119 21.7311H8.2223L8.43064 20.72L8.52091 20.2922V20.2533H5.55564V18.6666H9.95841L11.3473 17.1111H4.86119C4.67701 17.1111 4.50038 17.193 4.37015 17.3389C4.23991 17.4848 4.16675 17.6826 4.16675 17.8889V20.9533Z" fill="#160B32" />
            <path d="M23.2568 12.9655L20.9166 10.3444C20.8127 10.2278 20.6893 10.1352 20.5535 10.0721C20.4176 10.0089 20.272 9.97644 20.1249 9.97644C19.9778 9.97644 19.8322 10.0089 19.6963 10.0721C19.5605 10.1352 19.4371 10.2278 19.3332 10.3444L9.81239 21.07L9.02766 24.8111C8.99828 24.9725 8.99762 25.1388 9.02572 25.3005C9.05382 25.4622 9.11012 25.616 9.1914 25.7533C9.27268 25.8906 9.37734 26.0086 9.49939 26.1005C9.62144 26.1924 9.75848 26.2564 9.90266 26.2889C9.97424 26.2969 10.0464 26.2969 10.1179 26.2889C10.203 26.3044 10.2898 26.3044 10.3749 26.2889L13.7429 25.4566L23.2568 14.7778C23.3608 14.6621 23.4432 14.5246 23.4995 14.3731C23.5558 14.2215 23.5848 14.0591 23.5848 13.895C23.5848 13.7309 23.5558 13.5684 23.4995 13.4169C23.4432 13.2654 23.3608 13.1279 23.2568 13.0122V12.9655ZM13.0346 24.0411L10.4929 24.6711L11.111 21.8478L18.2499 13.7666L20.2082 15.96L13.0346 24.0411ZM20.9929 15.0811L19.0346 12.8878L20.1388 11.6666L22.111 13.8755L20.9929 15.0811Z" fill="#160B32" />
        </svg>
    );
}