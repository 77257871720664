const constants = {
    SUPPORT_BANNER: '/assets/images/support_banner.jpg',
    CONCIERGE_BANNER: '/assets/images/concierge_banner.jpg',
    UPLOAD_BANNER: '/assets/images/upload_banner.jpg',
    UPLOAD_BANNER_SEC: '/assets/images/upload_banner1.jpg',
    PAGE_NOT_FOUND_IMG: '/assets/images/404_page.jpg',
    MIN_CHAR: 3,
    MAX_CHAR: 10,
    MAX_CHAR_50: 50,
    MAX_CHAR_100: 100,
    ATTACHMENT_REQ: /(\.pdf)$/i,
    COLOR_GREEN: '#1EC365',
    COLOR_YELLOW: '#FFCC00',
    COLOR_BLUE: '#160B323D',
    COLOR_GRAY: '#F2F2F2',
    COLOR_GRAY2: '#C4C4C4',
    COLOR_RED2: '#EB5757',
    COLOR_RED3: '#B33717',
    COLOR_RED4: '#C88B7B',
    COLOR_GREEN2: '#1EC365',
    COLOR_GREEN3: '#1EC465',
    COLOR_DARK_BLUE: '#160B32',
    NOTIFICATION: [
        'negotiation_request',
        'settlement_offer_created',
        'manual_negotiation_closed',
        'offer_in_negotiation',
        'offer_settlement_started',
        'offer_settlement_rejected',
        'offer_settlement_payment_awaiting',
        'offer_settlement_payment_updated',
        'unpaid_invoice_of_enquiry_fees',
        'updated_paid_invoice_of_enquiry_fees',
        'upload_offer_added',
        'manual_offer_added',
    ],
    NOTIFICATION_URL: {
        'negotiation_request': {
            true: '/negotiation-details/',
            false: '/concierge/negotiation-details/',
        },
        'settlement_offer_created': {
            true: '/negotiation-details/',
        },
        'manual_negotiation_closed': {
            true: '/negotiation-requests',
            false: '/concierge/negotiations',
        },
        'offer_in_negotiation': {
            true: '/negotiation-details/',
            false: '/concierge/negotiation-details/',
        },
        'offer_settlement_started': {
            true: '/negotiation-requests',
            false: '/concierge/negotiations',
        },
        'offer_settlement_rejected': {
            true: '/negotiation-details/',
            false: '/concierge/negotiation-details/',
        },
        'offer_settlement_payment_awaiting': {
            true: '/deals-in-progress',
            false: '/concierge/deals-in-progress',
        },
        'offer_settlement_payment_updated': {
            true: '/deals-in-progress',
            false: '/concierge/deals-in-progress',
        },
        'unpaid_invoice_of_enquiry_fees': {
            true: '/my-profile',
            false: '/concierge/organisations',
        },
        'updated_paid_invoice_of_enquiry_fees': {
            true: '/my-profile',
            false: '/concierge/organisations',
        },
        'upload_offer_added': {
            true: '/my-offers-on-market',
            false: '/concierge/client-upload-requests',
        },
        'manual_offer_added': {
            true: '/my-offers-on-market',
            false: '/concierge/client-upload-requests',
        },
    },
}

export default constants;