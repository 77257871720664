import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TRLoader } from 'UIs/SkeletonLoader';
import { Pagination } from 'Plugins';
import fns from 'helpers/functions';
import * as NotificationService from 'Services/notification.service';
import { EyeButton } from 'components/interfaces/Buttons';

const AlertTable = ({ type }) => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(true);
    const [alerts, setAlerts] = useState(null);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fetchAlerts();

        return () => {
        }
    }, []);

    const fetchAlerts = async (page = 1) => {
        const res = await NotificationService.FetchAllNotificationAPI(type, page, sizePerPage);

        if (res.response) {
            setFetching(false);
            setAlerts(res.data.data);
            setTotalCount(res.data.totalSize);
        } else {
            setFetching(false);
            setAlerts(null);
            setTotalCount(0);
        }
    }

    const viewAlert = async (_id, url) => {
        const res = await NotificationService.FetchANotificationAPI(_id);

        if (res.response) {
            navigate(url);
        }
    }

    const TDClass = `text-xs px-4 py-3 text-center font-Averta whitespace-nowrap`;

    return (
        <div className='w-full float-left pl-16'>
            <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="py-2 inline-block min-w-full">
                            <div className="overflow-x-auto pb-2 xl:pb-0">
                                {
                                    fetching
                                        ?
                                        <TRLoader count={10} />
                                        :
                                        (
                                            alerts && alerts.length > 0
                                                ?
                                                <table className="min-w-full text-black mb-5">
                                                    <thead className="">
                                                        <tr>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Deal #</th>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Reference</th>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Date</th>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Status</th>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">By</th>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Action</th>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Attachment</th>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Next Steps</th>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Details</th>
                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className=''>
                                                        {
                                                            alerts.map((alert, index) => {
                                                                var viewurl = fns.URL_NOTIFICATION(alert?.type);
                                                                if (fns.IN_ARRAY(['negotiation_request', 'settlement_offer_created', 'offer_in_negotiation', 'offer_settlement_rejected'], alert?.type)) {
                                                                    var viewurl = viewurl + alert?.other_data?._ticket_id;
                                                                }

                                                                return (
                                                                    <tr key={index} className={`bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray`}>
                                                                        <td className={`${TDClass} ${alert?.read_at !== null ? 'text-[#757575]' : ''} min-w-[120px] xl:min-w-fit`}>
                                                                            {alert?.deal_id}
                                                                        </td>
                                                                        <td className={`${TDClass} ${alert?.read_at !== null ? 'text-[#757575]' : ''} min-w-[120px] xl:min-w-fit`}>
                                                                            {alert?.reference}
                                                                        </td>
                                                                        <td className={`${TDClass} ${alert?.read_at !== null ? 'text-[#757575]' : ''} min-w-[160px] xl:min-w-fit`}>
                                                                            {alert?.date}
                                                                        </td>
                                                                        <td className={`${TDClass} ${alert?.read_at !== null ? 'text-[#757575]' : ''} min-w-[120px] xl:min-w-fit ${alert?.read_at !== null ? '' : 'text-[#1EC365]'}`}>
                                                                            {alert?._status_text}
                                                                        </td>
                                                                        <td className={`${TDClass} ${alert?.read_at !== null ? 'text-[#757575]' : ''} min-w-[100px] xl:min-w-fit`}>
                                                                            {alert?.by_whom}
                                                                        </td>
                                                                        <td className={`${TDClass} ${alert?.read_at !== null ? 'text-[#757575]' : ''} min-w-[120px] xl:min-w-fit`}>
                                                                            {alert?.action}
                                                                        </td>
                                                                        <td className={`${TDClass} ${alert?.read_at !== null ? 'text-[#757575]' : ''} min-w-[100px] xl:min-w-fit`}>
                                                                            {alert?.attachment}
                                                                        </td>
                                                                        <td className={`${TDClass} ${alert?.read_at !== null ? 'text-[#757575]' : ''} min-w-[100px] xl:min-w-fit text-[#EB5757]`}>
                                                                            {alert?.next_step}
                                                                        </td>
                                                                        <td className={`${TDClass} ${alert?.read_at !== null ? 'text-[#757575]' : ''} min-w-[100px] xl:min-w-fit ${alert?.read_at !== null ? '' : 'text-[#1EC365]'} cursor-pointer`}>
                                                                            {
                                                                                viewurl ? <span>{alert?.read_at === null ? 'VIEW' : 'VIEWED'}</span> : null
                                                                            }
                                                                        </td>
                                                                        <td className={`${TDClass} min-w-[100px] xl:min-w-fit`}>
                                                                            {
                                                                                viewurl ? <VisitUrl _id={alert?._id} viewurl={viewurl} /> : null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <p className='text-center text-[14px] mt-2'>No notifications.</p>
                                        )
                                }

                                {
                                    total_count > sizePerPage
                                    &&
                                    <div className="float-left w-full">
                                        <div className="text-center float-left w-full">
                                            <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchAlerts(data.selected + 1) }} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertTable

const VisitUrl = ({ _id, viewurl }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(<EyeButton />);

    const viewAlert = async (_id, url) => {
        setLoading(<i className="fa-solid fa-spinner fa-spin"></i>);
        const res = await NotificationService.FetchANotificationAPI(_id);

        if (res.response) {
            navigate(url);
        } else {
            setLoading('View');
        }
    }

    return (
        <>
            <button onClick={(e) => { viewAlert(_id, viewurl); }} className='cursor-pointer'>{loading}</button>
        </>
    );
}