import { BlockLoader } from 'UIs/SkeletonLoader';
import React, { useEffect } from 'react'
import { useState } from 'react'
import * as ManageOfferService from 'Services/manage-offers.service';
import fns from 'helpers/functions';
import { Breadcrumb } from 'modules/fragments';
import { Pagination } from 'Plugins';
import { Link } from 'react-router-dom';

const ClosedOffers = () => {
    const [offerGroups, setOfferGroups] = useState(null);
    const [fetching, setFetching] = useState(true);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fns.CHECK_PERMISSION(["A5"], true);
        fetchDeals();

        return () => {
        }
    }, []);

    const fetchDeals = async (page = 1) => {
        const res = await ManageOfferService.FetchClosedDealsAPI(page, sizePerPage);

        if (res.response) {
            setFetching(false);
            setOfferGroups(res?.data?.data);
            setTotalCount(res?.data?.totalSize);
        } else {
            setFetching(false);
            setOfferGroups(null);
            setTotalCount(0);
        }
    }

    return (
        <>
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <Breadcrumb page={'MY CLOSED DEALS'} desc={' '} contact={null} />

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            {
                                fetching
                                    ?
                                    <>
                                        <div className='mb-10'><BlockLoader count={1} width={"100%"} height={'200px'} /></div>
                                        <div className='mb-10'><BlockLoader count={1} width={"100%"} height={'200px'} /></div>
                                        <div className='mb-10'><BlockLoader count={1} width={"100%"} height={'200px'} /></div>
                                    </>
                                    :
                                    (offerGroups !== null && typeof offerGroups !== 'undefined' && offerGroups.length > 0 && Object.keys(offerGroups[0]).length > 0)
                                        ?
                                        <>
                                            {
                                                Object.keys(offerGroups).map((keyName, i) => (
                                                    <div key={i} div className='float-left w-full'>
                                                        <div className='float-left w-full'>
                                                            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                                                <svg className='inline mr-2' width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g filter="url(#filter0_d_15_6)">
                                                                        <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                                                    </g>
                                                                    <path d="M16.875 30.75H36.125V33.5H16.875V30.75ZM23.2 27.0375L16.875 20.7125L19.625 18.1L23.2 21.675L33.375 11.5L36.125 14.25L23.2 27.0375Z" fill="#160B32" />
                                                                    <defs>
                                                                        <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                            <feOffset dy="4" />
                                                                            <feGaussianBlur stdDeviation="2" />
                                                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                                        </filter>
                                                                    </defs>
                                                                </svg>

                                                                <span className='uppercase'>{fns.STR_REPLACE(offerGroups[keyName]?.type, '_', ' ')}# {offerGroups[keyName]?.id_number_or_passport}</span>
                                                                <div className='w-full float-left pl-16 mt-2'>
                                                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-8 sm:pt-3 px-4 sm:px-8 pb-5 mb-10 relative">
                                                                        <div className='absolute left-1 top-1 text-center'></div>
                                                                        <div className="flex flex-col">
                                                                            <div className="overflow-x-auto">
                                                                                <div className="py-2 inline-block min-w-full">
                                                                                    <div className="overflow-x-auto mb-2">
                                                                                        <table className="min-w-full text-black">
                                                                                            <thead className="">
                                                                                                <tr>
                                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Uploaded</th>
                                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Outstanding Balance</th>
                                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">SH Ref</th>
                                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Account Type</th>
                                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Value</th>
                                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settled</th>
                                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Party</th>
                                                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">View Deal</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className=''>
                                                                                                {
                                                                                                    offerGroups[keyName]?.offers.map((offer, index) => (
                                                                                                        <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                                {offer?.uploaded_date}
                                                                                                            </td>
                                                                                                            <td className="text-xs px-4 py-2 text-center text-[#EB5757] font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                                {offer?._outstanding_balance_text}
                                                                                                            </td>
                                                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                                {offer?.sh_ref ? offer?.sh_ref : 'N/A'}
                                                                                                            </td>
                                                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                                {offer?._account_type_text}
                                                                                                            </td>
                                                                                                            <td className="text-xs px-4 py-2 text-center text-[#1EC365] font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                                {offer?._settlement_value_text}
                                                                                                            </td>
                                                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                                {offer?.settling_by_user}
                                                                                                            </td>
                                                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                                {offer?.settled_at}
                                                                                                            </td>
                                                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit cursor-pointer">
                                                                                                                <Link to={`/deal/${offer?._id}/details`}>
                                                                                                                    <svg className='m-auto' width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <path d="M10.4415 13.5C10.4415 14.2832 10.7526 15.0344 11.3064 15.5882C11.8602 16.142 12.6114 16.4531 13.3946 16.4531C14.1778 16.4531 14.9289 16.142 15.4828 15.5882C16.0366 15.0344 16.3477 14.2832 16.3477 13.5C16.3477 12.7168 16.0366 11.9656 15.4828 11.4118C14.9289 10.858 14.1778 10.5469 13.3946 10.5469C12.6114 10.5469 11.8602 10.858 11.3064 11.4118C10.7526 11.9656 10.4415 12.7168 10.4415 13.5ZM24.8432 12.8197C22.3436 7.5542 18.5652 4.9043 13.5001 4.9043C8.43229 4.9043 4.65651 7.5542 2.1569 12.8224C2.05664 13.0347 2.00464 13.2665 2.00464 13.5013C2.00464 13.7361 2.05664 13.968 2.1569 14.1803C4.65651 19.4458 8.43492 22.0957 13.5001 22.0957C18.5678 22.0957 22.3436 19.4458 24.8432 14.1776C25.0463 13.7505 25.0463 13.2548 24.8432 12.8197ZM13.3946 18.1406C10.8317 18.1406 8.75397 16.0629 8.75397 13.5C8.75397 10.9371 10.8317 8.85938 13.3946 8.85938C15.9575 8.85938 18.0352 10.9371 18.0352 13.5C18.0352 16.0629 15.9575 18.1406 13.3946 18.1406Z" fill="#160B32" />
                                                                                                                    </svg>
                                                                                                                </Link>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                        :
                                        <div className='float-left w-full'>
                                            <div className='float-left w-full'>
                                                <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                                    <div className='w-full float-left pl-16'>
                                                        <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-8 sm:pt-3 px-4 sm:px-8 pb-5 mb-10 relative">
                                                            <div className='absolute left-1 top-1 text-center'></div>
                                                            <div className="flex flex-col">
                                                                <div className="overflow-x-auto">
                                                                    <div className="py-2 inline-block min-w-full">
                                                                        <div className="overflow-x-auto mt-2 text-center">
                                                                            <p>No deals in progress</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            }

                            {
                                total_count > sizePerPage
                                &&
                                <div className="float-left w-full">
                                    <div className="text-center float-left w-full">
                                        <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchDeals(data.selected + 1) }} />
                                    </div>
                                </div>
                            }

                            {
                                fns.CHECK_PERMISSION(["A3", "A4"])
                                    ?
                                    <Link to={`/my-offers-on-market`} className='font-Averta_Semibold text-xs cursor-pointer float-left w-full leading-8 bg-[#F2F2F2] shadow-md shadow-black/25 rounded-lg p-2 px-3 hover:text-burgandy overflow-hidden'>
                                        <div className='inline-block align-middle mr-3'>
                                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.89 4H7.83001C7.33488 4.01311 6.86493 4.22107 6.52227 4.57871C6.17961 4.93635 5.99193 5.41477 6.00001 5.91V30.09C5.99193 30.5852 6.17961 31.0636 6.52227 31.4213C6.86493 31.7789 7.33488 31.9869 7.83001 32H28.17C28.6651 31.9869 29.1351 31.7789 29.4777 31.4213C29.8204 31.0636 30.0081 30.5852 30 30.09V11.92L21.89 4ZM21 13V5.84L28.3 13H21Z" fill="#160B32" />
                                            </svg>
                                        </div>
                                        {''} OFFER ON THE MARKET
                                        <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                    </Link>
                                    :
                                    null
                            }
                        </div>
                    </div>

                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    )
}

export default ClosedOffers