import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="clear-both"></div>
            <footer className="bg-blue p-5 pl-8">
                <ul className="max-w-md flex justify-between font-Averta">
                    <li><Link className="text-white text-xs font-Averta" to={`/support`}>{t('word-support')}</Link></li>
                    <li><Link className="text-white text-xs font-Averta" to={`/page/terms-conditions`}>{t('word-term-condition')}</Link></li>
                    <li><Link className="text-white text-xs font-Averta" to={`/page/faqs`}>{t('word-faqs')}</Link></li>
                </ul>
            </footer>
        </>
    )
};

export default Footer;