import axios from "axios";
import fns from "helpers/functions";
import authHeader from './auth-header';

const END_POINT = `${process.env.REACT_APP_API_URL}offers/`;

/**
 * Search offer by ID/passport number.
 * @type POST Request
 * @param {FormData} form Formdata object
 * @returns 
 */
export const SearchOffersAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}search`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`SearchOffersAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Search offer by ID/passport number.
 * @type POST Request
 * @param {FormData} form Formdata object
 * @returns 
 */
export const DownloadOfferCsvAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}download-csv`, form, {
        headers: authHeader(),
        responseType: 'blob',
    }).then((response) => {
        fns.DOWNLOAD_RESPONSE(response.data, Date.now() + '_offer.csv');
    }).catch((error) => {
        console.log(`DownloadOfferCsvAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Search offer by ID/passport number.
 * @type POST Request
 * @param {FormData} form Formdata object
 * @returns 
 */
export const DownloadOfferPdfAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}download-pdf/${_id}`, {}, {
        headers: authHeader(),
        responseType: 'blob',
    }).then((response) => {
        fns.DOWNLOAD_RESPONSE(response.data, Date.now() + '_offer.pdf');
    }).catch((error) => {
        console.log(`DownloadOfferPdfAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Lock and offer by given ID.
 * @type POST Request
 * @param {FormData} form Formdata object
 * @returns 
 */
export const LockOffersAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}make-lock`, form, {
        headers: authHeader(),
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`LockOffersAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Unlock and offer by given ID.
 * @type POST Request
 * @param {FormData} form Formdata object
 * @returns 
 */
export const UnlockOffersAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}make-unlock`, form, {
        headers: authHeader(),
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`UnlockOffersAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Unlock and offer by given ID.
 * @type POST Request
 * @param {FormData} form Formdata object
 * @returns 
 */
export const ExtendLockAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}extend-lock`, form, {
        headers: authHeader(),
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`ExtendLockAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }

            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Settle and offer by given ID.
 * @type POST Request
 * @param {FormData} form Formdata object
 * @returns 
 */
export const SettleOfferAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}make-settle`, form, {
        headers: authHeader(),
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`SettleOfferAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }

            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals in progress.
 * @type GET Request
 * @returns 
 */
export const FetchDealsInProgressAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}my-in-progress-offers`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchDealsInProgressAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals closed.
 * @type GET Request
 * @returns 
 */
export const FetchClosedDealsAPI = async (page, sizePerPage) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}my-closed-offers?page=${page}&sizePerPage=${sizePerPage}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchClosedDealsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals in progress for concierge.
 * @type GET Request
 * @returns 
 */
export const FetchDealsConciergeAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${process.env.REACT_APP_API_URL}concierge/offers/deals-in-progress`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchDealsConciergeAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals in progress.
 * @type GET Request
 * @returns 
 */
export const FetchLockedOffersAPI = async (page, sizePerPage) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}my-locked-offers?page=${page}&sizePerPage=${sizePerPage}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`FetchLockedOffersAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals in progress.
 * @type GET Request
 * @returns 
 */
export const FetchLiveOffersAPI = async (page, sizePerPage) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}my-offers-on-market?page=${page}&sizePerPage=${sizePerPage}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchLiveOffersAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals in progress.
 * @type GET Request
 * @returns 
 */
export const FetchLiveOfferAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}my-offers-on-market/${_id}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchLiveOfferAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals in progress.
 * @type GET Request
 * @returns 
 */
export const DeleteLiveOfferAPI = async (id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.delete(`${END_POINT}my-offers-on-market/${id}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`DeleteLiveOfferAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals details.
 * @type GET Request
 * @returns 
 */
export const FetchDealDetailsAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}${_id}/details`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchDealDetailsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals payment details.
 * @type GET Request
 * @returns 
 */
export const FetchDealPaymentsAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}${_id}/payment-log`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchDealPaymentsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals event details.
 * @type GET Request
 * @returns 
 */
export const FetchDealeventsAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}${_id}/event-log`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`FetchDealeventsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals event details.
 * @type POST Request
 * @returns 
 */
export const UpdateResPersonAPI = async (_id, _mid) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}${_id}/update-responsible-person`, { "responsible_user_id": _mid }, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`UpdateResPersonAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Create payment offer.
 * @type Post Request
 * @returns 
 */
export const CreatePaymentAPI = async (_id, form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${process.env.REACT_APP_API_URL}concierge/offers/${_id}/payment-request`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`CreatePaymentAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch deals event details.
 * @type GET Request
 * @returns 
 */
export const UpdatePaymentAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${process.env.REACT_APP_API_URL}concierge/offers/payment-request/${_id}/update-payment-status`, { "status": "payment_received" }, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`UpdateResPersonAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}