import axios from "axios";
import authHeader from './auth-header';

const END_POINT = `${process.env.REACT_APP_API_URL}`;

/**
 * Fetch Demo video link and sample CSV.
 * @type GET Request
 * @returns 
 */
export const FetchUploadDemoAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}upload-loan/page-data`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchUploadDemoAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Upload client upload from CSV file.
 * @param {FormData} form Form data
 * @returns 
 */
export const UploadCsvAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}upload-loan/csv`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`UploadCsvAPI: ${error.message}`);
        rResp = false;
        rData = error?.response?.data?.data;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch Account Types.
 * @type GET Request
 * @returns 
 */
export const FetchAccountTypesAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}account-types`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchAccountTypesAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch Account Types.
 * @type GET Request
 * @returns 
 */
export const FetchValidityListAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}offer-validity-list`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchValidityListAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Upload client upload from form.
 * @param {FormData} form Form data
 * @returns 
 */
export const UploadFormOfferAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}manual-offers/create`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`UploadFormOfferAPI: ${error.message}`);
        rResp = false;
        rData = error?.response?.data?.data;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Update client offer.
 * @param {FormData} form Form data
 * @returns 
 */
export const UpdateOfferAPI = async (_id, form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.put(`${END_POINT}offers/my-offers-on-market/${_id}`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`UpdateOfferAPI: ${error.message}`);
        rResp = false;
        rData = error?.response?.data?.data;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch pending offers for concierge to approve/reject.
 * @type GET Request
 * @returns 
 */
export const FetchOfferRequestsAPI = async (page, sizePerPage) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}concierge/pending-offers?page=${page}&sizePerPage=${sizePerPage}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchOfferRequestsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Update pending offer status.
 * @param {FormData} form Form data
 * @returns 
 */
export const UpdateOfferStatusAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}concierge/pending-offers/change-status`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`UpdateOfferStatusAPI: ${error.message}`);
        rResp = false;
        rData = error?.response?.data?.data;

        if (error?.response?.status === 4011) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}