import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'modules/fragments'
import fns from 'helpers/functions';
import EventLogs from './EventLogs';
import Payment from './Payment';
import Members from './Members';
import { GoBack } from 'UIs/Buttons';

const OrganisationDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlState = location.state;

    const [active_tab, setActiveTab] = useState(1);

    useEffect(() => {
        fns.CHECK_PERMISSION(["C1", "C3"], true);
        if (!urlState) {
            navigate(`/`);
        }

        return () => {
        }
    }, []);

    return (
        urlState
            ?
            <>
                <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                    <div className='w-full float-left'>

                        <Breadcrumb
                            page={'Organisation Details'}
                            desc={urlState?.institution_name}
                            contact={<GoBack onClick={() => { navigate(`/concierge/organisations`); }} name={'Go back'} />}
                        />

                        <div className='sm:w-11/12 w-full mx-auto px-5 py-5 pt-0 relative text-center'>
                            <nav className="m-auto max-w-[385px] w-full float-left lg:float-none mb-2">
                                <button onClick={() => { setActiveTab(1); }} className={`uppercase max-w-[125px] w-full float-left text-xs py-1 px-2 border ${active_tab === 1 ? 'bg-burgandy text-white border-black focus:outline-none' : 'hover:bg-burgandy hover:text-white border-black focus:outline-none'}`}>Members</button>
                                <button onClick={() => { setActiveTab(2); }} className={`uppercase max-w-[125px] w-full float-left text-xs py-1 px-2 border ${active_tab === 2 ? 'bg-burgandy text-white border-black focus:outline-none' : 'hover:bg-burgandy hover:text-white border-black focus:outline-none'}`}>Event Log</button>
                                <button onClick={() => { setActiveTab(3); }} className={`uppercase max-w-[125px] w-full float-left text-xs py-1 px-2 border ${active_tab === 3 ? 'bg-burgandy text-white border-black focus:outline-none' : 'hover:bg-burgandy hover:text-white border-black focus:outline-none'}`}>Payment</button>
                                <div className='clear-both'></div>
                            </nav>
                            <div className='clear-both'></div>
                        </div>

                        <div className='w-full float-left font-Averta'>
                            <div className="sm:w-11/12 w-full mx-auto px-5">
                                <div className='float-left w-full'>
                                    <div className='float-left w-full'>
                                        {
                                            active_tab === 1
                                                ?
                                                <Members />
                                                :
                                                null
                                        }

                                        {
                                            active_tab === 2
                                                ?
                                                <EventLogs />
                                                :
                                                null
                                        }

                                        {
                                            active_tab === 3
                                                ?
                                                <Payment />
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className='clear-both'></div>
                            </div>
                        </div>

                        <div className='clear-both'></div>
                    </div>
                </section>
            </>
            :
            null
    )
}

export default OrganisationDetails