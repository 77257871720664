import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Dropdown } from 'flowbite-react';
import authContext from 'contexts/auth/authContext';
import fns from 'helpers/functions';
import { useState } from 'react';
import * as NotificationService from 'Services/notification.service';

const Navbar = () => {
    const location = useLocation();
    const cntxAuth = useContext(authContext);
    const user = fns.LOGGED_USER();
    const [alert_count, setAlertCount] = useState(0);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (cntxAuth.isLogged) {
            setInterval(() => {
                fetchAlertCount();
            }, 15000);
        }

        return () => {
        }
    }, [alert_count])


    const fetchAlertCount = async () => {
        const res = await NotificationService.FetchNotificationCountsAPI();

        if (res.response) {
            setAlertCount(res?.data?.count ?? 0);
        } else {
            setAlertCount(0);
        }
    }

    return (
        <>
            <header className="bg-blue text-center shadow-md shadow-black/50 z-50 clear-both font-Averta sticky top-0">
                <div className="bg-burgandy float-left content-start items-center p-3 py-5 sm:p-3">
                    <Link to={`/`}><img className="sm:w-36 w-24" src={`${process.env.REACT_APP_FRONT_URL}assets/images/logo.svg`} alt="" /></Link>
                </div>
                {
                    cntxAuth.isLogged
                        ?
                        <>
                            <div className="float-left p-3 py-7 sm:p-6 sm:mt-1.5">
                                <h4 className='text-white text-sm max-w-[100px] truncate md:max-w-xs font-Averta_Bold uppercase'>{user?.is_concierge ? 'Concierge' : `${user?.organization_name}`}</h4>
                            </div>
                            <nav className="float-right grid gap-5 sm:gap-10 grid-cols-3 grid-rows-1  p-3 py-6 sm:p-6 mt-0.5">
                                <NavLink to={`/`}>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.8334 21.6669V13.1887C23.8335 12.5989 23.7131 12.0152 23.4797 11.4735C23.2463 10.9318 22.9048 10.4434 22.476 10.0384L14.4886 2.4897C14.0863 2.10937 13.5537 1.89746 13.0001 1.89746C12.4465 1.89746 11.9139 2.10937 11.5116 2.4897L3.52416 10.0384C3.09541 10.4434 2.75386 10.9318 2.52045 11.4735C2.28704 12.0152 2.16669 12.5989 2.16675 13.1887V21.6669C2.16675 22.2415 2.39502 22.7926 2.80135 23.1989C3.20768 23.6053 3.75878 23.8335 4.33341 23.8335H21.6667C22.2414 23.8335 22.7925 23.6053 23.1988 23.1989C23.6051 22.7926 23.8334 22.2415 23.8334 21.6669Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </NavLink>
                                <Link to={`/all-notifications`} className='cursor-pointer'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {
                                        alert_count > 0 ? <span className="absolute mt-[-25px] ml-[-10px] p-[5px] inline-block w-2 h-2 mr-2 bg-red-600 rounded-full">&nbsp;</span> : null
                                    }
                                </Link>
                                <Dropdown
                                    label={
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 12.5H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3 6.25H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3 18.75H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    }
                                    inline={true}
                                    arrowIcon={true}
                                >
                                    <Dropdown.Item>
                                        <Link to={`/my-profile`} className='cursor-pointer text-blue hover:text-burgandy'>
                                            <i className="fa fa-user text-md mr-1" aria-hidden="true"></i> <span>Profile</span>
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={`/logout`} className='cursor-pointer text-blue hover:text-burgandy'>
                                            <i className="fa fa-sign-out text-md" aria-hidden="true"></i> <span>Logout</span>
                                        </Link>
                                    </Dropdown.Item>
                                </Dropdown>
                            </nav>
                        </>
                        :
                        null
                }
                <div className="clear-both"></div>
            </header>
            <div className="clear-both"></div>
        </>
    )
};

export default Navbar;