import axios from "axios";
import authHeader from './auth-header';

const END_POINT = `${process.env.REACT_APP_API_URL}roles`;

/**
 * Fetch all permission related to the logged users organisation
 * @returns 
 */
export const FetchPermissionsAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${process.env.REACT_APP_API_URL}permissions`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchPermissionsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch all roles related to the logged users organisation
 * @returns 
 */
export const FetchRolesAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchRolesAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch single roles related from given id
 * @returns 
 */
export const FetchSingleRoleAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}/${_id}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchSingleRoleAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Save Role API call
 * @param {FormData} form Formdata object with role with permissions
 * @returns 
 */
export const SaveRoleAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}/create`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`SaveRoleAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }

            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Update Role API call
 * @param {string} _id Role id to update role
 * @param {FormData} form Formdata object with role with permissions
 * @returns 
 */
export const UpdateRoleAPI = async (_id, form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.put(`${END_POINT}/${_id}`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`UpdateRoleAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }

            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}