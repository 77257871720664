import React from 'react';
import { Carousel } from 'flowbite-react';
import { Link } from 'react-router-dom';
import fns from 'helpers/functions';
import QuickMenus from './QuickMenus';
import constants from 'helpers/constants';

const Dashboard = () => {
    const user = fns.LOGGED_USER();

    return (
        <section className="lg:min-h-[calc(100vh-80px-64px)] w-full float-left font-Averta m-0">
            <div className='w-full float-left relative'>
                <Carousel slideInterval={5000} indicators={false} leftControl={<></>} rightControl={<></>} base={"!rounded-none"}>
                    <img className='w-full float-left h-screen max-h-[874px] md:max-h-screen object-cover relative' src={constants.UPLOAD_BANNER} alt="" />
                    <img className='w-full float-left h-screen max-h-[874px] md:max-h-screen object-cover relative' src={constants.UPLOAD_BANNER_SEC} alt="" />
                </Carousel>
                <div className='absolute left-6 sm:left-10 top-2/4 -translate-y-2/4'>
                    <ul className='w-24 p-1 bg-white rounded-xl shadow-md shadow-black/25 text-center'>
                        {
                            fns.CHECK_PERMISSION(["B4"])
                                ?
                                <li className='py-5'>
                                    <Link to={`/deals-in-progress`} className='cursor-pointer m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy'>
                                        <svg className='m-auto mb-1' width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 2.375C15.7119 2.375 12.4976 3.35004 9.76365 5.17682C7.02968 7.0036 4.89882 9.60007 3.64051 12.6379C2.3822 15.6757 2.05297 19.0184 2.69445 22.2434C3.33593 25.4683 4.91931 28.4306 7.24436 30.7556C9.5694 33.0807 12.5317 34.6641 15.7566 35.3056C18.9816 35.947 22.3243 35.6178 25.3621 34.3595C28.3999 33.1012 30.9964 30.9703 32.8232 28.2364C34.65 25.5024 35.625 22.2881 35.625 19C35.62 14.5923 33.8668 10.3666 30.7501 7.2499C27.6334 4.1332 23.4077 2.38003 19 2.375ZM19 33.25C15.2207 33.25 11.5961 31.7487 8.92374 29.0763C6.25134 26.4039 4.75001 22.7793 4.75001 19C4.75001 15.2207 6.25134 11.5961 8.92374 8.92373C11.5961 6.25133 15.2207 4.75 19 4.75V19L29.0712 29.0712C27.7504 30.3967 26.1808 31.4482 24.4525 32.1653C22.7242 32.8824 20.8712 33.2511 19 33.25Z" fill="#160B32" />
                                        </svg>
                                        MY DEALS
                                    </Link>
                                </li>
                                :
                                null
                        }
                        {
                            fns.CHECK_PERMISSION(["B3"])
                                ?
                                <li className='py-5'>
                                    <Link to={`/locked-offers`} className='cursor-pointer m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy'>
                                        <svg className='m-auto mb-1' width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M30 17.5001C30 15.8915 28.6545 14.5834 27 14.5834H25.5V10.2084C25.5 6.18779 22.1355 2.91675 18 2.91675C13.8645 2.91675 10.5 6.18779 10.5 10.2084V14.5834H9C7.3455 14.5834 6 15.8915 6 17.5001V29.1668C6 30.7753 7.3455 32.0834 9 32.0834H27C28.6545 32.0834 30 30.7753 30 29.1668V17.5001ZM13.5 10.2084C13.5 7.79633 15.519 5.83342 18 5.83342C20.481 5.83342 22.5 7.79633 22.5 10.2084V14.5834H13.5V10.2084Z" fill="#160B32" />
                                        </svg>
                                        MY LOCKED OFFERS
                                    </Link>
                                </li>
                                :
                                null
                        }
                        {
                            fns.CHECK_PERMISSION(["B5"])
                                ?
                                <li className='py-5'>
                                    <Link to={`/negotiation-requests`} className='cursor-pointer m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy'>
                                        <svg className='m-auto mb-1' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.33337 22.1666V12.8333H5.83337C4.90512 12.8333 4.01488 13.202 3.3585 13.8584C2.70212 14.5148 2.33337 15.405 2.33337 16.3333V37.3333C2.33511 37.5499 2.39715 37.7618 2.51255 37.9452C2.62794 38.1286 2.79213 38.2763 2.98671 38.3716C3.17417 38.4568 3.38085 38.491 3.58576 38.4705C3.79067 38.45 3.98651 38.3756 4.15337 38.2549L10.1617 33.8333H25.8417C26.2839 33.8463 26.724 33.7685 27.1349 33.6045C27.5458 33.4406 27.9186 33.194 28.2303 32.8801C28.542 32.5662 28.786 32.1917 28.947 31.7797C29.1081 31.3677 29.1829 30.927 29.1667 30.4849V29.1666H16.3334C14.4769 29.1666 12.6964 28.4291 11.3836 27.1163C10.0709 25.8036 9.33337 24.0231 9.33337 22.1666Z" fill="#160B32" />
                                            <path d="M36.1667 4.66675H16.3334C15.4051 4.66675 14.5149 5.0355 13.8585 5.69187C13.2021 6.34825 12.8334 7.23849 12.8334 8.16675V22.1667C12.8334 23.095 13.2021 23.9852 13.8585 24.6416C14.5149 25.298 15.4051 25.6667 16.3334 25.6667H32.1417L37.7184 29.9951C37.8842 30.1177 38.0795 30.1941 38.2844 30.2166C38.4893 30.2392 38.6966 30.207 38.885 30.1234C39.0836 30.0289 39.2513 29.8801 39.369 29.6943C39.4867 29.5086 39.5494 29.2933 39.55 29.0734V8.16675C39.5505 7.25835 39.1979 6.38535 38.5665 5.73223C37.9351 5.0791 37.0746 4.69703 36.1667 4.66675Z" fill="#160B32" />
                                        </svg>
                                        MY REQUESTS
                                    </Link>
                                </li>
                                :
                                null
                        }

                        {
                            fns.CHECK_PERMISSION(["A3", "A4"])
                                ?
                                <li className='py-5'>
                                    <Link to={`/my-offers-on-market`} className='cursor-pointer m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy'>
                                        <svg className='m-auto mb-1' width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.5384 4.77783H9.13505C8.5574 4.79349 8.00913 5.04189 7.60936 5.46907C7.20959 5.89625 6.99062 6.46769 7.00005 7.05922V35.9409C6.99062 36.5324 7.20959 37.1039 7.60936 37.531C8.00913 37.9582 8.5574 38.2066 9.13505 38.2223H32.865C33.4427 38.2066 33.991 37.9582 34.3907 37.531C34.7905 37.1039 35.0095 36.5324 35 35.9409V14.2378L25.5384 4.77783ZM24.5 15.5278V6.97561L33.0167 15.5278H24.5Z" fill="#160B32" />
                                        </svg>
                                        MY MARKET OFFERS
                                    </Link>
                                </li>
                                :
                                null
                        }
                        <li className='py-5'>
                            <Link className='m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy uppercase' to={`/my-profile`}>
                                <svg className='m-auto mb-1' width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.5001 4.62891C17.8934 4.62891 17.2926 4.74842 16.732 4.98061C16.1714 5.21281 15.6621 5.55314 15.233 5.98218C14.804 6.41122 14.4637 6.92057 14.2315 7.48114C13.9993 8.04171 13.8798 8.64253 13.8798 9.24928C13.8798 9.85604 13.9993 10.4569 14.2315 11.0174C14.4637 11.578 14.804 12.0873 15.233 12.5164C15.6621 12.9454 16.1714 13.2858 16.732 13.518C17.2926 13.7501 17.8934 13.8697 18.5001 13.8697C19.7255 13.8697 20.9007 13.3829 21.7672 12.5164C22.6337 11.6499 23.1205 10.4747 23.1205 9.24928C23.1205 8.02388 22.6337 6.84867 21.7672 5.98218C20.9007 5.11569 19.7255 4.62891 18.5001 4.62891Z" fill="#160B32" />
                                    <path d="M28.9062 6.9375C27.9863 6.9375 27.104 7.30296 26.4535 7.95347C25.803 8.60399 25.4375 9.48628 25.4375 10.4062C25.4375 11.3262 25.803 12.2085 26.4535 12.859C27.104 13.5095 27.9863 13.875 28.9062 13.875C29.8262 13.875 30.7085 13.5095 31.359 12.859C32.0095 12.2085 32.375 11.3262 32.375 10.4062C32.375 9.48628 32.0095 8.60399 31.359 7.95347C30.7085 7.30296 29.8262 6.9375 28.9062 6.9375Z" fill="#160B32" />
                                    <path d="M8.09375 6.9375C7.17378 6.9375 6.29149 7.30296 5.64097 7.95347C4.99046 8.60399 4.625 9.48628 4.625 10.4062C4.625 11.3262 4.99046 12.2085 5.64097 12.859C6.29149 13.5095 7.17378 13.875 8.09375 13.875C9.01372 13.875 9.89601 13.5095 10.5465 12.859C11.197 12.2085 11.5625 11.3262 11.5625 10.4062C11.5625 9.48628 11.197 8.60399 10.5465 7.95347C9.89601 7.30296 9.01372 6.9375 8.09375 6.9375Z" fill="#160B32" />
                                    <path d="M11.5625 18.4838C11.5668 17.8733 11.8123 17.2893 12.2455 16.8591C12.6787 16.4289 13.2645 16.1875 13.875 16.1875H23.125C23.7383 16.1875 24.3265 16.4311 24.7602 16.8648C25.1939 17.2985 25.4375 17.8867 25.4375 18.5V25.4375C25.4373 26.1653 25.3234 26.8886 25.0999 27.5812C24.5878 29.1507 23.533 30.4862 22.1249 31.348C20.7167 32.2098 19.0475 32.5413 17.4169 32.2831C15.7863 32.0249 14.3011 31.1939 13.2282 29.9392C12.1552 28.6845 11.5646 27.0884 11.5625 25.4375V18.5V18.4838Z" fill="#160B32" />
                                    <path d="M9.25 18.5C9.25 17.6559 9.47431 16.8674 9.86975 16.1875H4.625C4.01169 16.1875 3.42349 16.4311 2.98982 16.8648C2.55614 17.2985 2.3125 17.8867 2.3125 18.5V24.2812C2.31219 25.2278 2.54427 26.1599 2.98838 26.9957C3.43249 27.8316 4.07503 28.5456 4.85958 29.0751C5.64414 29.6047 6.5467 29.9334 7.48801 30.0326C8.42931 30.1318 9.38057 29.9983 10.2582 29.6439C9.59266 28.341 9.24704 26.8983 9.25 25.4352V18.5Z" fill="#160B32" />
                                    <path d="M27.7502 18.5V25.4375C27.7502 26.9522 27.3871 28.3813 26.7419 29.6439C27.6196 29.9983 28.5709 30.1318 29.5122 30.0326C30.4535 29.9334 31.3561 29.6047 32.1406 29.0751C32.9252 28.5456 33.5677 27.8316 34.0118 26.9957C34.4559 26.1599 34.688 25.2278 34.6877 24.2812V18.5C34.6877 17.8867 34.4441 17.2985 34.0104 16.8648C33.5767 16.4311 32.9885 16.1875 32.3752 16.1875H27.1304C27.5236 16.8674 27.7502 17.6559 27.7502 18.5Z" fill="#160B32" />
                                </svg>
                                My team
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="w-8/12 sm:w-10/12 lg:w-9/12 lg:mx-auto right-0 lg:right-0 px-5 absolute lg:inset-x-0 top-2/4 -translate-y-2/4">
                    {
                        (!user?.is_concierge && (fns.CHECK_PERMISSION(["A1", "A2", "A3", "A4"])))
                        &&
                        <div className="md:w-[270px] w-full float-left bg-burgandy rounded-3xl shadow-md shadow-black/25 pt-8 px-8 pb-5">
                            <h2 className="text-white text-2xl md:text-3xl font-Averta_Bold pb-5">Try our Quick Offer Upload Feature</h2>
                            <p className='text-white text-lg pb-5 font-Averta'>Create one Settlement Offer, or batch upload, we'll have them on the marketplace in a snap.</p>
                            <Link to={`/upload-client`} className="cursor-pointer bg-blue text-white block text-center py-4 text-base font-Averta_Bold rounded-md hover:bg-white hover:text-blue">UPLOAD OFFERS</Link>
                        </div>
                    }
                    {
                        (!user?.is_concierge && (fns.CHECK_PERMISSION(["B1", "B2", "B3", "B4", "B5"])))
                        &&
                        <div className="md:w-[270px] w-full mt-10 md:mt-0 float-right bg-white rounded-3xl shadow-md shadow-black/25 pt-6 px-8 pb-5">
                            <h2 className="text-blue text-2xl md:text-5xl font-Averta_Black pb-5">Search. Match. Settle.</h2>
                            <p className='text-blue text-lg pb-5 font-Averta'>Find an offer, choose to settle, and let us do the work for you.</p>
                            <Link to={`/search-offers`} className="cursor-pointer bg-blue text-white block text-center py-4 text-base font-Averta_Bold rounded-md hover:bg-burgandy">SEARCH OFFERS</Link>
                        </div>
                    }
                </div>
            </div>

            <QuickMenus />
        </section>
    )
};

export default Dashboard;