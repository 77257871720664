import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LineLoader, ButtonLoader } from 'UIs/SkeletonLoader';
import fns from 'helpers/functions';
import * as OrgsService from 'Services/organisation.service';
import { GoBack } from 'components/interfaces/Buttons';
const PrimaryDetails = () => {
    const { _id } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();

    const [processing, setProcessing] = useState(false);
    const [organisation, setOrganisation] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fns.CHECK_PERMISSION(["C1"], true);

        if (_id !== '' && typeof _id !== 'undefined') {
            fetchOrgDetails(_id);
        } else {
            navigate(`/concierge/organisations`);
        }
    }, [reset]);

    /** Fetch organisation details with of given ID */
    const fetchOrgDetails = async () => {
        const res = await OrgsService.FetchOrgDetailsAPI(_id);

        if (res.response) {
            setLoading(false);
            setOrganisation(res.data);
            reset(res.data);
        } else {
            setLoading(false);
            setOrganisation(null);
        }
    }

    /** Save Primary details */
    const savePrimaryDetails = async (form) => {
        setProcessing(true);
        const res = await OrgsService.SaveOrgsStep3API(form);

        if (res.response) {
            setProcessing(false);
            fns.TOAST_SUC(res.data.message);
            navigate(`/concierge/organisations`);
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        !loading
            ?
            (
                (_id !== '' || typeof _id !== 'undefined')
                    ?
                    <>
                        <section className="w-full float-left font-Averta md:flex md:items-center">
                            <div className="lg:container mx-auto px-5">
                                <GoBack onClick={() => { navigate(`/concierge/organisation/${_id}/bank-details`); }} name={'Go back'} />
                            </div>
                        </section>
                        <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-5 w-full float-left font-Averta md:flex md:items-top">
                            <div className="lg:container mx-auto px-5">
                                <ul className="md:max-w-sm w-full float-left text-base font-Averta pb-6 pr-5">
                                    <li className="leading-9 pb-3 align-middle">
                                        <span className="hover:text-burgandy inline-block leading-5 align-middle cursor-pointer">
                                            <span className="align-middle rounded-full border-2 w-6 h-6 leading-5 mr-2 border-perot text-center inline-block bg-perot text-white"><i className="fa-solid fa-check"></i></span> Business Details
                                        </span>
                                        {/*
                                        <Link to={`/concierge/organisation/${_id}/bank-details`} className="float-right py-1 px-2 hover:text-burgandy"><i className="fa-solid fa-arrow-left"></i></Link>
                                        */}
                                       
                                    </li>
                                    <li className="leading-9 pb-3 align-middle">
                                        <span className="hover:text-burgandy inline-block leading-5 align-middle cursor-pointer">
                                            <span className="align-middle rounded-full border-2 w-6 h-6 leading-5 mr-2 border-perot text-center inline-block bg-perot text-white"><i className="fa-solid fa-check"></i></span> Bank Details
                                        </span>
                                    </li>
                                    <li className="leading-9 pb-3 align-middle">
                                        <span className="hover:text-burgandy inline-block leading-5 align-middle cursor-pointer">
                                            <span className={`rounded-full border-2 w-6 h-6 leading-5 mr-2 border-${(watch('contact_person') && watch('contact_email') && watch('contact_number') && watch('sec_contact_person') && watch('sec_contact_email') && watch('sec_contact_number')) ? 'perot' : 'burgandy'} text-center inline-block`}>3</span> Primary Contact
                                        </span>
                                    </li>
                                </ul>
                                <div className="md:w-2/5 w-full float-left">
                                    <div className="bg-blue w-full float-left p-3 px-4">
                                        <h3 className="float-left text-white text-xs font-Averta_Semibold">Primary Contact</h3>
                                        {/* <span className="float-right link text-xs text-white hover:text-gray cursor-pointer italic"><i className="fa-light fa-pen mr-2"></i> Edit</span> */}
                                    </div>
                                    <form onSubmit={handleSubmit(savePrimaryDetails)}>
                                        <input type="hidden"{...register("id", { required: "required" })} value={_id} />
                                        <div className="w-full float-left clear-both p-4 pt-1">
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Contact Person</label>
                                                <div className="float-right w-full md:w-3/5">
                                                    <input
                                                        {...register("contact_person", {
                                                            required: "required",
                                                            maxLength: {
                                                                value: 150,
                                                                message: "Contact person name can't be more than 150 alphabets."
                                                            }
                                                        })}
                                                        className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                        type="text"
                                                        placeholder="Steven Chris"
                                                        defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.contact_person}
                                                    />
                                                    <span className="text-red-500 text-sm font-Averta">
                                                        {errors.contact_person && errors.contact_person.type === "required" && 'Please enter contact person.'}
                                                        {errors.contact_person && errors.contact_person.type !== "required" && errors.contact_person.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Email</label>
                                                <div className="float-right w-full md:w-3/5">
                                                    <input
                                                        {...register("contact_email", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: "Entered value does not match email format"
                                                            }
                                                        })}
                                                        className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                        type="text"
                                                        placeholder="steven@bayport.co.za"
                                                        defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.contact_email}
                                                    />
                                                    <span className="text-red-500 text-sm font-Averta">
                                                        {errors.contact_email && errors.contact_email.type === "required" && 'Please enter contact person email.'}
                                                        {errors.contact_email && errors.contact_email.type !== "required" && errors.contact_email.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Mobile</label>
                                                <div className="float-right w-full md:w-3/5">
                                                    <input
                                                        {...register("contact_number", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /^\+91|\+27(5|6|7|8|9){1,2}[0-9]{1}[0-9]{7,9}$/,
                                                                message: "This value doesn't match mobile number format. (E.g. : +27987654321, +919876543210)"
                                                            }
                                                        })}
                                                        className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                        type="text"
                                                        placeholder="Mobile Number (E.g. : +279876543210)"
                                                        defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.contact_number}
                                                    />
                                                    <span className="text-red-500 text-sm font-Averta">
                                                        {errors.contact_number && errors.contact_number.type === "required" && 'Please enter contact person mobile number.'}
                                                        {errors.contact_number && errors.contact_number.type !== "required" && errors.contact_number.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full float-left mb-10"></div>
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Secondary Contact Person</label>
                                                <div className="float-right w-full md:w-3/5">
                                                    <input
                                                        {...register("sec_contact_person", {
                                                            required: "required",
                                                            maxLength: {
                                                                value: 150,
                                                                message: "Secondary contact person name can't be more than 150 alphabets."
                                                            }
                                                        })}
                                                        className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                        type="text"
                                                        placeholder="Elizabeth Edy"
                                                        defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.sec_contact_person}
                                                    />
                                                    <span className="text-red-500 text-sm font-Averta">
                                                        {errors.sec_contact_person && errors.sec_contact_person.type === "required" && 'Please enter secondary contact person.'}
                                                        {errors.sec_contact_person && errors.sec_contact_person.type !== "required" && errors.sec_contact_person.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Email</label>
                                                <div className="float-right w-full md:w-3/5">
                                                    <input
                                                        {...register("sec_contact_email", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: "Entered value does not match email format"
                                                            }
                                                        })}
                                                        className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                        type="text"
                                                        placeholder="elizabeth@bayport.co.za "
                                                        defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.sec_contact_email}
                                                    />
                                                    <span className="text-red-500 text-sm font-Averta">
                                                        {errors.sec_contact_email && errors.sec_contact_email.type === "required" && 'Please enter secondary contact person email.'}
                                                        {errors.sec_contact_email && errors.sec_contact_email.type !== "required" && errors.sec_contact_email.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Mobile</label>
                                                <div className="float-right w-full md:w-3/5">
                                                    <input
                                                        {...register("sec_contact_number", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /^\+91|\+27(5|6|7|8|9){1,2}[0-9]{1}[0-9]{7,9}$/,
                                                                message: "This value doesn’t match required mobile number format (E.g : +27987654321, +919876543210)"
                                                            }
                                                        })}
                                                        className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                        type="text"
                                                        placeholder="Mobile Number (E.g. : +279876543210)"
                                                        defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.sec_contact_number}
                                                    />
                                                    <span className="text-red-500 text-sm font-Averta">
                                                        {errors.sec_contact_number && errors.sec_contact_number.type === "required" && 'Please enter secondary contact person mobile number.'}
                                                        {errors.sec_contact_number && errors.sec_contact_number.type !== "required" && errors.sec_contact_number.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clear-both"></div>
                                        <button disabled={processing} className="float-right xl:-mr-80 mt-5 bg-blue py-3 px-12 rounded-xl text-burgandy hover:text-blue font-Averta_Semibold hover:bg-burgandy text-sm" type="submit">
                                            {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : organisation?.contact_email ? 'Save' : 'Send Invite'}
                                        </button>
                                        <div className="clear-both"></div>
                                    </form>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </section>
                    </>
                    : null
            )
            :
            <PageSkeleton />
    )
}

export default PrimaryDetails;

const PageSkeleton = () => {
    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-24 w-full float-left font-Averta md:flex md:items-center">
                <div className="lg:container mx-auto px-5">
                    <ul className="md:max-w-sm w-full float-left text-base font-Averta pb-6 pr-5">
                        <LineLoader width={'50%'} count={3} />
                    </ul>
                    <div className="md:w-2/5 w-full float-left">
                        <div className="bg-blue w-full float-left p-3 px-4">
                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Primary Contact</h3>
                            {/* <span className="float-right link text-xs text-white hover:text-gray cursor-pointer italic"><i className="fa-light fa-pen mr-2"></i> Edit</span> */}
                        </div>
                        <div className="w-full float-left clear-both p-4 pt-1">
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Contact Person</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Email</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Mobile</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-10"></div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Secondary Contact Person</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Email</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Mobile</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                        </div>
                        <div className="clear-both"></div>
                        <ButtonLoader />
                        <div className="clear-both"></div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </section>
        </>
    );
}