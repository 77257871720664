import { Modal } from 'flowbite-react';
import fns from 'helpers/functions';
import React, { useState } from 'react'
import * as ManageOfferService from 'Services/manage-offers.service';

const LockedOfferRow = ({ index, offer, ReloadOffer, handleSettleCheckboxChange }) => {
    const user = fns.LOGGED_USER();
    const [offer_id, setOfferId] = useState(null);
    const [modalUnlock, SetModalUnlock] = useState(false);
    const [modalExtend, SetModalExtend] = useState(false);

    const unlockOffer = async () => {
        const res = await ManageOfferService.UnlockOffersAPI({ ids: [offer_id] });
        if (res.response) {
            fns.TOAST_SUC(`${res?.data?.message}`);
            ReloadOffer();
        } else {
            fns.TOAST_ERR(res.errors);
        }
    }

    const extendLock = async () => {
        const res = await ManageOfferService.ExtendLockAPI({ ids: [offer_id] });
        if (res.response) {
            fns.TOAST_SUC(`${res?.data?.message}`);
            ReloadOffer();
        } else {
            console.log(res.errors);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <>
            <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                    {offer?.offer?.institution}
                </td>
                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#EB5757]">
                    {offer?.offer?._outstanding_balance_text}
                </td>
                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                    --
                </td>
                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                    {offer?.offer?._account_type_text}
                </td>
                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#1EC465]">
                    {offer?.offer?._settlement_value_text}
                </td>
                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                    <svg className='m-auto' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_253_19391)">
                            <path d="M10.59 10.59C10.384 10.811 10.1356 10.9883 9.85961 11.1113C9.58362 11.2343 9.28568 11.3004 8.98357 11.3057C8.68146 11.3111 8.38137 11.2555 8.10121 11.1423C7.82104 11.0292 7.56654 10.8607 7.35288 10.6471C7.13923 10.4334 6.97079 10.1789 6.85763 9.89875C6.74447 9.61859 6.68889 9.3185 6.69423 9.01639C6.69956 8.71428 6.76568 8.41634 6.88866 8.14034C7.01163 7.86435 7.18894 7.61594 7.41 7.40996M13.455 13.455C12.1729 14.4322 10.6118 14.9736 9 15C3.75 15 0.75 8.99996 0.75 8.99996C1.68292 7.26138 2.97685 5.74241 4.545 4.54496L13.455 13.455ZM7.425 3.17996C7.94125 3.05912 8.4698 2.99871 9 2.99996C14.25 2.99996 17.25 8.99996 17.25 8.99996C16.7947 9.85166 16.2518 10.6535 15.63 11.3925L7.425 3.17996Z" stroke="#160B32" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M0.75 0.749939L17.25 17.2499" stroke="#160B32" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_253_19391">
                                <rect width="18" height="18" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </td>
                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit text-[#818181]">
                    {/* {offer?.offer?.settlement_party} */}
                    {user?.organization_name}
                </td>
                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                    <div className={`w-[100px] bg-gradient-to-r from-[${fns.GET_COLOR_GRADIANT(offer?.settlement_group?._lock_expiry_days)}] h-[7px] block m-auto mb-1`}></div>
                    <span className='text-[8px] block'>{offer?.settlement_group?._lock_expiry_days} {offer?.settlement_group?._lock_expiry_days <= 1 ? 'day' : 'days'} left</span>
                </td>
                <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                    <input
                        className='w-[14px] h-[14px]'
                        id={`offer${index}`}
                        name={`offer${index}`}
                        onChange={handleSettleCheckboxChange}
                        type="checkbox"
                        value={offer?.offer?._id}
                        defaultChecked={false}
                    />
                </td>
                <td className="text-lg px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                    <svg onClick={(e) => { setOfferId(offer?.offer?._id); SetModalUnlock(true); }} className='m-auto cursor-pointer' width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_478_15885)">
                            <path d="M2.625 6.5625V19.6875C2.625 20.4094 3.21562 21 3.9375 21H15.75C16.4719 21 17.0625 20.4094 17.0625 19.6875V6.5625H2.625ZM6.5625 18.375H5.25V9.1875H6.5625V18.375ZM9.1875 18.375H7.875V9.1875H9.1875V18.375ZM11.8125 18.375H10.5V9.1875H11.8125V18.375ZM14.4375 18.375H13.125V9.1875H14.4375V18.375Z" fill="#CDCDCD" />
                            <path d="M17.3906 2.625H13.125V0.984375C13.124 0.723622 13.0199 0.473844 12.8355 0.289462C12.6512 0.10508 12.4014 0.0010368 12.1406 0L7.54688 0C7.28612 0.0010368 7.03634 0.10508 6.85196 0.289462C6.66758 0.473844 6.56354 0.723622 6.5625 0.984375V2.625H2.29688C2.03602 2.62569 1.78604 2.72963 1.60158 2.91408C1.41713 3.09854 1.31319 3.34852 1.3125 3.60938V5.25H18.375V3.60938C18.3743 3.34852 18.2704 3.09854 18.0859 2.91408C17.9015 2.72963 17.6515 2.62569 17.3906 2.625ZM11.8125 2.625H7.875V1.32956H11.8125V2.625Z" fill="#CDCDCD" />
                        </g>
                        <defs>
                            <clipPath id="clip0_478_15885">
                                <rect width="21" height="21" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </td>
                <td className="text-lg px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                    {
                        offer?.settlement_group?.lock_extended_count <= 2
                            ?
                            <span onClick={(e) => { setOfferId(offer?.offer?._id); SetModalExtend(true); }} className='cursor-pointer hover:text-gray'>
                                <i className="fa-regular fa-retweet"></i>
                            </span>
                            :
                            null
                    }
                </td>
            </tr>

            {
                modalUnlock
                    ?
                    <Modal show={true} className="bg-black/60">
                        <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalUnlock(!modalUnlock) }}>
                            &nbsp;
                        </Modal.Header>
                        <Modal.Body className='text-center pt-0'>
                            <div className="bg-white px-8 pt-0 pb-4 sm:pb-4">
                                <div className="text-center">
                                    <div className="pt-0 text-center">
                                        <h3 className="text-base font-Averta_Semibold leading-6 uppercase" id="modal-title">Please confirm</h3>
                                        <p className="mt-3 text-sm">Are you sure you want to unlock loan?</p>

                                        <p className="mt-3 text-sm">Doing this will unlock the loan and other credit providers will have immediate access to this loan information.</p>
                                    </div>
                                </div>
                            </div>
                            <button type='submit' onClick={() => { SetModalUnlock(false); unlockOffer(); }} className="mt-3 m-auto inline-flex w-full rounded-xl justify-center text-burgandy hover:text-blue bg-blue px-12 py-2 font-Averta_Semibold focus:outline-none sm:mt-4 sm:ml-3 sm:w-auto text-sm hover:bg-burgandy">Unlock</button>
                        </Modal.Body>
                    </Modal>
                    :
                    null
            }

            {
                modalExtend
                    ?
                    <Modal show={true} className="bg-black/60">
                        <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalExtend(!modalExtend) }}>
                            &nbsp;
                        </Modal.Header>
                        <Modal.Body className='text-center pt-0'>
                            <div className="bg-white px-8 pt-0 pb-4 sm:pb-4">
                                <div className="text-center">
                                    <div className="pt-0 text-center">
                                        <h3 className="text-base font-Averta_Semibold leading-6 uppercase" id="modal-title">Please confirm</h3>
                                        <p className="mt-3 text-sm">Are you sure you want to extend loan lock?</p>

                                        <p className="mt-3 text-sm">Doing this will prevent other credit providers from seeing the details for another 2 days.</p>
                                    </div>
                                </div>
                            </div>
                            <button type='submit' onClick={() => { SetModalExtend(false); extendLock(); }} className="mt-3 m-auto inline-flex w-full rounded-xl justify-center text-burgandy hover:text-blue bg-blue px-12 py-2 font-Averta_Semibold focus:outline-none sm:mt-4 sm:ml-3 sm:w-auto text-sm hover:bg-burgandy">Yes, Extend</button>
                        </Modal.Body>
                    </Modal>
                    :
                    null
            }

        </>
    )
}

export default LockedOfferRow