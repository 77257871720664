import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import authContext from 'contexts/auth/authContext';
import useEffectOnce from 'hooks/useEffectOnce';
import fns from 'helpers/functions';

const Logout = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    useEffectOnce(() => {
        localStorage.removeItem('loginfo');
        localStorage.removeItem('user');
        localStorage.removeItem('org_id');
        localStorage.removeItem('_token');
        localStorage.removeItem('refresh-token');
        cntxAuth.updateLogin(false);

        fns.TOAST_SUC('You are successfully logged out.');
        navigate('/login');
    }, [null]);

    return (
        <></>
    )
}

export default Logout