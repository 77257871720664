import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import authContext from 'contexts/auth/authContext';
import fns from 'helpers/functions';
import * as AuthService from 'Services/auth.service';

const OtpVerification = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const loginfo = JSON.parse(localStorage.getItem('loginfo'));

    const [processing, setProcessing] = useState(false);
    const [info, setInfo] = useState('');

    useEffect(() => {
        if (cntxAuth.isLogged) {
            navigate('/concierge');
            return;
        }

        if (loginfo && loginfo !== '') {
            setInfo(loginfo);
        } else {
            navigate('/login');
        }
    }, []);

    /** Update credentials with OTP */
    const handlOTPChange = (e) => {
        setValue('otp', e.target.value);
        setInfo({ ...info, otp: e.target.value });
    }

    /** Verify and login */
    const doVerify = async () => {
        setProcessing(true);
        const res = await AuthService.LoginVerifyAPI(info);

        if (res.response) {
            setProcessing(false);
            localStorage.removeItem('loginfo');
            localStorage.setItem('_token', res.data.data.access_token);
            localStorage.setItem('refresh-token', res.data.data.refresh_token);
            localStorage.setItem('org_id', res?.data?.data?.organization_id);
            localStorage.setItem('user', JSON.stringify(res?.data?.data));
            cntxAuth.updateLogin(true);

            fns.TOAST_SUC(res.data.message);
            if (res?.data?.data?.is_concierge) {
                navigate('/concierge');
            } else {
                navigate('/dashboard');
            }
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    /** Resend the OTP */
    const resendOTP = async () => {
        setProcessing(true);
        const res = await AuthService.LoginAPI(info.email, info.password);

        if (res.response) {
            setProcessing(false);
            localStorage.setItem('loginfo', JSON.stringify(loginfo));

            fns.TOAST_SUC(res.data.message);
            navigate('/login/verify-otp');
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-24 w-full float-left font-Averta md:flex md:items-center text-center">
                <div className="lg:container mx-auto px-5">
                    <div className='max-w-lg m-auto'>
                        <div>
                            <h2 className='text-blue font-Averta_Bold text-3xl leading-10 pb-5'>Waiting for One Time Password (OTP)</h2>
                            <p className='text-lg text-blue font-Averta pb-5'>Please enter the verification code sent to your mobile.</p>
                            <form onSubmit={handleSubmit(doVerify)}>
                                <div className='w-full float-left'>
                                    <div className="w-full float-left mb-2 p-1">
                                        <div className="m-auto w-10/12 relative">
                                            <input {...register("otp", {
                                                required: "required",
                                                pattern: {
                                                    value: /^[0-9]{6}$/,
                                                    message: "Entered value does not match OTP format"
                                                }
                                            })} onChange={handlOTPChange} type="text" id="floating_outlined1" className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer" placeholder={t('word-otp')} />
                                            <label htmlFor="floating_outlined1" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1 absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t('enter-otp')}</label>
                                        </div>
                                        <span className="text-red-500 text-sm font-Averta">
                                            {errors.otp && errors.otp.type === "required" && 'Please enter one-time password'}
                                            {errors.otp && errors.otp.type !== "required" && errors.otp.message}
                                        </span>
                                    </div>
                                    <div className="w-full float-left mb-2 p-1">
                                        <p className='text-sm font-Averta'>If you don’t receive a code, please click <span onClick={resendOTP} className='font-Averta_Semibold text-blue hover:text-burgandy cursor-pointer'>here</span>.</p>
                                    </div>
                                    <div className="clear-both"></div>
                                    <button disabled={processing} className="inline-block m-auto mt-16 mb-10 bg-blue hover:bg-burgandy py-4 px-12 rounded-xl text-burgandy hover:text-blue font-bold text-sm" type="submit">
                                        {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : t('word-verify')}
                                    </button>
                                    <div className="clear-both"></div>
                                </div>
                            </form>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </section>
        </>
    )
}

export default OtpVerification;