import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LineLoader, ButtonLoader } from 'UIs/SkeletonLoader';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import fns from 'helpers/functions';
import * as OrgsService from 'Services/organisation.service';
import * as GenService from 'Services/general.service';
import useEffectOnce from 'hooks/useEffectOnce';
import { GoBack } from 'components/interfaces/Buttons';
const BankDetails = () => {
    const { _id } = useParams();
    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false);
    const [organisation, setOrganisation] = useState(null);
    const [loading, setLoading] = useState(true);

    const [tax_add, setTaxAdd] = useState(true);
    const [tax_certificates, setTaxCertificates] = useState(null);

    const [reg_add, setRegAdd] = useState(true);
    const [reg_documents, setRegDocuments] = useState(null);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        id: Yup.string().required('Organisation is not selected.'),
        vat_num: Yup.string().required('Please enter VAT number.').matches(/^[0-9]{3,10}$/, 'This value doesn\'t match the required VAT number format (E.g. 41254).').min(3, 'Please enter at least 3 characters.').max(10, 'VAT number can not be more than 10 charaters.'),
        add_new_tax: Yup.boolean().default(true),
        tax_clearance_certificates: Yup.mixed().when('add_new_tax', {
            is: false,
            then: Yup.mixed().test("file", "Please select company tax clearance certificates", (value) => {
                return (value.length > 0 && value.length <= 10);
            }).test("file", "You can upload only 10 tax clearance certificates", (value) => {
                return (value.length <= 10);
            })
        }),
        add_reg_doc: Yup.boolean().default(true),
        company_registration_documents: Yup.mixed().when('add_reg_doc', {
            is: false,
            then: Yup.mixed().test("file", "Please select company registration documents", (value) => {
                return (value.length > 0 && value.length <= 10);
            }).test("file", "You can upload only 10 company registration documents", (value) => {
                return (value.length <= 10);
            })
        }),
        directors: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().notRequired(),
                documents: Yup.mixed().test("file", "Please select director's documents", (value) => {
                    return true;
                }).test("file", "You can upload only 10 documents", (value) => {
                    return (value.length <= 10);
                })
                .notRequired()
            })
        )
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, control, handleSubmit, formState, watch, setValue, setError } = useForm(formOptions);
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'directors', control });
    const INITIAL_STATE = { name: '', documents: "" };

    useEffectOnce(() => {
        fns.CHECK_PERMISSION(["C1"], true);

        if (_id !== '' && typeof _id !== 'undefined') {
            fetchOrgDetails(_id);
        } else {
            navigate(`/concierge/organisations`);
        }
    }, []);

    /** Fetch organisation details with of given ID */
    const fetchOrgDetails = async () => {
        const res = await OrgsService.FetchOrgDetailsAPI(_id);

        if (res.response) {
            setLoading(false);
            setOrganisation(res.data);
            setTaxCertificates(res.data?.tax_clearance_certificates);
            if (typeof res.data?.tax_clearance_certificates !== 'undefined' && res.data?.tax_clearance_certificates.length > 0) {
                setValue('add_new_tax', false);
                setTaxAdd(false);
            }
            setRegDocuments(res.data?.company_registration_documents);
            if (typeof res.data?.company_registration_documents !== 'undefined' && res.data?.company_registration_documents.length > 0) {
                setValue('add_reg_doc', false);
                setRegAdd(false);
            }
            if (typeof res.data?.directors !== 'undefined' && res.data?.directors.length > 0) {
                append(res.data?.directors);
            } else {
                addDirector();
            }
        } else {
            setLoading(false);
            setOrganisation(null);
        }
    }

    /** Save Primary details */
    const saveBankDetails = async (form) => {
        const forms = new FormData();
        forms.append('id', form.id);
        forms.append('vat_num', form.vat_num);

        if (typeof form.tax_clearance_certificates !== 'undefined') {
            for (var key in form.tax_clearance_certificates) {
                if (typeof form.tax_clearance_certificates[key] === 'object') {
                    forms.append(`tax_clearance_certificates`, form.tax_clearance_certificates[key]);
                }
            }
        }

        if (typeof form.company_registration_documents !== 'undefined') {
            for (var key in form.company_registration_documents) {
                if (typeof form.company_registration_documents[key] === 'object') {
                    forms.append(`company_registration_documents`, form.company_registration_documents[key]);
                }
            }
        }

        form.directors.map((item, i) => {
            for (var key in item) {
                if (key === 'documents') {
                    for (var keyys in item[key]) {
                        if (typeof item[key][keyys] === 'object') {
                            forms.append(`directors[${i}][${key}]`, item[key][keyys]);
                        }
                    }
                } else {
                    if (key === '_id') {
                        forms.append(`directors[${i}][id]`, item[key]);
                    } else {
                        forms.append(`directors[${i}][${key}]`, item[key]);
                    }
                }
            }
        });

        setProcessing(true);
        const res = await OrgsService.SaveOrgsStep2API(forms);

        if (res.response) {
            setProcessing(false);
            fns.TOAST_SUC(res.data.message);
            navigate(`/concierge/organisation/${res.data._id}/primary-details`);
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    /** Delete document */
    const deleteDocument = async (_id, index, type) => {
        const res = await GenService.DeleteDocumentAPI(_id);

        if (res.response) {
            fns.TOAST_SUC(res.data.message);

            if (type === 1) {
                const new_array = tax_certificates;
                new_array.splice(index, 1);
                setTaxCertificates(null);
                setTimeout(() => { setTaxCertificates(new_array); }, 200);
            } else if (type === 2) {
                const new_array = reg_documents;
                new_array.splice(index, 1);
                setRegDocuments(null);
                setTimeout(() => { setRegDocuments(new_array); }, 200);
            }
        } else if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    /** Delete document director */
    const deleteDocumentDir = async (_ids) => {
        const res = await GenService.DeleteDocumentAPI(_ids);

        if (res.response) {
            fns.TOAST_SUC(res.data.message);

            for (var key in fields) {
                remove(0);
            }

            const resx = await OrgsService.FetchOrgDetailsAPI(_id);
            if (resx.response) {
                if (typeof resx.data?.directors !== 'undefined' && resx.data?.directors.length > 0) {
                    append(resx.data?.directors);
                } else {
                    addDirector();
                }
            }
        } else if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    const downloadAttachment = async (_id, name) => {
        const res = await GenService.DownloadAttachmentAPI(_id, name);
        if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    const addDirector = () => {
        append(INITIAL_STATE);
    }

    return (
        !loading
            ?
            (
                (_id !== '' || typeof _id !== 'undefined')
                    ?
                    <>
                        <section className="w-full float-left font-Averta md:flex md:items-center">
                        <div className="lg:container mx-auto px-5">
                                <GoBack onClick={() => { navigate(`/concierge/organisation/${_id}/business-details`); }} name={'Go back'} />
                            </div>
                        </section>
                        <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-5 w-full float-left font-Averta md:flex md:items-top">
                            <div className="lg:container mx-auto px-5">
                                <ul className="md:max-w-sm w-full float-left text-base font-Averta pb-6 pr-5">
                                    <li className="leading-9 pb-3 align-middle">
                                        <span className="hover:text-burgandy inline-block leading-5 align-middle cursor-pointer">
                                            <span className="align-middle rounded-full border-2 w-6 h-6 leading-5 mr-2 border-perot text-center inline-block bg-perot text-white"><i className="fa-solid fa-check"></i></span> Business Details
                                        </span>
                                        {/*
                                        <Link to={`/concierge/organisation/${_id}/business-details`} className="float-right py-1 px-2 hover:text-burgandy"><i className="fa-solid fa-arrow-left"></i></Link>
                                        */}
                                    </li>
                                    <li className="leading-9 pb-3 align-middle">
                                        <span className="hover:text-burgandy inline-block leading-5 align-middle cursor-pointer">
                                            <span className={`rounded-full border-2 w-6 h-6 leading-5 mr-2 border-${organisation?.vat_num ? 'perot' : 'burgandy'} text-center inline-block`}>2</span> Bank Details
                                        </span>
                                    </li>
                                    <li className="leading-9 pb-3 text-gray">
                                        <span className='cursor-pointer'>
                                            <span className="rounded-full border-2 w-6 h-6 leading-5 mr-2 border-transparent text-center inline-block">3</span> Primary Contact
                                        </span>
                                    </li>
                                </ul>
                                <div className="md:w-2/5 w-full float-left">
                                    <div className="bg-blue w-full float-left p-3 px-4">
                                        <h3 className="float-left text-white text-xs font-Averta_Semibold">Bank Details</h3>
                                        {/* <span className="float-right link text-xs text-white hover:text-gray cursor-pointer italic"><i className="fa-light fa-pen mr-2"></i> Edit</span> */}
                                    </div>
                                    <form onSubmit={handleSubmit(saveBankDetails)}>
                                        <input type="hidden"{...register("id", { required: "required" })} value={_id} />
                                        <div className="w-full float-left clear-both p-4 pt-1">
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">VAT No.</label>
                                                <div className="float-right w-full md:w-3/5">
                                                    <input
                                                        {...register("vat_num")}
                                                        className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                        type="text"
                                                        placeholder="VAT No."
                                                        defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.vat_num}
                                                    />
                                                    {errors?.vat_num ? <span className="text-red-500 text-sm font-Averta">{errors?.vat_num?.message}</span> : null}
                                                </div>
                                            </div>
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Tax Clearance Certificate</label>
                                                <div className="float-right w-full md:w-3/5 border-b-1 border-blue">
                                                    {
                                                        tax_add
                                                            ?
                                                            <div>
                                                                <input
                                                                    {...register("tax_clearance_certificates")}
                                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                                    type="file"
                                                                    accept="image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                    multiple={true}
                                                                />
                                                                <span className="text-grey-200 text-sm font-Averta">( upload .pdf or .doc files only )</span><br></br>
                                                                {errors?.tax_clearance_certificates ? <span className="text-red-500 text-sm font-Averta">{errors?.tax_clearance_certificates?.message}</span> : null}
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    <div className="clear-both"></div>
                                                    <div className='pt-3'></div>
                                                    {
                                                        tax_add
                                                            ?
                                                            organisation?.vat_num
                                                                ?
                                                                <span className='cursor-pointer text-red-500 float-right' onClick={() => { setTaxAdd(false); setValue('add_new_tax', false); }}>Cancel</span>
                                                                :
                                                                <></>
                                                            :
                                                            <span className='cursor-pointer text-green-500 float-right' onClick={() => { setTaxAdd(true); setValue('add_new_tax', true); }}>Add</span>
                                                    }
                                                    <div className="clear-both"></div>
                                                    {
                                                        tax_certificates
                                                            ?
                                                            <div>
                                                                <ul className='float-left w-full m-0 p-0'>
                                                                    {
                                                                        tax_certificates.map((tax_certificate, indexc) => {
                                                                            return (
                                                                                <li key={indexc} className="relative pl-[20px] text-sm py-1.5 border-b border-gray truncate">
                                                                                    <i
                                                                                        onClick={(e) => {
                                                                                            fns.CONFIRM_BOX(
                                                                                                'Confirm to delete!',
                                                                                                'Are you sure to delete this document?',
                                                                                                'YES',
                                                                                                'CANCEL',
                                                                                                () => deleteDocument(tax_certificate?._id, indexc, 1),
                                                                                                ''
                                                                                            );
                                                                                        }}
                                                                                        className="fa-solid fa-trash-can text-[13px] cursor-pointer text-red-500 mr-2 absolute left-0 top-[9px]"></i>
                                                                                    <span className='cursor-pointer' onClick={() => { downloadAttachment(tax_certificate?._id, tax_certificate?.document_name) }}>{tax_certificate?.document_name}</span>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Company Reg. Documents</label>
                                                <div className="float-right w-full md:w-3/5">
                                                    {
                                                        reg_add
                                                            ?
                                                            <div>
                                                                <input
                                                                    {...register("company_registration_documents")}
                                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                                    type="file"
                                                                    accept="image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                    multiple={true}
                                                                />
                                                                <span className="text-grey-200 text-sm font-Averta">( upload .pdf or .doc files only )</span><br></br>
                                                                {errors?.company_registration_documents ? <span className="text-red-500 text-sm font-Averta">{errors?.company_registration_documents?.message}</span> : null}
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    <div className="clear-both"></div>
                                                    {
                                                        reg_add
                                                            ?
                                                            organisation?.vat_num
                                                                ?
                                                                <span className='cursor-pointer text-red-500 float-right' onClick={() => { setRegAdd(false); setValue('add_reg_doc', false); }}>Cancel</span>
                                                                :
                                                                <></>
                                                            :
                                                            <span className='cursor-pointer text-green-500 float-right' onClick={() => { setRegAdd(true); setValue('add_reg_doc', true); }}>Add</span>
                                                    }
                                                    <div className="clear-both"></div>
                                                    {
                                                        reg_documents
                                                            ?
                                                            <div>
                                                                <ul className='float-left w-full m-0 p-0'>
                                                                    {
                                                                        reg_documents.map((reg_document, indexc) => {
                                                                            return (
                                                                                <li key={indexc} className="relative pl-[20px] text-sm py-1.5 border-b border-gray truncate">
                                                                                    <i
                                                                                        onClick={(e) => {
                                                                                            fns.CONFIRM_BOX(
                                                                                                'Confirm to delete!',
                                                                                                'Are you sure to delete this document?',
                                                                                                'YES',
                                                                                                'CANCEL',
                                                                                                () => deleteDocument(reg_document?._id, indexc, 2),
                                                                                                ''
                                                                                            );
                                                                                        }}
                                                                                        className="fa-solid fa-trash-can cursor-pointer text-[13px] text-red-500 mr-2 absolute left-0 top-[9px]"></i>
                                                                                    <span className='cursor-pointer' onClick={() => { downloadAttachment(reg_document?._id, reg_document?.document_name) }}>{reg_document?.document_name}</span>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full float-left mb-2">
                                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Company Directors</label>
                                                <div className="float-right w-full md:w-3/5">
                                                    {fields.length < 5 ? <span onClick={addDirector} className='pt-3 cursor-pointer text-green-500 float-right'>Add</span> : null}
                                                    {
                                                        fields.map((element, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    {typeof element?._id !== 'undefined' ? <input {...register(`directors.${index}.id`)} type="hidden" defaultValue={element._id} /> : null}
                                                                    <div>
                                                                        <input
                                                                            {...register(`directors.${index}.name`)}
                                                                            className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                                            type="text"
                                                                            placeholder="Director's name"
                                                                        />
                                                                        {errors?.directors ? <span className="text-red-500 text-sm font-Averta">{errors?.directors?.[index]?.name?.message}</span> : null}
                                                                        <div className="clear-both"></div>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            {...register(`directors.${index}.documents`)}
                                                                            className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                                            type="file"
                                                                            accept="image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                            multiple={true}
                                                                        />
                                                                        <span className="text-grey-200 text-sm font-Averta">( upload .pdf or .doc files only )</span><br></br>
                                                                        {errors?.directors ? <span className="text-red-500 text-sm font-Averta">{errors?.directors?.[index]?.documents?.message}</span> : null}
                                                                        <div className="clear-both"></div>
                                                                    </div>
                                                                    {fields.length > 1 ? <span onClick={(e) => { remove(index); }} className='cursor-pointer text-red-500 float-right'>Remove</span> : null}
                                                                    {
                                                                        element?.director_documents
                                                                            ?
                                                                            <ul className='float-left w-full m-0 p-0'>
                                                                                {
                                                                                    element?.director_documents.map((document, indexc) => {
                                                                                        return (
                                                                                            <li key={indexc} className="relative pl-[20px] text-sm py-1.5 border-b border-gray truncate">
                                                                                                <i
                                                                                                    onClick={(e) => {
                                                                                                        fns.CONFIRM_BOX(
                                                                                                            'Confirm to delete!',
                                                                                                            'Are you sure to delete this document?',
                                                                                                            'YES',
                                                                                                            'CANCEL',
                                                                                                            () => deleteDocumentDir(document?._id, indexc, 2),
                                                                                                            ''
                                                                                                        );
                                                                                                    }}
                                                                                                    className="fa-solid fa-trash-can cursor-pointer text-[13px] text-red-500 mr-2 absolute left-0 top-[9px]"></i>
                                                                                                <span className='cursor-pointer' onClick={() => { downloadAttachment(document?._id, document?.document_name) }}>{document?.document_name}</span>
                                                                                            </li>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clear-both"></div>
                                        <button disabled={processing} className="float-right xl:-mr-80 mt-5 bg-blue py-3 px-12 rounded-xl text-burgandy hover:text-blue font-Averta_Semibold hover:bg-burgandy text-sm" type="submit">
                                            {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : <>Save</>}
                                        </button>
                                        <div className="clear-both"></div>
                                    </form>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </section>
                    </>
                    : null
            )
            :
            <PageSkeleton />
    )
}

export default BankDetails;

const PageSkeleton = () => {
    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-5 w-full float-left font-Averta md:flex md:items-center">
                <div className="lg:container mx-auto px-5">
                    <ul className="md:max-w-sm w-full float-left text-base font-Averta pb-6 pr-5">
                        <LineLoader width={'50%'} count={3} />
                    </ul>
                    <div className="md:w-2/5 w-full float-left">
                        <div className="bg-blue w-full float-left p-3 px-4">
                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Bank Details</h3>
                        </div>
                        <div className="w-full float-left clear-both p-4 pt-1">
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">VAT Number</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                        </div>
                        <div className="w-full float-left clear-both p-4 pt-1">
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">TAX Clearance Certificates</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                        </div>
                        <div className="w-full float-left clear-both p-4 pt-1">
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Company Registration Documents</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                        </div>
                        <div className="w-full float-left clear-both p-4 pt-1">
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Company Directors</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                        </div>
                        <div className="clear-both"></div>
                        <ButtonLoader />
                        <div className="clear-both"></div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </section>
        </>
    );
}