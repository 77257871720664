import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import fns from 'helpers/functions';
import { ButtonCLoader, LineLoader } from 'UIs/SkeletonLoader';
import * as NotificationService from 'Services/notification.service';

const DashNotifications = () => {
    const [fetching, setFetching] = useState(true);
    const [negotiations, setNotifications] = useState();

    useEffect(() => {
        fetchNotification();

        return () => {
        }
    }, []);

    const fetchNotification = async () => {
        const res = await NotificationService.FetchDashboardNotificationAPI();

        if (res.response) {
            setFetching(false);
            setNotifications(res.data);
        } else {
            setFetching(false);
            setNotifications(null);
        }
    }

    return (
        <>
            {
                fetching
                    ?
                    <PageSkeleton />
                    :
                    <div className='w-full bg-[#f2f2f2] px-4 py-4 md:pb-8 md:py-16 md:px-8 rounded-3xl shadow-md shadow-black/25'>
                        <ul className='float-left w-full'>
                            {
                                negotiations && negotiations !== null
                                    ?
                                    negotiations.map((negotiation, index) => {
                                        var viewurl = fns.URL_NOTIFICATION(negotiation?.type);
                                        if (fns.IN_ARRAY(['negotiation_request', 'settlement_offer_created', 'offer_in_negotiation', 'offer_settlement_rejected'], negotiation?.type)) {
                                            var viewurl = viewurl + negotiation?.other_data?._ticket_id;
                                        }

                                        return (
                                            <li key={index} className='float-left w-full my-2 bg-white px-4 py-2 rounded-lg shadow-md shadow-black/25 leading-10 sm:flex sm:justify-between uppercase'>
                                                <p className='float-left text-xs text-blue font-Averta sm:flex sm:justify-center sm:align-middle sm:items-center sm:pb-0 pb-3'>{negotiation?.description}</p>
                                                {
                                                    viewurl ? <VisitUrl _id={negotiation?._id} viewurl={viewurl} /> : null
                                                }
                                            </li>
                                        );
                                    })
                                    :
                                    null
                            }
                        </ul>
                        <Link to={`/all-notifications`} className='cursor-pointer float-right mt-5 text-blue text-sm hover:text-burgandy font-Averta_Semibold leading-10'>
                            SEE ALL NOTIFICATIONS
                            <svg className='inline-block ml-3 align-middle' width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.66797 27.0837L9.66797 5.91726C9.66797 5.2824 10.3383 4.9279 10.7959 5.32107L23.0678 15.9043C23.419 16.2072 23.419 16.7905 23.0678 17.0967L10.7959 27.6799C10.3383 28.073 9.66797 27.7185 9.66797 27.0837Z" fill="#160B32" />
                            </svg>
                        </Link>
                        <div className='clear-both'></div>
                    </div>
            }
        </>
    );
}

export default DashNotifications;

const VisitUrl = ({ _id, viewurl }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState('View');

    const viewAlert = async (_id, url) => {
        setLoading(<i className="fa-solid fa-spinner fa-spin"></i>);
        const res = await NotificationService.FetchANotificationAPI(_id);

        if (res.response) {
            navigate(url);
        } else {
            setLoading('View');
        }
    }

    return (
        <>
            <button onClick={(e) => { viewAlert(_id, viewurl); }} className='cursor-pointer float-right text-xs text-burgandy hover:text-blue rounded-xl font-Averta_Semibold sm:flex sm:justify-end sm:items-end bg-blue hover:bg-burgandy px-12 py-3 uppercase'>{loading}</button>
        </>
    );
}

const PageSkeleton = () => {
    return (
        <>
            <div className='w-full bg-[#f2f2f2] px-4 py-4 md:pb-8 md:py-16 md:px-8 rounded-3xl shadow-md shadow-black/25'>
                <ul className='float-left w-full'>
                    <ButtonCLoader classd={'float-left w-full my-2 bg-white px-4 py-2 rounded-lg shadow-md shadow-black/25 leading-10 sm:flex sm:justify-between'} />
                    <ButtonCLoader classd={'float-left w-full my-2 bg-white px-4 py-2 rounded-lg shadow-md shadow-black/25 leading-10 sm:flex sm:justify-between'} />
                    <ButtonCLoader classd={'float-left w-full my-2 bg-white px-4 py-2 rounded-lg shadow-md shadow-black/25 leading-10 sm:flex sm:justify-between'} />
                    <ButtonCLoader classd={'float-left w-full my-2 bg-white px-4 py-2 rounded-lg shadow-md shadow-black/25 leading-10 sm:flex sm:justify-between'} />
                </ul>
                <a onClick={(e) => { fns.COMMING_SOON_BOX(); }} className='float-right mt-5'>
                    <LineLoader width={'300px'} />
                </a>
                <div className='clear-both'></div>
            </div>
        </>
    );
}