import toast from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
// eslint-disable-next-line
import styles from "./function.css";
import constants from './constants';

const fns = {
    GET_HOURS_DAYS: (createdAt) => {
        var second = 1000;
        var minute = 60000;
        var hour = 3600000;
        var day = 86400000;
        var week = 604800000;
        var month = 2628001152;
        var year = 31536013824;
        var timeSincePost = 0;
        var timeSincePoststr = "";

        var timeDiffms = Math.abs(new Date() - new Date(createdAt));

        if ((timeSincePost = Math.floor(timeDiffms / year)) > 0) {
            timeSincePoststr = timeSincePost.toString() + " year(s) ago";
        } else if ((timeSincePost = Math.floor(timeDiffms / month)) > 0) {
            timeSincePoststr = timeSincePost.toString() + " mnoth(s) ago";
        } else if ((timeSincePost = Math.floor(timeDiffms / week)) > 0) {
            timeSincePoststr = timeSincePost.toString() + " week(s) ago";
        } else if ((timeSincePost = Math.floor(timeDiffms / day)) > 0) {
            timeSincePoststr = timeSincePost.toString() + " day(s) ago";
        } else if ((timeSincePost = Math.floor(timeDiffms / hour)) > 0) {
            timeSincePoststr = timeSincePost.toString() + " hour(s) ago";
        } else if ((timeSincePost = Math.floor(timeDiffms / minute)) > 0) {
            timeSincePoststr = timeSincePost.toString() + " minute(s) ago";
        } else if ((timeSincePost = Math.floor(timeDiffms / second)) > 0) {
            timeSincePoststr = timeSincePost.toString() + " second(s) ago";
        } else {
            timeSincePoststr = "now"
        }

        return timeSincePoststr;
    },
    IN_ARRAY: (array, search) => {
        return array.indexOf(search) != -1;
    },
    STR_REPLACE: (og_str, search, replace) => {
        return typeof og_str === 'string' ? og_str.replace(search, replace) : '';
    },
    ZERO_PAD: (num, places) => {
        return String(num).padStart(places, '0');
    },
    TOGGLE_ARR_ELE: (collection, item) => {
        var newcollection = collection;
        var idx = newcollection.indexOf(item);
        if (idx !== -1) {
            newcollection.splice(idx, 1);
        } else {
            newcollection.push(item);
        }

        return newcollection;
    },
    IS_UNDEFINED: (val) => {
        return typeof val === 'undefined';
    },
    TOAST_BOX: (color, type, message) => {
        setTimeout(() => {
            toast.custom((t) => (
                <div
                    className={`animate-enter animate-leave max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                    <div className={`flex-1 w-0 p-4`}>
                        <div className={`flex items-start`}>
                            <div className={`ml-3 flex-1`}>
                                <p className={`text-sm font-medium ${color}`}>{type}</p>
                                <p className={`mt-1 text-sm text-gray-500`}>{message}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`flex border-l border-gray-200`}>
                        <button
                            onClick={() => toast.dismiss(t.id)}
                            className={`w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500`}
                        >
                            Close
                        </button>
                    </div>
                </div>
            ));
        }, 200);
    },
    CONFIRM_BOX: (title, message, yesLabel, noLabel, yesFn, noFn = null) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [{
                label: yesLabel,
                className: '!rounded-xl hover:!bg-blue !bg-burgandy !p-4 !px-12 !text-blue hover:!text-burgandy !font-Averta_Semibold !text-md',
                onClick: () => yesFn()
            }, {
                label: noLabel,
                className: '!rounded-xl hover:!bg-burgandy !bg-blue !p-4 !px-12 !text-burgandy hover:!text-blue !font-Averta_Semibold !text-md',
                onClick: () => noFn
            }],
            closeOnClickOutside: true,
        });
    },
    COMMING_SOON_BOX: () => {
        confirmAlert({
            title: "Coming soon",
            message: "This feature isn't available right now.",
            buttons: [{
                label: "OK",
                className: '!rounded-xl hover:!bg-blue !bg-burgandy !p-4 !px-12 !text-blue hover:!text-burgandy !font-Averta_Semibold !text-md',
                onClick: () => { }
            }],
            closeOnClickOutside: true,
        });
    },
    INFO_BOX: (title, message) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [{
                label: "OK",
                className: '!rounded-xl hover:!bg-blue !bg-burgandy !p-4 !px-12 !text-blue hover:!text-burgandy !font-Averta_Semibold !text-md',
                onClick: () => { }
            }],
            closeOnClickOutside: true,
        });
    },
    TOAST_SUC: (msg) => {
        fns.TOAST_BOX(`text-green-500`, 'Success!', msg);
    },
    TOAST_WAR: (msg) => {
        fns.TOAST_BOX(`text-orange-500`, 'Warning!', msg);
    },
    TOAST_INFO: (msg) => {
        fns.TOAST_BOX(`text-sky-500`, 'Info!', msg);
    },
    TOAST_ERR_ONE: (msg) => {
        fns.TOAST_BOX(`text-red-500`, 'Failed!', msg);
    },
    TOAST_ERR: (errors) => {
        Object.keys(errors).forEach((key, index) => {
            fns.TOAST_BOX(`text-red-500`, 'Failed!', errors[key][0]);
        });
    },
    DOWNLOAD_RESPONSE: (res, file_name) => {
        const href = URL.createObjectURL(res);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    },
    LOGGED_USER: () => {
        return JSON.parse(localStorage.getItem('user'));
    },
    CHECK_PERMISSION: (hasPermission, redirect = false) => {
        var permissionResult = false;

        const user = fns.LOGGED_USER();
        const filteredArray = user?.permissions.filter(value => hasPermission.includes(value));

        if (filteredArray.length > 0) {
            permissionResult = true;
        }

        if (redirect) {
            if (!permissionResult) {
                window.location.href = '/';
            }
        }

        return permissionResult;
    },
    GET_COLOR_GRADIANT: (count) => {
        if (count <= 3) {
            return '#FF2222'
        }

        if (count <= 7) {
            return '#FFE222'
        }

        if (count > 7) {
            return '#1EC365'
        }
    },
    GET_COLOR_GRADIANT_REV: (count) => {
        if (count <= 3) {
            return '#1EC365'
        }

        if (count <= 7) {
            return '#FFE222'
        }

        if (count > 7) {
            return '#FF2222'
        }
    },
    URL_NOTIFICATION: (type) => {
        const user = !fns.LOGGED_USER()?.is_concierge;
        const types = constants.NOTIFICATION;
        const type_urls = constants.NOTIFICATION_URL;
        if (fns.IN_ARRAY(types, type)) {
            return type_urls[type][user];
        }

        return '';
    }
}

export default fns;