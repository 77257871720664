import constants from 'helpers/constants'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import fns from 'helpers/functions';
import { GoBackWhite } from '../../../components/interfaces/Buttons';

const Support = () => {
    const user = fns.LOGGED_USER();
    const navigate = useNavigate();
    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] w-full float-left font-Averta m-0">
                <div className='w-full float-left min-h-[730px] h-[calc(100vh-80px-64px)] relative'>
                    <img className='w-full float-left h-full object-cover absolute' src={constants.SUPPORT_BANNER} alt="" />

                    <h3 className='absolute top-5 left-0 float-left bg-blue text-white text-center text-xs p-5 w-80'>SUPPORT</h3>
                    
                    <div className='sm:w-11/12 w-full mx-auto px-5 pr-40 py-5 pt-16 xl:pt-0 xl:pl-64 relative'>
                    {user ? (
                            <div className="mx-auto px-5 bg-blue hover:bg-burgandy"><GoBackWhite onClick={() => { navigate(`/`); }} name={'Go back'} /></div>
                        ) : (
                            <div className="mx-auto px-5 bg-blue hover:bg-burgandy"><GoBackWhite onClick={() => { navigate(`/login`); }} name={'Back to Login'} /></div>
                    )}
                        <div className='clear-both'></div>
                    </div>
                    
                    
                    <div className="xl:w-9/12 w-full mx-auto px-5 py-5 lg:absolute lg:inset-x-0 lg:top-2/4 lg:-translate-y-2/4">
                        <div className="m-auto w-full">
                            <div className="w-full float-left lg:bg-white lg:rounded-3xl lg:shadow-md lg:shadow-black/25 py-16 lg:pt-7 px-4 lg:pb-7">
                                <h2 className="w-full float-left md:px-4 pb-5 font-Averta_Semibold text-xl">
                                    Hi{user && ' '+user.first_name}, how can we help you?
                                </h2>
                                <div className='clear-both'></div>
                                <div className="lg:flex lg:content-end mb-6">

                                    <div className="w-full lg:flex lg:flex-col md:w-6/12 lg:w-4/12 float-left md:px-4">
                                        <div className='block mb-8 text-xs font-Averta'>
                                            <div className='inline-block mr-4 align-top'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 2C6.486 2 2 6.486 2 12V16.143C2 17.167 2.897 18 4 18H5C5.26522 18 5.51957 17.8946 5.70711 17.7071C5.89464 17.5196 6 17.2652 6 17V11.857C6 11.5918 5.89464 11.3374 5.70711 11.1499C5.51957 10.9624 5.26522 10.857 5 10.857H4.092C4.648 6.987 7.978 4 12 4C16.022 4 19.352 6.987 19.908 10.857H19C18.7348 10.857 18.4804 10.9624 18.2929 11.1499C18.1054 11.3374 18 11.5918 18 11.857V18C18 19.103 17.103 20 16 20H14V19H10V22H16C18.206 22 20 20.206 20 18C21.103 18 22 17.167 22 16.143V12C22 6.486 17.514 2 12 2Z" fill="#160B32" />
                                                </svg>
                                            </div>
                                            <div className='inline-block align-middle'> CONCIERGE AVAILABLE: <span className='block'>09.00 - 17.00</span></div>
                                        </div>
                                        <div className='block mb-8 text-xs font-Averta'>
                                            <div className='inline-block mr-4 align-middle'>
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M22.4859 5.84492L19.7818 3.14336C19.5911 2.95177 19.3644 2.79976 19.1147 2.69605C18.865 2.59235 18.5973 2.539 18.327 2.53906C17.776 2.53906 17.258 2.75488 16.8695 3.14336L13.9598 6.05312C13.7682 6.24388 13.6162 6.4706 13.5125 6.72027C13.4088 6.96995 13.3554 7.23765 13.3555 7.50801C13.3555 8.05898 13.5713 8.57695 13.9598 8.96543L16.0875 11.0932C15.5894 12.191 14.897 13.1896 14.0436 14.041C13.1923 14.8965 12.1937 15.5915 11.0957 16.0926L8.96797 13.9648C8.77722 13.7733 8.5505 13.6212 8.30082 13.5175C8.05115 13.4138 7.78344 13.3605 7.51309 13.3605C6.96211 13.3605 6.44414 13.5764 6.05567 13.9648L3.14336 16.8721C2.95154 17.0632 2.79939 17.2903 2.69568 17.5404C2.59198 17.7906 2.53875 18.0587 2.53906 18.3295C2.53906 18.8805 2.75488 19.3984 3.14336 19.7869L5.84238 22.4859C6.46192 23.108 7.31758 23.4609 8.19609 23.4609C8.38145 23.4609 8.55918 23.4457 8.73438 23.4152C12.157 22.8516 15.5518 21.0311 18.2914 18.2939C21.0285 15.5594 22.8465 12.1672 23.4178 8.73438C23.5904 7.68574 23.2426 6.60664 22.4859 5.84492Z" fill="#160B32" />
                                                </svg>
                                            </div>
                                            <div className='inline-block align-middle'> 0861 232 4040</div>
                                        </div>
                                        <div className='block mb-8 text-xl font-Averta cursor-pointer hover:text-burgandy md:pt-16'>
                                            <div className='inline-block mr-4 align-middle'>
                                                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.5 1.8125C7.49356 1.8125 1.8125 7.49356 1.8125 14.5C1.8125 21.5064 7.49356 27.1875 14.5 27.1875C21.5064 27.1875 27.1875 21.5064 27.1875 14.5C27.1875 7.49356 21.5064 1.8125 14.5 1.8125ZM14.5 21.8633C13.8741 21.8633 13.3672 21.3563 13.3672 20.7305C13.3672 20.1046 13.8741 19.5977 14.5 19.5977C15.1259 19.5977 15.6328 20.1046 15.6328 20.7305C15.6328 21.3563 15.1259 21.8633 14.5 21.8633ZM16.2813 15.647C16.0249 15.746 15.8042 15.92 15.6481 16.1464C15.492 16.3727 15.4077 16.6408 15.4062 16.9157V17.5586C15.4062 17.6832 15.3043 17.7852 15.1797 17.7852H13.8203C13.6957 17.7852 13.5938 17.6832 13.5938 17.5586V16.9497C13.5938 16.2955 13.7835 15.6498 14.1573 15.1117C14.5227 14.585 15.0324 14.1828 15.6328 13.9534C16.5957 13.5824 17.2188 12.7753 17.2188 11.8945C17.2188 10.6456 15.9981 9.62891 14.5 9.62891C13.0019 9.62891 11.7812 10.6456 11.7812 11.8945V12.1098C11.7812 12.2344 11.6793 12.3363 11.5547 12.3363H10.1953C10.0707 12.3363 9.96875 12.2344 9.96875 12.1098V11.8945C9.96875 10.7815 10.4559 9.74219 11.3395 8.96904C12.1891 8.22422 13.3105 7.81641 14.5 7.81641C15.6895 7.81641 16.8109 8.22705 17.6605 8.96904C18.5441 9.74219 19.0312 10.7815 19.0312 11.8945C19.0312 13.5314 17.9522 15.0041 16.2813 15.647Z" fill="#160B32" />
                                                </svg>
                                            </div>
                                            <div className='inline-block align-middle font-Averta_Semibold'><Link to={`/page/faqs`}>See our FAQ Page</Link></div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:flex lg:flex-col md:w-6/12 lg:w-4/12 float-left md:px-4">
                                        {user ? (
                                                <div className='block mb-8 text-xs font-Averta'>
                                                <div className='inline-block mr-4 align-top'>
                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.211 2.19766C15.7788 1.80148 15.2104 1.58743 14.6243 1.60015C14.0382 1.61286 13.4796 1.85136 13.065 2.26591L2.314 13.0169C2.10272 13.2282 1.93512 13.479 1.82078 13.755C1.70644 14.031 1.64758 14.3269 1.64758 14.6257C1.64758 14.9244 1.70644 15.2203 1.82078 15.4963C1.93512 15.7724 2.10272 16.0232 2.314 16.2344L3.81875 17.7392C4.38262 18.303 5.1935 18.1682 5.67125 17.848C6.01504 17.6195 6.42732 17.5171 6.83808 17.558C7.24884 17.599 7.63276 17.7809 7.92466 18.0728C8.21655 18.3646 8.39842 18.7486 8.43938 19.1593C8.48035 19.5701 8.37787 19.9824 8.14937 20.3262C7.8325 20.8055 7.696 21.6164 8.25987 22.1803L9.7305 23.6509C9.94582 23.8665 10.2022 24.0366 10.4846 24.1511C10.7669 24.2657 11.0693 24.3224 11.3739 24.3178C11.6786 24.3133 11.9792 24.2476 12.258 24.1246C12.5367 24.0017 12.788 23.824 12.9967 23.6022L23.7185 12.2077C23.9251 11.9883 24.0862 11.7301 24.1922 11.448C24.2982 11.1659 24.3471 10.8655 24.3361 10.5644C24.325 10.2632 24.2543 9.96723 24.1279 9.69364C24.0015 9.42006 23.822 9.17431 23.5999 8.97066L22.1601 7.65116C22.0017 7.50947 21.8125 7.40662 21.6074 7.35077C21.4024 7.29492 21.1871 7.28761 20.9787 7.32941C20.6099 7.39441 20.2605 7.59104 20.007 7.86729C19.8483 8.04035 19.6571 8.18048 19.4443 8.27965C19.2315 8.37883 19.0012 8.43511 18.7667 8.4453C18.5321 8.45548 18.2978 8.41937 18.0772 8.33901C17.8566 8.25866 17.654 8.13564 17.4809 7.97698C17.1314 7.65655 16.9235 7.21039 16.9029 6.73666C16.8927 6.5021 16.9289 6.26782 17.0092 6.04721C17.0896 5.8266 17.2126 5.62398 17.3712 5.45091C17.6264 5.17629 17.7905 4.81066 17.8246 4.43854C17.8484 4.22738 17.8225 4.01359 17.7492 3.81415C17.6759 3.61472 17.557 3.43513 17.4021 3.28966L16.211 2.19766V2.19766Z" fill="#160B32" />
                                                    </svg>
                                                </div>
                                                <div className='inline-block align-middle uppercase'>Ticket/Deal Reference Number (Optional)</div>
                                                </div>
                                        ) : (
                                            <div className='block mb-8 text-xs font-Averta'>
                                                <div className='inline-block mr-4 align-top'>
                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.211 2.19766C15.7788 1.80148 15.2104 1.58743 14.6243 1.60015C14.0382 1.61286 13.4796 1.85136 13.065 2.26591L2.314 13.0169C2.10272 13.2282 1.93512 13.479 1.82078 13.755C1.70644 14.031 1.64758 14.3269 1.64758 14.6257C1.64758 14.9244 1.70644 15.2203 1.82078 15.4963C1.93512 15.7724 2.10272 16.0232 2.314 16.2344L3.81875 17.7392C4.38262 18.303 5.1935 18.1682 5.67125 17.848C6.01504 17.6195 6.42732 17.5171 6.83808 17.558C7.24884 17.599 7.63276 17.7809 7.92466 18.0728C8.21655 18.3646 8.39842 18.7486 8.43938 19.1593C8.48035 19.5701 8.37787 19.9824 8.14937 20.3262C7.8325 20.8055 7.696 21.6164 8.25987 22.1803L9.7305 23.6509C9.94582 23.8665 10.2022 24.0366 10.4846 24.1511C10.7669 24.2657 11.0693 24.3224 11.3739 24.3178C11.6786 24.3133 11.9792 24.2476 12.258 24.1246C12.5367 24.0017 12.788 23.824 12.9967 23.6022L23.7185 12.2077C23.9251 11.9883 24.0862 11.7301 24.1922 11.448C24.2982 11.1659 24.3471 10.8655 24.3361 10.5644C24.325 10.2632 24.2543 9.96723 24.1279 9.69364C24.0015 9.42006 23.822 9.17431 23.5999 8.97066L22.1601 7.65116C22.0017 7.50947 21.8125 7.40662 21.6074 7.35077C21.4024 7.29492 21.1871 7.28761 20.9787 7.32941C20.6099 7.39441 20.2605 7.59104 20.007 7.86729C19.8483 8.04035 19.6571 8.18048 19.4443 8.27965C19.2315 8.37883 19.0012 8.43511 18.7667 8.4453C18.5321 8.45548 18.2978 8.41937 18.0772 8.33901C17.8566 8.25866 17.654 8.13564 17.4809 7.97698C17.1314 7.65655 16.9235 7.21039 16.9029 6.73666C16.8927 6.5021 16.9289 6.26782 17.0092 6.04721C17.0896 5.8266 17.2126 5.62398 17.3712 5.45091C17.6264 5.17629 17.7905 4.81066 17.8246 4.43854C17.8484 4.22738 17.8225 4.01359 17.7492 3.81415C17.6759 3.61472 17.557 3.43513 17.4021 3.28966L16.211 2.19766V2.19766Z" fill="#160B32" />
                                                    </svg>
                                                </div>
                                                <div className='inline-block align-middle uppercase'>Contact Details</div>
                                            </div>
                                        )}
                                        <div className='block mb-8 text-xs font-Averta'>
                                            <div className='inline-block mr-4 align-middle'>
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M27.45 15.1095L5.45001 4.10952C5.27758 4.02328 5.0839 3.98873 4.89228 4.01002C4.70067 4.03131 4.5193 4.10754 4.37001 4.22952C4.22743 4.34901 4.12101 4.5059 4.06272 4.68256C4.00442 4.85922 3.99655 5.04864 4.04001 5.22952L6.69001 14.9995H18V16.9995H6.69001L4.00001 26.7395C3.95923 26.8906 3.95447 27.0491 3.98611 27.2023C4.01775 27.3555 4.0849 27.4992 4.18217 27.6218C4.27943 27.7443 4.4041 27.8423 4.54614 27.908C4.68818 27.9736 4.84364 28.0049 5.00001 27.9995C5.15655 27.9986 5.31068 27.9609 5.45001 27.8895L27.45 16.8895C27.6138 16.8056 27.7513 16.6781 27.8473 16.5211C27.9433 16.364 27.994 16.1836 27.994 15.9995C27.994 15.8155 27.9433 15.635 27.8473 15.478C27.7513 15.3209 27.6138 15.1934 27.45 15.1095Z" fill="#160B32" />
                                                </svg>
                                            </div>
                                            <div className='inline-block align-middle'> CONTACT MESSAGE</div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-4/12 float-left md:px-4 lg:flex lg:flex-col lg:mt-0 mt-5">
                                        {user ? (
                                            <div className="w-full float-left relative mb-5">
                                                <input type="text" id="floating_outlined1" className="w-full placeholder:text-[#F2F2F2] focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-5 rounded-xl py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer font-Averta bg-[#F2F2F2]" placeholder="Insert Ticket/Deal Reference Number" />
                                                <label htmlFor="floating_outlined1" className="float-left font-Averta text-blue bg-[#FFFFF] mx-3 px-1 absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Insert Ticket/Deal Reference Number</label>
                                            </div>
                                        ) : (
                                            <>
                                            <div className="w-full float-left relative mb-5">
                                                <input type="text" id="floating_outlined1" className="w-full placeholder:text-[#F2F2F2] focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-5 rounded-xl py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer font-Averta bg-[#F2F2F2]" placeholder="Name" />
                                                <label htmlFor="floating_outlined1" className="float-left font-Averta text-blue bg-[#FFFFF] mx-3 px-1 absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Name</label>
                                            </div>
                                            <div className="w-full float-left relative mb-5">
                                                <input type="text" id="floating_outlined1" className="w-full placeholder:text-[#F2F2F2] focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-5 rounded-xl py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer font-Averta bg-[#F2F2F2]" placeholder="Email Address" />
                                                <label htmlFor="floating_outlined1" className="float-left font-Averta text-blue bg-[#FFFFF] mx-3 px-1 absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Email Address</label>
                                            </div>
                                            </>
                                        )}
                                        <div className="w-full float-left relative">
                                            <textarea name="" id="floating_outlined2" className="w-full placeholder:text-[#F2F2F2] focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 h-[180px] rounded-xl py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer font-Averta resize-none bg-[#F2F2F2]" placeholder='Insert Message Here'></textarea>
                                            <label htmlFor="floating_outlined2" className="float-left font-Averta text-blue bg-[#F2F2F2] mx-3 px-1 absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/4 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Insert Message Here</label>
                                        </div>
                                        <div className="w-full float-left relative">
                                            <a className='float-right m-auto mt-8 mb-10 bg-blue hover:bg-burgandy py-4 px-10 rounded-xl text-burgandy hover:text-blue font-bold text-sm' href="">SEND CONTACT REQUEST</a>
                                        </div>
                                    </div>
                                    <div className='clear-both'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    )
}

export default Support