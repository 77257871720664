import React, { useEffect } from 'react'
import { Breadcrumb } from 'modules/fragments';
import * as ManageOfferService from 'Services/manage-offers.service';
import { useState } from 'react';
import { UserSqare, UserRound } from 'UIs/Icons';
import LockedOfferRow from './LockedOfferRow';
import fns from 'helpers/functions';
import { Modal } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'Plugins';

const LockedOffers = () => {
    const navigate = useNavigate();
    const [offerGroups, setOfferGroups] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [selectSettle, setSelectSettle] = useState([]);
    const [modalSettle, SetModalSettle] = useState(false);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fns.CHECK_PERMISSION(["B3"], true);

        fetchLockedOffers();

        return () => {
        }
    }, []);

    const fetchLockedOffers = async (page = 1) => {
        setFetching(true);
        const res = await ManageOfferService.FetchLockedOffersAPI(page, sizePerPage);

        if (res.response) {
            setFetching(false);
            setOfferGroups(res.data?.data?.locked_offers?.data);
            setTotalCount(res.data?.data?.locked_offers?.data?.totalSize);
        } else {
            setFetching(false);
            setOfferGroups(null);
            setTotalCount(0);
        }
    }

    const handleSettleCheckboxChange = (e) => {
        setSelectSettle(fns.TOGGLE_ARR_ELE(selectSettle, e.target.value));
    }

    const settleOffers = async () => {
        if (selectSettle?.length <= 0) {
            fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.');
            return;
        }

        const res = await ManageOfferService.SettleOfferAPI({ ids: selectSettle });
        if (res.response) {
            if (res?.data?.data?.settled_offers > 0) {
                fns.TOAST_SUC(`${res?.data?.data?.settled_offers} Offer(s) settled successfully`);
                navigate(`/deals-in-progress`);
            }
        } else {
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <>
            <section className="w-full float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <Breadcrumb page={'YOUR LOCKED DEALS'} contact={null} />

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            {
                                (offerGroups !== null && typeof offerGroups !== 'undefined' && offerGroups.length > 0 && Object.keys(offerGroups[0]).length > 0)
                                    ?
                                    Object.keys(offerGroups).map((keyName, i) => (
                                        Object.values(offerGroups[keyName]).map((offers, index) => {
                                            return (
                                                <div key={index} className='float-left w-full'>
                                                    <div className='float-left w-full'>
                                                        <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                                            <UserSqare />
                                                            <span className='uppercase'>{isNaN(parseInt(Object.keys(offerGroups[keyName])[0])) ? 'Passport' : 'ID'}# {Object.keys(offerGroups[keyName])[0]}</span>
                                                            <div className='w-full float-left pl-16'>
                                                                <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-8 sm:pt-3 px-4 sm:px-8 pb-5 mb-10 relative">
                                                                    <div className='absolute left-1 top-1 text-center'>
                                                                        {/* <UserRound /> */}
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <div className="overflow-x-auto">
                                                                            <div className="py-2 inline-block min-w-full">
                                                                                <div className="overflow-x-auto mb-2">
                                                                                    <table className="min-w-full text-black">
                                                                                        <thead className="">
                                                                                            <tr>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Institution</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Outstanding Balance</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">SH Ref</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Account Type</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Value</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Ref</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Party</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Lock Expires</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Select</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Bin</th>
                                                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Extends</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody className=''>
                                                                                            {
                                                                                                offers.map((offer, index) => {
                                                                                                    return <LockedOfferRow
                                                                                                        index={index}
                                                                                                        key={index}
                                                                                                        offer={offer}
                                                                                                        ReloadOffer={fetchLockedOffers}
                                                                                                        handleSettleCheckboxChange={handleSettleCheckboxChange}
                                                                                                    />
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    className='mt-8 float-right bg-[#2D6E5C] text-white text-sm font-Averta_Semibold py-4 px-10 rounded-xl hover:bg-burgandy'
                                                                    onClick={(e) => { (selectSettle?.length <= 0) ? fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.') : SetModalSettle(true); }}
                                                                >
                                                                    Let's Settle
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ))
                                    :
                                    <div className='float-left w-full'>
                                        <div className='float-left w-full'>
                                            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                                <div className='w-full float-left pl-16'>
                                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-8 sm:pt-5 px-4 sm:px-8 pb-5 mb-10 relative">
                                                        <div className="flex flex-col">
                                                            <div className="overflow-x-auto">
                                                                <div className="py-2 inline-block min-w-full">
                                                                    <div className="overflow-x-auto text-center text-[15px]">
                                                                        <span>No locked offers</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                            {
                                total_count > sizePerPage
                                &&
                                <div className="float-left w-full">
                                    <div className="text-center float-left w-full">
                                        <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchLockedOffers(data.selected + 1) }} />
                                    </div>
                                </div>
                            }
                            <div className='clear-both'></div>
                        </div>
                    </div>
                    <div className='clear-both'></div>
                </div>
            </section>

            <Modal show={modalSettle} className="bg-black/60">
                <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalSettle(!modalSettle) }}>
                    &nbsp;
                </Modal.Header>
                <Modal.Body className='text-center pt-0'>
                    <div className="bg-white px-8 pt-0 pb-4 sm:pb-4">
                        <div className="text-center">
                            <div className="pt-0 text-center">
                                <h3 className="text-base font-Averta_Semibold leading-6 uppercase" id="modal-title">Please confirm</h3>
                                <p className="mt-3 text-sm">Please confirm you would like the SettheHub Finance Team to get selected loans consolidated.</p>
                            </div>
                        </div>
                    </div>
                    <button type='submit' onClick={() => { SetModalSettle(false); settleOffers(); }} className="mt-3 m-auto inline-flex w-full rounded-xl justify-center text-burgandy hover:text-blue bg-blue px-12 py-2 font-Averta_Semibold focus:outline-none sm:mt-4 sm:ml-3 sm:w-auto text-sm hover:bg-burgandy">Yes, Let's Settle</button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LockedOffers