import { TRLoader } from 'UIs/SkeletonLoader';
import { Breadcrumb } from 'modules/fragments';
import React, { useEffect } from 'react';
import { useState } from 'react';
import * as ManageOffer from 'Services/manage-offers.service';
import { Link, useNavigate } from 'react-router-dom';
import fns from 'helpers/functions';
import { Pagination } from 'Plugins';

const MyOffersInMarket = () => {
    const navigate = useNavigate();
    const [offers, setOffers] = useState(null);
    const [fetching, setFetching] = useState(true);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fetchOffers();

        return () => {
        }
    }, []);

    const fetchOffers = async (page = 1) => {
        setFetching(true);
        const res = await ManageOffer.FetchLiveOffersAPI(page, sizePerPage);

        if (res.response) {
            setFetching(false);
            setOffers(res.data.data);
            setTotalCount(res?.data?.totalSize);
        } else {
            setFetching(false);
            setOffers(null);
            setTotalCount(0);
        }
    }

    const goToEdit = (_id) => {
        navigate(`/edit-offer`, { replace: false, state: { _id: _id } });
    }

    const deleteOffer = async (_id) => {
        const res = await ManageOffer.DeleteLiveOfferAPI(_id);

        if (res.response) {
            fetchOffers();
        } else if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    const THClass = "text-xs font-Averta px-4 py-4 text-center";

    return (
        <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
            <div className='w-full float-left'>

                <Breadcrumb
                    page={'MY OFFERS ON THE MARKET'}
                    desc={' '}
                    contact={null}
                />

                <div className='w-full float-left font-Averta'>
                    <div className="sm:w-11/12 w-full mx-auto px-5">
                        <div className='float-left w-full'>
                            <div className='float-left w-full'>

                                <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                    <div className='inline-block align-middle mr-3'>
                                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g filter="url(#filter0_d_15_6)">
                                                <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                            </g>
                                            <path d="M30.89 9H16.83C16.3349 9.01311 15.8649 9.22107 15.5223 9.57871C15.1796 9.93635 14.9919 10.4148 15 10.91V35.09C14.9919 35.5852 15.1796 36.0636 15.5223 36.4213C15.8649 36.7789 16.3349 36.9869 16.83 37H37.17C37.6651 36.9869 38.1351 36.7789 38.4777 36.4213C38.8204 36.0636 39.0081 35.5852 39 35.09V16.92L30.89 9ZM30 18V10.84L37.3 18H30Z" fill="#160B32" />
                                            <defs>
                                                <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="4" />
                                                    <feGaussianBlur stdDeviation="2" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                    OFFERS ON THE MARKET
                                    <div className='w-full float-left pl-16'>
                                        <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                            <div className="flex flex-col">
                                                <div className="overflow-x-auto">
                                                    <div className="py-2 inline-block min-w-full">
                                                        <div className="overflow-x-auto mb-2">
                                                            {
                                                                fetching
                                                                    ?
                                                                    <TRLoader count={10} />
                                                                    :
                                                                    (offers !== null && offers.length > 0)
                                                                        ?
                                                                        <table className="min-w-full text-black">
                                                                            <thead className="">
                                                                                <tr>
                                                                                    <th scope="col" className={THClass}>ID Number</th>
                                                                                    <th scope="col" className={THClass}>Uploaded</th>
                                                                                    <th scope="col" className={THClass}>Outstanding Balance</th>
                                                                                    <th scope="col" className={THClass}>SH Ref</th>
                                                                                    <th scope="col" className={THClass}>Account Type</th>
                                                                                    <th scope="col" className={THClass}>Settlement Value</th>
                                                                                    <th scope="col" className={THClass}>Expires In</th>
                                                                                    <th scope="col" className={THClass}>Status</th>
                                                                                    <th scope="col" className={THClass}>Edit Offer</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className=''>
                                                                                {
                                                                                    offers.map((offer, index) => {
                                                                                        const TBClass = `text-xs px-4 py-2 text-center font-Averta whitespace-nowrap xl:min-w-fit`;
                                                                                        const StColor = offer?._status_text === 'Active' ? '#1EC465' : offer?._status_text === 'Locked' ? '#FFCC00' : '#EB5757';
                                                                                        return (
                                                                                            <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                                <td className={`${TBClass} min-w-[160px]`}>{offer?.id_number}</td>
                                                                                                <td className={`${TBClass} min-w-[160px] text-[#818181]`}>{offer?.uploaded}</td>
                                                                                                <td className={`${TBClass} min-w-[160px] text-[#EB5757]`}>{offer?._outstanding_balance_text}</td>
                                                                                                <td className={`${TBClass} min-w-[160px]`}>{offer?.sh_ref ? offer?.sh_ref : 'N/A'}</td>
                                                                                                <td className={`${TBClass} min-w-[160px]`}>{offer?._account_type_text}</td>
                                                                                                <td className={`${TBClass} min-w-[160px] text-[#1EC465]`}>{offer?._settlement_value_text}</td>
                                                                                                <td className={`${TBClass} min-w-[160px] text-[#818181]`}>{offer?.expires_in}  {offer?.expires_in <= 1 ? 'day' : 'days'}</td>
                                                                                                <td className={`${TBClass} min-w-[160px]`}>
                                                                                                    <span className={`text-[${StColor}] inline-block align-middle`}>{offer?._status_text}</span>
                                                                                                    <div className={`rounded-full w-[14px] h-[14px] bg-[${StColor}] inline-block align-middle ml-3`}></div>
                                                                                                </td>
                                                                                                <td className={`${TBClass} min-w-[160px]`}>
                                                                                                    {
                                                                                                        offer?._status_text === 'Expired'
                                                                                                            ?
                                                                                                            <>
                                                                                                                <button onClick={(e) => { goToEdit(offer?._id); }} className='inline-block align-middle text-xs text-blue hover:text-burgandy py-1 border px-3' href="">EDIT</button>
                                                                                                                <span onClick={(e) => {
                                                                                                                    fns.CONFIRM_BOX(
                                                                                                                        'Confirm to delete!',
                                                                                                                        'Are you sure to delete this offer?',
                                                                                                                        'YES',
                                                                                                                        'CANCEL',
                                                                                                                        () => deleteOffer(offer?._id),
                                                                                                                        ''
                                                                                                                    );
                                                                                                                }} className='inline-block align-middle ml-5 cursor-pointer'>
                                                                                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <g clipPath="url(#clip0_640_16523)">
                                                                                                                            <path d="M2.625 6.5625V19.6875C2.625 20.4094 3.21562 21 3.9375 21H15.75C16.4719 21 17.0625 20.4094 17.0625 19.6875V6.5625H2.625ZM6.5625 18.375H5.25V9.1875H6.5625V18.375ZM9.1875 18.375H7.875V9.1875H9.1875V18.375ZM11.8125 18.375H10.5V9.1875H11.8125V18.375ZM14.4375 18.375H13.125V9.1875H14.4375V18.375Z" fill="#190833" />
                                                                                                                            <path d="M17.3906 2.625H13.125V0.984375C13.124 0.723622 13.0199 0.473844 12.8355 0.289462C12.6512 0.10508 12.4014 0.0010368 12.1406 0L7.54688 0C7.28612 0.0010368 7.03634 0.10508 6.85196 0.289462C6.66758 0.473844 6.56354 0.723622 6.5625 0.984375V2.625H2.29688C2.03602 2.62569 1.78604 2.72963 1.60158 2.91408C1.41713 3.09854 1.31319 3.34852 1.3125 3.60938V5.25H18.375V3.60938C18.3743 3.34852 18.2704 3.09854 18.0859 2.91408C17.9015 2.72963 17.6515 2.62569 17.3906 2.625ZM11.8125 2.625H7.875V1.32956H11.8125V2.625Z" fill="#190833" />
                                                                                                                        </g>
                                                                                                                        <defs>
                                                                                                                            <clipPath id="clip0_640_16523">
                                                                                                                                <rect width="21" height="21" fill="white" />
                                                                                                                            </clipPath>
                                                                                                                        </defs>
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <span className='inline-block align-middle text-xs text-[#C4C4C4] py-1 border px-3 hover:text-burgandy pointer-events-none' href="">EDIT</span>
                                                                                                                <span className='inline-block align-middle ml-5'>
                                                                                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <g clipPath="url(#clip0_640_16508)">
                                                                                                                            <path d="M2.625 6.5625V19.6875C2.625 20.4094 3.21562 21 3.9375 21H15.75C16.4719 21 17.0625 20.4094 17.0625 19.6875V6.5625H2.625ZM6.5625 18.375H5.25V9.1875H6.5625V18.375ZM9.1875 18.375H7.875V9.1875H9.1875V18.375ZM11.8125 18.375H10.5V9.1875H11.8125V18.375ZM14.4375 18.375H13.125V9.1875H14.4375V18.375Z" fill="#C4C4C4" />
                                                                                                                            <path d="M17.3906 2.625H13.125V0.984375C13.124 0.723622 13.0199 0.473844 12.8355 0.289462C12.6512 0.10508 12.4014 0.0010368 12.1406 0L7.54688 0C7.28612 0.0010368 7.03634 0.10508 6.85196 0.289462C6.66758 0.473844 6.56354 0.723622 6.5625 0.984375V2.625H2.29688C2.03602 2.62569 1.78604 2.72963 1.60158 2.91408C1.41713 3.09854 1.31319 3.34852 1.3125 3.60938V5.25H18.375V3.60938C18.3743 3.34852 18.2704 3.09854 18.0859 2.91408C17.9015 2.72963 17.6515 2.62569 17.3906 2.625ZM11.8125 2.625H7.875V1.32956H11.8125V2.625Z" fill="#C4C4C4" />
                                                                                                                        </g>
                                                                                                                        <defs>
                                                                                                                            <clipPath id="clip0_640_16508">
                                                                                                                                <rect width="21" height="21" fill="white" />
                                                                                                                            </clipPath>
                                                                                                                        </defs>
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                            </>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        <p>No offers are in the market.</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                total_count > sizePerPage
                                                &&
                                                <div className="float-left w-full">
                                                    <div className="text-center float-left w-full">
                                                        <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchOffers(data.selected + 1) }} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>

                                {
                                    fns.CHECK_PERMISSION(["A5"])
                                        ?
                                        <Link to={`/closed-offers`} className='float-left w-full align-middle text-xs font-Averta_Semibold py-2 px-3 rounded-lg bg-[#F2F2F2] shadow-md shadow-black/25'>
                                            <div className='inline-block align-middle mr-3'>
                                                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.875 24.75H26.125V27.5H6.875V24.75ZM13.2 21.0375L6.875 14.7125L9.625 12.1L13.2 15.675L23.375 5.5L26.125 8.25L13.2 21.0375Z" fill="#160B32" />
                                                </svg>
                                            </div>
                                            {''} DEALS CLOSED
                                            <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                        </Link>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className='clear-both'></div>
                    </div>
                </div>
                <div className='clear-both'></div>
            </div>
        </section>
    )
}

export default MyOffersInMarket