import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TRLoader, LineLoader, ButtonLoader, RoundedInputLoader } from 'UIs/SkeletonLoader';
import fns from 'helpers/functions';
import * as OrgService from 'Services/organisation.service';
import * as RoleService from 'Services/role.service';

const MemberDetails = () => {
    const { _token } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const [processing, setProcessing] = useState(false);
    const [members, setMembers] = useState(null);
    const [roles, setRoles] = useState(null);
    const organisation_id = localStorage.getItem('org_id');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchRoles();

        if (typeof _token !== 'undefined' && _token !== '') {
            fetchMembers();
        } else {
            navigate(`/login`);
        }
        return () => {
        }
    }, []);

    /** Fetch existing members for logged organisation */
    const fetchMembers = async () => {
        const res = await OrgService.FetchMembersAPI();

        if (res.response) {
            setMembers(res.data.data);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        } else {
            setMembers(null);
            setLoading(false);
        }
    }

    /** Fetch all the roles for logged organisation */
    const fetchRoles = async () => {
        const res = await RoleService.FetchRolesAPI();

        if (res.response) {
            setRoles(res.data);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        } else {
            setRoles(null);
            setLoading(false);
        }
    }

    /** Add new member to organisation */
    const SaveMember = async (form) => {
        setProcessing(true);
        const res = await OrgService.SaveMemberInfoAPI(organisation_id, form);

        if (res.response) {
            setProcessing(false);
            fetchMembers();
            setValue('first_name', '');
            setValue('last_name', '');
            setValue('email', '');
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    /** Delete member */
    const DeleteMember = async (_id) => {
        const res = await OrgService.DeleteMemberAPI(_id);

        if (res.response) {
            fetchMembers();
        } else if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        !loading
            ?
            (
                <>
                    <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-24 w-full float-left font-Averta md:flex md:items-center">
                        <div className="lg:container mx-auto px-5">
                            <ul className="md:max-w-sm w-full float-left text-blue font-Averta pb-6 pr-5">
                                <li className="leading-9 pb-3 align-middle">
                                    <span className="hover:text-burgandy inline-block leading-5 align-middle cursor-pointer">
                                        <span className="align-middle rounded-full border-2 w-6 h-6 leading-5 mr-2 border-perot text-center inline-block bg-perot text-white"><i className="fa-solid fa-check"></i></span> Personal Details
                                    </span>
                                    <Link to={`/self-register/update-personal-details/${_token}`} className="float-right py-1 px-2 hover:text-burgandy"><i className="fa-solid fa-arrow-left"></i></Link>
                                </li>
                                <li className="leading-9 pb-3 align-middle">
                                    <span className="hover:text-burgandy inline-block leading-5 align-middle cursor-pointer">
                                        <span className="align-middle rounded-full -mt-1 border-2 w-6 h-6 leading-5 mr-2 border-burgandy text-center inline-block">2</span> Member Details
                                    </span>
                                </li>
                            </ul>
                            <div className="md:w-2/5 w-full float-left">
                                <div className="bg-blue w-full float-left p-3 px-4">
                                    <h3 className="float-left text-white text-xs font-Averta_Semibold">Member Details</h3>
                                </div>
                                <form onSubmit={handleSubmit(SaveMember)}>
                                    <div className="w-full float-left clear-both p-4 pt-1">
                                        <div className="w-full float-left mb-2">
                                            <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Member Name</label>
                                            <div className="float-right w-full md:w-3/5">
                                                <input
                                                    {...register("first_name", {
                                                        required: "required",
                                                        maxLength: {
                                                            value: 50,
                                                            message: "First name can't be more than 50 alphabets."
                                                        }
                                                    })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    type="text"
                                                    placeholder="Lesedi"
                                                />
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.first_name && errors.first_name.type === "required" && 'Please enter first name.'}
                                                    {errors.first_name && errors.first_name.type !== "required" && errors.first_name.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-full float-left mb-2">
                                            <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Member Surname</label>
                                            <div className="float-right w-full md:w-3/5">
                                                <input
                                                    {...register("last_name", {
                                                        required: "required",
                                                        maxLength: {
                                                            value: 50,
                                                            message: "Last name can't be more than 50 alphabets."
                                                        }
                                                    })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    type="text"
                                                    placeholder="Phandiane"
                                                />
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.last_name && errors.last_name.type === "required" && 'Please enter last name.'}
                                                    {errors.last_name && errors.last_name.type !== "required" && errors.last_name.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-full float-left mb-10"></div>
                                        <div className="w-full float-left mb-2">
                                            <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Member Role</label>
                                            <div className="float-right w-full md:w-3/5">
                                                <select
                                                    {...register("role_id", { required: "required" })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    id="role"
                                                >
                                                    {
                                                        roles &&
                                                        roles.map((role, indexr) =>
                                                            <option key={indexr} value={role._id}>{role.name}</option>
                                                        )
                                                    }
                                                </select>
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.role_id && errors.role_id.type === "required" && 'Please select role.'}
                                                    {errors.role_id && errors.role_id.type !== "required" && errors.role_id.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-full float-left mb-2">
                                            <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">E-mail</label>
                                            <div className="float-right w-full md:w-3/5">
                                                <input
                                                    {...register("email", {
                                                        required: "required",
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: "Entered value does not match email format"
                                                        }
                                                    })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    type="text"
                                                    placeholder="lesedi@bayport.co.za"
                                                />
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.email && errors.email.type === "required" && 'Please mebmer email address.'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-full float-left">
                                            <button disabled={processing} className='float-right bg-lightperot hover:bg-perot text-white text-sm leading-6 p-1 px-10 rounded-full'>
                                                {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Save'}
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                <div className="bg-blue w-full float-left p-3 px-4 mt-2">
                                    <h3 className="float-left text-white text-xs font-Averta_Semibold">Added Members</h3>
                                </div>
                                <div className="w-full float-left clear-both p-4 pt-1">
                                    <div className="flex flex-col">
                                        <div className="overflow-x-auto">
                                            <div className="py-2 inline-block min-w-full">
                                                <div className="overflow-x-auto mb-2">
                                                    {
                                                        members && members.length > 0
                                                            ?
                                                            <table className="min-w-full text-black">
                                                                <thead className="">
                                                                    <tr>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-2 text-left"></th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-2 text-left">
                                                                            Name
                                                                        </th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-2 text-left">
                                                                            Role
                                                                        </th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-2 text-left"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className=''>
                                                                    {
                                                                        members
                                                                        &&
                                                                        members.map((member, indexm) =>
                                                                            <tr key={indexm} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                <td className="text-xs px-4 py-1 text-left font-Averta whitespace-nowrap min-w-[60px] xl:min-w-fit">
                                                                                    <div className='w-[30px] h-[30px] text-center border border-blue rounded-full leading-7'>{indexm + 1}</div>
                                                                                </td>
                                                                                <td className="text-xs px-4 py-1 text-left font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                    {member?.first_name}
                                                                                </td>
                                                                                <td className="text-xs px-4 py-1 text-left font-Averta whitespace-nowrap min-w-[200px] xl:min-w-fit">
                                                                                    {member?.roles[0]?.name}
                                                                                </td>
                                                                                <td className="text-sm px-4 py-1 text-left font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                    <span
                                                                                        onClick={(e) => {
                                                                                            fns.CONFIRM_BOX(
                                                                                                'Confirm to delete!',
                                                                                                'Are you sure to delete this?',
                                                                                                'YES',
                                                                                                'CANCEL',
                                                                                                () => DeleteMember(member?.encrypted_id),
                                                                                                ''
                                                                                            );
                                                                                        }}
                                                                                        className='float-right leading-8 px-3 hover:text-white hover:bg-blue rounded-md cursor-pointer'
                                                                                    >
                                                                                        <i className="fa-regular fa-trash"></i>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            :
                                                            <span className='leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue'>Your organisation don't have any members.</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                                <Link to={`/login`} className="float-right xl:-mr-80 mt-10 bg-blue py-5 px-20 rounded-full text-white font-bold text-sm">Finish</Link>
                                <div className="clear-both"></div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </section>
                </>
            )
            :
            <PageSkeleton />
    )
}

export default MemberDetails;

const PageSkeleton = () => {
    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-24 w-full float-left font-Averta md:flex md:items-center">
                <div className="lg:container mx-auto px-5">
                    <ul className="md:max-w-sm w-full float-left text-blue font-Averta pb-6 pr-5">
                        <LineLoader width={'50%'} count={2} />
                    </ul>
                    <div className="md:w-2/5 w-full float-left">
                        <div className="bg-blue w-full float-left p-3 px-4">
                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Member Details</h3>
                        </div>

                        <div className="w-full float-left clear-both p-4 pt-1">
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Member Name</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Member Surname</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-10"></div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Member Role</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">E-mail</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-right w-2/5">
                                <RoundedInputLoader className={''} width={'30%'} />
                            </div>
                        </div>

                        <div className="bg-blue w-full float-left p-3 px-4 mt-2">
                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Added Members</h3>
                        </div>
                        <div className="w-full float-left clear-both p-4 pt-1">
                            <div className="flex flex-col">
                                <div className="overflow-x-auto">
                                    <div className="py-2 inline-block min-w-full">
                                        <div className="overflow-x-auto mb-2">
                                            <TRLoader count={1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear-both"></div>
                        <ButtonLoader />
                        <div className="clear-both"></div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </section>
        </>
    );
}