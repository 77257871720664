import React, { useEffect, useState } from 'react'
import { TRLoader } from 'UIs/SkeletonLoader';
import { useParams } from 'react-router-dom';
import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { Pagination } from 'Plugins';
import fns from 'helpers/functions';
import constants from 'helpers/constants';
import * as OrgService from 'Services/organisation.service';
import * as GenService from 'Services/general.service';

const Payment = () => {
    const { _id } = useParams();
    const [fetching, setFetching] = useState(true);
    const [invoices, setInvoices] = useState(null);
    const [modalShow, SetModalShow] = useState(false);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fns.CHECK_PERMISSION(["C3"], true);
        fetchInvoices();

        return () => {
        }
    }, []);

    /** Fetch list of members */
    const fetchInvoices = async (page = 1) => {
        setFetching(true);
        const res = await OrgService.FetchOrgInvoicesAPI(_id, page, sizePerPage);

        if (res.response) {
            setFetching(false);
            setInvoices(res.data.data);
            setTotalCount(res.data.totalSize);
        } else {
            setFetching(false);
            setInvoices(null);
            setTotalCount(0);
        }
    }

    const downloadAttachment = async (_id, name) => {
        const res = await GenService.DownloadAttachmentAPI(_id, name);
        if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    const changeStatus = async (_id) => {
        setFetching(true);
        const res = await OrgService.UpdateInvoiceStatusAPI(_id);

        if (res.response) {
            fns.TOAST_SUC(res?.data.message);
            fetchInvoices();
        } else {
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <>
            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                <div className='inline-block align-middle mr-3'>
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_15_6)">
                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                        </g>
                        <g clipPath="url(#clip0_15_6)">
                            <path d="M31.75 23H21.25V26.5H31.75V23ZM36.6172 14.7422L31.2633 9.38281C31.0172 9.13672 30.6836 9 30.3336 9H30V16H37V15.6664C37 15.3219 36.8633 14.9883 36.6172 14.7422ZM28.25 16.4375V9H17.3125C16.5852 9 16 9.58516 16 10.3125V35.6875C16 36.4148 16.5852 37 17.3125 37H35.6875C36.4148 37 37 36.4148 37 35.6875V17.75H29.5625C28.8406 17.75 28.25 17.1594 28.25 16.4375ZM19.5 12.9375C19.5 12.6958 19.6958 12.5 19.9375 12.5H24.3125C24.5542 12.5 24.75 12.6958 24.75 12.9375V13.8125C24.75 14.0542 24.5542 14.25 24.3125 14.25H19.9375C19.6958 14.25 19.5 14.0542 19.5 13.8125V12.9375ZM19.5 16.4375C19.5 16.1958 19.6958 16 19.9375 16H24.3125C24.5542 16 24.75 16.1958 24.75 16.4375V17.3125C24.75 17.5542 24.5542 17.75 24.3125 17.75H19.9375C19.6958 17.75 19.5 17.5542 19.5 17.3125V16.4375ZM33.5 33.0625C33.5 33.3042 33.3042 33.5 33.0625 33.5H28.6875C28.4458 33.5 28.25 33.3042 28.25 33.0625V32.1875C28.25 31.9458 28.4458 31.75 28.6875 31.75H33.0625C33.3042 31.75 33.5 31.9458 33.5 32.1875V33.0625ZM33.5 22.125V27.375C33.5 27.8584 33.1084 28.25 32.625 28.25H20.375C19.8916 28.25 19.5 27.8584 19.5 27.375V22.125C19.5 21.6416 19.8916 21.25 20.375 21.25H32.625C33.1084 21.25 33.5 21.6416 33.5 22.125Z" fill="#160B32" />
                        </g>
                        <defs>
                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                            </filter>
                            <clipPath id="clip0_15_6">
                                <rect width="21" height="28" fill="white" transform="translate(16 9)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                INVOICES
                <div className='w-full float-left pl-16'>
                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-x-auto pb-4 xl:pb-0">
                                        <button onClick={() => { SetModalShow(true); }} className='float-right text-xs text-blue py-1 border px-3 bg-white w-[130px] text-center hover:text-burgandy uppercase'>Create Invoice</button>

                                        {
                                            fetching
                                                ?
                                                <TRLoader count={10} />
                                                :
                                                invoices && invoices.length > 0
                                                    ?
                                                    <>
                                                        <table className="min-w-full text-black mb-5">
                                                            <thead className="">
                                                                <tr>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Invoice #</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Date</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">SettleHub Account</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Reference</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Detail</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Amount</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Download</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className=''>
                                                                {
                                                                    invoices.map((invoice, index) =>
                                                                        <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                            <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                {invoice?.invoice_id}
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                {invoice?.date}
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                {invoice?.settlehub_account}
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                {invoice?.reference}
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                {invoice?.detail}
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit text-[#818181]">
                                                                                R{invoice?.amount}
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                {
                                                                                    invoice?.invoice_document
                                                                                        ?
                                                                                        <svg
                                                                                            onClick={() => { downloadAttachment(invoice?.invoice_document?._id, invoice?.invoice_document?.document_name) }}
                                                                                            className='m-auto cursor-pointer'
                                                                                            width="21"
                                                                                            height="28"
                                                                                            viewBox="0 0 21 28"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clipPath="url(#clip0_435_15753)"><path d="M15.75 14H5.25V17.5H15.75V14ZM20.6172 5.74219L15.2633 0.382812C15.0172 0.136719 14.6836 0 14.3336 0H14V7H21V6.66641C21 6.32188 20.8633 5.98828 20.6172 5.74219ZM12.25 7.4375V0H1.3125C0.585156 0 0 0.585156 0 1.3125V26.6875C0 27.4148 0.585156 28 1.3125 28H19.6875C20.4148 28 21 27.4148 21 26.6875V8.75H13.5625C12.8406 8.75 12.25 8.15938 12.25 7.4375ZM3.5 3.9375C3.5 3.69578 3.69578 3.5 3.9375 3.5H8.3125C8.55422 3.5 8.75 3.69578 8.75 3.9375V4.8125C8.75 5.05422 8.55422 5.25 8.3125 5.25H3.9375C3.69578 5.25 3.5 5.05422 3.5 4.8125V3.9375ZM3.5 7.4375C3.5 7.19578 3.69578 7 3.9375 7H8.3125C8.55422 7 8.75 7.19578 8.75 7.4375V8.3125C8.75 8.55422 8.55422 8.75 8.3125 8.75H3.9375C3.69578 8.75 3.5 8.55422 3.5 8.3125V7.4375ZM17.5 24.0625C17.5 24.3042 17.3042 24.5 17.0625 24.5H12.6875C12.4458 24.5 12.25 24.3042 12.25 24.0625V23.1875C12.25 22.9458 12.4458 22.75 12.6875 22.75H17.0625C17.3042 22.75 17.5 22.9458 17.5 23.1875V24.0625ZM17.5 13.125V18.375C17.5 18.8584 17.1084 19.25 16.625 19.25H4.375C3.89156 19.25 3.5 18.8584 3.5 18.375V13.125C3.5 12.6416 3.89156 12.25 4.375 12.25H16.625C17.1084 12.25 17.5 12.6416 17.5 13.125Z" fill="#160B32" /></g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_435_15753">
                                                                                                    <rect width="21" height="28" fill="white" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                        :
                                                                                        'N/A'
                                                                                }
                                                                            </td>
                                                                            <td className={`text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit uppercase`}>
                                                                                <span
                                                                                    onClick={() => {
                                                                                        if (invoice?.status === 'unpaid') {
                                                                                            fns.CONFIRM_BOX(
                                                                                                'Confirm payment!',
                                                                                                'Are you sure to update payment status?',
                                                                                                'YES',
                                                                                                'CANCEL',
                                                                                                () => changeStatus(invoice?.id),
                                                                                                ''
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    className={`text-[#${invoice?.status === 'paid' ? '1EC465' : 'EB5757'}] ${invoice?.status === 'unpaid' ? 'cursor-pointer' : ''}`}
                                                                                >
                                                                                    {invoice?.status === 'paid' ? invoice?.status : 'unpaid'}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </>
                                                    :
                                                    <p>No Invoices generated.</p>
                                        }

                                        {
                                            total_count > sizePerPage
                                                ?
                                                <div className="float-left w-full">
                                                    <div className="text-center float-left w-full">
                                                        <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchInvoices(data.selected + 1) }} />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modalShow} className="bg-black/60">
                <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalShow(!modalShow); }}>
                    Create New Invoice
                </Modal.Header>
                <Modal.Body className='text-center pt-0'>
                    {modalShow ? <CreateInvoice _id={_id} SetModalShow={SetModalShow} fetchInvoices={fetchInvoices} /> : null}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Payment

const CreateInvoice = ({ _id, SetModalShow, fetchInvoices }) => {
    const { register, handleSubmit, formState: { errors }, watch, setValue, setError, clearErrors } = useForm();
    const [processing, setProcessing] = useState(false);
    const today = new Date().getFullYear() + '-' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '-' + ("0" + new Date().getDate()).slice(-2);

    /** Edit organisation member */
    const SaveInvoice = async (form) => {
        const formx = new FormData();
        formx.append('file', form.file[0]);
        formx.append('start_date', form.start_date);
        formx.append('end_date', form.end_date);
        formx.append('settlehub_account', form.settlehub_account);
        formx.append('reference', form.reference);
        formx.append('detail', form.detail);
        formx.append('amount', form.amount);
        formx.append('status', 'unpaid');

        setProcessing(true);
        const res = await OrgService.CreateInvoiceAPI(_id, formx);

        if (res.response) {
            setProcessing(false);
            fetchInvoices();
            SetModalShow(false);
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    const handleInvoice = (e) => {
        var filePath = e.target.value;
        if (!constants.ATTACHMENT_REQ.exec(filePath)) {
            setValue('file');
            setError("file", { type: "focus", message: 'Please select PDF file only.' }, { shouldFocus: true });
            return false;
        }

        clearErrors('file');
    }

    return (
        <>
            <form onSubmit={handleSubmit(SaveInvoice)}>
                <div className="w-full float-left clear-both p-4 pt-1 text-left">
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Start date</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("start_date", {
                                    required: "required",
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="date"
                                placeholder="Lesedi"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.start_date && errors.start_date.type === "required" && 'Please select invoice start date.'}
                                {errors.start_date && errors.start_date.type !== "required" && errors.start_date.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">End date</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("end_date", {
                                    required: "required",
                                    min: {
                                        value: watch('start_date'),
                                        message: "Please select same or greater date."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="date"
                                placeholder="Lesedi"
                                min={watch('start_date')}
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.end_date && errors.end_date.type === "required" && 'Please select invoice end date.'}
                                {errors.end_date && errors.end_date.type !== "required" && errors.end_date.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Settlehub Account</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("settlehub_account", {
                                    required: "required",
                                    min: {
                                        value: 3,
                                        message: "Please enter at least 3 characters."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="Settlehub Account (E.g. : BP_0001)"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.settlehub_account && errors.settlehub_account.type === "required" && 'Please enter settlehub account. (E.g. : BP_0001)'}
                                {errors.settlehub_account && errors.settlehub_account.type !== "required" && errors.settlehub_account.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Settlehub reference</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("reference", {
                                    required: "required",
                                    min: {
                                        value: 3,
                                        message: "Please enter at least 3 characters."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="Settlehub reference (E.g. : SH_AG01_01)"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.reference && errors.reference.type === "required" && 'Please enter settlehub reference. (E.g. : SH_AG01_01)'}
                                {errors.reference && errors.reference.type !== "required" && errors.reference.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Invoice detail</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("detail", {
                                    required: "required",
                                    min: {
                                        value: 3,
                                        message: "Please enter at least 3 characters."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="Invoice detail (E.g. : Enquiry Fee)"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.detail && errors.detail.type === "required" && 'Please enter invoice detail. (E.g. : Enquiry Fee)'}
                                {errors.detail && errors.detail.type !== "required" && errors.detail.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Invoice amount</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("amount", {
                                    required: "required",
                                    min: {
                                        value: 1,
                                        message: "Invocie amount must greater then zero."
                                    },
                                    pattern: {
                                        value: /^[0-9\.]{1,8}$/,
                                        message: "Invocie amount must be numeric value."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="number"
                                placeholder="Invoice amount (E.g. : 100.00)"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.amount && errors.amount.type === "required" && 'Please enter invoice amount. (E.g. : 100.00)'}
                                {errors.amount && errors.amount.type !== "required" && errors.amount.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Invoice amount</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("file", {
                                    required: "required"
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="file"
                                accept="image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={handleInvoice}
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.file && errors.file.type === "required" && 'Please select invoice file.'}
                                {errors.file && errors.file.type !== "required" && errors.file.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left">
                        <button disabled={processing} className='float-right bg-lightperot hover:bg-perot text-white text-sm leading-6 p-1 px-10 rounded-full'>
                            {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Save'}
                        </button>
                    </div>
                </div>
                <div className='clear-both'></div>
            </form>
        </>
    );
}