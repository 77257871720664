import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GoBack } from 'UIs/Buttons';
import Details from './Details';
import EventLogs from './EventLogs';
import Payment from './Payment';
import { Breadcrumb } from 'modules/fragments';
import fns from 'helpers/functions';
import * as ManageOfferService from 'Services/manage-offers.service';

const DealDetails = () => {
    const navigate = useNavigate();
    const { _id } = useParams();
    const [fetching, setFetching] = useState(true);
    const [active_tab, setActiveTab] = useState(1);
    const [deal, setDeal] = useState(null);

    useEffect(() => {
        fns.CHECK_PERMISSION(["B4", "C2", "C3"], true);
        fetchDealDetails();

        return () => {
        }
    }, []);

    const fetchDealDetails = async () => {
        const res = await ManageOfferService.FetchDealDetailsAPI(_id);

        if (res.response) {
            setFetching(false);
            setDeal(res?.data);
        } else {
            setFetching(false);
            setDeal(null);
        }
    }

    return (
        <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
            <div className='w-full float-left'>

                <Breadcrumb
                    page={'Deal summary for:'}
                    desc={fetching ? ' ' : `${fns.STR_REPLACE(deal?.settlement_summary?.type, '_', ' ')}: ${deal?.id_number}`}
                    contact={<GoBack onClick={() => { navigate(-1); }} name={'Back to deals'} />}
                />

                <div className='sm:w-11/12 w-full mx-auto px-5 py-5 pt-0 relative text-center'>
                    <nav className="m-auto max-w-[385px] w-full float-left lg:float-none mb-2">
                        <button onClick={() => { setActiveTab(1); }} className={`uppercase max-w-[125px] w-full float-left text-xs py-1 px-2 border ${active_tab === 1 ? 'bg-burgandy text-white border-black focus:outline-none' : 'hover:bg-burgandy hover:text-white border-black focus:outline-none'}`}>Details</button>
                        <button onClick={() => { setActiveTab(2); }} className={`uppercase max-w-[125px] w-full float-left text-xs py-1 px-2 border ${active_tab === 2 ? 'bg-burgandy text-white border-black focus:outline-none' : 'hover:bg-burgandy hover:text-white border-black focus:outline-none'}`}>Event Log</button>
                        <button onClick={() => { setActiveTab(3); }} className={`uppercase max-w-[125px] w-full float-left text-xs py-1 px-2 border ${active_tab === 3 ? 'bg-burgandy text-white border-black focus:outline-none' : 'hover:bg-burgandy hover:text-white border-black focus:outline-none'}`}>Payment</button>
                        <div className='clear-both'></div>
                    </nav>
                    <div className='clear-both'></div>
                </div>

                <div className='w-full float-left font-Averta'>
                    <div className="sm:w-11/12 w-full mx-auto px-5">
                        <div className='float-left w-full'>
                            <div className='float-left w-full'>
                                {
                                    active_tab === 1
                                        ?
                                        <Details fetching={fetching} deal={deal} />
                                        :
                                        null
                                }

                                {
                                    active_tab === 2
                                        ?
                                        <EventLogs />
                                        :
                                        null
                                }

                                {
                                    active_tab === 3
                                        ?
                                        <Payment deal={deal} />
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className='clear-both'></div>
                    </div>
                </div>

                <div className='clear-both'></div>
            </div>
        </section>
    )
}

export default DealDetails;