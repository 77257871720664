import React, { useEffect, useState } from 'react'
import { TRLoader } from 'UIs/SkeletonLoader';
import { Pagination } from 'Plugins';
import fns from 'helpers/functions';
import * as UploadService from 'Services/client-upload.service';

const ClientUploadRequests = () => {
    const [offerRequests, setOfferRequests] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [selected, setSelected] = useState([]);
    const [processing, setProcessing] = useState(false);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fns.CHECK_PERMISSION(["C2"], true);

        fetchOfferRequests();

        return () => {
        }
    }, []);

    /** Fetch list of pending offers */
    const fetchOfferRequests = async (page = 1) => {
        setFetching(true);
        const res = await UploadService.FetchOfferRequestsAPI(page, sizePerPage);

        if (res.response) {
            setSelected([]);
            setFetching(false);
            setOfferRequests(res.data.data);
            setTotalCount(res?.data?.totalSize);
        } else {
            setSelected([]);
            setFetching(false);
            setOfferRequests(null);
            setTotalCount(0);
        }
    }

    /** Accept/Reject Offer */
    const acceptRejectOffer = async (flag) => {
        if (selected?.length <= 0) {
            fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.');
            return;
        }

        setProcessing(true);
        const res = await UploadService.UpdateOfferStatusAPI({ action: flag, ids: selected });

        if (res.response) {
            if (res?.data?.approved > 0) {
                fns.TOAST_SUC(`${res?.data?.approved} offer(s) approved.`);
            }

            if (res?.data?.rejected > 0) {
                fns.TOAST_SUC(`${res?.data?.rejected} offer(s) rejected.`);
            }

            if (res?.data?.already_approved > 0) {
                fns.TOAST_INFO(`${res?.data?.already_approved} offer(s) are already approved.`);
            }

            if (res?.data?.already_rejected > 0) {
                fns.TOAST_INFO(`${res?.data?.already_rejected} offer(s) are already rejected.`);
            }

            if (res?.data?.failed > 0) {
                fns.TOAST_ERR_ONE(`${res?.data?.failed} offer(s) are failed.`);
            }

            setProcessing(false);
            fetchOfferRequests();
        } else {
            if (res?.data?.rejected > 0) {
                fns.TOAST_SUC(`${res?.data?.rejected} offer(s) rejected.`);
            }

            if (res?.data?.already_approved > 0) {
                fns.TOAST_INFO(`${res?.data?.already_approved} offer(s) are already approved.`);
            }

            if (res?.data?.already_rejected > 0) {
                fns.TOAST_INFO(`${res?.data?.already_rejected} offer(s) are already rejected.`);
            }

            if (res?.data?.failed > 0) {
                fns.TOAST_ERR_ONE(`${res?.data?.failed} offer(s) are failed.`);
            }

            if (typeof res.errors !== 'undefined') {
                fns.TOAST_ERR(res.errors);
            }

            setProcessing(false);
        }
    }

    const handleCheckboxChange = (e) => {
        setSelected(fns.TOGGLE_ARR_ELE(selected, e.target.value));
    }

    return (
        <>
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <div className='sm:w-11/12 w-full mx-auto mb-10 px-5 py-5 pt-16 xl:pt-0 xl:pl-64 relative'>
                        <h3 className='absolute -left-[4.6%] top-0 bg-blue text-white text-center text-xs p-5 w-80 mr-4 uppercase'>Offer requests:</h3>
                    </div>
                    <div className='clear-both'></div>

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>
                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <div className='w-full float-left pl-16'>
                                            <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-5">
                                                <div className="flex flex-col">
                                                    <div className="overflow-x-auto">
                                                        <div className="py-2 inline-block min-w-full">
                                                            <div className="overflow-x-auto mb-2">
                                                                {
                                                                    fetching
                                                                        ?
                                                                        <TRLoader count={10} />
                                                                        :
                                                                        (
                                                                            offerRequests && offerRequests.length > 0
                                                                                ?
                                                                                <table className="min-w-full text-black">
                                                                                    <thead className="">
                                                                                        <tr>
                                                                                            <th scope="col" className="text-xs font-Averta px-4 py-4 text-center"></th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">ID/Passport Number</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Type</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Created</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Institution</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Outstanding Balance</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Code Bracket</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Account Number</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Account Type</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Settlement Value</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Ref.</th>
                                                                                            <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Expiry Date</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className=''>
                                                                                        {
                                                                                            offerRequests
                                                                                            &&
                                                                                            offerRequests.map((offer, index) => {
                                                                                                return (
                                                                                                    <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                                            <input id={`offer${index}`} name={`offer${index}`} onChange={handleCheckboxChange} type="checkbox" value={offer?._id} defaultChecked={false} />
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?.type === "passport" ? offer?.passport : offer?.id_number}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit uppercase">
                                                                                                            {fns.STR_REPLACE(offer?.type, '_', ' ')}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?.created_date}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?.institution}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?.outstanding_balance}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?.cd_bracket}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?.account_number}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?._account_type_text}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?.settlement_value}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?.settlement_reference}
                                                                                                        </td>
                                                                                                        <td className="text-sm px-4 py-4 whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                                            {offer?.settlement_offer_expiry}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                :
                                                                                <p className='text-center text-[14px] mt-2'>No upload requests found.</p>
                                                                        )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    total_count > sizePerPage
                                                    &&
                                                    <div className="float-left w-full">
                                                        <div className="text-center float-left w-full">
                                                            <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchOfferRequests(data.selected + 1) }} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            {
                                                (offerRequests !== null && typeof offerRequests !== 'undefined' && offerRequests.length > 0)
                                                    ?
                                                    <div className='float-right'>
                                                        <button
                                                            disabled={processing}
                                                            onClick={(e) => {
                                                                if (selected?.length <= 0) {
                                                                    fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.');
                                                                    return;
                                                                }

                                                                fns.CONFIRM_BOX(
                                                                    'Confirm to approve!',
                                                                    'Are you sure to approve this offer?',
                                                                    'YES',
                                                                    'CANCEL',
                                                                    () => acceptRejectOffer('approved'),
                                                                    ''
                                                                );
                                                            }}
                                                            className='rounded-xl full hover:bg-blue bg-burgandy p-4 px-12 text-blue hover:text-burgandy font-Averta_Semibold text-md'>
                                                            Approve
                                                        </button>
                                                        <button
                                                            disabled={processing}
                                                            onClick={(e) => {
                                                                if (selected?.length <= 0) {
                                                                    fns.INFO_BOX('No offer(s) selected', 'Please select at least one offer.');
                                                                    return;
                                                                }

                                                                fns.CONFIRM_BOX(
                                                                    'Confirm to reject!',
                                                                    'Are you sure to reject this offer?',
                                                                    'YES',
                                                                    'CANCEL',
                                                                    () => acceptRejectOffer('rejected'),
                                                                    ''
                                                                );
                                                            }}
                                                            className={`ml-2 rounded-xl hover:bg-burgandy bg-blue p-4 px-12 text-burgandy hover:text-blue font-Averta_Semibold text-md`}
                                                        >
                                                            Reject
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            <div className='clear-both'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>

                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    );
}

export default ClientUploadRequests