import React, { useEffect, useState } from 'react'
import { TRLoader } from 'UIs/SkeletonLoader';
import { Pagination } from 'Plugins';
import fns from 'helpers/functions';
import * as OrgService from 'Services/organisation.service';
import * as GenService from 'Services/general.service';

const InvoiceSection = () => {
    const [fetching, setFetching] = useState(true);
    const [invoices, setInvoices] = useState(null);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fetchInvoices();

        return () => {
        }
    }, []);

    /** Fetch list of members */
    const fetchInvoices = async (page = 1) => {
        setFetching(true);
        const res = await OrgService.FetchInvoicesAPI();

        if (res.response) {
            setFetching(false);
            setInvoices(res.data.data);
            setTotalCount(res.data.totalSize);
        } else {
            setFetching(false);
            setInvoices(null);
            setTotalCount(0);
        }
    }

    const downloadAttachment = async (_id, name) => {
        const res = await GenService.DownloadAttachmentAPI(_id, name);
        if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    const downloadInvoices = async (name) => {
        const res = await OrgService.DownloadInvoicesAPI('Invoices.csv');
        if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
            <div className='inline-block align-middle mr-3'>
                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_15_6)">
                        <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                    </g>
                    <g clipPath="url(#clip0_15_6)">
                        <path d="M31.75 23H21.25V26.5H31.75V23ZM36.6172 14.7422L31.2633 9.38281C31.0172 9.13672 30.6836 9 30.3336 9H30V16H37V15.6664C37 15.3219 36.8633 14.9883 36.6172 14.7422ZM28.25 16.4375V9H17.3125C16.5852 9 16 9.58516 16 10.3125V35.6875C16 36.4148 16.5852 37 17.3125 37H35.6875C36.4148 37 37 36.4148 37 35.6875V17.75H29.5625C28.8406 17.75 28.25 17.1594 28.25 16.4375ZM19.5 12.9375C19.5 12.6958 19.6958 12.5 19.9375 12.5H24.3125C24.5542 12.5 24.75 12.6958 24.75 12.9375V13.8125C24.75 14.0542 24.5542 14.25 24.3125 14.25H19.9375C19.6958 14.25 19.5 14.0542 19.5 13.8125V12.9375ZM19.5 16.4375C19.5 16.1958 19.6958 16 19.9375 16H24.3125C24.5542 16 24.75 16.1958 24.75 16.4375V17.3125C24.75 17.5542 24.5542 17.75 24.3125 17.75H19.9375C19.6958 17.75 19.5 17.5542 19.5 17.3125V16.4375ZM33.5 33.0625C33.5 33.3042 33.3042 33.5 33.0625 33.5H28.6875C28.4458 33.5 28.25 33.3042 28.25 33.0625V32.1875C28.25 31.9458 28.4458 31.75 28.6875 31.75H33.0625C33.3042 31.75 33.5 31.9458 33.5 32.1875V33.0625ZM33.5 22.125V27.375C33.5 27.8584 33.1084 28.25 32.625 28.25H20.375C19.8916 28.25 19.5 27.8584 19.5 27.375V22.125C19.5 21.6416 19.8916 21.25 20.375 21.25H32.625C33.1084 21.25 33.5 21.6416 33.5 22.125Z" fill="#160B32" />
                    </g>
                    <defs>
                        <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                        </filter>
                        <clipPath id="clip0_15_6">
                            <rect width="21" height="28" fill="white" transform="translate(16 9)" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            INVOICES
            <div className='w-full float-left pl-16'>
                <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="overflow-x-auto pb-4 xl:pb-0">
                                    {
                                        fetching
                                            ?
                                            <TRLoader count={10} />
                                            :
                                            invoices && invoices.length > 0
                                                ?
                                                <>
                                                    <table className="min-w-full text-black mb-5">
                                                        <thead className="">
                                                            <tr>
                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Invoice #</th>
                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Date</th>
                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">SettleHub Account</th>
                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Reference</th>
                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Detail</th>
                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Amount</th>
                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Download</th>
                                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className=''>
                                                            {
                                                                invoices.map((invoice, index) =>
                                                                    <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                        <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                            {invoice?.invoice_id}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                            {invoice?.date}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                            {invoice?.settlehub_account}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                            {invoice?.reference}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                            {invoice?.detail}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit text-[#818181]">
                                                                            R{invoice?.amount}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                            {
                                                                                invoice?.invoice_document
                                                                                    ?
                                                                                    <svg
                                                                                        onClick={() => { downloadAttachment(invoice?.invoice_document?._id, invoice?.invoice_document?.document_name) }}
                                                                                        className='m-auto cursor-pointer'
                                                                                        width="21"
                                                                                        height="28"
                                                                                        viewBox="0 0 21 28"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clipPath="url(#clip0_435_15753)"><path d="M15.75 14H5.25V17.5H15.75V14ZM20.6172 5.74219L15.2633 0.382812C15.0172 0.136719 14.6836 0 14.3336 0H14V7H21V6.66641C21 6.32188 20.8633 5.98828 20.6172 5.74219ZM12.25 7.4375V0H1.3125C0.585156 0 0 0.585156 0 1.3125V26.6875C0 27.4148 0.585156 28 1.3125 28H19.6875C20.4148 28 21 27.4148 21 26.6875V8.75H13.5625C12.8406 8.75 12.25 8.15938 12.25 7.4375ZM3.5 3.9375C3.5 3.69578 3.69578 3.5 3.9375 3.5H8.3125C8.55422 3.5 8.75 3.69578 8.75 3.9375V4.8125C8.75 5.05422 8.55422 5.25 8.3125 5.25H3.9375C3.69578 5.25 3.5 5.05422 3.5 4.8125V3.9375ZM3.5 7.4375C3.5 7.19578 3.69578 7 3.9375 7H8.3125C8.55422 7 8.75 7.19578 8.75 7.4375V8.3125C8.75 8.55422 8.55422 8.75 8.3125 8.75H3.9375C3.69578 8.75 3.5 8.55422 3.5 8.3125V7.4375ZM17.5 24.0625C17.5 24.3042 17.3042 24.5 17.0625 24.5H12.6875C12.4458 24.5 12.25 24.3042 12.25 24.0625V23.1875C12.25 22.9458 12.4458 22.75 12.6875 22.75H17.0625C17.3042 22.75 17.5 22.9458 17.5 23.1875V24.0625ZM17.5 13.125V18.375C17.5 18.8584 17.1084 19.25 16.625 19.25H4.375C3.89156 19.25 3.5 18.8584 3.5 18.375V13.125C3.5 12.6416 3.89156 12.25 4.375 12.25H16.625C17.1084 12.25 17.5 12.6416 17.5 13.125Z" fill="#160B32" /></g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_435_15753">
                                                                                                <rect width="21" height="28" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                    :
                                                                                    'N/A'
                                                                            }
                                                                        </td>
                                                                        <td className={`text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit text-[#${invoice?.status === 'paid' ? '1EC465' : 'EB5757'}] uppercase`}>
                                                                            {invoice?.status === 'paid' ? invoice?.status : 'unpaid'}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </>
                                                :
                                                <p>No Invoices generated.</p>
                                    }

                                    {
                                        total_count > sizePerPage
                                            ?
                                            <div className="float-left w-full">
                                                <div className="text-center float-left w-full">
                                                    <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchInvoices(data.selected + 1) }} />
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        total_count > 0
                                            ?
                                            <button onClick={downloadInvoices} className='float-right text-xs text-blue py-1 border px-3 bg-white w-[130px] text-center hover:text-burgandy uppercase'>Download All</button>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceSection