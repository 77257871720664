import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'flowbite-react';
import { ButtonRect, InputRect } from 'UIs/SkeletonLoader';
import fns from 'helpers/functions';
import * as RoleService from 'Services/role.service';

const RolesSection = ({ loadingRoles, roles, fetchRoles }) => {
    const [modalTitle, setModalTitle] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [viewPermission, setViewPermission] = useState(null);
    const [loadPermissionPop, setLoadPermissionPop] = useState(false);
    const [loadRoleFormPop, setLoadRoleFormPop] = useState(false);
    const [permissions, setPermissions] = useState(null);

    useEffect(() => {
        fetchPermissions();

        return () => {

        }
    }, []);

    /** Fetch single role */
    const fetchPermissions = async () => {
        const res = await RoleService.FetchPermissionsAPI();

        if (res.response) {
            setPermissions(res.data);
        } else {
            setPermissions(null);
        }
    }

    const callViewPermission = (_id, name) => {
        setViewPermission(_id);
        setModalShow(true);
        setModalTitle(`${name} permissions`);
        setLoadPermissionPop(true);
        setLoadRoleFormPop(false);
    }

    const callRoleForm = (_id, action) => {
        setViewPermission(_id);
        setModalShow(true);
        setModalTitle(`${action} Role`);
        setLoadPermissionPop(false);
        setLoadRoleFormPop(true);
    }

    return (
        !loadingRoles
            ?
            <>
                <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                    <div className='inline-block align-middle mr-3'>
                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1_2)">
                                <g filter="url(#filter0_d_1_2)">
                                    <path d="M43 0H11C7.13401 0 4 3.13401 4 7V39C4 42.866 7.13401 46 11 46H43C46.866 46 50 42.866 50 39V7C50 3.13401 46.866 0 43 0Z" fill="#F2F2F2" />
                                </g>
                                <path d="M23.06 18.67V21.03H30.92V18.67C30.92 16.5 29.16 14.74 26.99 14.74C24.82 14.74 23.06 16.5 23.06 18.67ZM19.92 21.03V18.67C19.92 14.77 23.09 11.6 26.99 11.6C30.89 11.6 34.06 14.77 34.06 18.67V21.03H34.85C36.58 21.03 37.99 22.44 37.99 24.17V33.6C37.99 35.33 36.58 36.74 34.85 36.74H19.14C17.41 36.74 16 35.33 16 33.6V24.17C16 22.44 17.41 21.03 19.14 21.03H19.93H19.92Z" fill="#160B32" />
                                <g clipPath="url(#clip1_1_2)">
                                    <path d="M29.13 33.06L28.65 31.8H24.58L24.1 33.09C23.91 33.59 23.75 33.93 23.62 34.1C23.49 34.28 23.27 34.36 22.97 34.36C22.71 34.36 22.49 34.27 22.29 34.08C22.09 33.89 22 33.68 22 33.44C22 33.3 22.02 33.16 22.07 33.02C22.12 32.87 22.19 32.67 22.3 32.41L24.86 25.91C24.93 25.72 25.02 25.5 25.12 25.24C25.22 24.98 25.33 24.76 25.45 24.59C25.57 24.42 25.72 24.28 25.91 24.17C26.1 24.06 26.33 24.01 26.61 24.01C26.89 24.01 27.13 24.06 27.32 24.17C27.51 24.28 27.66 24.42 27.78 24.58C27.9 24.74 27.99 24.93 28.07 25.12C28.15 25.31 28.25 25.57 28.37 25.9L30.99 32.37C31.2 32.86 31.3 33.22 31.3 33.44C31.3 33.66 31.2 33.89 31.01 34.08C30.82 34.27 30.58 34.37 30.31 34.37C30.15 34.37 30.01 34.34 29.9 34.29C29.79 34.23 29.69 34.16 29.61 34.06C29.53 33.96 29.45 33.81 29.36 33.61C29.27 33.41 29.19 33.23 29.13 33.07V33.06ZM25.11 30.28H28.1L26.59 26.14L25.11 30.28V30.28Z" fill="white" />
                                </g>
                            </g>
                            <defs>
                                <filter id="filter0_d_1_2" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_2" result="shape" />
                                </filter>
                                <clipPath id="clip0_1_2">
                                    <rect width="54" height="54" fill="white" />
                                </clipPath>
                                <clipPath id="clip1_1_2">
                                    <rect width="9.3" height="10.36" fill="white" transform="translate(22 24)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    Roles
                    <div className='w-full float-left pl-16'>
                        <div className="bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 pb-5 mb-10 lg:flex lg:content-end">
                            <div className="w-full lg:w-4/6 float-left">
                                {
                                    (roles && roles !== null && roles.length > 0)
                                        ?
                                        roles.map((role, index) =>
                                            <div key={index} className="w-full lg:w-3/6 float-left md:px-4">
                                                <label className='w-full float-left text-xs text-blue bg-white shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg'>
                                                    {role?.name}
                                                    <span onClick={() => { callRoleForm(role?._id, `Edit ${role?.name}`) }} className='float-right text-[16px] hover:text-burgandy cursor-pointer'><i className="fa-solid fa-edit"></i></span>
                                                    <span onClick={() => { callViewPermission(role?._id, role?.name) }} className='float-right text-[16px] hover:text-burgandy cursor-pointer mr-3'><i className="fa-solid fa-eye"></i></span>
                                                </label>
                                            </div>
                                        )
                                        :
                                        <div className="w-full lg:w-6/6 float-left md:px-4">
                                            <label className='w-full float-left text-xs text-blue py-4 rounded-lg'>No roles added.</label>
                                        </div>
                                }
                            </div>
                            <div className="w-full lg:w-4/12 float-left md:px-4 lg:flex lg:flex-col-reverse items-end lg:mt-0 mt-5">
                                <span onClick={() => { callRoleForm(null, 'Create New'); }} className='cursor-pointer float-right text-xs text-blue py-1 border px-3 bg-white w-[130px] text-center hover:text-burgandy'>+ ADD ROLE</span>
                            </div>
                            <div className='clear-both'></div>

                        </div>
                    </div>
                </div>

                <Modal show={modalShow} className="bg-black/60">
                    <Modal.Header className='border-b-0 pb-0' onClick={() => { setModalShow(!modalShow) }}>
                        {modalTitle}
                    </Modal.Header>
                    <Modal.Body className='text-center pt-7'>
                        {
                            modalShow
                                ?
                                <>
                                    {(loadPermissionPop && viewPermission !== null) ? <ViewPermissions permissionId={viewPermission} /> : null}
                                    {loadRoleFormPop ? <RoleForm permissionId={viewPermission} fetchRoles={fetchRoles} setModalShow={setModalShow} permissions={permissions} /> : null}
                                </>
                                :
                                null
                        }
                    </Modal.Body>
                </Modal>
            </>
            :
            <PageSkeleton />
    )
}

export default RolesSection

const PageSkeleton = () => {
    return (
        <>
            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                <div className='inline-block align-middle mr-3'>
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1_2)">
                            <g filter="url(#filter0_d_1_2)">
                                <path d="M43 0H11C7.13401 0 4 3.13401 4 7V39C4 42.866 7.13401 46 11 46H43C46.866 46 50 42.866 50 39V7C50 3.13401 46.866 0 43 0Z" fill="#F2F2F2" />
                            </g>
                            <path d="M23.06 18.67V21.03H30.92V18.67C30.92 16.5 29.16 14.74 26.99 14.74C24.82 14.74 23.06 16.5 23.06 18.67ZM19.92 21.03V18.67C19.92 14.77 23.09 11.6 26.99 11.6C30.89 11.6 34.06 14.77 34.06 18.67V21.03H34.85C36.58 21.03 37.99 22.44 37.99 24.17V33.6C37.99 35.33 36.58 36.74 34.85 36.74H19.14C17.41 36.74 16 35.33 16 33.6V24.17C16 22.44 17.41 21.03 19.14 21.03H19.93H19.92Z" fill="#160B32" />
                            <g clipPath="url(#clip1_1_2)">
                                <path d="M29.13 33.06L28.65 31.8H24.58L24.1 33.09C23.91 33.59 23.75 33.93 23.62 34.1C23.49 34.28 23.27 34.36 22.97 34.36C22.71 34.36 22.49 34.27 22.29 34.08C22.09 33.89 22 33.68 22 33.44C22 33.3 22.02 33.16 22.07 33.02C22.12 32.87 22.19 32.67 22.3 32.41L24.86 25.91C24.93 25.72 25.02 25.5 25.12 25.24C25.22 24.98 25.33 24.76 25.45 24.59C25.57 24.42 25.72 24.28 25.91 24.17C26.1 24.06 26.33 24.01 26.61 24.01C26.89 24.01 27.13 24.06 27.32 24.17C27.51 24.28 27.66 24.42 27.78 24.58C27.9 24.74 27.99 24.93 28.07 25.12C28.15 25.31 28.25 25.57 28.37 25.9L30.99 32.37C31.2 32.86 31.3 33.22 31.3 33.44C31.3 33.66 31.2 33.89 31.01 34.08C30.82 34.27 30.58 34.37 30.31 34.37C30.15 34.37 30.01 34.34 29.9 34.29C29.79 34.23 29.69 34.16 29.61 34.06C29.53 33.96 29.45 33.81 29.36 33.61C29.27 33.41 29.19 33.23 29.13 33.07V33.06ZM25.11 30.28H28.1L26.59 26.14L25.11 30.28V30.28Z" fill="white" />
                            </g>
                        </g>
                        <defs>
                            <filter id="filter0_d_1_2" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_2" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_2" result="shape" />
                            </filter>
                            <clipPath id="clip0_1_2">
                                <rect width="54" height="54" fill="white" />
                            </clipPath>
                            <clipPath id="clip1_1_2">
                                <rect width="9.3" height="10.36" fill="white" transform="translate(22 24)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                Roles
                <div className='w-full float-left pl-16'>
                    <div className="bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 pb-5 mb-10 lg:flex lg:content-end">
                        <div className="w-full lg:w-4/6 float-left">
                            <div className="w-full lg:w-3/6 float-left md:px-4 md:pr-12 pr-12 relative">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4 md:pr-12 pr-12 relative">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4 md:pr-12 pr-12 relative">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4 md:pr-12 pr-12 relative">
                                <InputRect />
                            </div>
                        </div>
                        <div className="w-full lg:w-4/12 float-left md:px-4 lg:flex lg:flex-col-reverse items-end lg:mt-0 mt-5">
                            <ButtonRect />
                        </div>
                        <div className='clear-both'></div>

                    </div>
                </div>
            </div>
        </>
    );
}

const ViewPermissions = ({ permissionId }) => {
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchRole();

        return () => {
        }
    }, []);

    /** Fetch single role */
    const fetchRole = async () => {
        const res = await RoleService.FetchSingleRoleAPI(permissionId);

        if (res.response) {
            setLoading(false);
            setRole(res.data);
        } else {
            setLoading(false);
            setRole(null);
        }
    }

    return (
        <>
            {
                loading
                    ?
                    <><i className="fa-solid fa-spinner fa-spin"></i> Loading...</>
                    :
                    (
                        role && role !== null
                            ?
                            <>
                                <div className="w-full float-left clear-both p-4 pt-1 text-left">
                                    <div className="w-full float-left mb-2 md:w-1/2">
                                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Role Name: </label>
                                        <div className="float-right w-full md:w-3/5 leading-10 pr-3">
                                            <span className="text-[13px] font-Averta">{role?.name}</span>
                                        </div>
                                    </div>
                                    <div className="w-full float-left mb-2 md:w-1/2">
                                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Role Permissions: </label>
                                        <div className="float-right w-full md:w-3/5 pt-3">
                                            <div className="text-[13px] font-Averta">
                                                {role?.permissions.map((permission, index) => `${permission}${index === role?.permissions.length - 1 ? '' : ', '}`)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='clear-both'></div>
                            </>
                            :
                            null
                    )
            }
        </>
    );
}

const RoleForm = ({ permissionId, fetchRoles, setModalShow, permissions }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [processing, setProcessing] = useState(false);
    const [role, setRole] = useState(null);

    useEffect(() => {
        fetchRole();
        reset(null);

        return () => {
        }
    }, [reset]);

    /** Fetch single role */
    const fetchRole = async () => {
        if (permissionId !== null) {
            const res = await RoleService.FetchSingleRoleAPI(permissionId);

            if (res.response) {
                setRole(res.data);
                reset(res.data);
            } else {
                setRole(null);
                reset(null);
            }
        }
    }

    /** Save role to organisation */
    const SaveRole = async (form) => {
        setProcessing(true);
        var res = null;
        if (role !== null) {
            var res = await RoleService.UpdateRoleAPI(role?._id, form);
        } else {
            var res = await RoleService.SaveRoleAPI(form);
        }

        if (res.response) {
            setProcessing(false);
            fetchRoles();
            setModalShow(false);
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(SaveRole)}>
                <div className="w-full float-left clear-both p-4 pt-1 text-left">
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-1/5 font-Averta_Semibold text-[13px] text-blue">Role Name</label>
                        <div className="float-right w-full md:w-4/5">
                            <input
                                {...register("name", {
                                    required: "required",
                                    maxLength: {
                                        value: 50,
                                        message: "Role name can't be more than 50 alphabets."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="Role Name"
                                defaultValue={role?.name}
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.name && errors.name.type === "required" && 'Please enter role name.'}
                                {errors.name && errors.name.type !== "required" && errors.name.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-1/5 font-Averta_Semibold text-[13px] text-blue">Role Description</label>
                        <div className="float-right w-full md:w-4/5">
                            <input
                                {...register("description", {
                                    required: "required",
                                    maxLength: {
                                        value: 150,
                                        message: "Role description can't be more than 150 alphabets."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="Role description"
                                defaultValue={role?.description}
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.description && errors.description.type === "required" && 'Please enter role description.'}
                                {errors.description && errors.description.type !== "required" && errors.description.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-1/5 font-Averta_Semibold text-[13px] text-blue">Role Permissions</label>
                        <div className="float-right w-full md:w-4/5 mt-2">
                            {
                                permissions.map((permission, index) => {
                                    return (
                                        <div className='w-full' key={index}>
                                            <input
                                                {...register("permissions", { required: "required" })}
                                                id={`permission${index}`}
                                                className="placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                type="checkbox"
                                                value={permission?.name}
                                            />
                                            <label htmlFor={`permission${index}`}> {permission?.name} - {permission.description}</label>
                                        </div>
                                    );
                                })
                            }
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.permissions && errors.permissions.type === "required" && 'Please select permissions.'}
                                {errors.permissions && errors.permissions.type !== "required" && errors.permissions.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left">
                        <button disabled={processing} className='float-right bg-lightperot hover:bg-perot text-white text-sm leading-6 p-1 px-10 rounded-full'>
                            {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Save'}
                        </button>
                    </div>
                </div>
                <div className='clear-both'></div>
            </form>
        </>
    );
}