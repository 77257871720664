import { BlockLoader, ButtonCLoader } from 'UIs/SkeletonLoader';
import { AttachmentIcon } from 'UIs/Icons';
import fns from 'helpers/functions';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as NegotitationService from 'Services/negotitation-deals.service';
import * as GenService from 'Services/general.service';
import { Breadcrumb } from 'modules/fragments';

const tdClass = `text-xs px-4 py-3 text-center font-Averta xl:min-w-fit`;

const MyNegotiationDetails = () => {
    const navigate = useNavigate();
    const { _id } = useParams();
    const [request, setRequest] = useState(null);
    const [fetching, setFetching] = useState(true);

    const [settling, setSettling] = useState(false);
    const [rejecting, setRejecting] = useState(false);

    useEffect(() => {
        fns.CHECK_PERMISSION(["B5"], true);
        fetchRequest();

        return () => {
        }
    }, []);

    const fetchRequest = async () => {
        setFetching(true);
        const res = await NegotitationService.FetchRequestAPI(_id);

        if (res.response) {
            setFetching(false);
            setRequest(res.data);
        } else {
            setFetching(false);
            setRequest(null);
        }
    }

    const settleDeal = async () => {
        setSettling(true);
        const res = await NegotitationService.SettleDealAPI(_id);

        if (res.response) {
            setSettling(false);
            fns.TOAST_SUC(res.data.message);
            navigate(`/deals-in-progress`);
        } else {
            setSettling(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    const rejectDeal = async () => {
        setRejecting(true);
        const res = await NegotitationService.RejectDealAPI(_id);

        if (res.response) {
            setRejecting(false);
            fns.TOAST_SUC(res.data.message);
            fetchRequest();
        } else {
            setRejecting(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    const downloadAttachment = async (_id, name) => {
        const res = await GenService.DownloadAttachmentAPI(_id, name);
        if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    const DetailsTitle = () => {
        return (
            <>
                <div className='inline-block align-middle mr-3'>
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_15_6)">
                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                        </g>
                        <path d="M37 7H17C16.4696 7 15.9609 7.21071 15.5858 7.58579C15.2107 7.96086 15 8.46957 15 9V37C15 37.5304 15.2107 38.0391 15.5858 38.4142C15.9609 38.7893 16.4696 39 17 39H37C37.5304 39 38.0391 38.7893 38.4142 38.4142C38.7893 38.0391 39 37.5304 39 37V9C39 8.46957 38.7893 7.96086 38.4142 7.58579C38.0391 7.21071 37.5304 7 37 7ZM22 31H20V29H22V31ZM22 27H20V25H22V27ZM22 23H20V21H22V23ZM22 19H20V17H22V19ZM22 15H20V13H22V15ZM34 31H24V29H34V31ZM34 27H24V25H34V27ZM34 23H24V21H34V23ZM34 19H24V17H34V19ZM34 15H24V13H34V15Z" fill="#160B32" />
                        <defs>
                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                SUMMARY
            </>
        );
    }

    return (
        <>
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <Breadcrumb
                        page={'TICKET DETAIL'}
                        desc={request ? fns.ZERO_PAD(request?.ticket, 6) : ' '}
                        contact={null}
                    />

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>

                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <DetailsTitle />

                                        {
                                            fetching
                                                ?
                                                <PageSkeleton />
                                                :
                                                <div className='w-full float-left pl-16'>
                                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                                        <div className="flex flex-col">
                                                            <div className="overflow-x-auto">
                                                                <div className="py-2 inline-block min-w-full">
                                                                    <div className="overflow-x-auto pb-2 xl:pb-0">
                                                                        <table className="min-w-full text-black mb-5">
                                                                            <THead />
                                                                            <tbody className=''>
                                                                                <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                    <td className={`${tdClass} whitespace-nowrap min-w-[120px]`}>{fns.ZERO_PAD(request?.ticket, 6)}</td>
                                                                                    <td className={`${tdClass} whitespace-nowrap min-w-[120px]`}>{request?.created_at}</td>
                                                                                    <td className={`${tdClass} whitespace-nowrap min-w-[160px]`}>{request?.institution_name}</td>
                                                                                    <td className={`${tdClass} whitespace-nowrap min-w-[160px]`}>{request?._outstanding_balance_text}</td>
                                                                                    <td className={`${tdClass} whitespace-nowrap min-w-[160px]`}>{request?.cd_bracket}</td>
                                                                                    <td className={`${tdClass} whitespace-nowrap min-w-[120px]`}>{request?._account_type_text}</td>
                                                                                    <td className={`${tdClass} whitespace-nowrap text-[#1EC365] min-w-[160px]`}>{request?._settlement_value_text}</td>
                                                                                    <td className={`${tdClass} whitespace-nowrap min-w-[120px]`}></td>
                                                                                    <td className={`${tdClass} whitespace-nowrap text-[#818181] min-w-[160px]`}>{request?.request_by}</td>
                                                                                    <td className={`${tdClass} min-w-[120px]`}>
                                                                                        {
                                                                                            request?.status === 3
                                                                                                ?
                                                                                                <span className='cursor-pointer' onClick={() => { downloadAttachment(request?.settlement_letter?._id, request?.settlement_letter?.document_name) }}>
                                                                                                    <AttachmentIcon />
                                                                                                    <p className='cursor-pointer break-words'>{request?.settlement_letter?.document_name}</p>
                                                                                                </span>
                                                                                                :
                                                                                                'N/A'}
                                                                                    </td>
                                                                                    <td className={`${tdClass} whitespace-nowrap text-[#1EC365] min-w-[100px]`}>{request?._status_text}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        request?.status !== 3
                                                            ?
                                                            <Link to={`/negotiation-requests`} className='float-right text-sm text-burgandy py-4 px-12 text-center rounded-xl hover:text-blue bg-blue hover:bg-burgandy mt-4 ml-4'>Go back</Link>
                                                            :
                                                            <>
                                                                <button disabled={rejecting || settling} onClick={settleDeal} className='float-right text-sm text-burgandy hover:text-blue py-4 px-12 text-center rounded-xl bg-blue hover:bg-burgandy mt-4 ml-4'>{rejecting ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Let\'s Settle'}</button>
                                                                <button disabled={rejecting || settling} onClick={rejectDeal} className='float-right text-sm text-blue hover:text-burgandy py-4 px-12 text-center rounded-xl bg-burgandy hover:bg-blue mt-4'>{settling ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Reject Deal'}</button>
                                                            </>
                                                    }
                                                </div>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>

                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    )
}

export default MyNegotiationDetails

const THead = () => {
    return (
        <thead className="">
            <tr>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Ticket</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Requested</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Institution</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Outstanding Balance</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Code Bracket</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Account Type</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Value</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Ref</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Party</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Attachment</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Status</th>
            </tr>
        </thead>
    );
}

const PageSkeleton = () => {
    return (
        <div className='w-full float-left pl-16'>
            <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="py-2 inline-block min-w-full">
                            <div className="overflow-x-auto pb-2 xl:pb-0">
                                <table className="min-w-full text-black mb-5">
                                    <THead />
                                    <tbody className=''>
                                        <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                            <td colSpan={11} className="pt-2">
                                                <BlockLoader width={"100%"} height={'40px'} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ButtonCLoader classd={'float-right text-sm py-4 px-3 text-center w-[220px] rounded-full mt-4 ml-4'} />
            <ButtonCLoader classd={'float-right text-sm py-4 px-3 text-center w-[220px] rounded-full mt-4 ml-4'} />
        </div>
    );
}