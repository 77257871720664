import fns from 'helpers/functions';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as GenService from 'Services/general.service';

const QuickMenus = () => {
    const [counts, setCounts] = useState(null);

    useEffect(() => {
        fetchCounts();

        return () => {
        }
    }, []);

    const fetchCounts = async () => {
        const res = await GenService.FetchMenuCountsAPI();

        if (res.response) {
            setCounts(res.data);
        } else {
            setCounts(null);
        }
    }

    return (
        <>
            <div className='clear-both'></div>
            <section className='bg-[#F2F2F2] w-full float-left'>
                <div className="lg:container mx-auto px-5 py-16">
                    <div className='float-left w-full'>
                        <ul className='float-left w-full'>
                            <li className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                <div className='inline-block align-middle mr-3'>
                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_15_6)">
                                            <rect x="4" width="46" height="46" rx="7" fill="white" />
                                        </g>
                                        <path d="M30.531 29.3665C29.291 30.095 27.7875 30.4515 26.16 30.1105C23.618 29.5835 21.5875 27.4445 21.2465 24.8715C20.7195 20.795 24.145 17.354 28.2215 17.8965C30.7945 18.2375 32.9335 20.2525 33.4605 22.81C33.8015 24.4375 33.445 25.941 32.7165 27.181L39.8 34.2645V17.416C39.8 16.6875 39.552 15.99 39.087 15.432L32.98 8.116C32.391 7.403 31.523 7 30.593 7H18.1C16.395 7 15 8.395 15 10.1V34.9C15 36.605 16.395 38 18.1 38H36.7C37.3975 38 38.0175 37.7675 38.5445 37.38L30.531 29.3665Z" fill="#160B32" />
                                        <path d="M27.4 27.15C29.1121 27.15 30.5 25.762 30.5 24.05C30.5 22.3379 29.1121 20.95 27.4 20.95C25.688 20.95 24.3 22.3379 24.3 24.05C24.3 25.762 25.688 27.15 27.4 27.15Z" fill="#160B32" />
                                        <defs>
                                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </div>
                                SEARCH & MATCH
                                <ul className='float-left w-full pl-16 pt-3'>
                                    {
                                        fns.CHECK_PERMISSION(["B4"])
                                            ?
                                            <li className='float-left w-full py-3'>
                                                <Link to={`/deals-in-progress`} className='cursor-pointer float-left w-full leading-8 bg-white shadow-md shadow-black/25 rounded-lg p-2 px-3 hover:text-burgandy overflow-hidden'>
                                                    <div className='inline-block align-middle mr-3'>
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 2C13.2311 2 10.5243 2.82109 8.22202 4.35943C5.91973 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C29.9958 12.2883 28.5194 8.72977 25.8948 6.10518C23.2702 3.48059 19.7117 2.00423 16 2ZM16 28C12.8174 28 9.76516 26.7357 7.51472 24.4853C5.26429 22.2348 4.00001 19.1826 4.00001 16C4.00001 12.8174 5.26429 9.76515 7.51472 7.51472C9.76516 5.26428 12.8174 4 16 4V16L24.481 24.481C23.3688 25.5972 22.047 26.4827 20.5916 27.0866C19.1361 27.6905 17.5757 28.0009 16 28Z" fill="#160B32" />
                                                        </svg>
                                                    </div>
                                                    {counts?.deal_in_progress} DEAL-IN-PROGRESS
                                                    <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                                </Link>
                                            </li>
                                            :
                                            null
                                    }
                                    {
                                        fns.CHECK_PERMISSION(["B3"])
                                            ?
                                            <li className='float-left w-full py-3'>
                                                <Link to={`/locked-offers`} className='cursor-pointer float-left w-full leading-8 bg-white shadow-md shadow-black/25 rounded-lg p-2 px-3 hover:text-burgandy overflow-hidden'>
                                                    <div className='inline-block align-middle mr-3'>
                                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M25 15C25 13.6213 23.8787 12.5 22.5 12.5H21.25V8.75C21.25 5.30375 18.4462 2.5 15 2.5C11.5538 2.5 8.75 5.30375 8.75 8.75V12.5H7.5C6.12125 12.5 5 13.6213 5 15V25C5 26.3787 6.12125 27.5 7.5 27.5H22.5C23.8787 27.5 25 26.3787 25 25V15ZM11.25 8.75C11.25 6.6825 12.9325 5 15 5C17.0675 5 18.75 6.6825 18.75 8.75V12.5H11.25V8.75Z" fill="#160B32" />
                                                        </svg>
                                                    </div>
                                                    {counts?.deals_locked} DEALS LOCKED
                                                    <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                                </Link>
                                            </li>
                                            :
                                            null
                                    }
                                    <li className='float-left w-full py-3'>
                                        <Link to={`/all-notifications`} className='cursor-pointer float-left w-full leading-8 bg-white shadow-md shadow-black/25 rounded-lg p-2 px-3 hover:text-burgandy overflow-hidden'>
                                            <div className='inline-block align-middle mr-3'>
                                                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.78115 12.5938C6.78115 10.2814 7.69973 8.06375 9.33481 6.42866C10.9699 4.79358 13.1875 3.875 15.4999 3.875C17.8123 3.875 20.0299 4.79358 21.665 6.42866C23.3001 8.06375 24.2186 10.2814 24.2186 12.5938V16.2828L26.0864 20.9521C26.1453 21.0991 26.1673 21.2583 26.1504 21.4157C26.1335 21.5732 26.0783 21.7241 25.9896 21.8553C25.9009 21.9864 25.7814 22.0938 25.6415 22.1681C25.5017 22.2424 25.3457 22.2812 25.1874 22.2812H5.8124C5.65405 22.2812 5.49812 22.2424 5.35827 22.1681C5.21842 22.0938 5.09891 21.9864 5.01019 21.8553C4.92148 21.7241 4.86626 21.5732 4.84939 21.4157C4.83251 21.2583 4.85449 21.0991 4.9134 20.9521L6.78115 16.2828V12.5938Z" fill="#160B32" />
                                                    <path d="M11.7449 24.2188C12.1731 25.8947 13.6862 27.125 15.4997 27.125C17.3132 27.125 18.8264 25.8947 19.2546 24.2188H11.7449Z" fill="#160B32" />
                                                    <circle cx="22" cy="9" r="6" fill="#EB5757" />
                                                </svg>
                                            </div>
                                            {counts?.notifications} NEW NOTIFICATION
                                            <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                        </Link>
                                    </li>

                                    {
                                        fns.CHECK_PERMISSION(["B5"])
                                            ?
                                            <li className='float-left w-full py-3'>
                                                <Link to={`/negotiation-requests`} className='cursor-pointer float-left w-full leading-8 bg-white shadow-md shadow-black/25 rounded-lg p-2 px-3 hover:text-burgandy overflow-hidden'>
                                                    <div className='inline-block align-middle mr-3'>
                                                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 19V11H5C4.20435 11 3.44129 11.3161 2.87868 11.8787C2.31607 12.4413 2 13.2044 2 14V32C2.00149 32.1857 2.05467 32.3674 2.15358 32.5245C2.25249 32.6817 2.39322 32.8083 2.56 32.89C2.72068 32.9631 2.89784 32.9923 3.07347 32.9747C3.24911 32.9572 3.41697 32.8934 3.56 32.79L8.71 29H22.15C22.529 29.0112 22.9063 28.9445 23.2585 28.8039C23.6106 28.6634 23.9302 28.4521 24.1974 28.183C24.4646 27.914 24.6736 27.5929 24.8117 27.2398C24.9498 26.8867 25.0139 26.5089 25 26.13V25H14C12.4087 25 10.8826 24.3679 9.75736 23.2426C8.63214 22.1174 8 20.5913 8 19Z" fill="#160B32" />
                                                            <path d="M31 4H14C13.2044 4 12.4413 4.31607 11.8787 4.87868C11.3161 5.44129 11 6.20435 11 7V19C11 19.7956 11.3161 20.5587 11.8787 21.1213C12.4413 21.6839 13.2044 22 14 22H27.55L32.33 25.71C32.4721 25.8151 32.6395 25.8806 32.8152 25.8999C32.9908 25.9192 33.1685 25.8917 33.33 25.82C33.5002 25.739 33.644 25.6115 33.7448 25.4522C33.8457 25.293 33.8995 25.1085 33.9 24.92V7C33.9004 6.22138 33.5981 5.47309 33.057 4.91327C32.5158 4.35345 31.7782 4.02595 31 4Z" fill="#160B32" />
                                                        </svg>
                                                    </div>
                                                    {counts?.negotiation_requests} NEGOTIATION REQUEST
                                                    <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                                </Link>
                                            </li>
                                            :
                                            null
                                    }
                                </ul>
                            </li>

                            {
                                fns.CHECK_PERMISSION(["A3", "A4", "A5"])
                                    ?
                                    <li className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <div className='inline-block align-middle mr-3'>
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_15_6)">
                                                    <rect x="4" width="46" height="46" rx="7" fill="white" />
                                                </g>
                                                <g clipPath="url(#clip0_15_6)">
                                                    <path d="M28.0833 16.6406V10H18.1875C17.5294 10 17 10.5225 17 11.1719V33.8281C17 34.4775 17.5294 35 18.1875 35H34.8125C35.4706 35 36 34.4775 36 33.8281V17.8125H29.2708C28.6177 17.8125 28.0833 17.2852 28.0833 16.6406ZM31.3084 27.188H28.0833V31.0942C28.0833 31.5259 27.7291 31.8755 27.2917 31.8755H25.7083C25.2709 31.8755 24.9167 31.5259 24.9167 31.0942V27.188H21.6916C20.9851 27.188 20.6323 26.3438 21.134 25.8521L25.9048 21.1792C26.2338 20.8564 26.7652 20.8564 27.0942 21.1792L31.865 25.8521C32.3672 26.3438 32.0149 27.188 31.3084 27.188ZM35.6536 15.127L30.8096 10.3418C30.587 10.1221 30.2852 10 29.9685 10H29.6667V16.25H36V15.9521C36 15.6445 35.8763 15.3467 35.6536 15.127Z" fill="#160B32" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="4" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                    </filter>
                                                    <clipPath id="clip0_15_6">
                                                        <rect width="19" height="25" fill="white" transform="translate(17 10)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        UPLOAD & SETTLE
                                        <ul className='float-left w-full pl-16 pt-3'>
                                            {
                                                fns.CHECK_PERMISSION(["A3", "A4"])
                                                    ?
                                                    <li className='float-left w-full py-3'>
                                                        <Link to={`/my-offers-on-market`} className='cursor-pointer float-left w-full leading-8 bg-white shadow-md shadow-black/25 rounded-lg p-2 px-3 hover:text-burgandy overflow-hidden'>
                                                            <div className='inline-block align-middle mr-3'>
                                                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M21.89 4H7.83001C7.33488 4.01311 6.86493 4.22107 6.52227 4.57871C6.17961 4.93635 5.99193 5.41477 6.00001 5.91V30.09C5.99193 30.5852 6.17961 31.0636 6.52227 31.4213C6.86493 31.7789 7.33488 31.9869 7.83001 32H28.17C28.6651 31.9869 29.1351 31.7789 29.4777 31.4213C29.8204 31.0636 30.0081 30.5852 30 30.09V11.92L21.89 4ZM21 13V5.84L28.3 13H21Z" fill="#160B32" />
                                                                </svg>
                                                            </div>
                                                            {counts?.offers_on_market} OFFER ON THE MARKET
                                                            <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                                        </Link>
                                                    </li>
                                                    :
                                                    null
                                            }
                                            {
                                                fns.CHECK_PERMISSION(["A5"])
                                                    ?
                                                    <li className='float-left w-full py-3'>
                                                        <Link to={`/closed-offers`} className='cursor-pointer float-left w-full leading-8 bg-white shadow-md shadow-black/25 rounded-lg p-2 px-3 hover:text-burgandy overflow-hidden'>
                                                            <div className='inline-block align-middle mr-3'>
                                                                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M6.875 24.75H26.125V27.5H6.875V24.75ZM13.2 21.0375L6.875 14.7125L9.625 12.1L13.2 15.675L23.375 5.5L26.125 8.25L13.2 21.0375Z" fill="#160B32" />
                                                                </svg>
                                                            </div>
                                                            {counts?.closed_offers} DEALS CLOSED
                                                            <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                                        </Link>
                                                    </li>
                                                    :
                                                    null
                                            }
                                        </ul>
                                    </li>
                                    :
                                    null
                            }
                        </ul>
                    </div>
                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    )
}

export default QuickMenus