import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ButtonRect, InputRect } from 'UIs/SkeletonLoader';
import fns from 'helpers/functions';
import * as OrgService from 'Services/organisation.service';

const UserDetails = ({ members }) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [myDetails, setMyDetails] = useState(null);
    const [otp, setOTP] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchUserInfo();

        return () => {
        }
    }, [reset]);

    /** Fetch logged user details */
    const fetchUserInfo = async () => {
        const res = await OrgService.FetchUserInfoAPI();

        if (res.response) {
            localStorage.setItem('user', JSON.stringify(res?.data?.data));
            setMyDetails(res.data.data);
            reset(res.data.data);
            setLoading(false);
        } else if (res.code === 403) {
            fns.TOAST_ERR(res.errors);
            navigate('/login');
            return;
        } else {
            setMyDetails(null);
            setLoading(false);
        }
    }

    /** Save profile details of logged user */
    const SaveProfile = async (form) => {
        setProcessing(true);
        const res = await OrgService.UpdateProfileAPI(form);

        if (res.response) {
            if (res.data.data.otp_sent) {
                setProcessing(false);
                fns.TOAST_SUC('OTP sent to updated mobile number.');
                setOTP(true);
            } else {
                setProcessing(false);
                fns.TOAST_SUC(res.data.message);
                setOTP(false);
                fetchUserInfo();
            }
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        !loading
            ?
            (
                <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                    <div className='inline-block align-middle mr-3'>
                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_15_6)">
                                <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                            </g>
                            <g clipPath="url(#clip0_15_6)">
                                <path d="M27 9.34766C25.2273 9.34766 23.8423 9.99575 22.8452 11.2919C21.848 12.5881 21.3494 14.2451 21.3494 16.2627C21.3371 18.7695 22.1496 20.7566 23.7869 22.224C23.9962 22.4197 24.0701 22.6703 24.0085 22.976L23.5838 23.8565C23.4484 24.15 23.2483 24.3792 22.9837 24.5443C22.719 24.7094 22.1619 24.9448 21.3125 25.2505C21.2756 25.2627 20.4969 25.5134 18.9766 26.0025C17.4562 26.4917 16.6468 26.7607 16.5483 26.8096C15.5142 27.2376 14.8371 27.6839 14.517 28.1486C14.1723 28.919 14 30.8694 14 33.9998H40C40 30.8694 39.8277 28.919 39.483 28.1486C39.1629 27.6839 38.4858 27.2376 37.4517 26.8096C37.3532 26.7607 36.5438 26.4917 35.0234 26.0025C33.5031 25.5134 32.7244 25.2627 32.6875 25.2505C31.8381 24.9448 31.281 24.7094 31.0163 24.5443C30.7517 24.3792 30.5516 24.15 30.4162 23.8565L29.9915 22.976C29.9299 22.6703 30.0038 22.4197 30.2131 22.224C31.8504 20.7566 32.6629 18.7695 32.6506 16.2627C32.6506 14.2451 32.152 12.5881 31.1548 11.2919C30.1577 9.99575 28.7727 9.34766 27 9.34766Z" fill="#160B32" />
                            </g>
                            <defs>
                                <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                </filter>
                                <clipPath id="clip0_15_6">
                                    <rect width="26" height="27" fill="white" transform="translate(14 7)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    MY DETAILS
                    <div className='w-full float-left pl-16'>
                        <form onSubmit={handleSubmit(SaveProfile)}>
                            <div className="bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 pb-5 mb-10 lg:flex lg:content-end">
                                <div className="w-full lg:w-4/6 float-left flex flex-wrap">
                                    <div className="w-full lg:w-3/6 float-left md:px-4">
                                        <input
                                            {...register("first_name", {
                                                required: "required",
                                                maxLength: {
                                                    value: 50,
                                                    message: "Name can't be more than 50 alphabets."
                                                }
                                            })}
                                            className='w-full float-left text-xs text-blue bg-white shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg'
                                            type="text"
                                            id="first_name"
                                            placeholder='Name'
                                            defaultValue={myDetails?.first_name}
                                        />
                                        {
                                            errors.first_name
                                                ?
                                                <>
                                                    <span className="text-red-500 text-xs font-Averta mb-3 w-full float-left">
                                                        {errors.first_name.type === "required" && 'Please enter your name.'}
                                                        {errors.first_name.type !== "required" && errors.first_name.message}
                                                    </span>
                                                </>
                                                : null
                                        }
                                    </div>
                                    <div className="w-full lg:w-3/6 float-left md:px-4">
                                        <input
                                            {...register("last_name", {
                                                required: "required",
                                                maxLength: {
                                                    value: 50,
                                                    message: "Surname can't be more than 50 alphabets."
                                                }
                                            })}
                                            className='w-full float-left text-xs text-blue bg-white shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg'
                                            type="text"
                                            id="last_name"
                                            placeholder='Surname'
                                            defaultValue={myDetails?.last_name}
                                        />
                                        {
                                            errors.last_name
                                                ?
                                                <>
                                                    <span className="text-red-500 text-xs font-Averta mb-3 w-full float-left">
                                                        {errors.last_name.type === "required" && 'Please enter your surname.'}
                                                        {errors.last_name.type !== "required" && errors.last_name.message}
                                                    </span>
                                                </>
                                                : null
                                        }
                                    </div>
                                    <div className="w-full lg:w-3/6 float-left md:px-4">
                                        <input
                                            {...register("office", {
                                                pattern: {
                                                    value: /^\+91|\+27(5|6|7|8|9){1,2}[0-9]{1}[0-9]{7,9}$/,
                                                    message: "This value doesn't match mobile number format. (E.g. : +27987654321, +919876543210)"
                                                }
                                            })}
                                            className='w-full float-left text-xs text-blue bg-white shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg'
                                            type="text"
                                            id="office"
                                            placeholder='Office number'
                                            defaultValue={myDetails?.office}
                                        />
                                        {
                                            errors.office
                                                ?
                                                <>
                                                    <span className="text-red-500 text-xs font-Averta mb-3 w-full float-left">
                                                        {errors.office.type === "required" && 'Please enter office number.'}
                                                        {errors.office.type !== "required" && errors.office.message}
                                                    </span>
                                                </>
                                                : null
                                        }
                                    </div>
                                    <div className="w-full lg:w-3/6 float-left md:px-4">
                                        <input
                                            {...register("mobile", {
                                                required: "required",
                                                pattern: {
                                                    value: /^\+91|\+27(5|6|7|8|9){1,2}[0-9]{1}[0-9]{7,9}$/,
                                                    message: "This value doesn't match mobile number format. (E.g. : +27987654321, +919876543210)"
                                                }
                                            })}
                                            className='w-full float-left text-xs text-blue bg-white shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg'
                                            type="text"
                                            id="mobile"
                                            placeholder="Mobile Number (E.g. : +279876543210)"
                                            defaultValue={myDetails?.mobile}
                                        />
                                        {
                                            errors.mobile
                                                ?
                                                <>
                                                    <span className="text-red-500 text-xs font-Averta mb-3 w-full float-left">
                                                        {errors.mobile.type === "required" && 'Please enter mobile number.'}
                                                        {errors.mobile.type !== "required" && errors.mobile.message}
                                                    </span>
                                                </>
                                                : null
                                        }
                                    </div>
                                    <div className="w-full lg:w-3/6 float-left md:px-4">
                                        <label className='w-full float-left text-xs text-blue bg-white shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg'>EMAIL: {myDetails?.email}</label>
                                    </div>
                                    {
                                        myDetails?.is_concierge === true
                                            ?
                                            <div className="w-full lg:w-3/6 float-left md:px-4">
                                                <select
                                                    {...register("report_to")}
                                                    className="w-full float-left text-xs text-blue bg-white shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none form-input font-Averta_Semibold border border-gray"
                                                    id="report_to"
                                                    defaultValue={''}
                                                >
                                                    <option value="">REPORT TO</option>
                                                    {
                                                        members
                                                        &&
                                                        members.map((member, index) =>
                                                            <option key={index} value={member.id}>{member.full_name}</option>
                                                        )
                                                    }
                                                </select>
                                                {
                                                    errors.report_to
                                                        ?
                                                        <>
                                                            <span className="text-red-500 text-xs font-Averta mb-3 w-full float-left">
                                                                {errors.report_to.type === "required" && 'Please select your reporting manager.'}
                                                                {errors.report_to.type !== "required" && errors.report_to.message}
                                                            </span>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        otp
                                            ?
                                            <div className="w-full lg:w-3/6 float-left md:px-4">
                                                <input
                                                    {...register("otp", {
                                                        required: "required",
                                                        pattern: {
                                                            value: /^[0-9]{6}$/,
                                                            message: "Entered value does not match OTP format"
                                                        }
                                                    })}
                                                    className='w-full float-left text-xs text-blue bg-white shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg'
                                                    type="text"
                                                    id="otp"
                                                    placeholder='OTP'
                                                />
                                                {
                                                    errors.otp
                                                        ?
                                                        <>
                                                            <span className="text-red-500 text-xs font-Averta mb-3 w-full float-left">
                                                                {errors.otp.type === "required" && 'Please enter otp number.'}
                                                                {errors.otp.type !== "required" && errors.otp.message}
                                                            </span>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className="w-full lg:w-4/12 float-right md:px-4 lg:flex lg:flex-col-reverse items-end lg:mt-0 mt-5">
                                    <button disabled={processing} className='float-right text-xs text-blue py-1 border px-3 bg-white w-[130px] text-center hover:text-burgandy uppercase'>
                                        {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Update'}
                                    </button>
                                </div>
                                <div className='clear-both'></div>
                            </div>
                        </form>
                    </div>
                </div>
            )
            :
            <PageSkeleton />
    )
}

export default UserDetails

const PageSkeleton = () => {
    return (
        <>
            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                <div className='inline-block align-middle mr-3'>
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_15_6)">
                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                        </g>
                        <g clipPath="url(#clip0_15_6)">
                            <path d="M27 9.34766C25.2273 9.34766 23.8423 9.99575 22.8452 11.2919C21.848 12.5881 21.3494 14.2451 21.3494 16.2627C21.3371 18.7695 22.1496 20.7566 23.7869 22.224C23.9962 22.4197 24.0701 22.6703 24.0085 22.976L23.5838 23.8565C23.4484 24.15 23.2483 24.3792 22.9837 24.5443C22.719 24.7094 22.1619 24.9448 21.3125 25.2505C21.2756 25.2627 20.4969 25.5134 18.9766 26.0025C17.4562 26.4917 16.6468 26.7607 16.5483 26.8096C15.5142 27.2376 14.8371 27.6839 14.517 28.1486C14.1723 28.919 14 30.8694 14 33.9998H40C40 30.8694 39.8277 28.919 39.483 28.1486C39.1629 27.6839 38.4858 27.2376 37.4517 26.8096C37.3532 26.7607 36.5438 26.4917 35.0234 26.0025C33.5031 25.5134 32.7244 25.2627 32.6875 25.2505C31.8381 24.9448 31.281 24.7094 31.0163 24.5443C30.7517 24.3792 30.5516 24.15 30.4162 23.8565L29.9915 22.976C29.9299 22.6703 30.0038 22.4197 30.2131 22.224C31.8504 20.7566 32.6629 18.7695 32.6506 16.2627C32.6506 14.2451 32.152 12.5881 31.1548 11.2919C30.1577 9.99575 28.7727 9.34766 27 9.34766Z" fill="#160B32" />
                        </g>
                        <defs>
                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                            </filter>
                            <clipPath id="clip0_15_6">
                                <rect width="26" height="27" fill="white" transform="translate(14 7)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                MY DETAILS
                <div className='w-full float-left pl-16'>

                    <div className="bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 pb-5 mb-10 lg:flex lg:content-end">
                        <div className="w-full lg:w-4/6 float-left">
                            <div className="w-full lg:w-3/6 float-left md:px-4">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4">
                                <InputRect />
                            </div>
                        </div>
                        <div className="w-full lg:w-4/12 float-right md:px-4 lg:flex lg:flex-col-reverse items-end lg:mt-0 mt-5">
                            <ButtonRect />
                        </div>
                        <div className='clear-both'></div>
                    </div>
                </div>
            </div>
        </>
    );
}