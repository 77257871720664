import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
    let pageCount = Math.ceil(props.totalCount / props.perPageRecord);

    return (
        <>
            <ReactPaginate
                forcePage={props.currentPage}
                previousLabel={<i className="fa-regular fa-angles-left"></i>}
                nextLabel={<i className="fa-regular fa-angles-right"></i>}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={props.handlePageClick}
                containerClassName={'pagination w-full float-left pt-5'}
                pageClassName={'inline-block p-0.5'}
                pageLinkClassName={'page-link w-[30px] h-[30px] bg-blue float-left text-white text-center leading-[30px] hover:bg-burgandy rounded-md'}
                previousClassName={'inline-block p-0.5'}
                previousLinkClassName={'page-link font-weight-bold w-[30px] h-[30px] bg-blue float-left text-white text-center leading-[30px] hover:bg-burgandy rounded-md'}
                nextClassName={'inline-block p-0.5'}
                nextLinkClassName={'page-link font-weight-bold w-[30px] h-[30px] bg-blue float-left text-white text-center leading-[30px] hover:bg-burgandy rounded-md'}
                activeLinkClassName={'active bg-burgandy'}
            />
        </>
    );
}

export default Pagination