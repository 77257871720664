import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlockLoader } from 'UIs/SkeletonLoader';
import Pipeline from './Pipeline';
import * as ManageOfferService from 'Services/manage-offers.service';
import * as OrgsService from 'Services/organisation.service';
import fns from 'helpers/functions';
import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import constants from 'helpers/constants';

const Payment = ({ deal }) => {
    const { _id } = useParams();
    const [fetching, setFetching] = useState(true);
    const [payment, setPayment] = useState(null);
    const [members, setMembers] = useState(null);
    const [res_person, setResPerson] = useState(null);
    const [edit, setEdit] = useState(false);
    const [modalShow, SetModalShow] = useState(false);
    const user = fns.LOGGED_USER();

    useEffect(() => {
        fetchPaymentDetails();
        fetchOrgMembers();

        return () => {
        }
    }, []);

    const fetchPaymentDetails = async () => {
        const res = await ManageOfferService.FetchDealPaymentsAPI(_id);

        if (res.response) {
            setFetching(false);
            setPayment(res?.data);
        } else {
            setFetching(false);
            setPayment(null);
        }
    }

    const fetchOrgMembers = async () => {
        if (user?.is_concierge === true) {
            const res = await OrgsService.FetchOrgMembersAPI(user?.organization_id, '', 1, 100);

            if (res.response) {
                const dt = res?.data?.data;
                setMembers(dt);
                setResPerson(dt.length > 0 ? typeof payment?.payment_log?.responsible_user?.id !== 'undefined' ? payment?.payment_log?.responsible_user?.id : dt[0].id : null)
            } else {
                setMembers(null);
            }
        }
    }

    const updateResponsible = async () => {
        const res = await ManageOfferService.UpdateResPersonAPI(_id, res_person);

        if (res.response) {
            fns.TOAST_SUC(res.data.message);
            fetchPaymentDetails();
            setEdit(false);
        } else {
            fetchPaymentDetails(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    const updatePayment = async (_id) => {
        const res = await ManageOfferService.UpdatePaymentAPI(_id);

        if (res.response) {
            fns.TOAST_SUC(res.data.message);
            fetchPaymentDetails();
        } else {
            fetchPaymentDetails(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        fetching
            ?
            <PageSkeleton />
            :
            <>
                <div className='w-full float-left font-Averta'>
                    <div className="sm:w-11/12 w-full mx-auto px-5">
                        <div className='float-left w-full'>
                            <div className='float-left w-full'>
                                <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                    <div className='inline-block align-middle mr-3'>
                                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g filter="url(#filter0_d_15_6)">
                                                <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                            </g>
                                            <path d="M17.4375 13.4375C16.4761 13.4375 15.5541 13.8194 14.8742 14.4992C14.1944 15.1791 13.8125 16.1011 13.8125 17.0625V18.875H39.1875V17.0625C39.1875 16.1011 38.8056 15.1791 38.1258 14.4992C37.4459 13.8194 36.5239 13.4375 35.5625 13.4375H17.4375Z" fill="#160B32" />
                                            <path d="M13.8125 27.9375V20.6875H39.1875V27.9375C39.1875 28.8989 38.8056 29.8209 38.1258 30.5008C37.4459 31.1806 36.5239 31.5625 35.5625 31.5625H17.4375C16.4761 31.5625 15.5541 31.1806 14.8742 30.5008C14.1944 29.8209 13.8125 28.8989 13.8125 27.9375ZM31.0312 26.125C30.7909 26.125 30.5604 26.2205 30.3904 26.3904C30.2205 26.5604 30.125 26.7909 30.125 27.0312C30.125 27.2716 30.2205 27.5021 30.3904 27.6721C30.5604 27.842 30.7909 27.9375 31.0312 27.9375H34.6562C34.8966 27.9375 35.1271 27.842 35.2971 27.6721C35.467 27.5021 35.5625 27.2716 35.5625 27.0312C35.5625 26.7909 35.467 26.5604 35.2971 26.3904C35.1271 26.2205 34.8966 26.125 34.6562 26.125H31.0312Z" fill="#160B32" />
                                            <defs>
                                                <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="4" />
                                                    <feGaussianBlur stdDeviation="2" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                    MY PAYMENT LOG
                                    <div className='w-full float-left pl-16'>
                                        <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                            <div className="flex flex-col">
                                                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                        <div className="overflow-x-auto pb-4 xl:pb-0">
                                                            <table className="min-w-full text-black">
                                                                <thead className="">
                                                                    <tr>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Payment Ref #</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Payor</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Amount</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">SH Ref</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Responsible</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Days Lapsed</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Status</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Details</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className=''>
                                                                    <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2">
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                            {payment?.payment_log?.payment_ref}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                            {payment?.payment_log?.Payor}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#1EC365]">
                                                                            {payment?.payment_log?.amount}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                            {payment?.payment_log?.sh_ref !== '' ? payment?.payment_log?.sh_ref : 'N/A'}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#4285F4]">
                                                                            {
                                                                                !edit
                                                                                    ?
                                                                                    payment?.payment_log?.responsible_user ? payment?.payment_log?.responsible_user?.full_name : 'N/A'
                                                                                    :
                                                                                    null
                                                                            }

                                                                            {
                                                                                user?.is_concierge === true
                                                                                    ?
                                                                                    edit
                                                                                        ?
                                                                                        <>
                                                                                            <select defaultValue={typeof payment?.payment_log?.responsible_user?.id !== 'undefined' ? payment?.payment_log?.responsible_user?.id : res_person} onChange={(e) => { setResPerson(e.target.value); }} >
                                                                                                {
                                                                                                    members && members.length > 0
                                                                                                        ?
                                                                                                        members.map((member, index) =>
                                                                                                            <option key={index} value={member?.id}>{member?.full_name}</option>
                                                                                                        )
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </select>
                                                                                            <br />
                                                                                            <span onClick={() => { updateResponsible(); }}><i className="m-1 p-1 bg-[#F2F2F2] border border-blue rounded-md fa fa-check text-[#1EC365]" aria-hidden="true"></i></span>
                                                                                            <span onClick={(e) => { setEdit(false); }}><i className="m-1 py-1 px-2 bg-[#F2F2F2] border border-blue rounded-md fa fa-times text-[#EB5757]" aria-hidden="true"></i></span>
                                                                                        </>
                                                                                        :
                                                                                        <span onClick={(e) => { setEdit(true); }} className='block text-[8px] text-blue/50 cursor-pointer'>{payment?.payment_log?.responsible_user ? 'CHANGE' : 'ADD'}</span>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {/* Sarah Pandu <span className='block text-[8px] text-blue/50 cursor-pointer'>CHANGE</span> */}
                                                                        </td>
                                                                        <td className="text-lg px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit text-[#1EC365]">
                                                                            {payment?.payment_log?.days_lapsed !== '' ? payment?.payment_log?.days_lapsed : 0}
                                                                        </td>
                                                                        <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                            <span className={`inline-block bg-[#${payment?.payment_log?.details === "Payment Done" ? '1EC365' : 'EB5757'}] w-[30px] h-[30px] align-middle rounded-full mr-3`}></span>
                                                                        </td>
                                                                        <td className={`text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit text-[#${payment?.payment_log?.details === "Payment Done" ? '1EC365' : 'EB5757'}]`}>
                                                                            {payment?.payment_log?.details === "Payment Done" ? 'Payment Done' : 'Payment Awaiting'}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                    <div className='inline-block align-middle mr-3'>
                                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g filter="url(#filter0_d_15_6)">
                                                <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                            </g>
                                            <path d="M25.5833 10.25C25.9591 10.25 26.3194 10.3993 26.5851 10.6649C26.8507 10.9306 27 11.2909 27 11.6667V14.5H34.0833C34.4591 14.5 34.8194 14.6493 35.0851 14.9149C35.3507 15.1806 35.5 15.5409 35.5 15.9167V23H38.3333C38.7091 23 39.0694 23.1493 39.3351 23.4149C39.6007 23.6806 39.75 24.0409 39.75 24.4167V34.3333C39.75 34.7091 39.6007 35.0694 39.3351 35.3351C39.0694 35.6007 38.7091 35.75 38.3333 35.75H28.4167C28.0409 35.75 27.6806 35.6007 27.4149 35.3351C27.1493 35.0694 27 34.7091 27 34.3333V31.5H19.9167C19.5409 31.5 19.1806 31.3507 18.9149 31.0851C18.6493 30.8194 18.5 30.4591 18.5 30.0833V23H15.6667C15.2909 23 14.9306 22.8507 14.6649 22.5851C14.3993 22.3194 14.25 21.9591 14.25 21.5833V11.6667C14.25 11.2909 14.3993 10.9306 14.6649 10.6649C14.9306 10.3993 15.2909 10.25 15.6667 10.25H25.5833ZM32.6667 17.3333H27V21.5833C27 21.9591 26.8507 22.3194 26.5851 22.5851C26.3194 22.8507 25.9591 23 25.5833 23H21.3333V28.6667H27V24.4167C27 24.0409 27.1493 23.6806 27.4149 23.4149C27.6806 23.1493 28.0409 23 28.4167 23H32.6667V17.3333Z" fill="#160B32" />
                                            <defs>
                                                <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="4" />
                                                    <feGaussianBlur stdDeviation="2" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                    SETTLEHUB SETTLEMENT LOG
                                    <div className='w-full float-left pl-16'>
                                        <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                            <div className="flex flex-col">
                                                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                        <div className="overflow-x-auto pb-4 xl:pb-0">
                                                            {
                                                                user?.is_concierge === true
                                                                    ?
                                                                    <button onClick={() => { SetModalShow(true); }} className='float-right text-xs text-blue py-1 border px-3 bg-white w-[130px] text-center hover:text-burgandy uppercase'>Create Invoice</button>
                                                                    :
                                                                    null
                                                            }
                                                            <table className="min-w-full text-black">
                                                                <thead className="">
                                                                    <tr>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Ref #</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Originator</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Amount</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">SH Ref</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Responsible</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Deal Ref #</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Days Lapsed</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Status</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Details</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className=''>
                                                                    {
                                                                        (payment?.settlehub_settlement_log && payment?.settlehub_settlement_log.length > 0)
                                                                            ?
                                                                            payment?.settlehub_settlement_log.map((settlehub_log, index) =>
                                                                                <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-black/50">
                                                                                        {settlehub_log?.settlement_ref}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-black/50">
                                                                                        {settlehub_log?.originator}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#1EC365]">
                                                                                        {settlehub_log?.amount}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-black/50">
                                                                                        {settlehub_log?.sh_ref}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#4285F4]">
                                                                                        SettleHub
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit text-black/50">
                                                                                        {fns.ZERO_PAD(settlehub_log?.deal_ref, 7)}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit text-black/50">
                                                                                        {settlehub_log?.days_lapsed}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                        <span className='inline-block bg-[#E0E0E0] w-[30px] h-[30px] align-middle rounded-full mr-3'></span>
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit text-[#1EC465] uppercase">
                                                                                        {settlehub_log?.details}
                                                                                        {
                                                                                            user?.is_concierge === true && settlehub_log?.status === 1
                                                                                                ?
                                                                                                <span
                                                                                                    onClick={() => {
                                                                                                        fns.CONFIRM_BOX(
                                                                                                            'Confirm payment!',
                                                                                                            'Are you sure to update payment status?',
                                                                                                            'YES',
                                                                                                            'CANCEL',
                                                                                                            () => updatePayment(settlehub_log?._id),
                                                                                                            ''
                                                                                                        );
                                                                                                    }}
                                                                                                    className='block text-[8px] text-blue/50 cursor-pointer'>UPDATE</span>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                            :
                                                                            <tr><td className='text-xs px-4 py-2 text-center font-Averta min-w-[220px] xl:min-w-fit text-black/50' colSpan={9}>No settlement payments from SettleHub.</td></tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Pipeline summary={deal?.tracking_summary} />
                            </div>
                        </div>
                        <div className='clear-both'></div>
                    </div>
                </div >
                <Modal show={modalShow} className="bg-black/60">
                    <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalShow(!modalShow); }}>
                        Create Payment
                    </Modal.Header>
                    <Modal.Body className='text-center pt-0'>
                        {modalShow ? <CreatePayment _id={_id} SetModalShow={SetModalShow} fetchPaymentDetails={fetchPaymentDetails} /> : null}
                    </Modal.Body>
                </Modal>
            </>
    )
}

export default Payment

const PageSkeleton = () => {
    return (
        <div className='w-full float-left font-Averta'>
            <div className="sm:w-11/12 w-full mx-auto px-5">
                <div className='float-left w-full'>
                    <div className='float-left w-full'>
                        <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                            <div className='inline-block align-middle mr-3'>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_15_6)">
                                        <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                    </g>
                                    <path d="M17.4375 13.4375C16.4761 13.4375 15.5541 13.8194 14.8742 14.4992C14.1944 15.1791 13.8125 16.1011 13.8125 17.0625V18.875H39.1875V17.0625C39.1875 16.1011 38.8056 15.1791 38.1258 14.4992C37.4459 13.8194 36.5239 13.4375 35.5625 13.4375H17.4375Z" fill="#160B32" />
                                    <path d="M13.8125 27.9375V20.6875H39.1875V27.9375C39.1875 28.8989 38.8056 29.8209 38.1258 30.5008C37.4459 31.1806 36.5239 31.5625 35.5625 31.5625H17.4375C16.4761 31.5625 15.5541 31.1806 14.8742 30.5008C14.1944 29.8209 13.8125 28.8989 13.8125 27.9375ZM31.0312 26.125C30.7909 26.125 30.5604 26.2205 30.3904 26.3904C30.2205 26.5604 30.125 26.7909 30.125 27.0312C30.125 27.2716 30.2205 27.5021 30.3904 27.6721C30.5604 27.842 30.7909 27.9375 31.0312 27.9375H34.6562C34.8966 27.9375 35.1271 27.842 35.2971 27.6721C35.467 27.5021 35.5625 27.2716 35.5625 27.0312C35.5625 26.7909 35.467 26.5604 35.2971 26.3904C35.1271 26.2205 34.8966 26.125 34.6562 26.125H31.0312Z" fill="#160B32" />
                                    <defs>
                                        <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                            MY PAYMENT LOG
                            <BlockLoader height={'200px'} />
                        </div>

                        <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                            <div className='inline-block align-middle mr-3'>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_15_6)">
                                        <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                    </g>
                                    <path d="M25.5833 10.25C25.9591 10.25 26.3194 10.3993 26.5851 10.6649C26.8507 10.9306 27 11.2909 27 11.6667V14.5H34.0833C34.4591 14.5 34.8194 14.6493 35.0851 14.9149C35.3507 15.1806 35.5 15.5409 35.5 15.9167V23H38.3333C38.7091 23 39.0694 23.1493 39.3351 23.4149C39.6007 23.6806 39.75 24.0409 39.75 24.4167V34.3333C39.75 34.7091 39.6007 35.0694 39.3351 35.3351C39.0694 35.6007 38.7091 35.75 38.3333 35.75H28.4167C28.0409 35.75 27.6806 35.6007 27.4149 35.3351C27.1493 35.0694 27 34.7091 27 34.3333V31.5H19.9167C19.5409 31.5 19.1806 31.3507 18.9149 31.0851C18.6493 30.8194 18.5 30.4591 18.5 30.0833V23H15.6667C15.2909 23 14.9306 22.8507 14.6649 22.5851C14.3993 22.3194 14.25 21.9591 14.25 21.5833V11.6667C14.25 11.2909 14.3993 10.9306 14.6649 10.6649C14.9306 10.3993 15.2909 10.25 15.6667 10.25H25.5833ZM32.6667 17.3333H27V21.5833C27 21.9591 26.8507 22.3194 26.5851 22.5851C26.3194 22.8507 25.9591 23 25.5833 23H21.3333V28.6667H27V24.4167C27 24.0409 27.1493 23.6806 27.4149 23.4149C27.6806 23.1493 28.0409 23 28.4167 23H32.6667V17.3333Z" fill="#160B32" />
                                    <defs>
                                        <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                            SETTLEHUB SETTLEMENT LOG
                            <BlockLoader height={'200px'} />
                        </div>

                        <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                            <div className='inline-block align-middle mr-3'>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_15_6)">
                                        <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                    </g>
                                    <path d="M17.781 19.5938C17.781 17.2814 18.6996 15.0637 20.3347 13.4287C21.9698 11.7936 24.1874 10.875 26.4998 10.875C28.8121 10.875 31.0298 11.7936 32.6649 13.4287C34.2999 15.0637 35.2185 17.2814 35.2185 19.5938V23.2828L37.0863 27.9521C37.1452 28.0991 37.1672 28.2583 37.1503 28.4157C37.1334 28.5732 37.0782 28.7241 36.9895 28.8553C36.9008 28.9864 36.7813 29.0938 36.6414 29.1681C36.5015 29.2424 36.3456 29.2812 36.1873 29.2812H16.8123C16.6539 29.2812 16.498 29.2424 16.3582 29.1681C16.2183 29.0938 16.0988 28.9864 16.0101 28.8553C15.9214 28.7241 15.8661 28.5732 15.8493 28.4157C15.8324 28.2583 15.8544 28.0991 15.9133 27.9521L17.781 23.2828V19.5938Z" fill="#160B32" />
                                    <path d="M22.7446 31.2188C23.1728 32.8947 24.686 34.125 26.4995 34.125C28.313 34.125 29.8262 32.8947 30.2544 31.2188H22.7446Z" fill="#160B32" />
                                    <defs>
                                        <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                            TRACKING SUMMARY
                            <BlockLoader height={'200px'} />
                        </div>
                    </div>
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    );
}

const CreatePayment = ({ _id, SetModalShow, fetchPaymentDetails }) => {
    const { register, handleSubmit, formState: { errors }, watch, setValue, setError, clearErrors } = useForm();
    const [processing, setProcessing] = useState(false);

    const SavePayment = async (form) => {
        const formx = new FormData();
        formx.append('file', form.file[0]);
        formx.append('payment_amount', form.payment_amount);
        formx.append('sh_ref', form.sh_ref);
        formx.append('payment_details', form.payment_details);
        formx.append('status', form.status);

        setProcessing(true);
        const res = await ManageOfferService.CreatePaymentAPI(_id, formx);

        if (res.response) {
            setProcessing(false);
            fetchPaymentDetails();
            SetModalShow(false);
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    const handleInvoice = (e) => {
        var filePath = e.target.value;
        if (!constants.ATTACHMENT_REQ.exec(filePath)) {
            setValue('file');
            setError("file", { type: "focus", message: 'Please select PDF file only.' }, { shouldFocus: true });
            return false;
        }

        clearErrors('file');
    }

    return (
        <>
            <form onSubmit={handleSubmit(SavePayment)}>
                <div className="w-full float-left clear-both p-4 pt-1 text-left">
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Payment File</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("file", {
                                    required: "required"
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="file"
                                accept="image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={handleInvoice}
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.file && errors.file.type === "required" && 'Please select payment file.'}
                                {errors.file && errors.file.type !== "required" && errors.file.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Payment amount</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("payment_amount", {
                                    required: "required",
                                    min: {
                                        value: 1,
                                        message: "Payment amount must greater then zero."
                                    },
                                    pattern: {
                                        value: /^[0-9\.]{1,8}$/,
                                        message: "Payment amount must be numeric value."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="number"
                                placeholder="Payment amount (E.g. : 100.00)"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.payment_amount && errors.payment_amount.type === "required" && 'Please enter payment amount. (E.g. : 100.00)'}
                                {errors.payment_amount && errors.payment_amount.type !== "required" && errors.payment_amount.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">SH Ref.</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("sh_ref", {
                                    required: "required",
                                    min: {
                                        value: 3,
                                        message: "Please enter at least 3 characters."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="SH_0001"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.sh_ref && errors.sh_ref.type === "required" && 'Please enter SH ref. (E.g. : SH_0001)'}
                                {errors.sh_ref && errors.sh_ref.type !== "required" && errors.sh_ref.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Payment details</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("payment_details", {
                                    required: "required",
                                    min: {
                                        value: 3,
                                        message: "Please enter at least 3 characters."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="Payment details"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.payment_details && errors.payment_details.type === "required" && 'Please enter payment details.'}
                                {errors.payment_details && errors.payment_details.type !== "required" && errors.payment_details.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Payment status</label>
                        <div className="float-right w-full md:w-3/5">
                            <select
                                {...register("status", { required: "required" })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                            >
                                <option value="payment_awaiting">Payment awaiting</option>
                                <option value="payment_received">Payment received</option>
                            </select>
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.status && errors.status.type === "required" && 'Please enter payment details.'}
                                {errors.status && errors.status.type !== "required" && errors.status.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left">
                        <button disabled={processing} className='float-right bg-lightperot hover:bg-perot text-white text-sm leading-6 p-1 px-10 rounded-full'>
                            {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Create'}
                        </button>
                    </div>
                </div>
                <div className='clear-both'></div>
            </form>
        </>
    );
}