import React, { useEffect, useState } from 'react';
import { LineLoader } from 'UIs/SkeletonLoader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from 'modules/fragments';
import fns from 'helpers/functions';
import constants from 'helpers/constants';
import * as GenService from 'Services/general.service';
import * as NegotitationService from 'Services/negotitation-deals.service';

const RequestTicketView = () => {
    const navigate = useNavigate();
    const { _id } = useParams();
    const [request, setRequest] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [fetching, setFetching] = useState(true);

    const [updating, setUpdating] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [editing, setEditing] = useState(false);
    const [settle_value, setSettleValue] = useState(0);
    const [settle_status, setSettleStatus] = useState('');

    const [settle_value_error, setSettleValueError] = useState(null);
    const [settle_status_error, setSettleStatusError] = useState(null);

    const [attachement, setAttachement] = useState(null);
    const [attachement_error, setAttachementError] = useState(null);
    const [attachement_name, setAttachementName] = useState(null);

    useEffect(() => {
        fetchRequest();
        fetchStatuses();

        return () => {
        }
    }, []);

    const setSelectedStatus = (status) => {
        if (fns.IN_ARRAY(['In Negotiation', 'Closed'], status)) {
            if ('In Negotiation' === status) {
                setSettleStatus('negotiating');
            }
            if ('Closed' === status) {
                setSettleStatus('rejected');
            }
        }
    }

    const fetchRequest = async () => {
        const res = await NegotitationService.FetchRequestAPI(_id);

        if (res.response) {
            setFetching(false);
            setRequest(res.data);
            setSettleValue(res.data?.settlement_value);
            setSelectedStatus(res.data?._status_text);
            setEditing(false);
        } else {
            setFetching(false);
            setRequest(null);
            setSettleValue(0);
            setSettleStatus('');
            setEditing(false);
        }
    }

    const fetchStatuses = async () => {
        const res = await NegotitationService.FetchStatusesAPI();

        if (res.response) {
            setStatuses(res.data);
        } else {
            setStatuses(null);
        }
    }

    const fileSelected = (e) => {
        var filePath = e.target.value;
        if (!constants.ATTACHMENT_REQ.exec(filePath)) {
            setAttachementError('Please upload PDF file only.');
            setAttachement(null);
            setAttachementName(null);
            return false;
        }

        setAttachement(e.target.files[0]);
        setAttachementName(e.target.files[0].name);
        setAttachementError(null);
    }

    const downloadAttachment = async (_id, name) => {
        const res = await GenService.DownloadAttachmentAPI(_id, name);
        if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    const updateRequest = async () => {
        const settle_value_x = parseInt(settle_value);

        if (isNaN(settle_value_x)) {
            setSettleValueError('Enter numberic and positive value only.');
            return;
        }

        if (settle_status === '') {
            setSettleStatusError('Pleas select request status.');
            return;
        }

        setSettleValueError('');
        setSettleStatusError('');

        const formx = {
            "settlement_value": settle_value_x,
            "action": settle_status
        }

        setUpdating(true);
        const res = await NegotitationService.UpdateRequestAPI(_id, formx);

        if (res.response) {
            setUpdating(false);
            fns.TOAST_SUC(res.data.message);
            fetchRequest();
        } else {
            setUpdating(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    const createSettlementOffer = async () => {
        if (attachement_error) {
            return;
        }

        if (attachement === null) {
            setAttachementError('Please upload settlement letter.');
            return;
        }

        const formx = new FormData();
        formx.append('file', attachement);

        setSubmitting(true);
        const res = await NegotitationService.CreateSettlementOfferAPI(_id, formx);

        if (res.response) {
            setSubmitting(false);
            fns.TOAST_SUC(res?.data.message);
            navigate(`/concierge/negotiations`);
        } else {
            setSubmitting(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <>
            {
                fetching
                    ?
                    <PageSkeleton />
                    :
                    <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                        <div className='w-full float-left'>

                            <Breadcrumb
                                page={'NEGOTIATION TICKET'}
                                desc={
                                    <>
                                        <span className='inline-block align-middle mr-3'>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="16" cy="16" r="15.5" fill="white" stroke="#160B32" />
                                                <path d="M16 8.6521C14.7727 8.6521 13.8139 9.10817 13.1236 10.0203C12.4332 10.9324 12.0881 12.0984 12.0881 13.5183C12.0795 15.2823 12.642 16.6806 13.7756 17.7132C13.9205 17.8509 13.9716 18.0273 13.929 18.2425L13.6349 18.862C13.5412 19.0685 13.4027 19.2299 13.2195 19.3461C13.0362 19.4622 12.6506 19.6279 12.0625 19.843C12.0369 19.8516 11.4979 20.028 10.4453 20.3722C9.39276 20.7164 8.83239 20.9057 8.7642 20.9401C8.0483 21.2413 7.57955 21.5554 7.35795 21.8824C7.11932 22.4245 7 23.797 7 25.9999H25C25 23.797 24.8807 22.4245 24.642 21.8824C24.4205 21.5554 23.9517 21.2413 23.2358 20.9401C23.1676 20.9057 22.6072 20.7164 21.5547 20.3722C20.5021 20.028 19.9631 19.8516 19.9375 19.843C19.3494 19.6279 18.9638 19.4622 18.7805 19.3461C18.5973 19.2299 18.4588 19.0685 18.3651 18.862L18.071 18.2425C18.0284 18.0273 18.0795 17.8509 18.2244 17.7132C19.358 16.6806 19.9205 15.2823 19.9119 13.5183C19.9119 12.0984 19.5668 10.9324 18.8764 10.0203C18.1861 9.10817 17.2273 8.6521 16 8.6521Z" fill="#160B32" />
                                            </svg>
                                        </span>
                                        {fns.STR_REPLACE(request?.type, '_', ' ')}: {request?.id_number}
                                    </>
                                }
                                contact={' '}
                            />

                            <div className='sm:w-11/12 w-full mx-auto px-5 py-5 md:pt-0 md:pl-80 lg:pl-72 xl:pl-64 relative'>
                                <h3 className='float-left md:float-none md:absolute md:-left-[4.6%] top-0 text-right text-xs md:w-80 mr-4'><span className='bg-burgandy px-8 py-1 border border-blue float-right text-white text-xs'>SUMMARY</span></h3>
                                <h4 className='float-right md:float-left text-blue text-xs sm:p-3 pt-0 sm:pt-0 leading-7 font-Averta_Semibold uppercase'>
                                    <span className='inline-block align-middle mr-3 -mt-1'>
                                        <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.47 1.94311C12.1375 1.59264 11.7003 1.40329 11.2494 1.41454C10.7985 1.42578 10.3689 1.63677 10.05 2.00349L1.77996 11.514C1.61744 11.7009 1.48852 11.9227 1.40056 12.1669C1.3126 12.4111 1.26733 12.6728 1.26733 12.9371C1.26733 13.2014 1.3126 13.4631 1.40056 13.7073C1.48852 13.9515 1.61744 14.1734 1.77996 14.3602L2.93746 15.6914C3.37121 16.1902 3.99496 16.0709 4.36246 15.7877C4.62691 15.5855 4.94405 15.4949 5.26002 15.5311C5.57599 15.5674 5.87132 15.7282 6.09585 15.9865C6.32039 16.2447 6.46029 16.5843 6.4918 16.9477C6.52331 17.311 6.44448 17.6757 6.26871 17.9799C6.02496 18.4039 5.91996 19.1212 6.35371 19.62L7.48496 20.921C7.65059 21.1117 7.84784 21.2621 8.065 21.3635C8.28216 21.4648 8.51481 21.515 8.74915 21.5109C8.98348 21.5069 9.21473 21.4488 9.42916 21.3401C9.64359 21.2313 9.83685 21.0741 9.99746 20.8779L18.245 10.7981C18.4039 10.6041 18.5278 10.3756 18.6093 10.1261C18.6909 9.87653 18.7285 9.61083 18.72 9.34442C18.7115 9.078 18.6571 8.81619 18.5599 8.57417C18.4627 8.33215 18.3246 8.11476 18.1537 7.93461L17.0462 6.76736C16.9244 6.64201 16.7788 6.55103 16.6211 6.50163C16.4633 6.45222 16.2977 6.44576 16.1375 6.48274C15.8537 6.54024 15.585 6.71417 15.39 6.95855C15.2679 7.11164 15.1208 7.2356 14.9571 7.32333C14.7934 7.41106 14.6163 7.46085 14.4359 7.46986C14.2554 7.47887 14.0752 7.44693 13.9055 7.37584C13.7358 7.30476 13.58 7.19593 13.4468 7.05558C13.178 6.77212 13.018 6.37745 13.0022 5.95838C12.9944 5.75088 13.0222 5.54363 13.084 5.34848C13.1458 5.15332 13.2404 4.97408 13.3625 4.82099C13.5587 4.57805 13.685 4.25461 13.7112 3.92542C13.7295 3.73863 13.7096 3.5495 13.6532 3.37308C13.5968 3.19666 13.5054 3.03779 13.3862 2.90911L12.47 1.94311Z" fill="#160B32" />
                                        </svg>
                                    </span>
                                    TICKET REQUEST: {fns.ZERO_PAD(request?.ticket, 6)}
                                </h4>
                                <div className='clear-both'></div>
                            </div>

                            <div className='w-full float-left font-Averta'>
                                <div className="sm:w-11/12 w-full mx-auto px-5">
                                    <div className='float-left w-full'>
                                        <div className='float-left w-full'>

                                            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                                <div className='w-full float-left'>
                                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-4">
                                                        <div className="flex flex-col">
                                                            <div className="overflow-x-auto">
                                                                <div className="py-2 inline-block min-w-full">
                                                                    <div className="overflow-x-auto pb-4 xl:pb-0">
                                                                        <table className="min-w-full text-black">
                                                                            <THeader status={request?.status} />
                                                                            <tbody className=''>
                                                                                <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                    <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                        {fns.ZERO_PAD(request?.ticket, 6)}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                        {request?.created_at}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                                        {request?.institution_name}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit text-[#EB5757]">
                                                                                        {request?._outstanding_balance_text}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                        {request?.cd_bracket}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                        {request?._account_type_text}
                                                                                    </td>
                                                                                    <td className="text-xs text-center font-Averta whitespace-wrap min-w-[420px] xl:min-w-fit text-[#1EC365] pt-1">
                                                                                        {
                                                                                            editing
                                                                                                ?
                                                                                                <>
                                                                                                    <input
                                                                                                        type='number'
                                                                                                        defaultValue={settle_value}
                                                                                                        onChange={(e) => { setSettleValueError(null); setSettleValue(e.target.value); }}
                                                                                                        className="appearance-none outline-none w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-1 border rounded-lg border-gray"
                                                                                                    />
                                                                                                    {settle_value_error ? <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{settle_value_error}</span> : null}
                                                                                                </>
                                                                                                :
                                                                                                request?._settlement_value_text
                                                                                        }
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                        {request?.request_by}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-3 text-center font-Averta min-w-[160px] max-w-[160px] xl:min-w-fit text-[#818181]">
                                                                                        {
                                                                                            request?.documents.length > 0
                                                                                                ?
                                                                                                <ol className='list-decimal'>
                                                                                                    {
                                                                                                        request?.documents.map((document, index) =>
                                                                                                            <li onClick={() => { downloadAttachment(document._id, document?.document_name) }} key={index} className='cursor-pointer break-words'>{document?.document_name}</li>
                                                                                                        )
                                                                                                    }
                                                                                                </ol>
                                                                                                :
                                                                                                "N/A"
                                                                                        }
                                                                                    </td>
                                                                                    <td className="text-xs text-center font-Averta min-w-[110px] p-2">
                                                                                        {
                                                                                            editing
                                                                                                ?
                                                                                                statuses !== null
                                                                                                    ?
                                                                                                    <>
                                                                                                        <select
                                                                                                            onChange={(e) => { setSettleStatus(e.target.value); }}
                                                                                                            defaultValue={settle_status}
                                                                                                            className="appearance-none outline-none w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-1 border rounded-lg border-gray"
                                                                                                        >
                                                                                                            <option value="">Select Status</option>
                                                                                                            {
                                                                                                                Object.keys(statuses).map((keyName, i) => {
                                                                                                                    return (
                                                                                                                        <option key={i} value={keyName}>{statuses[keyName]}</option>
                                                                                                                    );
                                                                                                                })
                                                                                                            }
                                                                                                        </select>
                                                                                                        {settle_status_error ? <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{settle_status_error}</span> : null}
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                :
                                                                                                request?._status_text
                                                                                        }
                                                                                    </td>
                                                                                    {
                                                                                        request?.status !== 3
                                                                                            ?
                                                                                            <td className="text-xs font-Averta px-2 py-1 px-2 text-center min-w-[220px] xl:min-w-fit" width={170}>
                                                                                                {
                                                                                                    editing
                                                                                                        ?
                                                                                                        <>
                                                                                                            <button disabled={updating} onClick={updateRequest} className='border border-blue text-xs py-1 px-6 hover:bg-blue hover:text-white mt-2 w-full'> {updating ? <i className="fa-solid fa-spinner fa-spin"></i> : 'SAVE'}</button>
                                                                                                            <button disabled={updating} onClick={() => { setEditing(false); setSettleValue(request?.settlement_value); setSettleStatus(''); }} className=' w-full text-[#160B32] opacity-50 hover:opacity-100 text-[10px] p-2'>Cancel</button>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <button onClick={() => { setEditing(true); }} className='text-[#160B32] opacity-50 hover:opacity-100 text-[10px]'>EDIT</button>
                                                                                                        </>
                                                                                                }
                                                                                            </td>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='float-left pt-6 sm:py-6 pb-4 sm:px-6 max-w-4xl'>
                                                            <h4 className='font-Averta text-xs pb-3'>CLIENT MESSAGE:</h4>
                                                            <p className='font-Averta text-sm'>{request?.client_message?.description}</p>
                                                        </div>
                                                        {
                                                            request?.status !== 3
                                                                ?
                                                                <div className="float-right text-center pt-3">
                                                                    <label htmlFor="file-upload" className="w-[150px] block shadow-md shadow-black/25 bg-white border border-blue py-4 relative cursor-pointer rounded-md focus-within:outline-none">
                                                                        <span className='block p-1'>
                                                                            <svg className='m-auto' width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M11.25 14.8312V30C11.25 32.9837 12.4353 35.8452 14.545 37.955C16.6548 40.0647 19.5163 41.25 22.5 41.25C25.4837 41.25 28.3452 40.0647 30.455 37.955C32.5647 35.8452 33.75 32.9837 33.75 30V11.25C33.75 9.26088 32.9598 7.35322 31.5533 5.9467C30.1468 4.54018 28.2391 3.75 26.25 3.75C24.2609 3.75 22.3532 4.54018 20.9467 5.9467C19.5402 7.35322 18.75 9.26088 18.75 11.25V28.4663C18.75 28.9587 18.847 29.4463 19.0355 29.9013C19.2239 30.3563 19.5001 30.7697 19.8484 31.1179C20.1966 31.4661 20.61 31.7423 21.0649 31.9308C21.5199 32.1193 22.0075 32.2162 22.5 32.2162V32.2162C23.4946 32.2162 24.4484 31.8212 25.1516 31.1179C25.8549 30.4146 26.25 29.4608 26.25 28.4663V15" stroke="#160B32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                            <p className="text-xs font-Averta text-blue pt-3">{attachement_name ? attachement_name : 'Attach Originator Settlement Letter'}</p>
                                                                        </span>
                                                                        <input onChange={fileSelected} id="file-upload" name="file-upload" type="file" accept="image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" className="sr-only" />
                                                                    </label>
                                                                    {
                                                                        attachement_error ? <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">{attachement_error}</span> : null
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='float-left w-full align-middle text-xs font-Averta_Semibold'>
                                                <div className='w-full float-left'>
                                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-5">
                                                        <div className="flex flex-col">
                                                            <div className="overflow-x-auto">
                                                                <div className="py-2 inline-block min-w-full">
                                                                    <div className="overflow-x-auto pb-4 xl:pb-0">
                                                                        <table className="min-w-full text-black">
                                                                            <tbody className=''>
                                                                                <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 text-black uppercase">
                                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap w-[120px]">
                                                                                        FROM:
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[180px] xl:min-w-fit">
                                                                                        <svg className='inline-block mr-3 align-middle -mt-1' width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M9 1.6521C7.77273 1.6521 6.81392 2.10817 6.12358 3.02031C5.43324 3.93244 5.08807 5.09843 5.08807 6.51827C5.07955 8.28231 5.64205 9.68063 6.77557 10.7132C6.92045 10.8509 6.97159 11.0273 6.92898 11.2425L6.63494 11.862C6.54119 12.0685 6.4027 12.2299 6.21946 12.3461C6.03622 12.4622 5.65057 12.6279 5.0625 12.843C5.03693 12.8516 4.49787 13.028 3.44531 13.3722C2.39276 13.7164 1.83239 13.9057 1.7642 13.9401C1.0483 14.2413 0.579545 14.5554 0.357955 14.8824C0.119318 15.4245 0 16.797 0 18.9999H18C18 16.797 17.8807 15.4245 17.642 14.8824C17.4205 14.5554 16.9517 14.2413 16.2358 13.9401C16.1676 13.9057 15.6072 13.7164 14.5547 13.3722C13.5021 13.028 12.9631 12.8516 12.9375 12.843C12.3494 12.6279 11.9638 12.4622 11.7805 12.3461C11.5973 12.2299 11.4588 12.0685 11.3651 11.862L11.071 11.2425C11.0284 11.0273 11.0795 10.8509 11.2244 10.7132C12.358 9.68063 12.9205 8.28231 12.9119 6.51827C12.9119 5.09843 12.5668 3.93244 11.8764 3.02031C11.1861 2.10817 10.2273 1.6521 9 1.6521Z" fill="#160B32" />
                                                                                        </svg>
                                                                                        {request?.creator?.full_name}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                        {request?.creator?.organization?.institution_name}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                        {request?.creator?.mobile}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                        {request?.creator?.email}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        request?.status !== 3 && request?.status !== 0
                                                            ?
                                                            <button onClick={createSettlementOffer} disabled={submitting} className='float-right text-sm text-burgandy hover:text-blue py-4 px-12 text-center rounded-xl bg-blue hover:bg-burgandy mt-4 ml-4'>{submitting ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Create Settlement Offer'}</button>
                                                            :
                                                            null
                                                    }
                                                    <Link to={`/concierge/negotiations`} className='float-right text-sm text-blue hover:text-burgandy py-4 px-12 text-center rounded-xl bg-burgandy hover:bg-blue mt-4'>Close Ticket</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='clear-both'></div>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </section>
            }
        </>
    )
}

export default RequestTicketView

const THeader = ({ status }) => {
    return (
        <thead className="">
            <tr>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Ticket</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Requested</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Originator</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Outstanding Balance</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Code Bracket</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Account Type</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Opening Offer</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Party</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Attachment</th>
                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Status</th>
                {
                    status !== 3
                        ?
                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center"></th>
                        :
                        null
                }
            </tr>
        </thead>
    );
}

const PageSkeleton = () => {
    return (
        <>
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <div className='sm:w-11/12 w-full mx-auto px-5 py-5 pt-16 md:pt-0 md:pl-80 lg:pl-72 xl:pl-64 relative'>
                        <h3 className='absolute -left-[4.6%] top-0 bg-blue text-white text-right text-xs p-5 w-80 mr-4'>NEGOTIATION TICKET</h3>
                        <h4 className='float-left text-blue text-lg sm:p-3 py-3 leading-7 font-Averta_Semibold'>
                            <LineLoader width={'2000%'} count={1} />
                        </h4>
                        <div className='clear-both'></div>
                    </div>

                    <div className='sm:w-11/12 w-full mx-auto px-5 py-5 md:pt-0 md:pl-80 lg:pl-72 xl:pl-64 relative'>
                        <h3 className='float-left md:float-none md:absolute md:-left-[4.6%] top-0 text-right text-xs md:w-80 mr-4'><span className='bg-burgandy px-8 py-1 border border-blue float-right text-white text-xs'>SUMMARY</span></h3>
                        <h4 className='float-right md:float-left text-blue text-xs sm:p-3 pt-0 sm:pt-0 leading-7 font-Averta_Semibold'>
                            <LineLoader width={'2000%'} count={1} />
                        </h4>
                        <div className='clear-both'></div>
                    </div>

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>

                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <div className='w-full float-left'>
                                            <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-4">
                                                <div className="flex flex-col">
                                                    <div className="overflow-x-auto">
                                                        <div className="py-2 inline-block min-w-full">
                                                            <div className="overflow-x-auto pb-4 xl:pb-0">
                                                                <table className="min-w-full text-black">
                                                                    <THeader />
                                                                    <tbody className=''>
                                                                        <tr>
                                                                            <td colSpan={11}>
                                                                                <LineLoader width={'100%'} count={1} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='float-left pt-6 sm:py-6 pb-4 sm:px-6 max-w-4xl'>
                                                    <h4 className='font-Averta text-xs pb-3'>CLIENT MESSAGE:</h4>
                                                    <LineLoader width={'100%'} count={1} />
                                                </div>
                                                <div className="float-right text-center pt-3">
                                                    <label htmlFor="file-upload" className="w-[150px] block shadow-md shadow-black/25 bg-white border border-blue py-4 relative cursor-pointer rounded-md focus-within:outline-none">
                                                        <span className='block'>
                                                            <svg className='m-auto' width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M11.25 14.8312V30C11.25 32.9837 12.4353 35.8452 14.545 37.955C16.6548 40.0647 19.5163 41.25 22.5 41.25C25.4837 41.25 28.3452 40.0647 30.455 37.955C32.5647 35.8452 33.75 32.9837 33.75 30V11.25C33.75 9.26088 32.9598 7.35322 31.5533 5.9467C30.1468 4.54018 28.2391 3.75 26.25 3.75C24.2609 3.75 22.3532 4.54018 20.9467 5.9467C19.5402 7.35322 18.75 9.26088 18.75 11.25V28.4663C18.75 28.9587 18.847 29.4463 19.0355 29.9013C19.2239 30.3563 19.5001 30.7697 19.8484 31.1179C20.1966 31.4661 20.61 31.7423 21.0649 31.9308C21.5199 32.1193 22.0075 32.2162 22.5 32.2162V32.2162C23.4946 32.2162 24.4484 31.8212 25.1516 31.1179C25.8549 30.4146 26.25 29.4608 26.25 28.4663V15" stroke="#160B32" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            <p className="text-xs font-Averta text-blue pt-3">Attach Originator Settlement Letter</p>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold'>
                                        <div className='w-full float-left'>
                                            <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-5">
                                                <div className="flex flex-col">
                                                    <div className="overflow-x-auto">
                                                        <div className="py-2 inline-block min-w-full">
                                                            <div className="overflow-x-auto pb-4 xl:pb-0">
                                                                <table className="min-w-full text-black">
                                                                    <tbody className=''>
                                                                        <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 text-black">
                                                                            <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap w-[120px]">
                                                                                FROM:
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[180px] xl:min-w-fit">
                                                                                <LineLoader width={'100%'} count={1} />
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                <LineLoader width={'100%'} count={1} />
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                <LineLoader width={'100%'} count={1} />
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                                <LineLoader width={'100%'} count={1} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className='float-right text-sm text-burgandy hover:text-blue py-4 px-12 text-center rounded-xl bg-blue hover:bg-burgandy mt-4 ml-4'>Create Settlement Offer</button>
                                            <Link to={`/concierge/negotiations`} className='float-right text-sm text-blue hover:text-burgandy py-4 px-12 text-center rounded-xl bg-burgandy hover:bg-blue mt-4'>Close Ticket</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>

                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    );
}