import fns from 'helpers/functions';
import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ page, desc = '', contact }) => {
    const user = fns.LOGGED_USER();

    return (
        <div className='sm:w-11/12 w-full mx-auto px-5 py-5 pt-16 xl:pt-0 xl:pl-64 relative'>
            <h3 className='absolute -left-[4.6%] top-0 bg-blue text-white text-center text-xs p-5 w-80 uppercase'>{page}</h3>
            <h4 className='float-left text-blue text-lg sm:p-3 py-3 leading-7 font-Averta_Semibold uppercase'>
                <span className='ml-5'>{desc ? desc : (user?.is_concierge ? '' : user?.organization_name)}</span>
            </h4>
            {
                contact ? contact : <Link to={`/support`} className='float-right text-xs text-blue py-1 border px-3 hover:text-burgandy mt-4 uppercase'>CONTACT CONCIERGE</Link>
            }
            <div className='clear-both'></div>
        </div>
    )
};

export default Breadcrumb;