import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Modal } from 'flowbite-react';
import { ButtonRect, InputRect } from 'UIs/SkeletonLoader';
import fns from 'helpers/functions';
import * as OrgService from 'Services/organisation.service';

const MembersSection = ({ loadingMembers, members, fetchMembers, roles }) => {
    const [modalShow, SetModalShow] = useState(false);
    const [modalShow2, SetModalShow2] = useState(false);
    const [member_id, SetMemberId] = useState(null);
    const user = fns.LOGGED_USER();

    useEffect(() => {
        return () => {
        }
    }, []);

    /** Delete member */
    const DeleteMember = async (_id) => {
        const res = await OrgService.DeleteMemberAPI(_id);

        if (res.response) {
            fetchMembers();
        } else if (res.errors) {
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        !loadingMembers
            ?
            <>
                <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                    <div className='inline-block align-middle mr-3'>
                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_15_6)">
                                <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                            </g>
                            <path d="M26.5 10.1294C25.9589 10.1294 25.423 10.236 24.923 10.4431C24.4231 10.6502 23.9688 10.9537 23.5861 11.3364C23.2035 11.719 22.8999 12.1733 22.6928 12.6733C22.4857 13.1732 22.3792 13.7091 22.3792 14.2503C22.3792 14.7914 22.4857 15.3273 22.6928 15.8273C22.8999 16.3272 23.2035 16.7815 23.5861 17.1642C23.9688 17.5468 24.4231 17.8504 24.923 18.0575C25.423 18.2646 25.9589 18.3711 26.5 18.3711C27.5929 18.3711 28.6411 17.937 29.4139 17.1642C30.1867 16.3914 30.6209 15.3432 30.6209 14.2503C30.6209 13.1573 30.1867 12.1092 29.4139 11.3364C28.6411 10.5636 27.5929 10.1294 26.5 10.1294Z" fill="#160B32" />
                            <path d="M35.7812 12.1875C34.9607 12.1875 34.1738 12.5134 33.5936 13.0936C33.0134 13.6738 32.6875 14.4607 32.6875 15.2812C32.6875 16.1018 33.0134 16.8887 33.5936 17.4689C34.1738 18.0491 34.9607 18.375 35.7812 18.375C36.6018 18.375 37.3887 18.0491 37.9689 17.4689C38.5491 16.8887 38.875 16.1018 38.875 15.2812C38.875 14.4607 38.5491 13.6738 37.9689 13.0936C37.3887 12.5134 36.6018 12.1875 35.7812 12.1875Z" fill="#160B32" />
                            <path d="M17.2188 12.1875C16.3982 12.1875 15.6113 12.5134 15.0311 13.0936C14.4509 13.6738 14.125 14.4607 14.125 15.2812C14.125 16.1018 14.4509 16.8887 15.0311 17.4689C15.6113 18.0491 16.3982 18.375 17.2188 18.375C18.0393 18.375 18.8262 18.0491 19.4064 17.4689C19.9866 16.8887 20.3125 16.1018 20.3125 15.2812C20.3125 14.4607 19.9866 13.6738 19.4064 13.0936C18.8262 12.5134 18.0393 12.1875 17.2188 12.1875Z" fill="#160B32" />
                            <path d="M20.3125 22.4856C20.3163 21.9411 20.5353 21.4202 20.9217 21.0365C21.3081 20.6528 21.8305 20.4375 22.375 20.4375H30.625C31.172 20.4375 31.6966 20.6548 32.0834 21.0416C32.4702 21.4284 32.6875 21.953 32.6875 22.5V28.6875C32.6874 29.3366 32.5858 29.9817 32.3864 30.5994C31.9297 31.9993 30.9889 33.1904 29.733 33.959C28.4771 34.7277 26.9883 35.0234 25.534 34.7931C24.0797 34.5628 22.7551 33.8216 21.7981 32.7025C20.8411 31.5835 20.3144 30.1599 20.3125 28.6875V22.5V22.4856Z" fill="#160B32" />
                            <path d="M18.25 22.5C18.25 21.7472 18.4501 21.0439 18.8028 20.4375H14.125C13.578 20.4375 13.0534 20.6548 12.6666 21.0416C12.2798 21.4284 12.0625 21.953 12.0625 22.5V27.6562C12.0622 28.5004 12.2692 29.3318 12.6653 30.0773C13.0614 30.8228 13.6345 31.4596 14.3342 31.9319C15.034 32.4042 15.8389 32.6974 16.6785 32.7858C17.518 32.8743 18.3665 32.7553 19.1493 32.4392C18.5556 31.2771 18.2474 29.9903 18.25 28.6854V22.5Z" fill="#160B32" />
                            <path d="M34.7502 22.5V28.6875C34.7502 30.0384 34.4264 31.3131 33.851 32.4392C34.6337 32.7553 35.4822 32.8743 36.3217 32.7858C37.1613 32.6974 37.9662 32.4042 38.666 31.9319C39.3657 31.4596 39.9388 30.8228 40.3349 30.0773C40.731 29.3318 40.938 28.5004 40.9377 27.6562V22.5C40.9377 21.953 40.7204 21.4284 40.3336 21.0416C39.9468 20.6548 39.4222 20.4375 38.8752 20.4375H34.1975C34.5481 21.0439 34.7502 21.7472 34.7502 22.5Z" fill="#160B32" />
                            <defs>
                                <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    MY ORGANISATION
                    <div className='w-full float-left pl-16'>
                        <div className="bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 pb-5 mb-10 lg:flex lg:content-end">
                            <div className="w-full lg:w-4/6 float-left">
                                {
                                    (members && members !== null && members.length > 0)
                                        ?
                                        members.map((member, index) => {
                                            return (
                                                (member?.email !== user?.email)
                                                    ?
                                                    <div key={index} className="w-full lg:w-3/6 float-left md:px-4 md:pr-12 pr-12 relative">
                                                        <label className='w-full float-left text-xs text-blue bg-white shadow-md shadow-black/25 mb-3 px-4 py-4 rounded-lg'>
                                                            {member?.full_name}
                                                            <span onClick={() => { SetModalShow2(true); SetMemberId(member?.encrypted_id); }} className='float-right text-[16px] hover:text-burgandy cursor-pointer ml-3'><i className="fa-solid fa-eye"></i></span>
                                                            <span className='float-right text-[#B33717]'>{member?.roles[0]?.name}</span>
                                                        </label>
                                                        {
                                                            fns.CHECK_PERMISSION(["D2"])
                                                            &&
                                                            <span
                                                                onClick={(e) => {
                                                                    fns.CONFIRM_BOX(
                                                                        'Confirm to delete!',
                                                                        'Are you sure to delete this member?',
                                                                        'YES',
                                                                        'CANCEL',
                                                                        () => DeleteMember(member.encrypted_id),
                                                                        ''
                                                                    );
                                                                }}
                                                                className='absolute right-0 top-0 leading-10 float-right w-[40px] h-[48px] text-center flex justify-center items-center text-lg text-blue hover:text-burgandy cursor-pointer'
                                                            >
                                                                <i className="fa-solid fa-trash-can"></i>
                                                            </span>
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            )
                                        })
                                        :
                                        <div className="w-full lg:w-6/6 float-left md:px-4">
                                            <label className='w-full float-left text-xs text-blue py-4 rounded-lg'>No members added.</label>
                                        </div>
                                }
                            </div>
                            {
                                fns.CHECK_PERMISSION(["D1"])
                                &&
                                <div className="w-full lg:w-4/12 float-left md:px-4 lg:flex lg:flex-col-reverse items-end lg:mt-0 mt-5">
                                    <span onClick={() => { SetModalShow(!modalShow) }} className='cursor-pointer float-right text-xs text-blue py-1 border px-3 bg-white w-[130px] text-center hover:text-burgandy'>+ ADD MEMBER</span>
                                </div>
                            }
                            <div className='clear-both'></div>
                        </div>
                    </div>
                </div>
                <Modal show={modalShow} className="bg-black/60">
                    <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalShow(!modalShow) }}>
                        Add New Member
                    </Modal.Header>
                    <Modal.Body className='text-center pt-0'>
                        {modalShow ? <AddMemberPop fetchMembers={fetchMembers} roles={roles} SetModalShow={SetModalShow} /> : null}
                    </Modal.Body>
                </Modal>
                <Modal show={modalShow2} className="bg-black/60">
                    <Modal.Header className='border-b-0 pb-0' onClick={() => { SetModalShow2(!modalShow2) }}>
                        Member Details
                    </Modal.Header>
                    <Modal.Body className='text-center pt-0'>
                        {modalShow2 ? <ViewMember member_id={member_id} /> : null}
                    </Modal.Body>
                </Modal>
            </>
            :
            <PageSkeleton />
    )
}

export default MembersSection;

const PageSkeleton = () => {
    return (
        <>
            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                <div className='inline-block align-middle mr-3'>
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_15_6)">
                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                        </g>
                        <path d="M26.5 10.1294C25.9589 10.1294 25.423 10.236 24.923 10.4431C24.4231 10.6502 23.9688 10.9537 23.5861 11.3364C23.2035 11.719 22.8999 12.1733 22.6928 12.6733C22.4857 13.1732 22.3792 13.7091 22.3792 14.2503C22.3792 14.7914 22.4857 15.3273 22.6928 15.8273C22.8999 16.3272 23.2035 16.7815 23.5861 17.1642C23.9688 17.5468 24.4231 17.8504 24.923 18.0575C25.423 18.2646 25.9589 18.3711 26.5 18.3711C27.5929 18.3711 28.6411 17.937 29.4139 17.1642C30.1867 16.3914 30.6209 15.3432 30.6209 14.2503C30.6209 13.1573 30.1867 12.1092 29.4139 11.3364C28.6411 10.5636 27.5929 10.1294 26.5 10.1294Z" fill="#160B32" />
                        <path d="M35.7812 12.1875C34.9607 12.1875 34.1738 12.5134 33.5936 13.0936C33.0134 13.6738 32.6875 14.4607 32.6875 15.2812C32.6875 16.1018 33.0134 16.8887 33.5936 17.4689C34.1738 18.0491 34.9607 18.375 35.7812 18.375C36.6018 18.375 37.3887 18.0491 37.9689 17.4689C38.5491 16.8887 38.875 16.1018 38.875 15.2812C38.875 14.4607 38.5491 13.6738 37.9689 13.0936C37.3887 12.5134 36.6018 12.1875 35.7812 12.1875Z" fill="#160B32" />
                        <path d="M17.2188 12.1875C16.3982 12.1875 15.6113 12.5134 15.0311 13.0936C14.4509 13.6738 14.125 14.4607 14.125 15.2812C14.125 16.1018 14.4509 16.8887 15.0311 17.4689C15.6113 18.0491 16.3982 18.375 17.2188 18.375C18.0393 18.375 18.8262 18.0491 19.4064 17.4689C19.9866 16.8887 20.3125 16.1018 20.3125 15.2812C20.3125 14.4607 19.9866 13.6738 19.4064 13.0936C18.8262 12.5134 18.0393 12.1875 17.2188 12.1875Z" fill="#160B32" />
                        <path d="M20.3125 22.4856C20.3163 21.9411 20.5353 21.4202 20.9217 21.0365C21.3081 20.6528 21.8305 20.4375 22.375 20.4375H30.625C31.172 20.4375 31.6966 20.6548 32.0834 21.0416C32.4702 21.4284 32.6875 21.953 32.6875 22.5V28.6875C32.6874 29.3366 32.5858 29.9817 32.3864 30.5994C31.9297 31.9993 30.9889 33.1904 29.733 33.959C28.4771 34.7277 26.9883 35.0234 25.534 34.7931C24.0797 34.5628 22.7551 33.8216 21.7981 32.7025C20.8411 31.5835 20.3144 30.1599 20.3125 28.6875V22.5V22.4856Z" fill="#160B32" />
                        <path d="M18.25 22.5C18.25 21.7472 18.4501 21.0439 18.8028 20.4375H14.125C13.578 20.4375 13.0534 20.6548 12.6666 21.0416C12.2798 21.4284 12.0625 21.953 12.0625 22.5V27.6562C12.0622 28.5004 12.2692 29.3318 12.6653 30.0773C13.0614 30.8228 13.6345 31.4596 14.3342 31.9319C15.034 32.4042 15.8389 32.6974 16.6785 32.7858C17.518 32.8743 18.3665 32.7553 19.1493 32.4392C18.5556 31.2771 18.2474 29.9903 18.25 28.6854V22.5Z" fill="#160B32" />
                        <path d="M34.7502 22.5V28.6875C34.7502 30.0384 34.4264 31.3131 33.851 32.4392C34.6337 32.7553 35.4822 32.8743 36.3217 32.7858C37.1613 32.6974 37.9662 32.4042 38.666 31.9319C39.3657 31.4596 39.9388 30.8228 40.3349 30.0773C40.731 29.3318 40.938 28.5004 40.9377 27.6562V22.5C40.9377 21.953 40.7204 21.4284 40.3336 21.0416C39.9468 20.6548 39.4222 20.4375 38.8752 20.4375H34.1975C34.5481 21.0439 34.7502 21.7472 34.7502 22.5Z" fill="#160B32" />
                        <defs>
                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                MY ORGANISATION
                <div className='w-full float-left pl-16'>
                    <div className="bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 pb-5 mb-10 lg:flex lg:content-end">
                        <div className="w-full lg:w-4/6 float-left">
                            <div className="w-full lg:w-3/6 float-left md:px-4 md:pr-12 pr-12 relative">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4 md:pr-12 pr-12 relative">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4 md:pr-12 pr-12 relative">
                                <InputRect />
                            </div>
                            <div className="w-full lg:w-3/6 float-left md:px-4 md:pr-12 pr-12 relative">
                                <InputRect />
                            </div>
                        </div>
                        <div className="w-full lg:w-4/12 float-left md:px-4 lg:flex lg:flex-col-reverse items-end lg:mt-0 mt-5">
                            <ButtonRect />
                        </div>
                        <div className='clear-both'></div>
                    </div>
                </div>
            </div>
        </>
    );
}

/** Add member form popup component */
const AddMemberPop = ({ fetchMembers, roles, SetModalShow }) => {
    const organisation_id = localStorage.getItem('org_id');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [processing, setProcessing] = useState(false);

    /** Add new member to organisation */
    const SaveMember = async (form) => {
        setProcessing(true);
        const res = await OrgService.SaveMemberInfoAPI(organisation_id, form);

        if (res.response) {
            setProcessing(false);
            fetchMembers();
            SetModalShow(false);
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(SaveMember)}>
                <div className="w-full float-left clear-both p-4 pt-1 text-left">
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Member Name</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("first_name", {
                                    required: "required",
                                    maxLength: {
                                        value: 50,
                                        message: "First name can't be more than 50 alphabets."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="Lesedi"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.first_name && errors.first_name.type === "required" && 'Please enter first name.'}
                                {errors.first_name && errors.first_name.type !== "required" && errors.first_name.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Member Surname</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("last_name", {
                                    required: "required",
                                    maxLength: {
                                        value: 50,
                                        message: "Last name can't be more than 50 alphabets."
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="Phandiane"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.last_name && errors.last_name.type === "required" && 'Please enter last name.'}
                                {errors.last_name && errors.last_name.type !== "required" && errors.last_name.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-10"></div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Member Role</label>
                        <div className="float-right w-full md:w-3/5">
                            <select
                                {...register("role_id", { required: "required" })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                id="role"
                            >
                                {
                                    roles &&
                                    roles.map((role, indexr) =>
                                        <option key={indexr} value={role._id}>{role.name}</option>
                                    )
                                }
                            </select>
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.role_id && errors.role_id.type === "required" && 'Please select role.'}
                                {errors.role_id && errors.role_id.type !== "required" && errors.role_id.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left mb-2">
                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">E-mail</label>
                        <div className="float-right w-full md:w-3/5">
                            <input
                                {...register("email", {
                                    required: "required",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Entered value does not match email format"
                                    }
                                })}
                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                type="text"
                                placeholder="lesedi@bayport.co.za"
                            />
                            <span className="text-red-500 text-sm font-Averta">
                                {errors.email && errors.email.type === "required" && 'Please mebmer email address.'}
                                {errors.email && errors.email.type !== "required" && errors.email.message}
                            </span>
                        </div>
                    </div>
                    <div className="w-full float-left">
                        <button disabled={processing} className='float-right bg-lightperot hover:bg-perot text-white text-sm leading-6 p-1 px-10 rounded-full'>
                            {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Save'}
                        </button>
                    </div>
                </div>
                <div className='clear-both'></div>
            </form>
        </>
    );
}

/** Add member form popup component */
const ViewMember = ({ member_id }) => {
    const [member, setMember] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchMember();

        return () => {
        }
    }, []);

    /** Fetch single member */
    const fetchMember = async () => {
        const res = await OrgService.FetchOrgMemberAPI(member_id);

        if (res.response) {
            setLoading(false);
            setMember(res.data);
        } else {
            setLoading(false);
            setMember(null);
        }
    }

    return (
        loading
            ?
            <><i className="fa-solid fa-spinner fa-spin"></i> Loading...</>
            :
            <>
                <div className="w-full float-left clear-both p-4 pt-1 text-left">
                    <div className="w-full float-left">
                        <label className="float-left leading-10 w-full md:w-1/5 font-Averta_Semibold text-[13px] text-blue">Name: </label>
                        <div className="float-right w-full md:w-4/5 mt-2">
                            {member?.full_name ?? 'N/A'}
                        </div>
                    </div>
                    <div className="w-full float-left">
                        <label className="float-left leading-10 w-full md:w-1/5 font-Averta_Semibold text-[13px] text-blue">Email: </label>
                        <div className="float-right w-full md:w-4/5 mt-2">
                            {member?.email ?? 'N/A'}
                        </div>
                    </div>
                    <div className="w-full float-left">
                        <label className="float-left leading-10 w-full md:w-1/5 font-Averta_Semibold text-[13px] text-blue">Mobile: </label>
                        <div className="float-right w-full md:w-4/5 mt-2">
                            {member?.mobile ?? 'N/A'}
                        </div>
                    </div>
                    <div className="w-full float-left">
                        <label className="float-left leading-10 w-full md:w-1/5 font-Averta_Semibold text-[13px] text-blue">Reporing to: </label>
                        <div className="float-right w-full md:w-4/5 mt-2">
                            {member?.reporting_manager !== '' ? member?.reporting_manager?.full_name : 'N/A'}
                        </div>
                    </div>
                    <div className="w-full float-left">
                        <label className="float-left leading-10 w-full md:w-1/5 font-Averta_Semibold text-[13px] text-blue">Role: </label>
                        <div className="float-right w-full md:w-4/5 mt-2">
                            {member?.roles[0]?.name ?? 'N/A'}
                        </div>
                    </div>
                </div>
                <div className='clear-both'></div>
            </>
    );
}