import { EyeButton } from 'UIs/Buttons';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'modules/fragments';
import { useState } from 'react';
import * as NegotitationService from 'Services/negotitation-deals.service';
import { TRLoader } from 'UIs/SkeletonLoader';
import fns from 'helpers/functions';
import { Pagination } from 'Plugins';

const MyNegotiations = () => {
    const [requests, setRequests] = useState(null);
    const [fetching, setFetching] = useState(true);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fns.CHECK_PERMISSION(["B5"], true);
        fetchRequests();

        return () => {
        }
    }, []);

    const fetchRequests = async (page = 1) => {
        setFetching(true);
        const res = await NegotitationService.FetchRequestsAPI(page, sizePerPage);

        if (res.response) {
            setFetching(false);
            setRequests(res.data?.negotiation_requests?.data);
            setTotalCount(res.data?.negotiation_requests?.totalSize);
        } else {
            setFetching(false);
            setRequests(null);
            setTotalCount(0);
        }
    }

    const ListTitle = () => {
        return (
            <>
                <div className='inline-block align-middle mr-3'>
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_15_6)">
                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                        </g>
                        <path d="M17 22V14H14C13.2044 14 12.4413 14.3161 11.8787 14.8787C11.3161 15.4413 11 16.2044 11 17V35C11.0015 35.1857 11.0547 35.3674 11.1536 35.5245C11.2525 35.6817 11.3932 35.8083 11.56 35.89C11.7207 35.9631 11.8978 35.9923 12.0735 35.9747C12.2491 35.9572 12.417 35.8934 12.56 35.79L17.71 32H31.15C31.529 32.0112 31.9063 31.9445 32.2585 31.8039C32.6106 31.6634 32.9302 31.4521 33.1974 31.183C33.4646 30.914 33.6736 30.5929 33.8117 30.2398C33.9498 29.8867 34.0139 29.5089 34 29.13V28H23C21.4087 28 19.8826 27.3679 18.7574 26.2426C17.6321 25.1174 17 23.5913 17 22Z" fill="#160B32" />
                        <path d="M40 7H23C22.2044 7 21.4413 7.31607 20.8787 7.87868C20.3161 8.44129 20 9.20435 20 10V22C20 22.7956 20.3161 23.5587 20.8787 24.1213C21.4413 24.6839 22.2044 25 23 25H36.55L41.33 28.71C41.4721 28.8151 41.6395 28.8806 41.8152 28.8999C41.9908 28.9192 42.1685 28.8917 42.33 28.82C42.5002 28.739 42.644 28.6115 42.7448 28.4522C42.8457 28.293 42.8995 28.1085 42.9 27.92V10C42.9004 9.22138 42.5981 8.47309 42.057 7.91327C41.5158 7.35345 40.7782 7.02595 40 7Z" fill="#160B32" />
                        <defs>
                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                MY NEGOTIATION REQUESTS
            </>
        );
    }

    const trClass = `text-xs px-4 py-3 text-center font-Averta whitespace-nowrap xl:min-w-fit`;
    const thtrClass = `text-xs font-Averta px-4 py-4 text-center`;

    return (
        <>
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <Breadcrumb
                        page={'NEGOTIATION REQUESTS'}
                        desc={' '}
                        contact={null}
                    />

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>

                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <ListTitle />

                                        <div className='w-full float-left pl-16'>
                                            <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                                <div className="flex flex-col">
                                                    <div className="overflow-x-auto">
                                                        <div className="py-2 inline-block min-w-full">
                                                            <div className="overflow-x-auto pb-2 text-center">
                                                                {
                                                                    fetching
                                                                        ?
                                                                        <TRLoader count={10} />
                                                                        :
                                                                        (
                                                                            (requests !== null && typeof requests !== 'undefined' && requests.length > 0)
                                                                                ?
                                                                                <table className="min-w-full text-black mb-5">
                                                                                    <thead className="">
                                                                                        <tr>
                                                                                            <th scope="col" className={thtrClass}>Ticket</th>
                                                                                            <th scope="col" className={thtrClass}>Requested</th>
                                                                                            <th scope="col" className={thtrClass}>Institution</th>
                                                                                            <th scope="col" className={thtrClass}>Outstanding Balance</th>
                                                                                            <th scope="col" className={thtrClass}>Code Bracket</th>
                                                                                            <th scope="col" className={thtrClass}>Account Type</th>
                                                                                            <th scope="col" className={thtrClass}>Settlement Value</th>
                                                                                            <th scope="col" className={thtrClass}>Ref</th>
                                                                                            <th scope="col" className={thtrClass}>Settlement Party</th>
                                                                                            <th scope="col" className={thtrClass}>Status</th>
                                                                                            <th scope="col" className={thtrClass}>View Request</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className=''>
                                                                                        {
                                                                                            requests.map((request, index) =>
                                                                                                <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                                    <td className={`${trClass} min-w-[120px]`}>{fns.ZERO_PAD(request?.ticket, 6)}</td>
                                                                                                    <td className={`${trClass} min-w-[120px]`}>{request?.created_at}</td>
                                                                                                    <td className={`${trClass} min-w-[160px]`}>{request?.institution_name}</td>
                                                                                                    <td className={`${trClass} min-w-[160px]`}>{request?._outstanding_balance_text}</td>
                                                                                                    <td className={`${trClass} min-w-[160px]`}>{request?.cd_bracket}</td>
                                                                                                    <td className={`${trClass} min-w-[120px]`}>{request?._account_type_text}</td>
                                                                                                    <td className={`${trClass} font-bold text-${(request?.status === 0 ? '[#EB5757]' : '')}${(request?.status === 1 || request?.status === 2 ? '[#FFCC00]' : '')}${(request?.status === 3 ? '[#1EC365]' : '')} min-w-[160px] uppercase`}>{request?.status === 1 || request?.status === 2 ? 'Awaiting' : request?._settlement_value_text}</td>
                                                                                                    <td className={`${trClass} min-w-[120px]`}></td>
                                                                                                    <td className={`${trClass} text-[#818181] min-w-[160px]`}>{request?.request_by}</td>
                                                                                                    <td className={`${trClass} font-bold text-${(request?.status === 0 ? '[#EB5757]' : '')}${(request?.status === 1 || request?.status === 2 ? '[#FFCC00]' : '')}${(request?.status === 3 ? '[#1EC365]' : '')} min-w-[100px] uppercase`}>{request?._status_text}</td>
                                                                                                    <td className={`${trClass} min-w-[120px]`}>
                                                                                                        <Link to={`/negotiation-details/${request?._id}`}><EyeButton /></Link>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                        {/* <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                                            <td className="text-xs px-4 py-3 text-left font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                                000099
                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                                08/03/21
                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                                African Bank
                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                                R33 506, 00
                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                                                AB03
                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                                Credit Card
                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit text-[#FFCC00]">
                                                                                                AWAITING
                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">

                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit text-[#818181]">
                                                                                                Bayport
                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit text-[#FFCC00]">
                                                                                                IN NEGOTIATION
                                                                                            </td>
                                                                                            <td className="text-xs px-4 py-3 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                                <Link to={`/negotiation-details/:_id`}><EyeButton /></Link>
                                                                                            </td>
                                                                                        </tr> */}
                                                                                    </tbody>
                                                                                </table>
                                                                                :
                                                                                <p className=''>
                                                                                    No deals requested.
                                                                                </p>
                                                                        )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    total_count > sizePerPage
                                                    &&
                                                    <div className="float-left w-full">
                                                        <div className="text-center float-left w-full">
                                                            <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchRequests(data.selected + 1) }} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>

                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    )
}

export default MyNegotiations