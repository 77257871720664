import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TRLoader } from 'UIs/SkeletonLoader';
import { Pagination } from 'Plugins';
import fns from 'helpers/functions';
import * as OrgService from 'Services/organisation.service';

const EventLogs = () => {
    const { _id } = useParams();
    const [fetching, setFetching] = useState(true);
    const [logs, setLogs] = useState(null);
    const sizePerPage = 10;
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fns.CHECK_PERMISSION(["C3"], true);
        fetchLogs();

        return () => {
        }
    }, []);

    /** Fetch logs */
    const fetchLogs = async (page = 1) => {
        setFetching(true);
        const res = await OrgService.FetchOrgLogsAPI(_id, page, sizePerPage);

        if (res.response) {
            setFetching(false);
            setLogs(res.data.data);
            setTotalCount(res.data.totalSize);
        } else {
            setFetching(false);
            setLogs(null);
            setTotalCount(0);
        }
    }

    return (
        <>
            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                <div className='inline-block align-middle mr-3'>
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_15_6)">
                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                        </g>
                        <g clipPath="url(#clip0_15_6)">
                            <path d="M35.0903 11.2425L35.3232 11.0097C35.4984 10.8346 35.7064 10.6957 35.9353 10.6009C36.1642 10.5062 36.4096 10.4574 36.6573 10.4575C36.905 10.4576 37.1503 10.5065 37.3792 10.6013C37.6081 10.6962 37.816 10.8352 37.9911 11.0105C38.1662 11.1857 38.3051 11.3937 38.3999 11.6226C38.4946 11.8515 38.5434 12.0969 38.5433 12.3446C38.5432 12.5924 38.4943 12.8377 38.3995 13.0665C38.3046 13.2954 38.1656 13.5033 37.9903 13.6784L37.7575 13.9097C38.1558 14.3563 38.3681 14.9384 38.351 15.5366C38.3338 16.1348 38.0885 16.7038 37.6653 17.1269L21.5841 33.2097C21.4836 33.3097 21.3577 33.3804 21.22 33.4144L14.97 34.9769C14.8392 35.0094 14.7022 35.0076 14.5724 34.9716C14.4425 34.9355 14.3241 34.8665 14.2288 34.7712C14.1335 34.6759 14.0645 34.5575 14.0284 34.4277C13.9924 34.2978 13.9906 34.1608 14.0232 34.03L15.5857 27.78C15.6198 27.6429 15.6906 27.5176 15.7903 27.4175L30.856 12.3519C30.7053 12.2458 30.522 12.1964 30.3384 12.2125C30.1549 12.2285 29.9829 12.3088 29.8528 12.4394L24.7091 17.5847C24.6364 17.6573 24.5502 17.7149 24.4553 17.7543C24.3604 17.7936 24.2587 17.8138 24.156 17.8138C24.0532 17.8138 23.9515 17.7936 23.8566 17.7543C23.7617 17.7149 23.6755 17.6573 23.6028 17.5847C23.5302 17.512 23.4726 17.4258 23.4333 17.3309C23.394 17.236 23.3737 17.1343 23.3737 17.0316C23.3737 16.9288 23.394 16.8271 23.4333 16.7322C23.4726 16.6373 23.5302 16.5511 23.6028 16.4784L28.7497 11.3347C29.1731 10.9113 29.7425 10.666 30.3411 10.6491C30.9396 10.6323 31.5219 10.8451 31.9685 11.2441C32.3978 10.8604 32.9533 10.6481 33.5291 10.6479C34.1049 10.6476 34.6606 10.8592 35.0903 11.2425Z" fill="#160B32" />
                        </g>
                        <defs>
                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                            </filter>
                            <clipPath id="clip0_15_6">
                                <rect width="25" height="25" fill="white" transform="translate(14 10)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                Event Logs
                <div className='w-full float-left pl-16'>
                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-x-auto pb-4 xl:pb-0">
                                        {
                                            fetching
                                                ?
                                                <TRLoader count={10} />
                                                :
                                                logs && logs.length > 0
                                                    ?
                                                    <>
                                                        <table className="min-w-full text-black mb-5">
                                                            <thead className="">
                                                                <tr>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Event: </th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">By Whom?</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Ago:</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Timestamp:</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className=''>
                                                                {
                                                                    logs.map((log, index) =>
                                                                        <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                            <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                {log?.description}
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                {log?.by_whom}
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                {fns.GET_HOURS_DAYS(log?.created_at)}
                                                                            </td>
                                                                            <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit">
                                                                                {log?.created_at}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </>
                                                    :
                                                    <p>No logs found.</p>
                                        }

                                        {
                                            total_count > sizePerPage
                                                ?
                                                <div className="float-left w-full">
                                                    <div className="text-center float-left w-full">
                                                        <Pagination totalCount={total_count} perPageRecord={sizePerPage} handlePageClick={(data) => { fetchLogs(data.selected + 1) }} />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventLogs