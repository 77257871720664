/**
 * Returns JSON object with access token
 * @returns JSON object containing access token
 */
export default function authHeader() {
    const user = localStorage.getItem('_token');

    if (user && typeof user !== 'undefined') {
        return { 'x-access-token': user };              // for Node.js Express back-end
        // return { Authorization: 'Bearer ' + user };  // for Spring Boot back-end
    } else {
        return {};
    }
}
