import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { t } from 'i18next';
import fns from 'helpers/functions';
import authContext from 'contexts/auth/authContext';
import * as AuthService from 'Services/auth.service';

const Login = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const user = fns.LOGGED_USER();
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        localStorage.removeItem('loginfo');

        if (cntxAuth.isLogged) {
            if (user.is_concierge) {
                navigate('/concierge');
            } else {
                navigate('/dashboard');
            }
        }
    }, []);

    /** Login form submit */
    const doLogin = async (form) => {
        setProcessing(true);
        const res = await AuthService.LoginAPI(form.email, form.password);

        if (res.response) {
            setProcessing(false);
            localStorage.setItem('loginfo', JSON.stringify(form));

            fns.TOAST_SUC(res.data.message);
            navigate('/login/verify-otp');
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-24 w-full float-left font-Averta text-center md:flex md:items-center">
                <div className="lg:container mx-auto px-5">
                    <div className='max-w-lg m-auto'>
                        <div>
                            <h2 className='text-blue font-Averta_Bold text-3xl leading-10 pb-5'>{t('word-login')}</h2>
                            <p className='text-lg text-blue font-Averta pb-5'>{t('welcome-settlehub')}<br />{t('login-info')}</p>
                            <form onSubmit={handleSubmit(doLogin)}>
                                <div className='w-full float-left'>
                                    <div className="w-full float-left mb-2 p-1">
                                        <div className="m-auto w-10/12 relative">
                                            <input {...register("email", {
                                                required: "required",
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: "Entered value does not match email format"
                                                }
                                            })} type="text" autoComplete="off" id="floating_outlined1" className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer" placeholder={t('word-username')} />
                                            <label htmlFor="floating_outlined1" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1 absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t('word-username')}</label>
                                        </div>
                                        <span className="text-red-500 text-sm font-Averta">
                                            {errors.email && errors.email.type === "required" && 'Please enter username'}
                                            {errors.email && errors.email.type !== "required" && errors.email.message}
                                        </span>
                                    </div>
                                    <div className="w-full float-left mb-2 p-1">
                                        <div className="m-auto w-10/12 relative">
                                            <input {...register("password", {
                                                required: "required",
                                                minLength: {
                                                    value: 8,
                                                    message: "Password can not be less than 8 characters."
                                                }
                                            })} type="password" autoComplete="off" id="floating_outlined2" className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer" placeholder="**********" />
                                            <label htmlFor="floating_outlined2" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1  absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t('word-password')}</label>
                                        </div>
                                        <span className="text-red-500 text-sm font-Averta">
                                            {errors.password && errors.password.type === "required" && 'Please enter password.'}
                                            {errors.password && errors.password.type !== "required" && errors.password.message}
                                        </span>
                                    </div>
                                    <div className="clear-both"></div>
                                    <button disabled={processing} type='submit' className="inline-block m-auto mt-16 mb-10 bg-blue hover:bg-burgandy py-4 px-12 rounded-xl text-burgandy hover:text-blue font-bold text-sm

">
                                        {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : t('word-login')}
                                    </button>
                                    <div className="clear-both"></div>
                                    <Link to={`/forgot-password`} className='clear-both text-xs font text-blue hover:text-burgandy cursor-pointer'>{t('i-forgot-password')}</Link>
                                    <div className="clear-both"></div>
                                </div>
                            </form>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </section>
        </>
    )
};

export default Login;