import React, { useEffect, useState } from 'react';
import constants from 'helpers/constants';
import fns from 'helpers/functions';
import { useTranslation } from 'react-i18next';
import * as TestService from 'Services/test.service';
import { Dropdown } from 'flowbite-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const TestPage = () => {
    const { t, i18n } = useTranslation();
    const [post, setPost] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const rolesData = await TestService.TestGetAPI();
        setPost(rolesData);
    }

    const MakeToast = () => {
        fns.TOAST_BOX(`text-purple-500`, 'Success!!!', 'Testing the message from the component.');
    }

    const CallConfirm = () => {
        fns.CONFIRM_BOX('Confirm to submit', 'Are you sure to do this.', 'Confirm', 'Cancel', () => alert("rrrrrrrr"), '');
    }

    console.log(fns.GET_HOURS_DAYS("2023-01-18 19:18:00"));

    return (
        <div>
            <div className="text-center">
                <br />
                <h1 className="text-3xl font-bold text-blue/30 font-Averta-Bold">
                    {process.env.REACT_APP_API_URL} - {t('Thanks.1')} {constants.MAX_CHAR_50} - {fns.IS_UNDEFINED('varss') ? 'TRUE' : 'FALSE'}!!!
                </h1>

                <br />
                <button onClick={(e) => { i18n.changeLanguage('en'); }}>English</button> | <button onClick={(e) => { i18n.changeLanguage('za'); }}>Affrican</button>
                <hr />

                {
                    post
                        ?
                        <>
                            <h1>{post.title}</h1>
                            <p>{post.body}</p>
                        </>
                        : null
                }

                <button className={'text-red-500'} onClick={MakeToast}>Make Toast</button>

                <hr />


                <Dropdown label={<p className='text-red-500'>Test</p>} inline={true} arrowIcon={true}>
                    <Dropdown.Item>Dashboard</Dropdown.Item>
                    <Dropdown.Item>Settings</Dropdown.Item>
                    <Dropdown.Item>Earnings</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>Separated link</Dropdown.Item>
                </Dropdown>

                <hr />

                <button onClick={CallConfirm}>CONFIRM BOX</button>
            </div>
        </div>
    );
};

export default TestPage;