import { BlockLoader } from 'components/interfaces/SkeletonLoader';
import React from 'react';
import Pipeline from './Pipeline';

const Details = ({ fetching, deal }) => {

    return (
        fetching
            ?
            <PageSkeleton />
            :
            <div className='w-full float-left font-Averta'>
                <div className="sm:w-11/12 w-full mx-auto px-5">
                    <div className='float-left w-full'>
                        <div className='float-left w-full'>
                            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                <div className='inline-block align-middle mr-3'>
                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_15_6)">
                                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                        </g>
                                        <path d="M27 9C19.2688 9 13 15.2688 13 23C13 30.7313 19.2688 37 27 37C34.7313 37 41 30.7313 41 23C41 15.2688 34.7313 9 27 9ZM27.6969 29.7875L27.7031 30.7781C27.7031 30.9156 27.5906 31.0312 27.4531 31.0312H26.5656C26.4281 31.0312 26.3156 30.9188 26.3156 30.7812V29.8C23.5406 29.5938 22.2344 28.0125 22.0938 26.2938C22.0813 26.1469 22.1969 26.0219 22.3438 26.0219H23.7875C23.9094 26.0219 24.0156 26.1094 24.0344 26.2281C24.1938 27.2188 24.9656 27.9594 26.35 28.1437V23.6844L25.5781 23.4875C23.9438 23.0969 22.3875 22.0781 22.3875 19.9656C22.3875 17.6875 24.1187 16.4625 26.3313 16.2469V15.2156C26.3313 15.0781 26.4438 14.9656 26.5813 14.9656H27.4594C27.5969 14.9656 27.7094 15.0781 27.7094 15.2156V16.2375C29.85 16.4531 31.4562 17.7031 31.6437 19.65C31.6594 19.7969 31.5437 19.925 31.3937 19.925H29.9906C29.8656 19.925 29.7594 19.8313 29.7437 19.7094C29.6187 18.7969 28.8875 18.0531 27.6969 17.8906V22.0875L28.4906 22.2719C30.5156 22.7719 31.8937 23.7406 31.8937 25.9094C31.8937 28.2625 30.1437 29.575 27.6969 29.7875ZM24.3313 19.8219C24.3313 20.6156 24.8219 21.2312 25.8781 21.6125C26.025 21.6719 26.1719 21.7188 26.3469 21.7688V17.8938C25.1938 18.0406 24.3313 18.6875 24.3313 19.8219ZM27.9719 24.0469C27.8844 24.0281 27.7969 24.0062 27.6969 23.9781V28.1562C29.0281 28.0375 29.9469 27.3062 29.9469 26.0812C29.9469 25.1219 29.45 24.4969 27.9719 24.0469Z" fill="#160B32" />
                                        <defs>
                                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </div>
                                SETTLEMENT SUMMARY
                                <div className='w-full float-left pl-16'>
                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                        <div className="flex flex-col">
                                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                    <div className="overflow-x-auto pb-4 xl:pb-0">
                                                        <table className="min-w-full text-black">
                                                            <thead className="">
                                                                <tr>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Institution</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Outstanding Balance</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">SH Ref</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Account Type</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Value</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Party</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Details</th>
                                                                    <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className=''>
                                                                <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 border-b border-gray">
                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                        {deal?.settlement_summary?.institution}
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#EB5757]">
                                                                        {deal?.settlement_summary?._outstanding_balance_text}
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                        {deal?.settlement_summary?.sh_ref ?? 'N/A'}
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                        {deal?.settlement_summary?._account_type_text}
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit text-[#1EC465]">
                                                                        {deal?.settlement_summary?._settlement_value_text}
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[120px] xl:min-w-fit text-[#818181]">
                                                                        {deal?.settlement_summary?.settlement_party}
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit text-[#818181]">
                                                                        Valid
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[100px] xl:min-w-fit">
                                                                        <span className='inline-block bg-[#1EC465] w-[30px] h-[30px] align-middle rounded-full mr-3'></span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                <div className='inline-block align-middle mr-3'>
                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_15_6)">
                                            <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                        </g>
                                        <g clipPath="url(#clip0_15_6)">
                                            <path d="M27 8.3479C25.2273 8.3479 23.8423 8.996 22.8452 10.2922C21.848 11.5884 21.3494 13.2453 21.3494 15.263C21.3371 17.7698 22.1496 19.7569 23.7869 21.2243C23.9962 21.4199 24.0701 21.6706 24.0085 21.9763L23.5838 22.8567C23.4484 23.1502 23.2483 23.3795 22.9837 23.5446C22.719 23.7096 22.1619 23.945 21.3125 24.2507C21.2756 24.263 20.4969 24.5137 18.9766 25.0028C17.4562 25.4919 16.6468 25.7609 16.5483 25.8099C15.5142 26.2378 14.8371 26.6842 14.517 27.1488C14.1723 27.9192 14 29.8696 14 33.0001H40C40 29.8696 39.8277 27.9192 39.483 27.1488C39.1629 26.6842 38.4858 26.2378 37.4517 25.8099C37.3532 25.7609 36.5438 25.4919 35.0234 25.0028C33.5031 24.5137 32.7244 24.263 32.6875 24.2507C31.8381 23.945 31.281 23.7096 31.0163 23.5446C30.7517 23.3795 30.5516 23.1502 30.4162 22.8567L29.9915 21.9763C29.9299 21.6706 30.0038 21.4199 30.2131 21.2243C31.8504 19.7569 32.6629 17.7698 32.6506 15.263C32.6506 13.2453 32.152 11.5884 31.1548 10.2922C30.1577 8.996 28.7727 8.3479 27 8.3479Z" fill="#160B32" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dy="4" />
                                                <feGaussianBlur stdDeviation="2" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                            </filter>
                                            <clipPath id="clip0_15_6">
                                                <rect width="26" height="27" fill="white" transform="translate(14 6)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                CREATED BY
                                <div className='w-full float-left pl-16'>
                                    <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                        <div className="flex flex-col">
                                            <div className="overflow-x-auto">
                                                <div className="py-2 inline-block min-w-full">
                                                    <div className="overflow-x-auto pb-4 xl:pb-0">
                                                        <table className="min-w-full text-black">
                                                            <tbody className=''>
                                                                <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2 text-black">
                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap w-[60px]">
                                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9 1.6521C7.77273 1.6521 6.81392 2.10817 6.12358 3.02031C5.43324 3.93244 5.08807 5.09843 5.08807 6.51827C5.07955 8.28231 5.64205 9.68063 6.77557 10.7132C6.92045 10.8509 6.97159 11.0273 6.92898 11.2425L6.63494 11.862C6.54119 12.0685 6.4027 12.2299 6.21946 12.3461C6.03622 12.4622 5.65057 12.6279 5.0625 12.843C5.03693 12.8516 4.49787 13.028 3.44531 13.3722C2.39276 13.7164 1.83239 13.9057 1.7642 13.9401C1.0483 14.2413 0.579545 14.5554 0.357955 14.8824C0.119318 15.4245 0 16.797 0 18.9999H18C18 16.797 17.8807 15.4245 17.642 14.8824C17.4205 14.5554 16.9517 14.2413 16.2358 13.9401C16.1676 13.9057 15.6072 13.7164 14.5547 13.3722C13.5021 13.028 12.9631 12.8516 12.9375 12.843C12.3494 12.6279 11.9638 12.4622 11.7805 12.3461C11.5973 12.2299 11.4588 12.0685 11.3651 11.862L11.071 11.2425C11.0284 11.0273 11.0795 10.8509 11.2244 10.7132C12.358 9.68063 12.9205 8.28231 12.9119 6.51827C12.9119 5.09843 12.5668 3.93244 11.8764 3.02031C11.1861 2.10817 10.2273 1.6521 9 1.6521Z" fill="#160B32" />
                                                                        </svg>
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                        {deal?.created_by?.name}
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                        {deal?.created_by?.institution_name}
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[160px] xl:min-w-fit">
                                                                        {deal?.created_by?.phone}
                                                                    </td>
                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                                        {deal?.created_by?.email}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Pipeline summary={deal?.tracking_summary} />
                        </div>
                    </div>
                    <div className='clear-both'></div>
                </div>
            </div>
    )
}

export default Details

const PageSkeleton = () => {
    return (
        <div className='w-full float-left font-Averta'>
            <div className="sm:w-11/12 w-full mx-auto px-5">
                <div className='float-left w-full'>
                    <div className='float-left w-full'>
                        <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                            <div className='inline-block align-middle mr-3'>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_15_6)">
                                        <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                    </g>
                                    <path d="M27 9C19.2688 9 13 15.2688 13 23C13 30.7313 19.2688 37 27 37C34.7313 37 41 30.7313 41 23C41 15.2688 34.7313 9 27 9ZM27.6969 29.7875L27.7031 30.7781C27.7031 30.9156 27.5906 31.0312 27.4531 31.0312H26.5656C26.4281 31.0312 26.3156 30.9188 26.3156 30.7812V29.8C23.5406 29.5938 22.2344 28.0125 22.0938 26.2938C22.0813 26.1469 22.1969 26.0219 22.3438 26.0219H23.7875C23.9094 26.0219 24.0156 26.1094 24.0344 26.2281C24.1938 27.2188 24.9656 27.9594 26.35 28.1437V23.6844L25.5781 23.4875C23.9438 23.0969 22.3875 22.0781 22.3875 19.9656C22.3875 17.6875 24.1187 16.4625 26.3313 16.2469V15.2156C26.3313 15.0781 26.4438 14.9656 26.5813 14.9656H27.4594C27.5969 14.9656 27.7094 15.0781 27.7094 15.2156V16.2375C29.85 16.4531 31.4562 17.7031 31.6437 19.65C31.6594 19.7969 31.5437 19.925 31.3937 19.925H29.9906C29.8656 19.925 29.7594 19.8313 29.7437 19.7094C29.6187 18.7969 28.8875 18.0531 27.6969 17.8906V22.0875L28.4906 22.2719C30.5156 22.7719 31.8937 23.7406 31.8937 25.9094C31.8937 28.2625 30.1437 29.575 27.6969 29.7875ZM24.3313 19.8219C24.3313 20.6156 24.8219 21.2312 25.8781 21.6125C26.025 21.6719 26.1719 21.7188 26.3469 21.7688V17.8938C25.1938 18.0406 24.3313 18.6875 24.3313 19.8219ZM27.9719 24.0469C27.8844 24.0281 27.7969 24.0062 27.6969 23.9781V28.1562C29.0281 28.0375 29.9469 27.3062 29.9469 26.0812C29.9469 25.1219 29.45 24.4969 27.9719 24.0469Z" fill="#160B32" />
                                    <defs>
                                        <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                            SETTLEMENT SUMMARY
                            <BlockLoader height={'200px'} />
                        </div>

                        <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                            <div className='inline-block align-middle mr-3'>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_15_6)">
                                        <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                    </g>
                                    <g clipPath="url(#clip0_15_6)">
                                        <path d="M27 8.3479C25.2273 8.3479 23.8423 8.996 22.8452 10.2922C21.848 11.5884 21.3494 13.2453 21.3494 15.263C21.3371 17.7698 22.1496 19.7569 23.7869 21.2243C23.9962 21.4199 24.0701 21.6706 24.0085 21.9763L23.5838 22.8567C23.4484 23.1502 23.2483 23.3795 22.9837 23.5446C22.719 23.7096 22.1619 23.945 21.3125 24.2507C21.2756 24.263 20.4969 24.5137 18.9766 25.0028C17.4562 25.4919 16.6468 25.7609 16.5483 25.8099C15.5142 26.2378 14.8371 26.6842 14.517 27.1488C14.1723 27.9192 14 29.8696 14 33.0001H40C40 29.8696 39.8277 27.9192 39.483 27.1488C39.1629 26.6842 38.4858 26.2378 37.4517 25.8099C37.3532 25.7609 36.5438 25.4919 35.0234 25.0028C33.5031 24.5137 32.7244 24.263 32.6875 24.2507C31.8381 23.945 31.281 23.7096 31.0163 23.5446C30.7517 23.3795 30.5516 23.1502 30.4162 22.8567L29.9915 21.9763C29.9299 21.6706 30.0038 21.4199 30.2131 21.2243C31.8504 19.7569 32.6629 17.7698 32.6506 15.263C32.6506 13.2453 32.152 11.5884 31.1548 10.2922C30.1577 8.996 28.7727 8.3479 27 8.3479Z" fill="#160B32" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                        </filter>
                                        <clipPath id="clip0_15_6">
                                            <rect width="26" height="27" fill="white" transform="translate(14 6)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            CREATED BY
                            <BlockLoader height={'100px'} />
                        </div>

                        <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                            <div className='inline-block align-middle mr-3'>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_15_6)">
                                        <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                    </g>
                                    <path d="M17.781 19.5938C17.781 17.2814 18.6996 15.0637 20.3347 13.4287C21.9698 11.7936 24.1874 10.875 26.4998 10.875C28.8121 10.875 31.0298 11.7936 32.6649 13.4287C34.2999 15.0637 35.2185 17.2814 35.2185 19.5938V23.2828L37.0863 27.9521C37.1452 28.0991 37.1672 28.2583 37.1503 28.4157C37.1334 28.5732 37.0782 28.7241 36.9895 28.8553C36.9008 28.9864 36.7813 29.0938 36.6414 29.1681C36.5015 29.2424 36.3456 29.2812 36.1873 29.2812H16.8123C16.6539 29.2812 16.498 29.2424 16.3582 29.1681C16.2183 29.0938 16.0988 28.9864 16.0101 28.8553C15.9214 28.7241 15.8661 28.5732 15.8493 28.4157C15.8324 28.2583 15.8544 28.0991 15.9133 27.9521L17.781 23.2828V19.5938Z" fill="#160B32" />
                                    <path d="M22.7446 31.2188C23.1728 32.8947 24.686 34.125 26.4995 34.125C28.313 34.125 29.8262 32.8947 30.2544 31.2188H22.7446Z" fill="#160B32" />
                                    <defs>
                                        <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                            TRACKING SUMMARY
                            <BlockLoader height={'200px'} />
                        </div>

                    </div>
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    );
}