import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'modules/fragments';
import fns from 'helpers/functions';
import * as OrgService from 'Services/organisation.service';
import * as RoleService from 'Services/role.service';
import UserDetails from './UserDetails';
import MembersSection from './MembersSection';
import RolesSection from './RolesSection';
import InvoiceSection from './InvoiceSection';

const MyProfile = () => {
    const [members, setMembers] = useState(null);
    const [roles, setRoles] = useState(null);
    const [loadingMembers, setLoadingMembers] = useState(true);
    const [loadingRoles, setLoadingRoles] = useState(true);
    const user = fns.LOGGED_USER();

    useEffect(() => {
        fetchMembers();
        fetchRoles();

        return () => {
        }
    }, []);

    /** Fetch Members of organisation */
    const fetchMembers = async () => {
        const res = await OrgService.FetchMemberListAPI();

        if (res.response) {
            setMembers(res.data.data);
            setLoadingMembers(false);
        } else {
            setMembers(null);
            setLoadingMembers(false);
        }
    }

    /** Fetch all the roles for logged organisation */
    const fetchRoles = async () => {
        const res = await RoleService.FetchRolesAPI();

        if (res.response) {
            setRoles(res.data);
            setLoadingRoles(false);
        } else {
            setRoles(null);
            setLoadingRoles(false);
        }
    }

    return (
        <>
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <Breadcrumb page={'Profile Management'} contact={null} />

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>

                                    <UserDetails members={members} />
                                    {
                                        fns.CHECK_PERMISSION(["D1", "D2"])
                                        &&
                                        <MembersSection loadingMembers={loadingMembers} members={members} setMembers={setMembers} fetchMembers={fetchMembers} roles={roles} />
                                    }

                                    {
                                        (user?.is_organization || user?.is_concierge)
                                            ?
                                            <RolesSection loadingRoles={loadingRoles} roles={roles} fetchRoles={fetchRoles} />
                                            :
                                            null
                                    }

                                    {
                                        user?.is_organization
                                            ?
                                            <InvoiceSection />
                                            :
                                            null
                                    }


                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>

                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    )
}

export default MyProfile