import axios from "axios";
import authHeader from './auth-header';

const END_POINT = `${process.env.REACT_APP_API_URL}notifications/`;

/**
 * Fetch counts of notification
 * @returns 
 */
export const FetchNotificationCountsAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}count`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchNotificationCountsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch dashboard notifications.
 * @returns 
 */
export const FetchDashboardNotificationAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}dashboard`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchDashboardNotificationAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch all notifications.
 * @returns 
 */
export const FetchAllNotificationAPI = async (type = 'today', page = 1, sizePerPage = 10) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}list?type=${type}&page=${page}&sizePerPage=${sizePerPage}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchAllNotificationAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch a notifications.
 * @returns 
 */
export const FetchANotificationAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}${_id}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchANotificationAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}