import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LineLoader, SmLineLoader, ButtonLoader } from 'UIs/SkeletonLoader';
import fns from 'helpers/functions';
import * as OrgsService from 'Services/organisation.service';
import { GoBack } from 'components/interfaces/Buttons';

const BusinessDetails = () => {
    const { _id } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();

    const [processing, setProcessing] = useState(false);
    const [organisation, setOrganisation] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fns.CHECK_PERMISSION(["C1"], true);

        if (_id !== '' && typeof _id !== 'undefined') {
            fetchOrgDetails(_id);
        } else {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    }, []);

    /** Fetch organisation details with of given ID */
    const fetchOrgDetails = async () => {
        const res = await OrgsService.FetchOrgDetailsAPI(_id);

        if (res.response) {
            setOrganisation(res.data);
            setLoading(false);
        } else {
            setOrganisation(null);
        }
    }

    /** Save Organisation business details */
    const saveBusinessDetails = async (form) => {
        if (form.is_ncp === false && form.is_ocp === false) {
            setError("is_ncp", { type: "focus", message: 'Please select at least one type.' }, { shouldFocus: true });
            return false;
        }

        var nForm = form;
        if (_id !== '' || typeof _id !== 'undefined') {
            nForm = { ...nForm, 'id': _id };
        }

        setProcessing(true);
        const res = await OrgsService.SaveOrgsStep1API(nForm);

        if (res.response) {
            setProcessing(false);
            fns.TOAST_SUC(res.data.message);
            navigate(`/concierge/organisation/${res.data._id}/bank-details`);
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        !loading
            ?
            <>
                <section className="w-full float-left font-Averta md:flex md:items-center">
                    <div className="lg:container mx-auto px-5">
                        <GoBack onClick={() => { navigate(`/concierge/organisations`); }} name={'Go back'} />
                    </div>
                </section>
                <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-5 w-full float-left font-Averta md:flex md:items-top">
                    <div className="lg:container mx-auto px-5">
                        <ul className="md:max-w-sm w-full float-left text-base font-Averta pb-6 pr-5">
                            <li className="leading-9 pb-3 active">
                                <span className="hover:text-burgandy cursor-pointer">
                                    <span className="rounded-full border-2 w-6 h-6 leading-5 mr-2 border-burgandy text-center inline-block">1</span> Business Details
                                </span>
                            </li>
                            <li className="leading-9 pb-3 text-gray">
                                <span className='cursor-pointer'>
                                    <span className="rounded-full border-2 w-6 h-6 leading-5 mr-2 border-transparent text-center inline-block">2</span> Bank Details
                                </span>
                            </li>
                            <li className="leading-9 pb-3 text-gray">
                                <span className='cursor-pointer'>
                                    <span className="rounded-full border-2 w-6 h-6 leading-5 mr-2 border-transparent text-center inline-block">3</span> Primary Contact
                                </span>
                            </li>
                        </ul>
                        <div className="md:w-2/5 w-full float-left">
                            <div className="bg-blue w-full float-left p-3 px-4">
                                <h3 className="float-left text-white text-xs font-Averta_Semibold">Business Details</h3>
                                {/* <span className="float-right link text-xs text-white hover:text-gray cursor-pointer italic"><i className="fa-light fa-pen mr-2"></i> Edit</span> */}
                            </div>
                            <form onSubmit={handleSubmit(saveBusinessDetails)}>
                                <div className="w-full float-left clear-both p-4 pt-1">
                                    <div className="w-full float-left mb-2">
                                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Institution</label>
                                        <div className="float-right w-full md:w-3/5">
                                            <input
                                                {...register("institution_name", {
                                                    required: "required",
                                                    maxLength: {
                                                        value: 150,
                                                        message: "Institution name can't be more than 150 alphabets."
                                                    }
                                                })}
                                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                type="text"
                                                placeholder="e.g. ABC (Pty) Ltd"
                                                defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.institution_name}
                                            />
                                            <span className="text-red-500 text-sm font-Averta">
                                                {errors.institution_name && errors.institution_name.type === "required" && 'Please enter institution name.'}
                                                {errors.institution_name && errors.institution_name.type !== "required" && errors.institution_name.message}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-full float-left mb-2">
                                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Address</label>
                                        <div className="float-right w-full md:w-3/5">
                                            <>
                                                <input
                                                    {...register("address_street", {
                                                        /*required: "required",*/
                                                        maxLength: {
                                                            value: 150,
                                                            message: "Street address can't be more than 150 alphabets."
                                                        }
                                                    })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    type="text"
                                                    placeholder="Street address"
                                                    defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.address_street}
                                                />
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.address_street && errors.address_street.type === "required" && 'Please enter street address.'}
                                                    {errors.address_street && errors.address_street.type !== "required" && errors.address_street.message}
                                                </span>
                                            </>
                                            <>
                                                <input
                                                    {...register("address_premise", {
                                                        /*required: "required",*/
                                                        maxLength: {
                                                            value: 50,
                                                            message: "Suburb can't be more than 50 alphabets."
                                                        }
                                                    })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    type="text"
                                                    placeholder="Suburb"
                                                    defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.address_premise}
                                                />
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.address_premise && errors.address_premise.type === "required" && 'Please enter suburb.'}
                                                    {errors.address_premise && errors.address_premise.type !== "required" && errors.address_premise.message}
                                                </span>
                                            </>
                                            <>
                                                <input
                                                    {...register("address_city", {
                                                        /*required: "required",*/
                                                        maxLength: {
                                                            value: 100,
                                                            message: "City name can't be more than 100 alphabets."
                                                        }
                                                    })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    type="text"
                                                    placeholder="City name"
                                                    defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.address_city}
                                                />
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.address_city && errors.address_city.type === "required" && 'Please enter city name.'}
                                                    {errors.address_city && errors.address_city.type !== "required" && errors.address_city.message}
                                                </span>
                                            </>
                                            <>
                                                <input
                                                    {...register("address_state", {
                                                        /*required: "required",*/
                                                        maxLength: {
                                                            value: 100,
                                                            message: "Province name can't be more than 100 alphabets."
                                                        }
                                                    })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    type="text"
                                                    placeholder="Province"
                                                    defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.address_state}
                                                />
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.address_state && errors.address_state.type === "required" && 'Please enter province name.'}
                                                    {errors.address_state && errors.address_state.type !== "required" && errors.address_state.message}
                                                </span>
                                            </>
                                            <>
                                                <input
                                                    {...register("address_country", {
                                                        /*required: "required",*/
                                                        maxLength: {
                                                            value: 100,
                                                            message: "Country name can't be more than 100 alphabets."
                                                        }
                                                    })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    type="text"
                                                    placeholder="Country"
                                                    defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.address_country}
                                                />
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.address_country && errors.address_country.type === "required" && 'Please enter country name.'}
                                                    {errors.address_country && errors.address_country.type !== "required" && errors.address_country.message}
                                                </span>
                                            </>
                                            <>
                                                <input
                                                    {...register("address_pin_code", {
                                                        /*required: "required",*/
                                                        pattern: {
                                                            value: /^[0-9]{4}$/,
                                                            message: "Entered value does not match postal code format."
                                                        }
                                                    })}
                                                    className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                    type="text"
                                                    placeholder="Postal code"
                                                    defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.address_pin_code}
                                                />
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.address_pin_code && errors.address_pin_code.type === "required" && 'Please enter state name.'}
                                                    {errors.address_pin_code && errors.address_pin_code.type !== "required" && errors.address_pin_code.message}
                                                </span>
                                            </>
                                        </div>
                                    </div>
                                    <div className="w-full float-left mb-3">
                                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Capability</label>
                                        <div className="float-right w-full md:w-3/5 pt-2">
                                            <div className="flex items-center mb-4">
                                                <input
                                                    {...register("is_ncp")}
                                                    id="default-checkbox"
                                                    type="checkbox"
                                                    value="1"
                                                    onChange={(e) => { clearErrors('account_type'); }}
                                                    className="bg-white rounded-lg focus:outline-none"
                                                    defaultChecked={(!fns.IS_UNDEFINED(organisation) && organisation?.is_ncp === 1)}
                                                />
                                                <label htmlFor="default-checkbox" className="ml-2 text-xs cursor-pointer">Search Account</label>
                                            </div>
                                            <div className="flex items-center">
                                                <input
                                                    {...register("is_ocp")}
                                                    id="checked-checkbox"
                                                    type="checkbox"
                                                    value="1"
                                                    onChange={(e) => { clearErrors('account_type'); }}
                                                    className="bg-white rounded-lg focus:outline-none"
                                                    defaultChecked={(!fns.IS_UNDEFINED(organisation) && organisation?.is_ocp === 1)}
                                                />
                                                <label htmlFor="checked-checkbox" className="ml-2 text-xs cursor-pointer">Upload Account</label>
                                            </div>
                                            <span className="text-red-500 text-sm font-Averta">
                                                {errors.is_ncp && errors.is_ncp.message}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-full float-left mb-2">
                                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Reg No.</label>
                                        <div className="float-right w-full md:w-3/5">
                                            <input
                                                {...register("registration_date", {
                                                    /*required: "required",*/
                                                    pattern: {
                                                        value: /^\d{4}\/\d{6}\/\d{2}$/,
                                                        message: "Input value doesn’t match registration number format (e.g. 2008/010333/05)"
                                                    }
                                                })}
                                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                type="text"
                                                placeholder="e.g. 2008/010333/05"
                                                defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.registration_date}
                                            />
                                            <span className="text-red-500 text-sm font-Averta">
                                                {errors.registration_date && errors.registration_date.type === "required" && 'Please enter registration number.'}
                                                {errors.registration_date && errors.registration_date.type !== "required" && errors.registration_date.message}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-full float-left mb-2">
                                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">FSP No.</label>
                                        <div className="float-right w-full md:w-3/5">
                                            <input
                                                {...register("fsp_number", {
                                                    /*required: "required",*/
                                                    minLength: {
                                                        value: 3,
                                                        message: "FSP number must contain a maximum of 15 digits (E.g. 49846)"
                                                    },
                                                    maxLength: {
                                                        value: 15,
                                                        message: "FSP number must contain a maximum of 15 digits (E.g. 49846)"
                                                    },
                                                    pattern: {
                                                        value: /^[0-9\.]{3,15}$/,
                                                        message: "FSP number must be numeric value."
                                                    }
                                                })}
                                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                type="number"
                                                placeholder="000000000000000"
                                                defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.fsp_number}
                                            />
                                            <span className="text-red-500 text-sm font-Averta">
                                                {errors.fsp_number && errors.fsp_number.type === "required" && 'Please enter FSP number.'}
                                                {errors.fsp_number && errors.fsp_number.type !== "required" && errors.fsp_number.message}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-full float-left mb-2">
                                        <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">NCR No.:</label>
                                        <div className="float-right w-full md:w-3/5">
                                            <input
                                                {...register("reg_credit_provider", {
                                                    /*required: "required",*/
                                                    minLength: {
                                                        value: 3,
                                                        message: "NCR number must contain a maximum of 15 digits (E.g. 49846)"
                                                    },
                                                    maxLength: {
                                                        value: 15,
                                                        message: "NCR number must contain a maximum of 15 digits (E.g. 49846)"
                                                    },
                                                    pattern: {
                                                        value: /^[0-9\.]{3,15}$/,
                                                        message: "NCR number must be numeric value."
                                                    }
                                                })}
                                                className="w-full placeholder:text-blue/50 focus:border-b-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-1 py-3 border-b border-gray"
                                                type="number"
                                                placeholder="000000000000000"
                                                defaultValue={!fns.IS_UNDEFINED(organisation) && organisation?.reg_credit_provider}
                                            />
                                            <span className="text-red-500 text-sm font-Averta">
                                                {errors.reg_credit_provider && errors.reg_credit_provider.type === "required" && 'Please enter Register NCR number.'}
                                                {errors.reg_credit_provider && errors.reg_credit_provider.type !== "required" && errors.reg_credit_provider.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-blue w-full float-left p-3 px-4 mt-4">
                                    <h3 className="float-left text-white text-xs font-Averta_Semibold">Declarations</h3>
                                </div>
                                <div className="w-full clear-both p-4 pt-1">
                                    <ul className="float-left w-full py-5">
                                        <li className="float-left w-full leading-6 text-xs text-blue">SettleHub Agent Terms and Conditions <Link target="_blank" to={`/page/terms-conditions`} className="float-right hover:text-blue italic leading-6 font-Averta text-xs text-[#86A7A0] cursor-pointer">Read more here</Link></li>
                                        <li className="float-left w-full leading-6 text-xs text-blue">POPIA regulations <Link target="_blank" to={`/page/terms-conditions`} className="float-right hover:text-blue italic leading-6 font-Averta text-xs text-[#86A7A0] cursor-pointer">Read more here</Link></li>
                                        <li className="float-left w-full leading-6 text-xs text-blue">Indemnity Form <Link target="_blank" to={`/page/terms-conditions`} className="float-right hover:text-blue italic leading-6 font-Averta text-xs text-[#86A7A0] cursor-pointer">Read more here</Link></li>
                                        <li className="float-left w-full leading-6 text-xs text-blue">SettleHub/Counterparty Agreement <Link target="_blank" to={`/page/terms-conditions`} className="float-right hover:text-blue italic leading-6 font-Averta text-xs text-[#86A7A0] cursor-pointer">Read more here</Link></li>
                                    </ul>
                                    <div className="w-full clear-both">
                                        <input
                                            {...register("declarations", { required: "required" })}
                                            className="mr-2 mt-1 float-left"
                                            type="checkbox"
                                            id="declarations"
                                            defaultChecked={organisation}
                                        />
                                        <label htmlFor="declarations" className="float-left leading-5 italic font-Averta_Bold text-xs text-blue cursor-pointer">I have ensured the regulations above are in place</label>
                                        <br />
                                        <span className="text-red-500 text-sm font-Averta">
                                            {errors.declarations && errors.declarations.type === "required" && 'You must ensure the above declarations are in place to proceed.'}
                                        </span>
                                    </div>
                                </div>
                                <button disabled={processing} className="float-right xl:-mr-80 mt-5 bg-blue py-3 px-12 rounded-xl text-burgandy hover:text-blue  font-Averta_Semibold text-sm hover:bg-burgandy">
                                    {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Next'}
                                </button>
                            </form>
                            <div className="clear-both"></div>
                        </div>
                        <div className="clear-both"></div>
                    </div>
                </section>
            </>
            :
            <PageSkeleton />
    )
}

export default BusinessDetails;

const PageSkeleton = () => {
    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-24 w-full float-left font-Averta md:flex md:items-center">
                <div className="lg:container mx-auto px-5">
                    <ul className="md:max-w-sm w-full float-left text-base font-Averta pb-6 pr-5">
                        <LineLoader width={'50%'} count={3} />
                    </ul>
                    <div className="md:w-2/5 w-full float-left">
                        <div className="bg-blue w-full float-left p-3 px-4">
                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Business Details</h3>
                        </div>

                        <div className="w-full float-left clear-both p-4 pt-1">
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Institution</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Address</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader count={4} />
                                </div>
                            </div>
                            <div className="w-full float-left mb-3">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Capability</label>
                                <div className="float-right w-full md:w-3/5 pt-2">
                                    <SmLineLoader count={2} width={'50%'} />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">Reg No.</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">FSP No.</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2">
                                <label className="float-left leading-10 w-full md:w-2/5 font-Averta_Semibold text-[13px] text-blue">NCR No.:</label>
                                <div className="float-right w-full md:w-3/5">
                                    <LineLoader />
                                </div>
                            </div>
                        </div>
                        <div className="bg-blue w-full float-left p-3 px-4 mt-4">
                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Declarations</h3>
                        </div>
                        <div className="w-full clear-both p-4 pt-1">
                            <ul className="float-left w-full py-5">
                                <LineLoader />
                            </ul>
                            <div className="w-full clear-both">
                                <LineLoader width={'65%'} />
                            </div>
                        </div>
                        <ButtonLoader />
                        <div className="clear-both"></div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </section>
        </>
    );
}