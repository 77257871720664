import axios from "axios";
import fns from "helpers/functions";
import authHeader from './auth-header';

const END_POINT = `${process.env.REACT_APP_API_URL}organizations/`;
const END_POINT2 = `${process.env.REACT_APP_API_URL}concierge/organizations/`;

/**
 * Fetch all the organisation names list
 * @returns 
 */
export const FetchInstitutionsAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}list`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchInstitutionsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch all the organisation list
 * @param {string} search_text Search any organisation
 * @returns 
 */
export const FetchOrgsAPI = async (search_text = '', page = 1, sizePerPage = 10) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}list?page=${page}&sizePerPage=${sizePerPage}`, {
        search_text: search_text
    }, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchOrgsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch single organisation details
 * @param {string} _id organisation id
 * @returns 
 */
export const FetchOrgDetailsAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}${_id}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchOrgDetailsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Save organisation details for the step 1.
 * @param {FormData} form Form data
 * @returns 
 */
export const SaveOrgsStep1API = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}submit_step1`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`SaveOrgsStep1API: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Save organisation bank details.
 * @param {FormData} form Form data
 * @returns 
 */
export const SaveOrgsStep2API = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}submit-bank-detail`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`SaveOrgsStep2API: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Save organisation details for the step 2.
 * @param {FormData} form Form data
 * @returns 
 */
export const SaveOrgsStep3API = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}submit_step2`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`SaveOrgsStep3API: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch oraganisation basic details for self registration.
 * @param {string} _token registration token
 * @returns
 */
export const FetchOrgRegAPI = async (_token) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}setup/${_token}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`FetchOrgRegAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Save personal details for user
 * @param {string} _token Token string for saveing the details
 * @param {FormData} form Form containing data
 * @returns 
 */
export const SavePersonalInfoAPI = async (_token, form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}setup/${_token}`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`SavePersonalInfoAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            // window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Update personal details for user
 * @param {string} _token Token string for saveing the details
 * @param {FormData} form Form containing data
 * @returns 
 */
export const UpdatePersonalInfoAPI = async (_token, form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}setup/update/${_token}`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`UpdatePersonalInfoAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch all members for logged organisation.
 * @returns 
 */
export const FetchMembersAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}members_list`, {
        "search_text": ""
    }, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchMembersAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch logged users details.
 * @returns 
 */
export const FetchUserInfoAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}member/get-profile`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`FetchUserInfoAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Save Member details
 * @param {string} _token Token string for saveing the details
 * @param {FormData} form Form containing data
 * @returns 
 */
export const SaveMemberInfoAPI = async (_token, form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}${_token}/members/create`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`SaveMemberInfoAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Delete member from the organisation
 * @param {string} _id id of the user
 * @returns 
 */
export const DeleteMemberAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.delete(`${END_POINT}member/${_id}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`DeleteMemberAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Update logged members profile details
 * @param {FormData} form Contains user details
 * @returns 
 */
export const UpdateProfileAPI = async (form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.put(`${END_POINT}member/update-profile`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`UpdateProfileAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }

            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch logged members details
 * @returns 
 */
export const FetchMemberListAPI = async () => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}my_members`, {
        "search_text": "",
        "is_type": "list"
    }, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchMemberListAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }

            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch all members of organisation.
 * @param {string} _id organisation id.
 * @param {string} search_text Search any organisation.
 * @param {number} page page number.
 * @param {number} sizePerPage records per page.
 * @returns 
 */
export const FetchOrgMembersAPI = async (_id, search_text = '', page = 1, sizePerPage = 10) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT}${_id}/members?page=${page}&sizePerPage=${sizePerPage}`, {
        search_text: search_text
    }, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchOrgMembersAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch member's details.
 * @param {string} _id Search any organisation.
 * @returns 
 */
export const FetchOrgMemberAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}members/${_id}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchOrgMemberAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Update member's details.
 * @param {string} _id organisation id.
 * @param {FormData} form form body.
 * @returns 
 */
export const UpdateMemberInfoAPI = async (_id, form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.put(`${END_POINT}members/${_id}`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`UpdateMemberInfoAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch member's details.
 * @param {string} _id Search any organisation.
 * @returns 
 */
export const FetchOrgInvoicesAPI = async (_id, page = 1, sizePerPage = 10) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT2}${_id}/enquiry-fees/invoices?page=${page}&sizePerPage=${sizePerPage}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchOrgInvoicesAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Create invoice for organisation.
 * @param {string} _id organisation id.
 * @param {FormData} form form body.
 * @returns 
 */
export const CreateInvoiceAPI = async (_id, form) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT2}${_id}/enquiry-fees/create_invoice`, form, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`CreateInvoiceAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch all invoices.
 * @param {string} _id Search any organisation.
 * @returns 
 */
export const FetchInvoicesAPI = async (_id, page = 1, sizePerPage = 10) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${process.env.REACT_APP_API_URL}enquiry-fees/my_invoices?page=${page}&sizePerPage=${sizePerPage}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchInvoicesAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Update invoice status.
 * @param {string} _id invoice id.
 * @returns 
 */
export const UpdateInvoiceStatusAPI = async (_id) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${END_POINT2}enquiry-fees/invoices/${_id}/update-invoice-status`, { status: 'paid' }, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data;
    }).catch((error) => {
        console.log(`UpdateInvoiceStatusAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Fetch organisations logs.
 * @param {string} _id Search any organisation.
 * @returns 
 */
export const FetchOrgLogsAPI = async (_id, page = 1, sizePerPage = 10) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.get(`${END_POINT}${_id}/event-log?page=${page}&sizePerPage=${sizePerPage}`, {
        headers: authHeader()
    }).then((response) => {
        rResp = true;
        rData = response.data.data;
    }).catch((error) => {
        console.log(`FetchOrgLogsAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}

/**
 * Download attachment file.
 * @type POST Request
 * @param {_id} form Document ID.
 * @returns 
 */
export const DownloadInvoicesAPI = async (name) => {
    var rData = null;
    var rCode = 200;
    var rResp = false;
    var rErro = null;

    const data = await axios.post(`${process.env.REACT_APP_API_URL}enquiry-fees/invoices/download`, {}, {
        headers: authHeader(),
        responseType: 'blob',
    }).then((response) => {
        fns.DOWNLOAD_RESPONSE(response.data, name);
    }).catch((error) => {
        console.log(`DownloadInvoicesAPI: ${error.message}`);
        rResp = false;

        if (error?.response?.status === 401) {
            window.location.href = "/logout";
        }

        if (error?.response?.status === 404) {
            rErro = { 'page-not-found': ['Request URL not found.'] };
            rCode = error?.response?.status;
        } else {
            if (typeof error?.response?.data?.data === 'string') {
                rErro = { 'validation-error': [error?.response?.data?.data] };
            } else {
                rErro = error?.response?.data?.data?.errors;
            }
            rCode = error?.response?.data?.statusCode;
        }
    });

    return { data: rData, code: rCode, response: rResp, errors: rErro };
}