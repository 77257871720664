import constants from 'helpers/constants';
import React from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import QuickMenus from '../QuickMenus';
import { useState } from 'react';
import * as UploadService from 'Services/client-upload.service';
import * as ManageOffer from 'Services/manage-offers.service';
import { TRLoader, ButtonCLoader } from 'UIs/SkeletonLoader';
import fns from 'helpers/functions';
import { useForm } from 'react-hook-form';

const EditOffer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlState = location.state
    const [accountType, setAccountType] = useState(null);
    const [validityList, setValidityList] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [offer, setOffer] = useState(null);
    const [processing, setProcessing] = useState(false);
    const user = fns.LOGGED_USER();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const today = new Date().getFullYear() + '-' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '-' + ("0" + new Date().getDate()).slice(-2);

    const [form, setForm] = useState({
        created_date: "",
        account_number: "",
        outstanding_balance: "",
        cd_bracket: "",
        account_type: "",
        settlement_value: "",
        offer_validity: "",
    });

    useEffect(() => {
        if (!urlState) {
            navigate(`/`);
        }

        fetchAccountType();
        fetchValidityList();
        fetchOffer();

        return () => {
        }
    }, []);

    const fetchAccountType = async () => {
        const res = await UploadService.FetchAccountTypesAPI();

        if (res.response) {
            var newObj = [];
            for (let atype in res.data) {
                newObj.push({ id: atype, value: res.data[atype] });
            }

            setAccountType(newObj);
        } else {
            setAccountType(null);
        }
    }

    const fetchValidityList = async () => {
        const res = await UploadService.FetchValidityListAPI();

        if (res.response) {
            var newObj = [];
            for (let atype in res.data) {
                newObj.push({ id: atype, value: res.data[atype] });
            }

            setValidityList(newObj);
        } else {
            setValidityList(null);
        }
    }

    const fetchOffer = async () => {
        setFetching(true);
        const res = await ManageOffer.FetchLiveOfferAPI(urlState._id);

        if (res.response) {
            setFetching(false);
            setOffer(res.data);
        } else {
            setFetching(false);
            setOffer(null);
        }
    }

    const SaveForm = async (formx) => {
        setProcessing(true);
        const res = await UploadService.UpdateOfferAPI(urlState._id, formx);

        if (res.response) {
            setProcessing(false);
            navigate(`/my-offers-on-market`);
        } else {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        urlState && offer
            ?
            <>
                <section className="lg:min-h-[calc(100vh-80px-64px)] w-full float-left font-Averta m-0">
                    <div className='w-full float-left relative min-h-[730px]'>
                        <img className='w-full float-left h-full absolute object-cover' src={constants.UPLOAD_BANNER_SEC} alt="" />
                        <div className=''>
                            <h3 className='absolute top-5 left-0 float-left bg-blue text-white text-right text-xs p-5 w-80'>CONFIRM & UPLOAD OFFER</h3>
                            <h4 className='absolute top-[76px] left-24 rounded-b-lg float-left bg-white text-blue text-right text-xs p-5 py-2 leading-9'>
                                <svg className='inline-block mr-3 align-middle' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.8902 4H7.83025C7.33512 4.01311 6.86517 4.22107 6.52251 4.57871C6.17986 4.93635 5.99217 5.41477 6.00025 5.91V30.09C5.99217 30.5852 6.17986 31.0636 6.52251 31.4213C6.86517 31.7789 7.33512 31.9869 7.83025 32H28.1702C28.6654 31.9869 29.1353 31.7789 29.478 31.4213C29.8206 31.0636 30.0083 30.5852 30.0002 30.09V11.92L21.8902 4ZM21.0002 13V5.84L28.3002 13H21.0002Z" fill="#160B32" />
                                </svg>
                                UPDATE LOAN OFFER
                            </h4>
                            <div className="w-12/12 sm:w-11/12 mx-auto px-5 py-5 absolute inset-x-0 top-3/4 -translate-y-3/4 sm:top-2/4 sm:-translate-y-2/4">
                                <div className="m-auto w-full">
                                    <form onSubmit={handleSubmit(SaveForm)}>
                                        <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-7">
                                            <div className="flex flex-col">
                                                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div className="py-2 pt-10 inline-block min-w-full sm:px-6 lg:px-8 relative">
                                                        {/* <h3 className='absolute font-Averta top-0 left-0 float-left bg-blue text-white text-right text-xs p-5 py-1'>LOAN OFFER #: 000258</h3> */}
                                                        <div className="overflow-x-auto pb-14">
                                                            <table className="min-w-full">
                                                                <thead className="">
                                                                    <tr>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">ID Number</th>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Created</th>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Institution</th>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Outstanding Balance</th>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Code Bracket</th>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Account Number</th>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Account Type</th>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Settlement Value</th>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Ref.</th>
                                                                        <th scope="col" className="text-xs font-Averta px-6 py-4 text-left">Valid For</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className=''>
                                                                    {
                                                                        fetching
                                                                            ?
                                                                            <tr>
                                                                                <td colSpan={9}><TRLoader /></td>
                                                                            </tr>
                                                                            :
                                                                            <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-10">
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap text-[#160B32] font-bold !min-w-[150px] xl:min-w-fit">
                                                                                    {offer?.id_number}
                                                                                </td>
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap !min-w-[150px] xl:min-w-fit align-top">
                                                                                    <input
                                                                                        {...register("created_date", {
                                                                                            required: "required",
                                                                                            max: {
                                                                                                value: today,
                                                                                                message: "Please select past date or current date."
                                                                                            }
                                                                                        })}
                                                                                        defaultValue={typeof offer?.created_date !== 'undefined' && offer?.created_date !== '' ? offer?.created_date.split("/").reverse().join("-") : today}
                                                                                        id="created_date"
                                                                                        type="date"
                                                                                        className="w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-gray"
                                                                                        placeholder="Created date"
                                                                                    />
                                                                                    {
                                                                                        errors.created_date
                                                                                            ?
                                                                                            <>
                                                                                                <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                                                    {errors.created_date.type === "required" && 'Please enter created date.'}
                                                                                                    {errors.created_date && errors.created_date.type !== "required" && errors.created_date.message}
                                                                                                </span>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap !min-w-[150px] xl:min-w-fit">
                                                                                    {user.organization_name}
                                                                                </td>
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap !min-w-[150px] xl:min-w-fit align-top">
                                                                                    <input
                                                                                        {...register("outstanding_balance", {
                                                                                            required: "required",
                                                                                            pattern: {
                                                                                                value: /^\d+$/,
                                                                                                message: "Outstanding balance must be numberic value."
                                                                                            },
                                                                                            min: {
                                                                                                value: 1,
                                                                                                message: "Outstanding balance can not be less than or equal to zero."
                                                                                            }
                                                                                        })}
                                                                                        defaultValue={offer?.outstanding_balance}
                                                                                        className="w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-gray"
                                                                                        type="text"
                                                                                        placeholder="R5000000"
                                                                                    />
                                                                                    {
                                                                                        errors.outstanding_balance
                                                                                            ?
                                                                                            <>
                                                                                                <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                                                    {errors.outstanding_balance.type === "required" && 'Please enter outstanding balance.'}
                                                                                                    {errors.outstanding_balance && errors.outstanding_balance.type !== "required" && errors.outstanding_balance.message}
                                                                                                </span>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap !min-w-[150px] xl:min-w-fit align-top">
                                                                                    <input
                                                                                        {...register("cd_bracket", {
                                                                                            required: "required",
                                                                                            pattern: {
                                                                                                value: /^CD/i,
                                                                                                message: "Code bracket must contain CD in the starting."
                                                                                            }
                                                                                        })}
                                                                                        defaultValue={offer?.cd_bracket}
                                                                                        id="cd_bracket"
                                                                                        className="w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-gray"
                                                                                        type="text"
                                                                                        placeholder="Code"
                                                                                    />
                                                                                    {
                                                                                        errors.cd_bracket
                                                                                            ?
                                                                                            <>
                                                                                                <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                                                    {errors.cd_bracket.type === "required" && 'Please enter code bracket.'}
                                                                                                    {errors.cd_bracket && errors.cd_bracket.type !== "required" && errors.cd_bracket.message}
                                                                                                </span>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap !min-w-[150px] xl:min-w-fit align-top">
                                                                                    <input
                                                                                        {...register("account_number", { required: "required" })}
                                                                                        defaultValue={offer?.account_number}
                                                                                        id="account_number"
                                                                                        className="w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-gray"
                                                                                        type="text"
                                                                                        placeholder="Account Number"
                                                                                    />
                                                                                    {
                                                                                        errors.account_number
                                                                                            ?
                                                                                            <>
                                                                                                <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                                                    {errors.account_number.type === "required" && 'Please enter account number.'}
                                                                                                </span>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap !min-w-[150px] xl:min-w-fit align-top">
                                                                                    <select
                                                                                        {...register("account_type", { required: "required" })}
                                                                                        defaultValue={offer?.account_type}
                                                                                        id="account_type"
                                                                                        className="w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-gray"
                                                                                    >
                                                                                        <option value="">Select account type</option>
                                                                                        {
                                                                                            accountType !== null
                                                                                                ?
                                                                                                <>
                                                                                                    {
                                                                                                        (accountType !== null && accountType.length > 0)
                                                                                                        &&
                                                                                                        accountType.map((type, index) => {
                                                                                                            return (
                                                                                                                <option key={index} value={type.id}>{type.value}</option>
                                                                                                            );
                                                                                                        })
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </select>
                                                                                    {
                                                                                        errors.account_type
                                                                                            ?
                                                                                            <>
                                                                                                <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                                                    {errors.account_type.type === "required" && 'Please select account type.'}
                                                                                                </span>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap !min-w-[150px] xl:min-w-fit align-top">
                                                                                    <input
                                                                                        {...register("settlement_value", {
                                                                                            required: "required",
                                                                                            pattern: {
                                                                                                value: /^\d+$/,
                                                                                                message: "Settlement value must be numberic value."
                                                                                            },
                                                                                            min: {
                                                                                                value: 1,
                                                                                                message: "Settlement value can not be less than or equal to zero."
                                                                                            }
                                                                                        })}
                                                                                        defaultValue={offer?.settlement_value}
                                                                                        id="settlement_value"
                                                                                        className="w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-gray"
                                                                                        type="text"
                                                                                        placeholder="R50000"
                                                                                    />
                                                                                    {
                                                                                        errors.settlement_value
                                                                                            ?
                                                                                            <>
                                                                                                <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                                                    {errors.settlement_value.type === "required" && 'Please enter settlement value.'}
                                                                                                    {errors.settlement_value && errors.settlement_value.type !== "required" && errors.settlement_value.message}
                                                                                                </span>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap !min-w-[150px] xl:min-w-fit align-top">
                                                                                    <input
                                                                                        {...register("settlement_reference", { required: "required" })}
                                                                                        defaultValue={offer?.settlement_reference}
                                                                                        id="settlement_reference"
                                                                                        className="w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-gray"
                                                                                        type="text"
                                                                                        placeholder="Ref."
                                                                                    />
                                                                                    {
                                                                                        errors.settlement_reference
                                                                                            ?
                                                                                            <>
                                                                                                <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                                                    {errors.settlement_reference.type === "required" && 'Please enter settlement reference.'}
                                                                                                </span>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                                <td className="text-sm px-4 py-4 whitespace-nowrap !min-w-[150px] xl:min-w-fit align-top">
                                                                                    <select
                                                                                        {...register("offer_validity", { required: "required" })}
                                                                                        defaultValue={offer?.offer_validity}
                                                                                        id="offer_validity"
                                                                                        className="w-full placeholder:text-blue/50 focus:border-1 focus:border-blue focus:placeholder:text-white focus:outline-none text-sm form-input font-Averta text-blue px-3 py-3 border rounded-lg border-gray"
                                                                                    >
                                                                                        <option value="">Select validity</option>
                                                                                        {
                                                                                            validityList !== null
                                                                                                ?
                                                                                                <>
                                                                                                    {
                                                                                                        (validityList !== null && validityList.length > 0)
                                                                                                        &&
                                                                                                        validityList.map((type, index) => {
                                                                                                            return (
                                                                                                                <option key={index} value={type.id}>{type.value}</option>
                                                                                                            );
                                                                                                        })
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </select>
                                                                                    {
                                                                                        errors.offer_validity
                                                                                            ?
                                                                                            <>
                                                                                                <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                                                    {errors.offer_validity.type === "required" && 'Please select offer validity.'}
                                                                                                </span>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className='w-full md:max-w-md pb-3'>
                                                            <div className="relative float-left max-w-[45px] md:w-2/12 mr-2 md:mt-1 align-middle select-none transition duration-200 ease-in">
                                                                <input
                                                                    {...register("terms", { required: "required" })}
                                                                    id="toggle"
                                                                    type="checkbox"
                                                                    className="toggle-checkbox absolute block w-5 h-5 rounded-full bg-[#C4C4C4] border-2 border-[#F2F2F2] appearance-none cursor-pointer -mt-0.5"
                                                                />
                                                                <label htmlFor="toggle" className="toggle-label w-[45px] block overflow-hidden h-4 rounded-full bg-gray cursor-pointer"></label>
                                                                <div className='clear-both'></div>
                                                            </div>
                                                            <label htmlFor="toggle" className="cursor-pointer text-xs float-left md:w-10/12">I have read and accepted the Terms and Conditions of SettleHub & publishing an Offer on SettleHub <Link target="_blank" to={`/page/terms-conditions`}  className='font-Averta_Semibold text-blue hover:text-burgandy'>here</Link></label>
                                                            {
                                                                errors.terms
                                                                    ?
                                                                    <>
                                                                        <span className="text-red-500 text-xs font-Averta mt-1 w-full float-left">
                                                                            {errors.terms.type === "required" && 'Please agree terms & conditions.'}
                                                                        </span>
                                                                    </>
                                                                    : null
                                                            }
                                                            <div className='clear-both'></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            fetching
                                                ?
                                                <ButtonCLoader classd={'mt-8 float-right text-sm font-Averta_Semibold leading-8 px-14 mr-10 rounded-lg p-3 w-[150px]'} />
                                                :
                                                <button disabled={processing} className='mt-8 float-right bg-blue text-burgandy hover:text-blue text-sm font-Averta_Semibold py-4 px-12 mr-10 rounded-xl p-3 hover:bg-burgandy'>{processing ? <i className="fa-solid fa-spinner fa-spin"></i> : 'UPLOAD'}</button>
                                        }
                                    </form>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>

                    <QuickMenus />

                </section >
            </>
            :
            null
    )
}

export default EditOffer