import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import constants from 'helpers/constants';
import fns from 'helpers/functions';
import DashNotifications from './DashNotifications';
import * as GenService from 'Services/general.service';

const ConciergeHome = () => {
    const user = fns.LOGGED_USER();             // Logged users details
    const [counts, setCounts] = useState(null);

    useEffect(() => {
        fetchCounts();

        return () => {
        }
    }, []);

    const fetchCounts = async () => {
        const res = await GenService.FetchMenuCountsAPI();

        if (res.response) {
            setCounts(res.data);
        } else {
            setCounts(null);
        }
    }

    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] w-full float-left font-Averta m-0">
                <div className='w-full float-left relative'>
                    <img className='w-full float-left h-screen max-h-[874px] md:max-h-screen object-cover' src={constants.CONCIERGE_BANNER} alt="" />
                    <div className='absolute left-6 sm:left-10 top-2/4 -translate-y-2/4'>
                        <ul className='w-24 p-1 bg-white rounded-xl shadow-md shadow-black/25 text-center'>
                            {
                                fns.CHECK_PERMISSION(["C1", "C3"])
                                    ?
                                    <li className='py-5'>
                                        <Link to={`/concierge/organisations`} className='m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy break-words uppercase'>
                                            <svg className='m-auto mb-1' width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.5001 4.62891C17.8934 4.62891 17.2926 4.74842 16.732 4.98061C16.1714 5.21281 15.6621 5.55314 15.233 5.98218C14.804 6.41122 14.4637 6.92057 14.2315 7.48114C13.9993 8.04171 13.8798 8.64253 13.8798 9.24928C13.8798 9.85604 13.9993 10.4569 14.2315 11.0174C14.4637 11.578 14.804 12.0873 15.233 12.5164C15.6621 12.9454 16.1714 13.2858 16.732 13.518C17.2926 13.7501 17.8934 13.8697 18.5001 13.8697C19.7255 13.8697 20.9007 13.3829 21.7672 12.5164C22.6337 11.6499 23.1205 10.4747 23.1205 9.24928C23.1205 8.02388 22.6337 6.84867 21.7672 5.98218C20.9007 5.11569 19.7255 4.62891 18.5001 4.62891Z" fill="#160B32" />
                                                <path d="M28.9062 6.9375C27.9863 6.9375 27.104 7.30296 26.4535 7.95347C25.803 8.60399 25.4375 9.48628 25.4375 10.4062C25.4375 11.3262 25.803 12.2085 26.4535 12.859C27.104 13.5095 27.9863 13.875 28.9062 13.875C29.8262 13.875 30.7085 13.5095 31.359 12.859C32.0095 12.2085 32.375 11.3262 32.375 10.4062C32.375 9.48628 32.0095 8.60399 31.359 7.95347C30.7085 7.30296 29.8262 6.9375 28.9062 6.9375Z" fill="#160B32" />
                                                <path d="M8.09375 6.9375C7.17378 6.9375 6.29149 7.30296 5.64097 7.95347C4.99046 8.60399 4.625 9.48628 4.625 10.4062C4.625 11.3262 4.99046 12.2085 5.64097 12.859C6.29149 13.5095 7.17378 13.875 8.09375 13.875C9.01372 13.875 9.89601 13.5095 10.5465 12.859C11.197 12.2085 11.5625 11.3262 11.5625 10.4062C11.5625 9.48628 11.197 8.60399 10.5465 7.95347C9.89601 7.30296 9.01372 6.9375 8.09375 6.9375Z" fill="#160B32" />
                                                <path d="M11.5625 18.4838C11.5668 17.8733 11.8123 17.2893 12.2455 16.8591C12.6787 16.4289 13.2645 16.1875 13.875 16.1875H23.125C23.7383 16.1875 24.3265 16.4311 24.7602 16.8648C25.1939 17.2985 25.4375 17.8867 25.4375 18.5V25.4375C25.4373 26.1653 25.3234 26.8886 25.0999 27.5812C24.5878 29.1507 23.533 30.4862 22.1249 31.348C20.7167 32.2098 19.0475 32.5413 17.4169 32.2831C15.7863 32.0249 14.3011 31.1939 13.2282 29.9392C12.1552 28.6845 11.5646 27.0884 11.5625 25.4375V18.5V18.4838Z" fill="#160B32" />
                                                <path d="M9.25 18.5C9.25 17.6559 9.47431 16.8674 9.86975 16.1875H4.625C4.01169 16.1875 3.42349 16.4311 2.98982 16.8648C2.55614 17.2985 2.3125 17.8867 2.3125 18.5V24.2812C2.31219 25.2278 2.54427 26.1599 2.98838 26.9957C3.43249 27.8316 4.07503 28.5456 4.85958 29.0751C5.64414 29.6047 6.5467 29.9334 7.48801 30.0326C8.42931 30.1318 9.38057 29.9983 10.2582 29.6439C9.59266 28.341 9.24704 26.8983 9.25 25.4352V18.5Z" fill="#160B32" />
                                                <path d="M27.7502 18.5V25.4375C27.7502 26.9522 27.3871 28.3813 26.7419 29.6439C27.6196 29.9983 28.5709 30.1318 29.5122 30.0326C30.4535 29.9334 31.3561 29.6047 32.1406 29.0751C32.9252 28.5456 33.5677 27.8316 34.0118 26.9957C34.4559 26.1599 34.688 25.2278 34.6877 24.2812V18.5C34.6877 17.8867 34.4441 17.2985 34.0104 16.8648C33.5767 16.4311 32.9885 16.1875 32.3752 16.1875H27.1304C27.5236 16.8674 27.7502 17.6559 27.7502 18.5Z" fill="#160B32" />
                                            </svg>
                                            SettleHub Participants
                                        </Link>
                                    </li>
                                    :
                                    null
                            }
                            {
                                fns.CHECK_PERMISSION(["C2"])
                                    ?
                                    <li className='py-5'>
                                        <Link to={`/concierge/client-upload-requests`} className='m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy break-words uppercase'>
                                            <i className="fa-solid fa-file-arrow-up fa-3x w-full pb-2"></i>
                                            Client Uploads Requests
                                        </Link>
                                    </li>
                                    :
                                    null
                            }
                            {
                                fns.CHECK_PERMISSION(["C2", "C3"])
                                    ?
                                    <li className='py-5'>
                                        <Link to={`/concierge/deals-in-progress`} className='cursor-pointer m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy uppercase'>
                                            <svg className='m-auto mb-1' width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 2.375C15.7119 2.375 12.4976 3.35004 9.76365 5.17682C7.02968 7.0036 4.89882 9.60007 3.64051 12.6379C2.3822 15.6757 2.05297 19.0184 2.69445 22.2434C3.33593 25.4683 4.91931 28.4306 7.24436 30.7556C9.5694 33.0807 12.5317 34.6641 15.7566 35.3056C18.9816 35.947 22.3243 35.6178 25.3621 34.3595C28.3999 33.1012 30.9964 30.9703 32.8232 28.2364C34.65 25.5024 35.625 22.2881 35.625 19C35.62 14.5923 33.8668 10.3666 30.7501 7.2499C27.6334 4.1332 23.4077 2.38003 19 2.375ZM19 33.25C15.2207 33.25 11.5961 31.7487 8.92374 29.0763C6.25134 26.4039 4.75001 22.7793 4.75001 19C4.75001 15.2207 6.25134 11.5961 8.92374 8.92373C11.5961 6.25133 15.2207 4.75 19 4.75V19L29.0712 29.0712C27.7504 30.3967 26.1808 31.4482 24.4525 32.1653C22.7242 32.8824 20.8712 33.2511 19 33.25Z" fill="#160B32" />
                                            </svg>
                                            My deals
                                        </Link>
                                    </li>
                                    :
                                    null
                            }
                            {
                                fns.CHECK_PERMISSION(["C5"])
                                    ?
                                    <li className='py-5'>
                                        <Link to={`/concierge/negotiations`} className='cursor-pointer m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy uppercase'>
                                            <svg className='m-auto mb-1' width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.33337 22.1666V12.8333H5.83337C4.90512 12.8333 4.01488 13.202 3.3585 13.8584C2.70212 14.5148 2.33337 15.405 2.33337 16.3333V37.3333C2.33511 37.5499 2.39715 37.7618 2.51255 37.9452C2.62794 38.1286 2.79213 38.2763 2.98671 38.3716C3.17417 38.4568 3.38085 38.491 3.58576 38.4705C3.79067 38.45 3.98651 38.3756 4.15337 38.2549L10.1617 33.8333H25.8417C26.2839 33.8463 26.724 33.7685 27.1349 33.6045C27.5458 33.4406 27.9186 33.194 28.2303 32.8801C28.542 32.5662 28.786 32.1917 28.947 31.7797C29.1081 31.3677 29.1829 30.927 29.1667 30.4849V29.1666H16.3334C14.4769 29.1666 12.6964 28.4291 11.3836 27.1163C10.0709 25.8036 9.33337 24.0231 9.33337 22.1666Z" fill="#160B32" />
                                                <path d="M36.1667 4.66675H16.3334C15.4051 4.66675 14.5149 5.0355 13.8585 5.69187C13.2021 6.34825 12.8334 7.23849 12.8334 8.16675V22.1667C12.8334 23.095 13.2021 23.9852 13.8585 24.6416C14.5149 25.298 15.4051 25.6667 16.3334 25.6667H32.1417L37.7184 29.9951C37.8842 30.1177 38.0795 30.1941 38.2844 30.2166C38.4893 30.2392 38.6966 30.207 38.885 30.1234C39.0836 30.0289 39.2513 29.8801 39.369 29.6943C39.4867 29.5086 39.5494 29.2933 39.55 29.0734V8.16675C39.5505 7.25835 39.1979 6.38535 38.5665 5.73223C37.9351 5.0791 37.0746 4.69703 36.1667 4.66675Z" fill="#160B32" />
                                            </svg>
                                            My negotiations
                                        </Link>
                                    </li>
                                    :
                                    null
                            }
                            <li className='py-5'>
                                <Link className='m-auto block text-center text-xs font-Averta_Semibold text-blue hover:text-burgandy uppercase' to={`/my-profile`}>
                                    <svg className='m-auto mb-1' width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5001 4.62891C17.8934 4.62891 17.2926 4.74842 16.732 4.98061C16.1714 5.21281 15.6621 5.55314 15.233 5.98218C14.804 6.41122 14.4637 6.92057 14.2315 7.48114C13.9993 8.04171 13.8798 8.64253 13.8798 9.24928C13.8798 9.85604 13.9993 10.4569 14.2315 11.0174C14.4637 11.578 14.804 12.0873 15.233 12.5164C15.6621 12.9454 16.1714 13.2858 16.732 13.518C17.2926 13.7501 17.8934 13.8697 18.5001 13.8697C19.7255 13.8697 20.9007 13.3829 21.7672 12.5164C22.6337 11.6499 23.1205 10.4747 23.1205 9.24928C23.1205 8.02388 22.6337 6.84867 21.7672 5.98218C20.9007 5.11569 19.7255 4.62891 18.5001 4.62891Z" fill="#160B32" />
                                        <path d="M28.9062 6.9375C27.9863 6.9375 27.104 7.30296 26.4535 7.95347C25.803 8.60399 25.4375 9.48628 25.4375 10.4062C25.4375 11.3262 25.803 12.2085 26.4535 12.859C27.104 13.5095 27.9863 13.875 28.9062 13.875C29.8262 13.875 30.7085 13.5095 31.359 12.859C32.0095 12.2085 32.375 11.3262 32.375 10.4062C32.375 9.48628 32.0095 8.60399 31.359 7.95347C30.7085 7.30296 29.8262 6.9375 28.9062 6.9375Z" fill="#160B32" />
                                        <path d="M8.09375 6.9375C7.17378 6.9375 6.29149 7.30296 5.64097 7.95347C4.99046 8.60399 4.625 9.48628 4.625 10.4062C4.625 11.3262 4.99046 12.2085 5.64097 12.859C6.29149 13.5095 7.17378 13.875 8.09375 13.875C9.01372 13.875 9.89601 13.5095 10.5465 12.859C11.197 12.2085 11.5625 11.3262 11.5625 10.4062C11.5625 9.48628 11.197 8.60399 10.5465 7.95347C9.89601 7.30296 9.01372 6.9375 8.09375 6.9375Z" fill="#160B32" />
                                        <path d="M11.5625 18.4838C11.5668 17.8733 11.8123 17.2893 12.2455 16.8591C12.6787 16.4289 13.2645 16.1875 13.875 16.1875H23.125C23.7383 16.1875 24.3265 16.4311 24.7602 16.8648C25.1939 17.2985 25.4375 17.8867 25.4375 18.5V25.4375C25.4373 26.1653 25.3234 26.8886 25.0999 27.5812C24.5878 29.1507 23.533 30.4862 22.1249 31.348C20.7167 32.2098 19.0475 32.5413 17.4169 32.2831C15.7863 32.0249 14.3011 31.1939 13.2282 29.9392C12.1552 28.6845 11.5646 27.0884 11.5625 25.4375V18.5V18.4838Z" fill="#160B32" />
                                        <path d="M9.25 18.5C9.25 17.6559 9.47431 16.8674 9.86975 16.1875H4.625C4.01169 16.1875 3.42349 16.4311 2.98982 16.8648C2.55614 17.2985 2.3125 17.8867 2.3125 18.5V24.2812C2.31219 25.2278 2.54427 26.1599 2.98838 26.9957C3.43249 27.8316 4.07503 28.5456 4.85958 29.0751C5.64414 29.6047 6.5467 29.9334 7.48801 30.0326C8.42931 30.1318 9.38057 29.9983 10.2582 29.6439C9.59266 28.341 9.24704 26.8983 9.25 25.4352V18.5Z" fill="#160B32" />
                                        <path d="M27.7502 18.5V25.4375C27.7502 26.9522 27.3871 28.3813 26.7419 29.6439C27.6196 29.9983 28.5709 30.1318 29.5122 30.0326C30.4535 29.9334 31.3561 29.6047 32.1406 29.0751C32.9252 28.5456 33.5677 27.8316 34.0118 26.9957C34.4559 26.1599 34.688 25.2278 34.6877 24.2812V18.5C34.6877 17.8867 34.4441 17.2985 34.0104 16.8648C33.5767 16.4311 32.9885 16.1875 32.3752 16.1875H27.1304C27.5236 16.8674 27.7502 17.6559 27.7502 18.5Z" fill="#160B32" />
                                    </svg>
                                    My team
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="w-8/12 sm:w-10/12 lg:w-9/12 lg:mx-auto right-0 lg:right-0 px-5 absolute lg:inset-x-0 top-2/4 -translate-y-2/4">
                        <h2 className="text-white text-lg md:text-xl font-Averta_Semibold pb-5 uppercase">Hello {user.first_name}!</h2>
                        <DashNotifications />
                    </div>
                </div>

                <div className='clear-both'></div>
                <section className='bg-[#F2F2F2] w-full float-left font-Averta'>
                    <div className="lg:container mx-auto px-5 py-16">
                        <div className='float-left w-full'>
                            <ul className='float-left w-full pt-3'>
                                {
                                    fns.CHECK_PERMISSION(["C5"])
                                        ?
                                        <li className='float-left w-full py-3'>
                                            <Link to={`/concierge/negotiations`} className='cursor-pointer float-left w-full leading-8 text-xs bg-white shadow-md shadow-black/25 rounded-lg p-2 px-3 hover:text-burgandy overflow-hidden'>
                                                <div className='inline-block align-middle mr-3'>
                                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 19V11H5C4.20435 11 3.44129 11.3161 2.87868 11.8787C2.31607 12.4413 2 13.2044 2 14V32C2.00149 32.1857 2.05467 32.3674 2.15358 32.5245C2.25249 32.6817 2.39322 32.8083 2.56 32.89C2.72068 32.9631 2.89784 32.9923 3.07347 32.9747C3.24911 32.9572 3.41697 32.8934 3.56 32.79L8.71 29H22.15C22.529 29.0112 22.9063 28.9445 23.2585 28.8039C23.6106 28.6634 23.9302 28.4521 24.1974 28.183C24.4646 27.914 24.6736 27.5929 24.8117 27.2398C24.9498 26.8867 25.0139 26.5089 25 26.13V25H14C12.4087 25 10.8826 24.3679 9.75736 23.2426C8.63214 22.1174 8 20.5913 8 19Z" fill="#160B32" />
                                                        <path d="M31 4H14C13.2044 4 12.4413 4.31607 11.8787 4.87868C11.3161 5.44129 11 6.20435 11 7V19C11 19.7956 11.3161 20.5587 11.8787 21.1213C12.4413 21.6839 13.2044 22 14 22H27.55L32.33 25.71C32.4721 25.8151 32.6395 25.8806 32.8152 25.8999C32.9908 25.9192 33.1685 25.8917 33.33 25.82C33.5002 25.739 33.644 25.6115 33.7448 25.4522C33.8457 25.293 33.8995 25.1085 33.9 24.92V7C33.9004 6.22138 33.5981 5.47309 33.057 4.91327C32.5158 4.35345 31.7782 4.02595 31 4Z" fill="#160B32" />
                                                    </svg>
                                                </div>
                                                {counts?.negotiation_requests} NEGOTIATION REQUESTS
                                                <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                            </Link>
                                        </li>
                                        :
                                        null
                                }

                                {
                                    fns.CHECK_PERMISSION(["C2", "C3"])
                                        ?
                                        <li className='float-left w-full py-3'>
                                            <Link to={`/concierge/deals-in-progress`} className='cursor-pointer float-left w-full leading-8 text-xs bg-white shadow-md shadow-black/25 rounded-lg p-2 px-3 hover:text-burgandy overflow-hidden'>
                                                <div className='inline-block align-middle mr-3'>
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16 2C13.2311 2 10.5243 2.82109 8.22202 4.35943C5.91973 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C29.9958 12.2883 28.5194 8.72977 25.8948 6.10518C23.2702 3.48059 19.7117 2.00423 16 2ZM16 28C12.8174 28 9.76516 26.7357 7.51472 24.4853C5.26429 22.2348 4.00001 19.1826 4.00001 16C4.00001 12.8174 5.26429 9.76515 7.51472 7.51472C9.76516 5.26428 12.8174 4 16 4V16L24.481 24.481C23.3688 25.5972 22.047 26.4827 20.5916 27.0866C19.1361 27.6905 17.5757 28.0009 16 28Z" fill="#160B32" />
                                                    </svg>
                                                </div>
                                                {counts?.deal_in_progress} DEALS-IN-PROGRESS
                                                <i className="float-right leading-8 fa-solid fa-chevron-right"></i>
                                            </Link>
                                        </li>
                                        :
                                        null
                                }
                            </ul>
                        </div>
                        <div className='clear-both'></div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default ConciergeHome