import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import authContext from 'contexts/auth/authContext';

import { TestPage } from 'MdlPages';
import { PageNotFound } from 'ErrorPages';

import { CommonCms, Support } from 'MdlCms';
import { Login, ForgotPassword, ResetPassword, Logout, OtpVerification } from 'MdlPages/login';
import MyProfile from 'MdlPages/profile/MyProfile';
import { AllNotifications, ViewNotification } from 'MdlPages/notifications';

import DashboardLayout from 'MdlDashboard/organization/DashboardLayout';

import ConciergeHome from 'MdlDashboard/concierge/ConciergeHome';
import { BusinessDetails, BankDetails, PrimaryDetails, Organisations, OrganisationDetails } from 'MdlDashboard/concierge/organisation';
import { PersonalDetails, MemberDetails } from 'MdlPages/register';
import { ClientUploadRequests } from 'MdlPages/client_upload';
import { NegotiationsConcierge, RequestTicketView } from 'MdlDashboard/concierge/manual_request'

import Dashboard from 'MdlDashboard/organization/Dashboard';
import { ClientUpload, ClientUploadManual } from 'MdlDashOrg/client_upload';
import { SearchOffers, LockedOffers, MyOffersInMarket, EditOffer } from 'MdlDashOrg/search_offers';
import { MyDealsInProgress, DealDetails, ClosedOffers } from 'MdlDashOrg/deals_in_progress';
import { CreateNegotationTicket, MyNegotiations, MyNegotiationDetails, NegotitationCreated } from 'MdlDashOrg/negotiations';

import SearchedOfferList from 'MdlDashboard/organization/SearchedOfferList';
import CreateManualNegotiationRequest from 'MdlDashboard/organization/CreateManualNegotiationRequest';
// import MyNegotiationsC from 'MdlDashboard/organization/MyNegotiationsC';
import MyDealsDetails from 'MdlDashboard/organization/MyDealsDetails';
import MyProfileDetails from 'MdlDashboard/organization/MyProfileDetails';
import MyAlerts from 'MdlDashboard/organization/MyAlerts';
import MyLiveOffers from 'MdlDashboard/organization/MyLiveOffers';
import UpdateExpiredOffers from 'MdlDashboard/organization/UpdateExpiredOffers';
import MyClosedDeals from 'MdlDashboard/organization/MyClosedDeals';
import InProgressDetails from 'MdlDashboard/concierge/InProgressDetails';
// import RequestTicketView from 'MdlDashboard/concierge/RequestTicketView';

/** Middleware to check if user is not logged in */
const PrivateRoute = ({ children }) => {
    const cntxAuth = useContext(authContext);
    return cntxAuth.isLogged ? children : <Navigate to="/login" />;
}

/** Middleware to check if user is logged in */
const LoggedRoute = ({ children }) => {
    const cntxAuth = useContext(authContext);
    return cntxAuth.isLogged ? <Navigate to="/login" /> : children;
}

const Router = () => {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<DashboardLayout />}>
                    <Route exact index element={<Login />} />
                    <Route exact path="verify-otp" element={<OtpVerification />} /> {/* :_token Development use */}
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Route>
                <Route exact path="/forgot-password" element={<LoggedRoute><ForgotPassword /></LoggedRoute>} />
                <Route exact path="/reset-password/:_token" element={<LoggedRoute><ResetPassword /></LoggedRoute>} />
                <Route exact path="/logout" element={<Logout />} />
                <Route exact path="page/:_page" element={<CommonCms />} />
                <Route exact path="support" element={<Support />} />

                <Route exact path="/my-profile" element={<PrivateRoute><MyProfile /></PrivateRoute>} />
                <Route exact path="/all-notifications" element={<PrivateRoute><AllNotifications /></PrivateRoute>} />
                <Route exact path="/view-notification" element={<PrivateRoute><ViewNotification /></PrivateRoute>} />

                <Route path="/concierge" element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
                    <Route exact index element={<ConciergeHome />} />
                    <Route exact path="organisations" element={<Organisations />} />
                    <Route exact path="organisation/create" element={<BusinessDetails />} />
                    <Route exact path="organisation/:_id/business-details" element={<BusinessDetails />} />
                    <Route exact path="organisation/:_id/bank-details" element={<BankDetails />} />
                    <Route exact path="organisation/:_id/primary-details" element={<PrimaryDetails />} />
                    <Route exact path="organisation/:_id/details" element={<OrganisationDetails />} />
                    <Route exact path="client-upload-requests" element={<ClientUploadRequests />} />
                    <Route exact path="deals-in-progress" element={<MyDealsInProgress is_concierge={true} />} />
                    <Route exact path="negotiations" element={<NegotiationsConcierge />} />
                    <Route exact path="negotiation-details/:_id" element={<RequestTicketView />} />
                </Route>

                <Route path="/self-register" element={<LoggedRoute><DashboardLayout /></LoggedRoute>}>
                    <Route exact path="personal-details/:_token" element={<PersonalDetails />} />
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Route>

                <Route path="/self-register" element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
                    <Route exact path="update-personal-details/:_token2" element={<PersonalDetails />} />
                    <Route exact path="member-details/:_token" element={<MemberDetails />} />
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Route>

                <Route path="/dashboard" element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
                    <Route exact index element={<Dashboard />} />
                </Route>

                <Route exact path="upload-client" element={<PrivateRoute><ClientUpload /></PrivateRoute>} />
                <Route exact path="upload-client-manually" element={<PrivateRoute><ClientUploadManual /></PrivateRoute>} />
                <Route exact path="search-offers" element={<PrivateRoute><SearchOffers /></PrivateRoute>} />

                <Route exact path="deals-in-progress" element={<PrivateRoute><MyDealsInProgress is_concierge={false} /></PrivateRoute>} />
                <Route exact path="deal/:_id/details" element={<PrivateRoute><DealDetails is_concierge={false} /></PrivateRoute>} />
                <Route exact path="locked-offers" element={<PrivateRoute><LockedOffers /></PrivateRoute>} />
                <Route exact path="create-negotiation-request" element={<PrivateRoute><CreateNegotationTicket /></PrivateRoute>} />
                <Route exact path="negotiation-request-created" element={<PrivateRoute><NegotitationCreated /></PrivateRoute>} />
                <Route exact path="negotiation-requests" element={<PrivateRoute><MyNegotiations /></PrivateRoute>} />
                <Route exact path="negotiation-details/:_id" element={<PrivateRoute><MyNegotiationDetails /></PrivateRoute>} />
                <Route exact path="my-offers-on-market" element={<PrivateRoute><MyOffersInMarket /></PrivateRoute>} />
                <Route exact path="edit-offer" element={<PrivateRoute><EditOffer /></PrivateRoute>} />
                <Route exact path="closed-offers" element={<PrivateRoute><ClosedOffers /></PrivateRoute>} />


                {
                    /*
                    <Route exact path="/" element={<Home />} />
                    <Route path="/onboard-client" element={<DashboardLayout />}>
                        <Route exact index element={<BusinessDetails />} />
                        <Route exact path="business-details" element={<BusinessDetails />} />
                        <Route exact path="primary-details" element={<PrimaryDetails />} />
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Route>
    
                    <Route path="/dashboard" element={<DashboardLayout />}>
                        <Route exact index element={<Dashboard />} />
                        <Route exact path="upload-client" element={<ClientUpload />} />
                        <Route exact path="upload-client-manually" element={<ClientUploadManual />} />
                        <Route exact path="search-offer" element={<SearchOffers />} />
                        <Route exact path="searched-offer-listing" element={<SearchedOfferList />} />
                        <Route exact path="create-manual-negotiation-request" element={<CreateManualNegotiationRequest />} />
                        <Route exact path="my-deals-in-progress" element={<MyDealsInProgress />} />
                        <Route exact path="my-deals-details" element={<MyDealsDetails />} />
                        <Route exact path="my-profile-details" element={<MyProfileDetails />} />
                        <Route exact path="my-alerts" element={<MyAlerts />} />
                        <Route exact path="my-negotiation-details" element={<MyNegotiationDetails />} />
                        <Route exact path="my-live-offers" element={<MyLiveOffers />} />
                        <Route exact path="update-expired-offers" element={<UpdateExpiredOffers />} />
                        <Route exact path="my-closed-deals" element={<MyClosedDeals />} />
                        <Route exact path="concierge-deal-inprogress" element={<InProgressDetails />} />
                        <Route exact path="request-ticket-view" element={<RequestTicketView />} />
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Route>
    
                    <Route exact path="terms-conditions" element={<TermsConditions />} />
                    <Route exact path="faqs" element={<FAQ />} />
                    */
                }

                <Route exact path="/testpage" element={<TestPage />} />

                {/* ERROR ROUTES */}
                <Route path="/404" element={<PageNotFound />} />
                <Route path="*" element={<Navigate replace to="/404" />} />
                {/* ERROR ROUTES */}
            </Routes>
        </>
    )
};

export default Router;