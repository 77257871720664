import fns from 'helpers/functions';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'modules/fragments';
import { useLocation } from "react-router-dom";
import { useState } from 'react';
import * as NegotitationService from 'Services/negotitation-deals.service';
import useEffectOnce from 'hooks/useEffectOnce';

const NegotitationCreated = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlState = location.state;
    const [ticket, setTicket] = useState(null);
    const [fetching, setFetching] = useState(true);

    useEffectOnce(() => {
        fns.CHECK_PERMISSION(["B5"], true);
        if (!urlState) {
            navigate(`/`);
        }

        fetchTicket();

        return () => {
        }
    }, []);

    const fetchTicket = async () => {
        const res = await NegotitationService.FetchTicketAPI(urlState?.id);

        if (res.response) {
            setFetching(false);
            setTicket(res.data);
        } else {
            setFetching(false);
            setTicket(null);
        }
    }

    return (
        urlState
            ?
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>
                    <Breadcrumb
                        page={'YOUR SEARCH RESULTS'}
                        desc={
                            <>
                                <span className='inline-block align-middle mr-3'>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="15.5" fill="white" stroke="#160B32" />
                                        <path d="M16 8.6521C14.7727 8.6521 13.8139 9.10817 13.1236 10.0203C12.4332 10.9324 12.0881 12.0984 12.0881 13.5183C12.0795 15.2823 12.642 16.6806 13.7756 17.7132C13.9205 17.8509 13.9716 18.0273 13.929 18.2425L13.6349 18.862C13.5412 19.0685 13.4027 19.2299 13.2195 19.3461C13.0362 19.4622 12.6506 19.6279 12.0625 19.843C12.0369 19.8516 11.4979 20.028 10.4453 20.3722C9.39276 20.7164 8.83239 20.9057 8.7642 20.9401C8.0483 21.2413 7.57955 21.5554 7.35795 21.8824C7.11932 22.4245 7 23.797 7 25.9999H25C25 23.797 24.8807 22.4245 24.642 21.8824C24.4205 21.5554 23.9517 21.2413 23.2358 20.9401C23.1676 20.9057 22.6072 20.7164 21.5547 20.3722C20.5021 20.028 19.9631 19.8516 19.9375 19.843C19.3494 19.6279 18.9638 19.4622 18.7805 19.3461C18.5973 19.2299 18.4588 19.0685 18.3651 18.862L18.071 18.2425C18.0284 18.0273 18.0795 17.8509 18.2244 17.7132C19.358 16.6806 19.9205 15.2823 19.9119 13.5183C19.9119 12.0984 19.5668 10.9324 18.8764 10.0203C18.1861 9.10817 17.2273 8.6521 16 8.6521Z" fill="#160B32" />
                                    </svg>
                                </span>
                                {fns.STR_REPLACE(urlState?.formx?.search_type, '_', ' ')}: {urlState?.formx?.search_term}
                            </>
                        }
                        contact={null}
                    />

                    <div className='lg:w-5/12 sm:w-10/12 w-full mx-auto px-5 py-5 pt-0 relative'>
                        <div className='px-5 py-2 bg-[#F2F2F2] rounded-lg text-xl shadow-md shadow-black/25 text-center font-Averta_Semibold mb-10 text-blue'>
                            THANK YOU FOR SUBMITTING THE DETAILS <br />A MANUAL TICKET HAS BEEN GENERATED
                        </div>
                    </div>

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>
                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                                            <div className="flex flex-col">
                                                <div className="overflow-x-auto">
                                                    <div className="py-2 inline-block min-w-full">
                                                        <div className="overflow-x-auto mb-2">
                                                            <table className="min-w-full text-black">
                                                                <thead className="">
                                                                    <tr>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-left">Institution</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Outstanding Balance</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Code Bracket</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Account Number</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Account Type</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Your Offer</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Settlement Party</th>
                                                                        <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Any Client Attachments?</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className=''>
                                                                    {
                                                                        ticket?.tickets
                                                                            ?
                                                                            ticket?.tickets.map((request, index) =>
                                                                                <tr key={index} className="bg-white shadow-md shadow-black/25 rounded-lg mb-2">
                                                                                    <td className="text-xs px-4 py-2 text-left font-Averta whitespace-nowrap min-w-[200px]">
                                                                                        {request?.organization?.institution_name}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px] text-[#EB5757]">
                                                                                        {request?._outstanding_balance_text}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px]">
                                                                                        {request?.cd_bracket}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px]">
                                                                                        {request?.account_number}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px]">
                                                                                        {request?._account_type_text}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px] text-[#1EC465]">
                                                                                        {request?._settlement_value_text}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center font-Averta whitespace-nowrap min-w-[200px] text-[#818181]">
                                                                                        {request?.settlement_party_organization?.institution_name}
                                                                                    </td>
                                                                                    <td className="text-xs px-4 py-2 text-center text-[#818181] font-Averta whitespace-nowrap min-w-[200px]">
                                                                                        <svg className='m-auto' width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M15.8093 2.88892H5.65486C5.29726 2.89838 4.95786 3.04858 4.71038 3.30688C4.4629 3.56517 4.32736 3.91069 4.33319 4.26836V21.7317C4.32736 22.0894 4.4629 22.4349 4.71038 22.6932C4.95786 22.9515 5.29726 23.1017 5.65486 23.1111H20.3449C20.7024 23.1017 21.0419 22.9515 21.2893 22.6932C21.5368 22.4349 21.6724 22.0894 21.6665 21.7317V8.60892L15.8093 2.88892ZM15.1665 9.38892V4.21781L20.4387 9.38892H15.1665Z" fill="#190833" />
                                                                                        </svg>
                                                                                        {
                                                                                            request?.documents.length > 0
                                                                                                ?
                                                                                                <ol className='list-decimal'>
                                                                                                    {
                                                                                                        request?.documents.map((document, i) =>
                                                                                                            <li key={i}>{document.document_name}</li>
                                                                                                        )
                                                                                                    }
                                                                                                </ol>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                            :
                                                                            null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm:w-10/12 w-full sm:mt-0 mt-3 mx-auto">
                                                <div className='w-full float-left text-xs text-blue font-Averta_Semibold leading-8 pb-4'>
                                                    <svg className='mr-3 inline-block' width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_245_26897)">
                                                            <path d="M11.8108 0.794987L11.9412 0.645987C12.0393 0.533904 12.1558 0.445008 12.284 0.384374C12.4122 0.32374 12.5496 0.292556 12.6883 0.292603C12.8271 0.292649 12.9644 0.323925 13.0926 0.384645C13.2208 0.445364 13.3372 0.534339 13.4353 0.646487C13.5333 0.758636 13.6111 0.891763 13.6642 1.03827C13.7172 1.18477 13.7445 1.34178 13.7445 1.50034C13.7444 1.6589 13.7171 1.81589 13.6639 1.96236C13.6108 2.10883 13.533 2.2419 13.4348 2.35399L13.3045 2.50199C13.5275 2.78785 13.6464 3.16038 13.6368 3.54322C13.6272 3.92605 13.4898 4.29019 13.2528 4.56099L4.24733 14.854C4.19104 14.918 4.12056 14.9633 4.04346 14.985L0.543458 15.985C0.470214 16.0058 0.393497 16.0047 0.320764 15.9816C0.248032 15.9585 0.181758 15.9144 0.128385 15.8534C0.0750119 15.7924 0.0363555 15.7166 0.0161733 15.6335C-0.00400895 15.5504 -0.00503042 15.4627 0.0132082 15.379L0.888208 11.379C0.907353 11.2912 0.946968 11.2111 1.00283 11.147L9.43958 1.50499C9.3552 1.43712 9.25255 1.40551 9.14976 1.41576C9.04697 1.42601 8.95065 1.47745 8.87783 1.56099L5.99733 4.85399C5.95666 4.90048 5.90837 4.93735 5.85522 4.96251C5.80207 4.98767 5.74511 5.00062 5.68758 5.00062C5.63006 5.00062 5.57309 4.98767 5.51995 4.96251C5.4668 4.93735 5.41851 4.90048 5.37783 4.85399C5.33716 4.8075 5.30489 4.75231 5.28288 4.69157C5.26086 4.63083 5.24953 4.56573 5.24953 4.49999C5.24953 4.43424 5.26086 4.36914 5.28288 4.3084C5.30489 4.24766 5.33716 4.19248 5.37783 4.14599L8.26008 0.853987C8.49719 0.583003 8.81605 0.426008 9.15124 0.415223C9.48643 0.404437 9.81251 0.540679 10.0626 0.795987C10.303 0.550426 10.6141 0.414599 10.9365 0.414415C11.259 0.414231 11.5702 0.549701 11.8108 0.794987Z" fill="#160B32" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_245_26897">
                                                                <rect width="14" height="16" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    ANY AD HOC DETAILS FOR US TO CONSIDER?
                                                </div>
                                                <div className='w-full float-left text-xs text-blue font-Averta_Semibold pb-4'>
                                                    <div className="w-full h-[155px] text-sm font-Averta text-blue px-3 py-2 border rounded-lg border-black">{ticket?.description}</div>
                                                </div>
                                                <div className='w-full float-left text-xs text-blue font-Averta pt-10 pb-3'>
                                                    <svg className='m-auto' width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 41C0 30.1261 4.31963 19.6976 12.0086 12.0086C19.6976 4.31963 30.1261 0 41 0C51.8739 0 62.3024 4.31963 69.9914 12.0086C77.6804 19.6976 82 30.1261 82 41C82 51.8739 77.6804 62.3024 69.9914 69.9914C62.3024 77.6804 51.8739 82 41 82C30.1261 82 19.6976 77.6804 12.0086 69.9914C4.31963 62.3024 0 51.8739 0 41H0ZM38.6603 58.548L62.2653 29.0389L58.0013 25.6277L37.8731 50.7799L23.616 38.9008L20.1173 43.0992L38.6603 58.5535V58.548Z" fill="#1EC465" />
                                                    </svg>
                                                    <div className='clear-both'></div>
                                                </div>
                                                <div className='clear-both'></div>
                                            </div>
                                        </div>
                                        <Link to={`/dashboard`} className='text-center float-right bg-blue text-burgandy text-sm font-Averta_Semibold py-4 rounded-xl px-12 hover:text-blue hover:bg-burgandy'>Return to Dashboard</Link>
                                    </div>
                                    <div className='clear-both'></div>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>
                    <div className='clear-both'></div>
                </div>
            </section>
            :
            null
    )
}

export default NegotitationCreated