import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import authContext from 'contexts/auth/authContext';
import useEffectOnce from 'hooks/useEffectOnce';
import { LineLoader, RoundedInputLoader, ButtonLoader } from 'UIs/SkeletonLoader';
import fns from 'helpers/functions';
import * as OrgService from 'Services/organisation.service';
import * as GenService from 'Services/general.service';

const PersonalDetails = () => {
    const { _token } = useParams();
    const { _token2 } = useParams();
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const { register, trigger, handleSubmit, watch, formState: { errors } } = useForm();
    const [organisation, setOrganisation] = useState(null);
    const [verified, setVerified] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffectOnce(() => {
        if (typeof _token !== 'undefined' && _token !== '') {
            fetchOrganisation();
        } else if (window.location.href.indexOf("update-personal-details") > -1 && typeof _token2 !== 'undefined' && _token2 !== '') {
            fetchUserInfo();
        } else {
            navigate(`/login`);
        }
    }, []);

    /** Fetch basic details of organisation for registration */
    const fetchOrganisation = async () => {
        const res = await OrgService.FetchOrgRegAPI(_token);

        if (res.response) {
            setOrganisation(res.data.data);
            setLoading(false);
        } else if (res.code === 403) {
            fns.TOAST_ERR(res.errors);
            navigate('/login');
            return;
        } else {
            setOrganisation(null);
            setLoading(false);
        }
    }

    /** Fetch basic details of user for registration */
    const fetchUserInfo = async () => {
        const res = await OrgService.FetchUserInfoAPI();

        if (res.response) {
            setOrganisation(res.data.data);
            setLoading(false);
        } else if (res.code === 403) {
            fns.TOAST_ERR(res.errors);
            navigate('/login');
            return;
        } else {
            setOrganisation(null);
            setLoading(false);
        }
    }

    /** Verify mobile nubmer */
    const verifyMobile = async () => {
        setSending(true);
        const result = await trigger("mobile", { shouldFocus: true });

        if (result) {
            const mobile = watch('mobile');
            const res = await GenService.SendOtpAPI({ "mobile": mobile });

            if (res.response) {
                setSending(false);
                fns.TOAST_SUC(res.data.message);
            } else if (res.errors) {
                setSending(false);
                fns.TOAST_ERR(res.errors);
            }
        } else {
            setSending(false);
        }
    }

    /** Save personal info & register self */
    const SavePersonalInfoAPI = async (form) => {
        var res = '';
        var token = '';

        setProcessing(true);
        if (typeof _token !== 'undefined' && _token !== '') {
            res = await OrgService.SavePersonalInfoAPI(_token, form);
            token = _token;
        } else if (window.location.href.indexOf("update-personal-details") > -1 && typeof _token2 !== 'undefined' && _token2 !== '') {
            res = await OrgService.UpdatePersonalInfoAPI(_token2, form);
            token = _token2;
        }

        if (res.response) {
            setVerified(true);
            setTimeout(() => {
                setProcessing(false);
                localStorage.setItem('_token', res.data.data.access_token);
                localStorage.setItem('refresh-token', res.data.data.refresh_token);
                localStorage.setItem('org_id', res?.data?.data?.organization_id);
                localStorage.setItem('user', JSON.stringify(res?.data?.data));
                cntxAuth.updateLogin(true);
                fns.TOAST_SUC(res.data.message);

                if (organisation.is_organization) {
                    navigate(`/self-register/member-details/${token}`);
                } else {
                    navigate(`/login`);
                }
            }, 2000);
        } else if (res.errors) {
            setProcessing(false);
            fns.TOAST_ERR(res.errors);
        }
    }

    return (
        !loading
            ?
            (
                organisation
                    ?
                    <>
                        <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-24 w-full float-left font-Averta md:flex md:items-center">
                            <div className="lg:container mx-auto px-5">
                                <ul className="md:max-w-sm w-full float-left text-blue font-Averta pb-6 pr-5">
                                    <li className="leading-9 pb-3 align-middle">
                                        <span className="hover:text-burgandy inline-block leading-5 align-middle cursor-pointer">
                                            <span className="align-middle rounded-full -mt-1 border-2 w-6 h-6 leading-5 mr-2 border-burgandy text-center inline-block">1</span> Personal Details
                                        </span>
                                    </li>
                                    <li className="pt-4 pb-10">
                                        <p className="leading-6 text-blue font-Averta xl:w-3/5"><strong className="text-lg font-Averta_Bold">Hello! <br />Your manager has <br />activated your <br />SettleHub account.</strong> <br />Please complete the form to get started. Your username is: <a className="text-blue font-Averta_Semibold hover:text-burgandy" href={`mailto:${organisation?.email}`}>{organisation?.email}</a></p>
                                    </li>
                                    {
                                        organisation.is_organization
                                            ?
                                            <li className="leading-9 pb-3 align-middle">
                                                <span className="text-gray inline-block leading-5 align-middle cursor-pointer">
                                                    <span className="align-middle rounded-full -mt-1 border-2 w-6 h-6 leading-5 mr-2 border-transparent text-center inline-block text-gray">2</span> Member Details
                                                </span>
                                            </li>
                                            : null
                                    }
                                </ul>

                                <form onSubmit={handleSubmit(SavePersonalInfoAPI)}>
                                    <div className="md:w-2/5 w-full float-left">
                                        <div className="bg-blue w-full float-left p-3 px-4">
                                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Personal Details and Login</h3>
                                        </div>
                                        <div className="w-full float-left clear-both p-3 pt-5">
                                            <div className="w-full md:w-2/4 float-left mb-2 p-1 relative">
                                                <div className="m-auto relative">
                                                    <input
                                                        {...register("first_name", {
                                                            required: "required",
                                                            maxLength: {
                                                                value: 50,
                                                                message: "First name can't be more than 50 alphabets."
                                                            }
                                                        })}
                                                        defaultValue={organisation?.first_name}
                                                        type="text"
                                                        id="floating_outlined"
                                                        className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer"
                                                        placeholder="John"
                                                    />
                                                    <label htmlFor="floating_outlined" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1 absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">First Name</label>
                                                </div>
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.first_name && errors.first_name.type === "required" && 'Please enter first name.'}
                                                    {errors.first_name && errors.first_name.type !== "required" && errors.first_name.message}
                                                </span>
                                            </div>
                                            <div className="w-full md:w-2/4 float-left mb-2 p-1 relative">
                                                <div className="m-auto relative">
                                                    <input
                                                        {...register("last_name", {
                                                            required: "required",
                                                            maxLength: {
                                                                value: 50,
                                                                message: "Last name can't be more than 50 alphabets."
                                                            }
                                                        })}
                                                        defaultValue={organisation?.last_name}
                                                        type="text"
                                                        id="floating_outlined1"
                                                        className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer"
                                                        placeholder="Doe"
                                                    />
                                                    <label htmlFor="floating_outlined1" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1 absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Surname</label>
                                                </div>
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.last_name && errors.last_name.type === "required" && 'Please enter last name.'}
                                                    {errors.last_name && errors.last_name.type !== "required" && errors.last_name.message}
                                                </span>
                                            </div>
                                            <div className="w-full float-left mb-2 p-1 relative">
                                                <div className="m-auto relative">
                                                    <input
                                                        type="text"
                                                        id="floating_outlined2"
                                                        className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer"
                                                        placeholder="John.doe@gmail.com"
                                                        value={organisation?.email}
                                                        disabled={true}
                                                    />
                                                    <label htmlFor="floating_outlined2" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1 absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Username or Email</label>
                                                </div>
                                            </div>
                                            <div className="w-full float-left mb-2 p-1 relative">
                                                <div className="m-auto relative">
                                                    <input
                                                        {...register("mobile", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /^\+91|\+27(5|6|7|8|9){1,2}[0-9]{1}[0-9]{7,9}$/,
                                                                message: "This value doesn't match mobile number format. (E.g. : +27987654321, +919876543210)"
                                                            }
                                                        })}
                                                        type="text"
                                                        id="floating_outlined3"
                                                        className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer"
                                                        placeholder="Mobile Number (E.g. : +279876543210)"
                                                        value={organisation?.mobile}
                                                    />
                                                    <label htmlFor="floating_outlined3" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1 absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Mobile Number</label>
                                                </div>
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.mobile && errors.mobile.type === "required" && 'Please enter mobile number.'}
                                                    {errors.mobile && errors.mobile.type !== "required" && errors.mobile.message}
                                                </span>
                                            </div>
                                            <div className="w-full md:w-2/4 float-left mb-2 p-1 relative">
                                                <div className="m-auto relative">
                                                    <input
                                                        {...register("password", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                                message: "Password must contains at least a letter, number and a special character."
                                                            },
                                                            minLength: {
                                                                value: 8,
                                                                message: "Password can not be less than 8 characters."
                                                            }
                                                        })}
                                                        type="password"
                                                        id="floating_outlined4"
                                                        className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer"
                                                        placeholder="************"
                                                    />
                                                    <label htmlFor="floating_outlined4" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1 absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Password</label>
                                                </div>
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.password && errors.password.type === "required" && 'Please enter password.'}
                                                    {errors.password && errors.password.type !== "required" && errors.password.message}
                                                </span>
                                            </div>
                                            <div className="w-full md:w-2/4 float-left mb-2 p-1 relative">
                                                <div className="m-auto relative">
                                                    <input
                                                        {...register("password_confirmation", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                                message: "Password must contains at least a letter, number and a special character."
                                                            },
                                                            minLength: {
                                                                value: 8,
                                                                message: "Confirm password can not be less than 8 characters."
                                                            },
                                                            validate: (val: string) => {
                                                                if (watch('password') !== val) {
                                                                    return 'Confirm password do not match.'
                                                                }
                                                            }
                                                        })}
                                                        type="password"
                                                        id="floating_outlined5"
                                                        className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer"
                                                        placeholder="************"
                                                    />
                                                    <label htmlFor="floating_outlined5" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1 absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Confirm Password</label>
                                                </div>
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.password_confirmation && errors.password_confirmation.type === "required" && 'Please enter confirm password.'}
                                                    {errors.password_confirmation && errors.password_confirmation.type !== "required" && errors.password_confirmation.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="bg-blue w-full float-left p-3 px-4 mt-2">
                                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Verify your account</h3>
                                        </div>
                                        <div className='w-full float-left py-5 grid grid-cols-3 gap-4'>
                                            <p className='float-left text-sm col-span-2'>We will send you an <strong>One Time Password</strong> to your mobile number.</p>
                                            <span onClick={() => { if (!sending) { verifyMobile(); } }} className='float-left bg-lightperot text-center rounded-full text-sm text-white p-1 leading-8 hover:bg-perot cursor-pointer'>
                                                {sending ? <i className="fa-solid fa-spinner fa-spin"></i> : 'Get OTP'}
                                            </span>
                                        </div>
                                        <div className="w-full float-left clear-both p-3 pt-0">
                                            <div className="full md:w-2/4 float-left mb-2 p-1 relative">
                                                <div className="m-auto relative">
                                                    <input
                                                        {...register("otp", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /^[0-9]{6}$/,
                                                                message: "Entered value does not match OTP format"
                                                            }
                                                        })}
                                                        type="text"
                                                        id="floating_outlined6"
                                                        className="w-full placeholder:text-white focus:placeholder:text-blue/50 focus:outline-none text-sm form-input font-normal text-blue px-6 rounded-full py-3 border border-blue block pb-2.5 pt-4 border-1 appearance-none focus:ring-0 peer"
                                                        placeholder="***********"
                                                    />
                                                    <label htmlFor="floating_outlined6" className="float-left font-Averta_Semibold text-[13px] text-blue bg-white mx-5 px-1 absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">OTP</label>
                                                </div>
                                                <span className="text-red-500 text-sm font-Averta">
                                                    {errors.otp && errors.otp.type === "required" && 'Please enter OTP.'}
                                                    {errors.otp && errors.otp.type !== "required" && errors.otp.message}
                                                </span>
                                            </div>
                                            {
                                                verified
                                                    ?
                                                    <div className="w-full md:w-2/4 float-left mb-2 p-1">
                                                        <p className='text-perot leading-10 pt-1 text-sm font-Averta_Semibold'><i className="fa-solid fa-circle-check"></i> Verified!</p>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className="clear-both"></div>
                                        <button disabled={processing} className="float-right xl:-mr-80 mt-5 bg-blue py-5 px-20 rounded-full text-white font-Averta_Semibold hover:bg-burgandy text-sm" type="submit">
                                            {processing ? <i className="fa-solid fa-spinner fa-spin"></i> : organisation?.is_organization ? 'Next' : 'Finish'}
                                        </button>
                                        <div className="clear-both"></div>
                                    </div>
                                </form>
                                <div className="clear-both"></div>
                            </div>
                        </section>
                    </>
                    : null
            )
            :
            <PageSkeleton />
    )
}

export default PersonalDetails;

const PageSkeleton = () => {
    return (
        <>
            <section className="lg:min-h-[calc(100vh-80px-64px)] py-16 md:py-24 w-full float-left font-Averta md:flex md:items-center">
                <div className="lg:container mx-auto px-5">
                    <ul className="md:max-w-sm w-full float-left text-blue font-Averta pb-6 pr-5">
                        <LineLoader width={'50%'} count={2} />
                    </ul>

                    <div className="md:w-2/5 w-full float-left">
                        <div className="bg-blue w-full float-left p-3 px-4">
                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Personal Details and Login</h3>
                        </div>
                        <div className="w-full float-left clear-both p-3 pt-5">
                            <div className="w-full md:w-2/4 float-left mb-2 p-1 relative">
                                <div className="m-auto relative">
                                    <RoundedInputLoader />
                                </div>
                            </div>
                            <div className="w-full md:w-2/4 float-left mb-2 p-1 relative">
                                <div className="m-auto relative">
                                    <RoundedInputLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2 p-1 relative">
                                <div className="m-auto relative">
                                    <RoundedInputLoader />
                                </div>
                            </div>
                            <div className="w-full float-left mb-2 p-1 relative">
                                <div className="m-auto relative">
                                    <RoundedInputLoader />
                                </div>
                            </div>
                            <div className="w-full md:w-2/4 float-left mb-2 p-1 relative">
                                <div className="m-auto relative">
                                    <RoundedInputLoader />
                                </div>
                            </div>
                            <div className="w-full md:w-2/4 float-left mb-2 p-1 relative">
                                <div className="m-auto relative">
                                    <RoundedInputLoader />
                                </div>
                            </div>
                        </div>
                        <div className="bg-blue w-full float-left p-3 px-4 mt-2">
                            <h3 className="float-left text-white text-xs font-Averta_Semibold">Verify your account</h3>
                        </div>
                        <div className='w-full float-left py-5 grid grid-cols-3 gap-4'>
                            <LineLoader width={'320px'} />
                            <br />
                            <RoundedInputLoader />
                        </div>
                        <div className="w-full float-left clear-both p-3 pt-0">
                            <div className="full md:w-2/4 float-left mb-2 p-1 relative">
                                <div className="m-auto relative">
                                    <RoundedInputLoader />
                                </div>
                            </div>
                        </div>
                        <div className="clear-both"></div>
                        <ButtonLoader />
                        <div className="clear-both"></div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </section>
        </>
    );
}