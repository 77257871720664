import fns from 'helpers/functions';
import { Breadcrumb } from 'modules/fragments';
import React from 'react';
import AlertTable from './AlertTable';

const AllNotifications = () => {
    const user = fns.LOGGED_USER();

    return (
        <>
            <section className="w-full lg:min-h-[calc(100vh-80px-64px)] float-left font-Averta relative py-24 pt-3">
                <div className='w-full float-left'>

                    <Breadcrumb page={'ALL NOTIFICATIONS'} desc={' '}  contact={user?.is_concierge ? ' ' : null} />

                    <div className='w-full float-left font-Averta'>
                        <div className="sm:w-11/12 w-full mx-auto px-5">
                            <div className='float-left w-full'>
                                <div className='float-left w-full'>

                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <div className='inline-block align-middle mr-3'>
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_15_6)">
                                                    <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                                </g>
                                                <path d="M24.2813 21C24.2813 21.6962 24.5578 22.3639 25.0501 22.8562C25.5424 23.3484 26.2101 23.625 26.9063 23.625C27.6025 23.625 28.2701 23.3484 28.7624 22.8562C29.2547 22.3639 29.5313 21.6962 29.5313 21C29.5313 20.3038 29.2547 19.6361 28.7624 19.1438C28.2701 18.6516 27.6025 18.375 26.9063 18.375C26.2101 18.375 25.5424 18.6516 25.0501 19.1438C24.5578 19.6361 24.2813 20.3038 24.2813 21ZM37.0828 20.3953C34.861 15.7148 31.5024 13.3594 27 13.3594C22.4953 13.3594 19.1391 15.7148 16.9172 20.3977C16.8281 20.5864 16.7819 20.7925 16.7819 21.0012C16.7819 21.2099 16.8281 21.416 16.9172 21.6047C19.1391 26.2852 22.4977 28.6406 27 28.6406C31.5047 28.6406 34.861 26.2852 37.0828 21.6023C37.2633 21.2227 37.2633 20.782 37.0828 20.3953ZM26.9063 25.125C24.6281 25.125 22.7813 23.2781 22.7813 21C22.7813 18.7219 24.6281 16.875 26.9063 16.875C29.1844 16.875 31.0313 18.7219 31.0313 21C31.0313 23.2781 29.1844 25.125 26.9063 25.125Z" fill="#160B32" />
                                                <defs>
                                                    <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="4" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </div>
                                        NEW NOTIFICATION LOG - TODAY
                                        <AlertTable type={'today'} />
                                    </div>

                                    <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
                                        <div className='inline-block align-middle mr-3'>
                                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_15_6)">
                                                    <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                                                </g>
                                                <path d="M27.2295 15.2647H25.7734C25.6402 15.2647 25.5312 15.3736 25.5312 15.5068V23.8411C25.5312 23.9198 25.5675 23.9925 25.6311 24.0379L30.6353 27.6919C30.7443 27.7706 30.8956 27.7494 30.9744 27.6404L31.8402 26.4598C31.9219 26.3478 31.8977 26.1964 31.7887 26.1207L27.4717 22.9995V15.5068C27.4717 15.3736 27.3627 15.2647 27.2295 15.2647ZM33.8836 17.5412L38.6305 18.7007C38.7819 18.737 38.9302 18.622 38.9302 18.4676L38.9544 13.5784C38.9544 13.3756 38.7213 13.2606 38.5639 13.3877L33.7928 17.1144C33.7568 17.1423 33.7293 17.1798 33.7136 17.2226C33.6979 17.2654 33.6946 17.3117 33.7041 17.3563C33.7136 17.4009 33.7355 17.4419 33.7672 17.4746C33.799 17.5073 33.8393 17.5304 33.8836 17.5412ZM38.9605 26.6565L37.244 26.0662C37.1841 26.0457 37.1186 26.0494 37.0615 26.0766C37.0044 26.1037 36.9601 26.1522 36.9382 26.2115C36.8807 26.3659 36.8202 26.5173 36.7566 26.6687C36.2177 27.9432 35.4457 29.0905 34.4588 30.0744C33.4829 31.0534 32.3263 31.8337 31.0531 32.3722C29.7341 32.9298 28.3164 33.216 26.8844 33.2138C25.4373 33.2138 24.0357 32.9322 22.7158 32.3722C21.4426 31.8337 20.286 31.0534 19.31 30.0744C18.3261 29.0905 17.5541 27.9432 17.0122 26.6687C16.4577 25.349 16.1736 23.9315 16.1767 22.5C16.1767 21.0529 16.4582 19.6482 17.0183 18.3283C17.5572 17.0538 18.3291 15.9064 19.3161 14.9226C20.292 13.9436 21.4486 13.1632 22.7218 12.6248C24.0357 12.0647 25.4404 11.7832 26.8874 11.7832C28.3345 11.7832 29.7362 12.0647 31.0561 12.6248C32.3293 13.1632 33.4859 13.9436 34.4619 14.9226C34.7706 15.2344 35.0613 15.5583 35.3277 15.9004L37.138 14.4836C34.7555 11.4381 31.047 9.4794 26.8814 9.48243C19.6279 9.48545 13.8033 15.3767 13.8759 22.6332C13.9486 29.7626 19.7459 35.5176 26.8874 35.5176C32.5032 35.5176 37.2864 31.9574 39.1088 26.9714C39.1542 26.8442 39.0876 26.702 38.9605 26.6565Z" fill="#160B32" />
                                                <defs>
                                                    <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="4" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </div>
                                        NOTIFICATION LOG - HISTORY
                                        <AlertTable type={'history'} />
                                    </div>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>

                    <div className='clear-both'></div>
                </div>
            </section>
        </>
    )
}

export default AllNotifications;