export const EyeButton = ({ onClick }) => {
    return (
        <svg onClick={onClick} className='cursor-pointer m-auto' width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4415 13.5C10.4415 14.2832 10.7526 15.0344 11.3064 15.5882C11.8602 16.142 12.6114 16.4531 13.3946 16.4531C14.1778 16.4531 14.9289 16.142 15.4828 15.5882C16.0366 15.0344 16.3477 14.2832 16.3477 13.5C16.3477 12.7168 16.0366 11.9656 15.4828 11.4118C14.9289 10.858 14.1778 10.5469 13.3946 10.5469C12.6114 10.5469 11.8602 10.858 11.3064 11.4118C10.7526 11.9656 10.4415 12.7168 10.4415 13.5ZM24.8432 12.8197C22.3436 7.5542 18.5652 4.9043 13.5001 4.9043C8.43229 4.9043 4.65651 7.5542 2.1569 12.8224C2.05664 13.0347 2.00464 13.2665 2.00464 13.5013C2.00464 13.7361 2.05664 13.968 2.1569 14.1803C4.65651 19.4458 8.43492 22.0957 13.5001 22.0957C18.5678 22.0957 22.3436 19.4458 24.8432 14.1776C25.0463 13.7505 25.0463 13.2548 24.8432 12.8197ZM13.3946 18.1406C10.8317 18.1406 8.75397 16.0629 8.75397 13.5C8.75397 10.9371 10.8317 8.85938 13.3946 8.85938C15.9575 8.85938 18.0352 10.9371 18.0352 13.5C18.0352 16.0629 15.9575 18.1406 13.3946 18.1406Z" fill="#160B32" />
        </svg>
    )
}

export const EditButton = ({ onClick }) => {
    return (
        <i onClick={onClick} className="fa-regular fa-edit text-xl cursor-pointer"></i>
    )
}

export const PlusButton = ({ onClick }) => {
    return (
        <svg onClick={onClick} className='float-right cursor-pointer' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 4C22.6 4 28 9.4 28 16C28 22.6 22.6 28 16 28C9.4 28 4 22.6 4 16C4 9.4 9.4 4 16 4ZM16 2C8.3 2 2 8.3 2 16C2 23.7 8.3 30 16 30C23.7 30 30 23.7 30 16C30 8.3 23.7 2 16 2Z" fill="#1EC465" />
            <path d="M24 15H17V8H15V15H8V17H15V24H17V17H24V15Z" fill="#1EC465" />
        </svg>
    )
}

export const MembersButton = ({ onClick }) => {
    return (
        <i onClick={onClick} className="fa fa-users text-[18px]" aria-hidden="true"></i>
    )
}

export const FaEyeButton = ({ onClick }) => {
    return (
        <i onClick={onClick} className="fa fa-eye text-[18px]" aria-hidden="true"></i>
    )
}

export const GoBack = ({ onClick, name }) => {
    return (
        <button className={'float-right text-xs text-blue py-1 border px-3 hover:text-burgandy mt-4 uppercase'} onClick={(e) => { e.preventDefault(); onClick(); }}><i className="fa-solid fa-arrow-left mt-1"></i> {name}</button>
    )
}
export const GoBackWhite = ({ onClick, name }) => {
    return (
        <button className={'float-right text-xs text-blue py-1 border px-3 hover:text-burgandy mt-4 uppercase bg-white'} onClick={(e) => { e.preventDefault(); onClick(); }}><i className="fa-solid fa-arrow-left mt-1"></i> {name}</button>
    )
}
export const SimpleWhite = ({ onClick, name }) => {
    return (
        <button className={'float-right text-xs text-blue py-1 border px-3 hover:text-burgandy mt-4 uppercase bg-white'} onClick={(e) => { e.preventDefault(); onClick(); }}>{name}</button>
    )
}