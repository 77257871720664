import React from 'react'

const Pipeline = ({ summary }) => {
    return (
        <div className='float-left w-full align-middle text-xs font-Averta_Semibold py-6'>
            <div className='inline-block align-middle mr-3'>
                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_15_6)">
                        <rect x="4" width="46" height="46" rx="7" fill="#F2F2F2" />
                    </g>
                    <path d="M17.781 19.5938C17.781 17.2814 18.6996 15.0637 20.3347 13.4287C21.9698 11.7936 24.1874 10.875 26.4998 10.875C28.8121 10.875 31.0298 11.7936 32.6649 13.4287C34.2999 15.0637 35.2185 17.2814 35.2185 19.5938V23.2828L37.0863 27.9521C37.1452 28.0991 37.1672 28.2583 37.1503 28.4157C37.1334 28.5732 37.0782 28.7241 36.9895 28.8553C36.9008 28.9864 36.7813 29.0938 36.6414 29.1681C36.5015 29.2424 36.3456 29.2812 36.1873 29.2812H16.8123C16.6539 29.2812 16.498 29.2424 16.3582 29.1681C16.2183 29.0938 16.0988 28.9864 16.0101 28.8553C15.9214 28.7241 15.8661 28.5732 15.8493 28.4157C15.8324 28.2583 15.8544 28.0991 15.9133 27.9521L17.781 23.2828V19.5938Z" fill="#160B32" />
                    <path d="M22.7446 31.2188C23.1728 32.8947 24.686 34.125 26.4995 34.125C28.313 34.125 29.8262 32.8947 30.2544 31.2188H22.7446Z" fill="#160B32" />
                    <defs>
                        <filter id="filter0_d_15_6" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_6" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_6" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
            TRACKING SUMMARY
            <div className='w-full float-left pl-16'>
                <div className="w-full float-left bg-[#F2F2F2] rounded-3xl shadow-md shadow-black/25 pt-5 px-4 sm:px-8 pb-5 mb-10">
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="overflow-x-auto pb-4 xl:pb-0">
                                    <table className="min-w-full text-black">
                                        <thead className="">
                                            <tr>
                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Offer activated</th>
                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Payment pipeline activated</th>
                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Payment received</th>
                                                <th scope="col" className="text-xs font-Averta px-4 py-4 text-center">Loan settled</th>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            <tr className="bg-white shadow-md shadow-black/25 rounded-lg mb-2">
                                                <td className="items-center whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                    <h2 className="flex flex-row flex-nowrap items-center my-3">
                                                        <span className="flex-grow block m-auto w-[10px] border-t border-dashed border-transparent" aria-hidden="true" role="presentation"></span>
                                                        <span className="flex-none block mx-4 mt-2 mb-2 text-center">
                                                            <span className={`bg-[#1EC365] block w-[30px] h-[30px] m-auto mb-1 rounded-full`}></span>
                                                            <strong className={`flex-grow block font-Averta text-[9px] text-blue/70`}>{summary?.offer_activated?.date}</strong>
                                                        </span>
                                                        <span className={`flex-grow block m-auto w-[10px] border-t border-dashed border-[#1EC365]`} aria-hidden="true" role="presentation"></span>
                                                    </h2>
                                                </td>
                                                <td className="items-center whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                    <h2 className="flex flex-row flex-nowrap items-center my-3">
                                                        <span className={`flex-grow block m-auto w-[10px] border-t border-dashed border-[#${summary?.payment_pipeline_activated?.status ? '1EC365' : '818181'}]`} aria-hidden="true" role="presentation"></span>
                                                        {
                                                            summary?.payment_pipeline_activated?.status
                                                                ?
                                                                <span className="flex-none block mx-4 mt-2 mb-2 text-center">
                                                                    <span className={`bg-[#1EC365] block w-[30px] h-[30px] m-auto mb-1 rounded-full`}></span>
                                                                    <strong className={`flex-grow block font-Averta text-[9px] text-blue/70`}>{summary?.payment_pipeline_activated?.date}</strong>
                                                                </span>
                                                                :
                                                                <span className={`flex-none block mx-4 mt-2 mb-2 bg-[#C4C4C4] w-[30px] h-[30px] rounded-full`}></span>
                                                        }
                                                        <span className={`flex-grow block m-auto w-[10px] border-t border-dashed border-[#${summary?.payment_pipeline_activated?.status ? '1EC365' : '818181'}]`} aria-hidden="true" role="presentation"></span>
                                                    </h2>
                                                </td>
                                                <td className="items-center whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                    <h2 className="flex flex-row flex-nowrap items-center my-3">
                                                        <span className={`flex-grow block m-auto w-[10px] border-t border-dashed border-[#${summary?.payment_received?.status ? '1EC365' : '818181'}]`} aria-hidden="true" role="presentation"></span>
                                                        {
                                                            summary?.payment_received?.status
                                                                ?
                                                                <span className="flex-none block mx-4 mt-2 mb-2 text-center">
                                                                    <span className={`bg-[#1EC365] block w-[30px] h-[30px] m-auto mb-1 rounded-full`}></span>
                                                                    <strong className={`flex-grow block font-Averta text-[9px] text-blue/70`}>{summary?.payment_received?.date}</strong>
                                                                </span>
                                                                :
                                                                <span className={`flex-none block mx-4 mt-2 mb-2 bg-[#C4C4C4] w-[30px] h-[30px] rounded-full`}></span>
                                                        }
                                                        <span className={`flex-grow block m-auto w-[10px] border-t border-dashed border-[#${summary?.payment_received?.status ? '1EC365' : '818181'}]`} aria-hidden="true" role="presentation"></span>
                                                    </h2>
                                                </td>
                                                <td className="items-center whitespace-nowrap min-w-[220px] xl:min-w-fit">
                                                    <h2 className="flex flex-row flex-nowrap items-center my-3">
                                                        <span className={`flex-grow block m-auto w-[10px] border-t border-dashed border-[#${summary?.loan_settled?.status ? '1EC365' : '818181'}]`} aria-hidden="true" role="presentation"></span>
                                                        {
                                                            summary?.loan_settled?.status
                                                                ?
                                                                <span className="flex-none block mx-4 mt-2 mb-2 text-center">
                                                                    <span className={`bg-[#1EC365] block w-[30px] h-[30px] m-auto mb-1 rounded-full`}></span>
                                                                    <strong className={`flex-grow block font-Averta text-[9px] text-blue/70`}>{summary?.loan_settled?.date}</strong>
                                                                </span>
                                                                :
                                                                <span className={`flex-none block mx-4 mt-2 mb-2 bg-[#C4C4C4] w-[30px] h-[30px] rounded-full`}></span>
                                                        }
                                                        <span className="flex-grow block m-auto w-[10px] border-t border-dashed border-transparent" aria-hidden="true" role="presentation"></span>
                                                    </h2>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pipeline